import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";

import { AuthService } from "./auth.service";
import { NavigationService } from './navigation.service';

@Injectable()
export class SuperAdminGuardService implements CanActivate {

  constructor(private readonly authService: AuthService, private readonly nav: NavigationService) { }

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    if (!this.authService.showVengresoAdminSection()) {
      this.nav.navigateTo(["/flyboard"]);
      return false;
    }

    const url: string = state.url;
    const roles = this.authService.getUserRole();

    if (roles.includes("Vengreso Admin")) {
      if (!url.includes("vengreso-admin") && !this.authService.onCompanyMasqueradeSession) {
        this.nav.navigateTo(["/vengreso-admin/users"]);
        return false;
      }
    } else {
      this.nav.navigateTo(["/flyboard"]);
      return false;
    }

    return true;
  }
}
