import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { COMPANIES_TABLE_COLUMNS } from '../../admin.data';
import { SharedDataService } from 'src/app/core/core-services/services/shared-data.service';

@Component({
  selector: 'app-customize-columns',
  templateUrl: './customize-columns.component.html',
  styleUrls: ['./customize-columns.component.scss']
})
export class CustomizeColumnsComponent implements OnInit {
  tableColumns = COMPANIES_TABLE_COLUMNS;
  
  constructor(
    public dialogRef: MatDialogRef<CustomizeColumnsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly sharedData: SharedDataService
  ) { }

  ngOnInit(): void {
    this.tableColumns = this.tableColumns.map(
       column => this.data.selectedColumns.some(c => c.key === column.key && c.checked) ? {...column, checked: true} : column
    );
  }

  get allChecked(): boolean {
    return this.tableColumns.filter(column => !column.required).every(column => column.checked);
  }

  get someChecked(): boolean {
    return this.tableColumns.filter(column => column.checked && !column.required).length > 0 && !this.allChecked;
  }

  checkAll(checked: boolean) {
    this.tableColumns.filter(column => !column.required).forEach(column => {
      column.checked = checked;
    });
  }

  save() {
    let headers = this.tableColumns.map(
      column => column.checked ? column : {...column, checked: false}
    );

    this.sharedData.setAttribute('cmc_companies_visible_columns', headers);
    
    this.close(headers);
  }

  close(data?: any) {
    this.dialogRef.close(data);
  }
}
