import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-check-item-completed',
  templateUrl: './check-item-completed.component.html',
  styleUrls: ['./check-item-completed.component.scss']
})
export class CheckItemCompletedComponent {
  @Input() active = false;
  @Input() checked = false;
  @Input() enable = false;
  @Input() step = 0;
  @Input() label = "";
  @Output() onClick = new EventEmitter<void>();

  constructor() { }

  setStep() {
    if (!this.enable) {
      return;
    }

    if (this.onClick) {
      this.onClick.emit();
    }
  }
}
