import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { AuthService, UserDetails } from "src/app/core/core-services/services/auth.service";
import { DialogService } from "src/app/core/core-services/services/dialog.service";
import { ExtensionService } from "src/app/core/core-services/services/extension.service";
import { SharedDataService } from "src/app/core/core-services/services/shared-data.service";
import { GlobalHookService } from "src/app/global-hook.service";
import { VideoComponent } from "../../video/video.component";
import { NgImageSliderComponent } from "ng-image-slider";
import { lastValueFrom } from "rxjs";
import { NgbPopover, NgbPopoverConfig } from "@ng-bootstrap/ng-bootstrap";
import { YouTubePlayer } from "@angular/youtube-player";
import { DataService } from "src/app/core/core-services/services/dataservices/data.service";

@Component({
  selector: "app-welcome-modal",
  templateUrl: "./welcome-modal.component.html",
  styleUrls: ["./welcome-modal.component.scss"],
})
export class WelcomeModalComponent implements OnInit {
  imageObject1: Array<object> = [
    {
      video: "https://www.youtube.com/embed/m7I0fVtJzqw",
      title: "3:07",
    },
    {
      video: "https://www.youtube.com/embed/WVYgnRXefLU",
      title: "1:13",
    },
    {
      video: "https://www.youtube.com/embed/gIWlxiAVnbg",
      title: "3:10",
    },
    {
      video: "https://www.youtube.com/embed/DtyZby0m2c8",
      title: "3:10",
    },
    {
      video: "https://www.youtube.com/embed/Uz2mgJrX5ys",
      title: "1:27",
    },
    {
      video: "https://www.youtube.com/embed/djgCUclifR0",
      title: "1:31",
    },
    {
      video: "https://www.youtube.com/embed/OHeN6xzqzA8",
      title: "1:19",
    },
  ];
  imageObject2: Array<object> = [
    {
      video: "https://www.youtube.com/embed/OHeN6xzqzA8",
      title: "1:20",
    },
    {
      video: "https://www.youtube.com/embed/vXS4S5ZtaL4",
      title: "0:57",
    },
    {
      video: "https://www.youtube.com/embed/iV0ub9bekIo",
      title: "1:10",
    },
    {
      video: "https://www.youtube.com/embed/gnZyQ6P1KA8",
      title: "1:55",
    },
    {
      video: "https://www.youtube.com/embed/y1gA5EMS20c",
      title: "1:20",
    },
    {
      video: "https://www.youtube.com/embed/GL07zxwJ5Zw",
      title: "1:01",
    },
    {
      video: "https://www.youtube.com/embed/QzuL9vARewU",
      title: "1:37",
    }
  ];
  @ViewChild('popover', { static: false }) popover!: NgbPopover;

  determinate: any;
  step = -1;
  progressValue: number;
  isInstalled = false;

  constructor(
    private readonly sharedService: SharedDataService,
    private readonly authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<WelcomeModalComponent>,
    public dialog: DialogService,
    private readonly router: Router,
    private readonly extensionService: ExtensionService,
    private readonly globalHookService: GlobalHookService,
    public sharedData: SharedDataService,
    private readonly dataService: DataService,
    config: NgbPopoverConfig,
  ) {
    config.container = "body";
    config.triggers = "manual";
    config.placement = ["bottom-start"];
    config.autoClose = false;
    config.animation = true;
    if (data.step !== undefined) {
      this.step = Math.min(data.step, this.maxStep);

      this.extensionService.isExtensionInstalled().then((isInstalled) => {
        this.isInstalled = isInstalled;
        if (this.step === 0 && this.isInstalled) {
          this.step++;
          this.adjustModalWidth();
          lastValueFrom(this.authService.patchOnboarding(
            this.user.id, 
            this.step,
            this.maxStep + 1,
            undefined,
            false
          ));
        }
      });
    }
  }

  get user() {
    return this.authService.userDetails.user_details;
  }

  ngOnInit(): void {
    let onboarding_percentage = this.sharedService.getAttribute(
      "onboarding_percentage"
    );
    this.progressValue =
      onboarding_percentage || this.user?.onboarding_percentage || 0;
    this.adjustModalWidth();
    this.extensionService.isExtensionInstalled().then((isInstalled) => {
      this.isInstalled = isInstalled;
      if (this.step === 0 && this.isInstalled) {
        this.step++;
        this.adjustModalWidth();
        lastValueFrom(this.authService.patchOnboarding(
          this.user.id, 
          this.step,
          this.maxStep + 1,
          undefined,
          false
        ));
      }
    });
  }

  newOnboardingPresentationShowMe() {
    this.navigateToOnboarding();
    lastValueFrom(this.authService.patchOnboarding(
      this.user.id, 
      this.authService.userDetails.user_details.onboardingv2_step,
      this.maxStep + 1,
      undefined,
      false,
      true
    ));
  }

  newOnboardingPresentationDismiss() {
    this.popover.close();
    lastValueFrom(this.authService.patchOnboarding(
      this.user.id, 
      this.authService.userDetails.user_details.onboardingv2_step,
      this.maxStep + 1,
      undefined,
      false,
      true
    ));
  }

  backToOnboarding() {
    this.setStep(Math.min(this.authService.userDetails.user_details.onboardingv2_step, this.maxStep));
  }
  //#region Video players
  @ViewChild('step1Video', { static: false }) step1Video!: YouTubePlayer;
  @ViewChild('step2Video', { static: false }) step2Video!: YouTubePlayer;
  @ViewChild('step3Video', { static: false }) step3Video!: YouTubePlayer;
  @ViewChild('step4Video', { static: false }) step4Video!: YouTubePlayer;
  @ViewChild('step5Video', { static: false }) step5Video!: YouTubePlayer;
  @ViewChild('step6Video', { static: false }) step6Video!: YouTubePlayer;
  step1VideoDuration: number = 0;
  step2VideoDuration: number = 0;
  step3VideoDuration: number = 0;
  step4VideoDuration: number = 0;
  step5VideoDuration: number = 0;
  step6VideoDuration: number = 0;
  step1WatchedPercentage: number = 0;
  step2WatchedPercentage: number = 0;
  step3WatchedPercentage: number = 0;
  step4WatchedPercentage: number = 0;
  step5WatchedPercentage: number = 0;
  step6WatchedPercentage: number = 0;
  step1Interval: any;
  step2Interval: any;
  step3Interval: any;
  step4Interval: any;
  step5Interval: any;
  step6Interval: any;

  onPlayerReady(event: any, step: number) {
    setTimeout(() => {
      this[`step${step}VideoDuration`] = this[`step${step}Video`].getDuration();
      this[`step${step}Video`].setVolume(20);
    }, 200);
  }

  onStateChange(event: any, step: number) {
    if (this.user.onboardingv2_step > step) {
      return;
    }

    if (event.data === 1 || event.data === 3) {
      this.startTracking(step);
    } else {
      clearInterval(this[`step${step}Interval`]);
    }
  }

  startTracking(step: number) {
    const videoPlayer = this[`step${step}Video`];
    clearInterval(this[`step${step}Interval`]);

    if (this[`step${step}WatchedPercentage`] >= 75) {
      return;
    }

    this[`step${step}Interval`] = setInterval(() => {
      if (videoPlayer) {
        const currentTime = videoPlayer.getCurrentTime();
        this[`step${step}WatchedPercentage`] = (currentTime / this[`step${step}VideoDuration`]) * 100;
        console.log(`🎥 Wathed: ${this[`step${step}WatchedPercentage`].toFixed(2)}%`);

        if (this[`step${step}WatchedPercentage`] >= 75) {
          lastValueFrom(this.authService.patchOnboarding(
            this.user.id,
            step + 1,
            this.maxStep + 1,
            undefined,
            false
          ));
          clearInterval(this[`step${step}Interval`]);
        }
      }
    }, 2000);
  }
  //#endregion Video players

  get walkthroughProgress() {
    const total = this.maxStep+1;
    const complete = Math.min((this.authService.userDetails.user_details.onboardingv2_step ?? 0), total);
    return `${complete}/${total}`;
  }

  adjustModalWidth() {
    switch (this.step) {
      case 0:
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
        if (this.dialogRef?.componentInstance?.dialogRef) {
          this.dialogRef.componentInstance.dialogRef.updateSize("800px");
        }
        break;
      default:
        if (this.dialogRef?.componentInstance?.dialogRef) {
          this.dialogRef.componentInstance.dialogRef.updateSize("400px");
        }
        break;
    }
    setTimeout(() => {
      if (!this.authService.userDetails.user_details.onboardingv2_presented && this.popover) {
        this.popover.open();
      }
    }, 2000);
    this.clearVideosInterval();
  }

  setStep(step: number) {
    this.step = Math.min(step, this.maxStep);
    this.adjustModalWidth();
  }

  clearVideosInterval() {
    setTimeout(() => {
      clearInterval(this.step1Interval);
      clearInterval(this.step2Interval);
      clearInterval(this.step3Interval);
      clearInterval(this.step4Interval);
      clearInterval(this.step5Interval);
      clearInterval(this.step6Interval);
    }, 1000);
  }

  async next() {
    this.clearVideosInterval();
    let step = this.step;
    step++;
    if (step === 0) {
      const isInstalled = await this.extensionService.isExtensionInstalled(); 
      this.isInstalled = isInstalled;
      if (this.isInstalled) {
        step++;
      }
    }

    await lastValueFrom(this.authService.patchOnboarding(this.user.id, step, this.maxStep + 1));
    this.step = Math.min(step, this.maxStep);
    this.adjustModalWidth();
    if (step > this.maxStep && this.globalHookService.components?.topBar) {
      this.close();
      this.globalHookService.components.topBar.showLearningResourcesTooltip();
    }
  }
  
  goToFlyLearning() {
    let user = this.authService.userDetails;
    let email = user.user_details?.email;

    const data = { Email: email };
    const url = "v1/authenticateUser";
    this.dataService
      .post({ url, data, isLoader: true })
      .subscribe(async (response: any) => {
        if (response?.result) {
          let redirect_url = response.result.redirect_url
          if (redirect_url) {
            window.open(redirect_url, "_blank").focus();
            this.next();
          }
        }
      });

  }

  get maxStep() {
    return this.showFlyLearning ? 6 : 5;
  }

  get showFlyLearning() {
    let planIdType = this.sharedData.getAttribute("planIdType") ?? this.sharedData.getAttribute("planId");

    return !!planIdType && ["sales-pro-yearly", "sales-pro-monthly", "pro-plan-teams-smb", "pro-plan-teams-ent"].includes(planIdType);
  }

  navigateToMoreLearningVideos() {
    this.step = 8;
  }

  tryAddAFlyCut() {
    this.next();
    this.close();
    if (this.globalHookService.components.dashboard) {
      this.globalHookService.components.dashboard?.startWalkMeStepOne();
    }
  }

  navigateToExtension() {
    let site =
      "https://chrome.google.com/webstore/detail/fly-msg-for-chrome/giidlnpcdhcldhfccdhkaicefhpokghc?hl=en&authuser=0";
    if (this.sharedData.getAttribute("browser_type") == "edge") {
      site =
        "https://microsoftedge.microsoft.com/addons/detail/flymsg-text-expansion-to/mmgiiedikndnbnjnhpgjdgoacimlhdkn";
    }
    window.open(site, "_blank");
  }

  tryItOnLinkedIn() {
    this.next();
    window.open("https://www.linkedin.com", "_blank");
  }

  tryAddAFlyPlate() {
    this.next();
    this.close();
    if (this.globalHookService.components.dashboard) {
      this.globalHookService.components.menu?.startWalkMeStepTree();
    }
  }

  playVideo1(videoIndex: number) {
    this.dialog.openDialogComponent(
      VideoComponent,
      { self: this, class: "video-dialog", link: this.imageObject1[videoIndex] },
      "800px"
    );
  }
  playVideo2(videoIndex: number) {
    this.dialog.openDialogComponent(
      VideoComponent,
      { self: this, class: "video-dialog", link: this.imageObject2[videoIndex] },
      "800px"
    );
  }

  tryTestAFlyCut() {
    this.next();
    this.close();
    if (this.globalHookService.components.dashboard) {
      this.globalHookService.components.dashboard?.startWalkMeStepTwo();
    }
  }

  tryFlyCutLater() {
    this.next();
  }

  tryFlyEngageLater() {
    this.next();
  }

  tryFlyPostLater() {
    this.next();
  }

  @ViewChild("nav1") slider1: NgImageSliderComponent;
  @ViewChild("nav2") slider2: NgImageSliderComponent;

  prevImageClick() {
    this.slider1.prev();
    this.slider2.prev();
  }

  nextImageClick() {
    this.slider1.next();
    this.slider2.next();
  }

  navigateToOnboarding(action?: string) {
    if (action === "retake") {
      this.dialogRef.close({ action: "retake_onboarding" });
      this.router.navigate([`/onboarding`], {});
      return;
    }

    this.dialogRef.close({ action: "retake_onboarding" });
    let user = this.authService.userDetails;
    let step = this.sharedService.getAttribute("onboarding_step");

    const onboarding_step = step || user.user_details.onboarding_step || "";
    if (onboarding_step === "10") {
      this.router.navigate([`/onboarding`], {});
    } else {
      this.router.navigate([`/onboarding${onboarding_step}`], {});
    }
  }

  close(end: boolean = false) {
    if (end) {  
      this.globalHookService.onboardingActive = false;

      if (this.globalHookService.components?.topBar) {
        this.close();
        this.globalHookService.components.topBar.showLearningResourcesTooltip();
      }
    }

    this.dialogRef.close(this.step);
  }
}
