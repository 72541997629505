import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, FormControl } from "@angular/forms";
import { RxwebValidators } from "@rxweb/reactive-form-validators";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { distinctUntilChanged } from "rxjs/operators";

import { ExtensionService } from "src/app/core/core-services/services/extension.service";
import { DataService } from "src/app/core/core-services/services/dataservices/data.service";
import { NotificationService } from "src/app/core/core-services/services/notification.service";
import { CategoryRenameComponent } from "../../shortcuts/add-shortcut-category/category-rename/category-rename.component";
import { DialogService } from "src/app/core/core-services/services/dialog.service";
import { ShortcutCategoryService, SHORTCUT_CATEGORIES } from "src/app/core/core-services/services/shortcut-category.service";
import { SharedDataService } from "src/app/core/core-services/services/shared-data.service";
import { DynamicStorage } from "src/app/core/core-services/decorators/dynamic-storage";

@Component({
  selector: "app-template-category-copy",
  templateUrl: "./template-category-copy.component.html",
  styleUrls: ["./template-category-copy.component.scss"]
})
export class TemplateCategoryCopyComponent implements OnInit {

  public form: FormGroup;
  @DynamicStorage(SHORTCUT_CATEGORIES) shortcutCategoryList: any[];
  filteredCategoryList: any;
  filterCtrl = new FormControl();
  exactMatch: boolean = false;
  @ViewChild("selectElem") selectElem: any;
  @ViewChild("matSelectElem") matSelectElem: any;

  constructor(
    private readonly fb: FormBuilder,
    private readonly dataService: DataService,
    private readonly notification: NotificationService,
    private readonly extensionService: ExtensionService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TemplateCategoryCopyComponent>,
    public dialog: DialogService,
    private readonly sharedData: SharedDataService,
    private readonly shortcutCategoryService: ShortcutCategoryService
  ) { }

  ngOnInit(): void {
    this.getShortcutCategory();

    this.form = this.fb.group({
      template_category_id: [this.data.self.categoryId],
      category_id: [null, [RxwebValidators.required()]]
    });

    this.filterCtrl.valueChanges.subscribe(() => {
      this.filterCategories();
    });
    
    this.sharedData.storage.observe(SHORTCUT_CATEGORIES)
    .pipe(
      distinctUntilChanged(
        (prev, curr) => prev.value === curr.value
      )
    )
    .subscribe((value) => {
      this.shortcutCategoryList = value;
      this.filteredCategoryList = [...this.shortcutCategoryList];
    });

    this.filteredCategoryList = [...this.shortcutCategoryList];
  }

  filterCategories() {
    this.exactMatch = false;
    let search = this.filterCtrl.value;
    search = search.toLowerCase();
    if (!search) {
      this.filteredCategoryList = [...this.shortcutCategoryList];
      return;
    }

    let filteredCategoryList = [];
    for (const o of this.shortcutCategoryList) {
      let catSearch = o.name.toLowerCase().indexOf(search) > -1;
      if (catSearch) {
        this.exactMatch = search === o.name.toLowerCase() ? true : false;
        filteredCategoryList.push({ ...o, sub_category_lv1: [] });
        continue;
      }
      for (const sub_category_lv1 of o.sub_category_lv1) {
        let catSearch =
          sub_category_lv1.name.toLowerCase().indexOf(search) > -1;
        if (catSearch) {
          this.exactMatch =
            search === sub_category_lv1.name.toLowerCase() ? true : false;
          filteredCategoryList.push({
            ...sub_category_lv1,
            sub_category_lv1: [],
            name: `${o.name} / ${sub_category_lv1.name}`,
          });
          continue;
        }
        for (const sub_category_lv2 of sub_category_lv1.sub_category_lv2) {
          let catSearch =
            sub_category_lv2.name.toLowerCase().indexOf(search) > -1;
          if (catSearch) {
            this.exactMatch =
              search === sub_category_lv2.name.toLowerCase() ? true : false;
            filteredCategoryList.push({
              ...sub_category_lv2,
              sub_category_lv1: [],
              name: `${o.name} / ${sub_category_lv1.name} / ${sub_category_lv2.name}`,
            });
            continue;
          }
        }
      }
    }
    this.filteredCategoryList = filteredCategoryList;
  }

  getShortcutCategory(reloadFromApi = false) {
    this.shortcutCategoryService.loadShortcutCategory({reloadFromApi});
  }

  saveCategory(data) {
    this.dataService.post({ url: "v1/user/shortcut-category", data }).subscribe(
      (response: any) => {
        if (response && response.result) {          
          this.extensionService.sendUpdateMessage();
          this.getShortcutCategory(true);
          this.data.self.categoryId = response.result
          let newlyCreatedCategoryId = this.data.self?.categoryId
          if(this.form && newlyCreatedCategoryId){
            this.form.patchValue({category_id: newlyCreatedCategoryId})
            this.matSelectElem.close()
          }
          this.notification.toast("Category added successfully!");
        }
      },
      () => {}
    );
  }

  close(): void {
    this.dialogRef.close();
  }

  copyAllCategoryTemplateToShortcut(data: any) {
    this.dataService.post({ url: "v1/user/copy-shortcut/category/single", data }).subscribe((response: any) => {
      if (response && response.result) {
        this.notification.toast("Templates added successfully.");
        this.extensionService.sendUpdateMessage();
        this.data.self.listService.getList(true);
        this.close();
      }
    });
  }

  shortcutCategoryRename(data: any) {
    this.dialog.openDialogComponent(CategoryRenameComponent, {
      self: this,
      data,
    });
  }
}
