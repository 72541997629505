import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService, UserDetails } from '../../core/core-services/services/auth.service';
import { getPlanId } from "src/app/core/core-services/constants/plans.constants";
import { NotifyService } from '../../core/menu/not.service';
import { NotificationService } from '../../core/core-services/services/notification.service';
import { Role } from '../../core/core-services/constants/roles.constant';
import { SharedDataService } from 'src/app/core/core-services/services/shared-data.service';

@Component({
  selector: 'app-cmc',
  templateUrl: './cmc.component.html',
  styleUrls: ['./cmc.component.scss']
})
export class CMCComponent implements OnInit {
  user: UserDetails;
  planId: any;
  mainNav: string = '';
  navItems = [];
  
  constructor (
    public authService: AuthService,
    public sharedData: SharedDataService,
    public notifyService: NotifyService,
    private readonly router: Router,
    private readonly notification: NotificationService
  ) { }

  get userRole(): Role {
    const roles = this.authService.getUserRole();

    return roles.sort((a, b) => {
      if (a === 'Vengreso Admin') return -1;
      if (b === 'Vengreso Admin') return 1;
      if (a === 'Global Admin') return -1;
      if (b === 'Global Admin') return 1;
      if (a === 'Group Admin') return -1;
      if (b === 'Group Admin') return 1;
      if (a === 'Reporting Admin') return -1;
      if (b === 'Reporting Admin') return 1;
      return 0;
    })[0];
  }

  ngOnInit(): void {
    this.user = this.authService.userDetails.user_details;
    this.planId = getPlanId();

    const roles = this.authService.getUserRole();

    if (roles.includes('Vengreso Admin')) {
      this.setVengresoAdminNav();
    } else {
      this.goToDashboard();
    }
  }

  copyToClipboard(email: string): void {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(email).then(() => {
        this.notification.toast("Email copied to clipboard!", "Ok", 2000);
      });
    }
  }

  setVengresoAdminNav(){
    this.mainNav = 'Client Mgmt Center';
    this.navItems = [
      { label: 'Users', link: '/vengreso-admin/users' },
      { label: 'Companies', link: '/vengreso-admin/companies' },
      { label: 'Reporting', link: '/vengreso-admin/reporting' }
    ];
  }

  goToDashboard(): void {
    this.router.navigate(["/flyboard"]);
  }

  goToSettings(): void {
    this.router.navigate(["/settings"], {
      queryParams: { tab: "subscription" },
    });
  }

  goToPlayList(): void {
    window.open("https://www.youtube.com/@Vengreso/playlists", "_blank");
  }

  navigateToExtension() {
    let site =
      "https://chrome.google.com/webstore/detail/fly-msg-for-chrome/giidlnpcdhcldhfccdhkaicefhpokghc?hl=en&authuser=0";
    if (this.sharedData.getAttribute("browser_type") == "edge") {
      site =
        "https://microsoftedge.microsoft.com/addons/detail/flymsg-text-expansion-to/mmgiiedikndnbnjnhpgjdgoacimlhdkn";
    }
    window.open(site, "_blank");
  }
}
