import { Injectable } from "@angular/core";
import { HeapService } from "./heap.service";
import { GTMService } from "./gtm.service";

export interface IAnalyticsService {
  track(eventName: string, eventProps: any): Promise<void>;
  identify(user_id: string): Promise<void>;
  addUserProperties(properties: { [key: string]: any }): Promise<void>;
  identity(): Promise<string>;
  userId(): Promise<string>;
}

@Injectable({
  providedIn: "root",
})
export class AnalyticsService {
  constructor(
    private readonly heapService: HeapService,
    private readonly gtmService: GTMService
  ) {}

  async track(eventName: string, eventProps: any) {
    this.heapService.track(eventName, eventProps);
    this.gtmService.track(eventName, eventProps);
  }

  async identify(identity) {
    this.heapService.identify(identity);
    this.gtmService.identify(identity);
  }
  
  async addUserProperties(properties: {
    [key: string]: any;
  }) {
    this.heapService.addUserProperties(properties);
    this.gtmService.addUserProperties(properties);
  }

  async identity() {
    return {
      heap: await this.heapService.identity(),
      gtm: await this.gtmService.identity(),
    }
  }

  async userId() {
    return {
      heap: await this.heapService.userId(),
      gtm: await this.gtmService.userId(),
    }
  }
}
