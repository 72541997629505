import { Component, OnInit } from "@angular/core";
import { DialogService } from "src/app/core/core-services/services/dialog.service";
import { MetaService } from "src/app/core/core-services/services/meta.service";
import { SharedDataService } from "src/app/core/core-services/services/shared-data.service";
import { FlyCutInviteComponent } from "../../share/fly-cut-invite/fly-cut-invite.component";
import { Store } from "@ngrx/store";
import { PlanState } from "src/app/core/store/plan/plan.reducer";
import { AuthService } from "src/app/core/core-services/services/auth.service";

@Component({
  selector: "app-flyshare-rewards",
  templateUrl: "./flyshare-rewards.component.html",
  styleUrls: ["./flyshare-rewards.component.scss"],
})
export class FlyshareRewardsComponent implements OnInit {
  firstPersonSignedUp: any;
  secondPersonSignedUp: any;
  thirdPersonSignedUp: any;
  fourthPersonSignedUp: any;
  currentPlan$ = this.store.select(state => state.data);

  constructor(
    private readonly dialog: DialogService,
    private readonly sharedData: SharedDataService,
    private readonly authService: AuthService,
    private readonly metaService:MetaService,
    private readonly store: Store<PlanState>,
  ) {}

  ngOnInit(): void {
    this.currentPlan$
      .subscribe(({data: currentPlan}: any) => {
          if (currentPlan?.referrals) {
            const referrals = currentPlan.referrals;
            if (referrals[0]) {
              this.firstPersonSignedUp = JSON.parse(
                referrals[0]
              );
            }
            if (referrals[1]) {
              this.secondPersonSignedUp = JSON.parse(
                referrals[1]
              );
            }
            if (referrals[2]) {
              this.thirdPersonSignedUp = JSON.parse(
                referrals[2]
              );
            }
            if (referrals[3]) {
              this.fourthPersonSignedUp = JSON.parse(
                referrals[3]
              );
            }
          }

          if (currentPlan?.referral_key) {
            let user = this.authService.userDetails;
            user.user_details.referral_key = currentPlan?.referral_key;
            this.authService.saveUserDetail(user);
          }
        }
    );
    
    this.metaService.addMetaTag(
      'FlyMSG Rewards Page',
      "Share the love and earn rewards when you refer others to FlyMSG and they sign up! Help them to save 1-hour a day too!");
  }

  inviteMore(): void {
    this.dialog.openDialogComponent(FlyCutInviteComponent, {parentPage: 'subscription'}, "859px");
  }
}
