import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

export interface ConfirmationDialogData {
  message: string;
  title: string;
  subtitle?: string;
  buttonConfirm?: string;
  buttonCancel?: string;
}

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent {
  message: SafeHtml;
  title: string;
  subtitle: SafeHtml;
  buttonConfirm: string;
  buttonCancel: string;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { data: ConfirmationDialogData},
    private readonly sanitizer: DomSanitizer
  ) {
    this.message = this.sanitizer.bypassSecurityTrustHtml(this.data.data.message);
    this.title = this.data.data.title;
    if (this.data.data.subtitle) {
      this.subtitle = this.sanitizer.bypassSecurityTrustHtml(this.data.data.subtitle);
    }
    this.buttonConfirm = this.data.data.buttonConfirm;
    this.buttonCancel = this.data.data.buttonCancel;
   }

   success() {
    this.close(true);
  }

  close(confirm?: boolean) {
    this.dialogRef.close(confirm);
  }
}
