import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";

import { AuthService } from "./auth.service";
import { NavigationService } from "./navigation.service";
import { LoadingService } from "./loader.service";
import { SharedDataService } from "./shared-data.service";
import { MasqueradeUserResponse } from "src/app/admin/services/users/users-dto";
import { ExtensionService } from "./extension.service";

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(
    private readonly authService: AuthService,
    private readonly sharedData: SharedDataService,
    private readonly nav: NavigationService,
    private readonly loadingService: LoadingService,
    private readonly extensionService: ExtensionService,
  ) {
    this.loadingService.start();
    setTimeout(() => {
      this.loadingService.stop();
    }, 200);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const { session } = route.queryParams;
    const currentId = sessionStorage.getItem('fms-cmc-masquerading-user-id');
    if (session || currentId) {
      const userDetail = this.sharedData.getAttribute(session ?? currentId) as MasqueradeUserResponse;

      if (userDetail) {
        sessionStorage.setItem('fms-cmc-masquerading-user-id', userDetail.user_details.id);
        sessionStorage.setItem('fms-cmc-masquerading-user-details', JSON.stringify(userDetail));
        this.sharedData.deleteAttribute(session ?? currentId);
        this.nav.navigateTo([state.url.split('?')[0]]);

        this.extensionService.sendMasqueradeUser(userDetail.user_details.id)
          .catch(() => {
            alert('Failed to send masquerade user to extension');
          });
        return true;
      }
    }

    const url: string = state.url;

    return this.checkLogin(url);
  }

  checkLogin(url?: string): boolean {

    if (this.isLoggedIn()) {
      return true;
    }

    // Store the attempted URL for redirecting
    if (url)
      this.authService.redirectUrl = url;

    // Navigate to the login page with extras
    if (!window.document.URL.includes('session')) {
      window.open(window.document.baseURI + 'session/signin', '_self');
    }
    return false;
  }

  validateStatus() {
    setTimeout(() => {
      if (this.checkLogin()) {
        if (window.document.URL.includes('signin')) {
          window.open(window.document.baseURI + 'flyboard', '_self');
          return
        }
      } else
        this.validateStatus()
    }, 3000);
  }

  isLoggedIn() {
    return Boolean(
      this.authService.accessToken &&
      this.authService.userDetails
    );
  }
}
