import { Injectable } from "@angular/core";
import { Location } from "@angular/common";
import { Router, ActivatedRoute } from "@angular/router";

@Injectable()

export class NavigationService {
    constructor(private location: Location, private router: Router, private route: ActivatedRoute) { }

    back() {
        return this.location.back();
    }

    forward() {
        return this.location.forward();
    }

    navigateTo(routeName: any[], extras = {}) {
        this.router.navigate(routeName, extras);
    }

    getParam(param: string | number) {
        return this.route.snapshot.params[param];
    }

    navigateToUrl(site: any) {
        return window.open(site, "_blank");
    }
}
