import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { NotificationService } from "src/app/core/core-services/services/notification.service";

import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { catchError, of } from "rxjs";
import { NgbPopoverConfig } from "@ng-bootstrap/ng-bootstrap";
import { DialogService } from "src/app/core/core-services/services/dialog.service";
import { SuperAdminApiService } from "../../services/super-admin-api.service";
import { CMCCompany } from "../admin-companies.component";

@Component({
  selector: "app-bulk-deactivate-companies",
  templateUrl: "./bulk-deactivate-companies.component.html",
  styleUrls: ["./bulk-deactivate-companies.component.scss"],
})
export class BulkDeactivateCompaniesComponent implements OnInit {
  dataSource: MatTableDataSource<CMCCompany>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = [
    "select",
    "name",
    "actions",
  ];

  companies: CMCCompany[] = [];

  constructor(
    public dialogRef: MatDialogRef<BulkDeactivateCompaniesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      companies: CMCCompany[];
    },
    private readonly notification: NotificationService,
    private readonly apiService: SuperAdminApiService,
    public dialog: DialogService,
    config: NgbPopoverConfig,
  ) {
    this.companies = data.companies;
    config.triggers = "hover";
    config.container = "body";
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.companies);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  get hasSelectedCompanies(): boolean {
    return this.dataSource.data.some(company => company.checked);
  }

  deactivateCompanies(): void {
    const apiData = this.dataSource.data
      .filter(company => company.checked)
      .map(company => company.id);

    if (apiData.length === 0) { 
      this.close();
      return;
    }

    this.apiService.deactivateCompany(apiData)
      .pipe(
        catchError((error) => {
          let message = "An error occurred while deactivating companies";
          if (error?.error?.result?.message) {
            message = error.error.result.message;
          }
          this.notification.toastWithConfig(message, null, {
            verticalPosition: "top",
            horizontalPosition: "center",
            panelClass: ["red-notification"],
          });
          return of({ error: true, message: message });
        })
      )
      .subscribe((response: any) => {
        if (response?.result?.success) {
          const message = "Companies deactivated successfully";
          this.notification.toastWithConfig(message, null, {
            verticalPosition: "top",
            horizontalPosition: "center",
            panelClass: ["red-notification"],
          });
          this.close(apiData);
        } else {
          this.close();
        }
      });
  }

  removeInConfirmationView(element: CMCCompany): void {
    this.dataSource.data = this.dataSource.data.filter(company => company.id !== element.id);

    if (this.paginator) {
      this.paginator.firstPage();
    }

    this.dataSource.paginator = this.paginator;

    if (this.dataSource.data.length === 0) {
      this.close();
    }
  }

  close(data?: string[]): void {
    this.dialogRef.close(data);
  }
}
