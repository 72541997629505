import { Component, OnInit } from '@angular/core';
import { Location } from "@angular/common";
import { MatDialogRef } from '@angular/material/dialog';
import { AdminApiService } from '../../services/admin-api.service';
import { NotificationService } from 'src/app/core/core-services/services/notification.service';
import { AuthService } from 'src/app/core/core-services/services/auth.service';
import { FormBuilder, FormGroup, Validators, } from '@angular/forms';
import { lastValueFrom } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-vengreso-reactivate-user',
  templateUrl: './reactivate-user.component.html',
  styleUrls: ['./reactivate-user.component.scss']
})
export class VengresoReactivateUserComponent implements OnInit {
  form: FormGroup;
  invalidEmail: boolean = false;
  plans: any = [
    {
      value: "Starter",
      label: "Starter",
      licensesAvailable: 0
    },
    {
      value: "Growth",
      label: "Growth",
      licensesAvailable: 0
    },
    {
      value: "Sales Pro",
      label: "Sales Pro",
      licensesAvailable: 0
    },
    {
      value: "Sales Pro Teams",
      label: "Sales Pro Teams",
      licensesAvailable: 0
    }
  ];
  
  constructor(
    public dialogRef: MatDialogRef<VengresoReactivateUserComponent>,
    private readonly adminApiService: AdminApiService,
    private readonly notification: NotificationService,
    private readonly authService: AuthService,
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly location: Location
  ) { }

  ngOnInit(): void {
    this.adminApiService.getCompanyLicenses().subscribe({
      next: (response: any) => {
        this.plans = response.result.licenses;
      }, 
      error: (error) => console.log(error)
    });

    this.form = this.fb.group({
      first_name: ['', [Validators.required]],
      last_name: [ '',[Validators.required]],
      email: ['',[Validators.required, Validators.email]],
      selected_plan: ['', [Validators.required]],
    });
  }

  get isVengresoAdmin(): boolean {
    return this.authService.getUserRole() === "Vengreso Admin";
  }

  async onAdd() {
    const data = this.form.value;

    const response: any =  await lastValueFrom(this.adminApiService.validateUserEmailUniqueness(data.email));

    if (response && response.result && response.result.exists === true) {
      this.invalidEmail = true;
      this.form.controls['email'].setErrors({ invalid: true });
      return;
    } else {
      this.invalidEmail = false;
      this.form.controls['email'].setErrors(null);
    }

    this.adminApiService.addUserManually(data).subscribe({
      next: (response: any) => {
        if (response && response.result && response.result.success == true) {
          this.notification.toastWithConfig("User added", null, {
            verticalPosition: "top",
            horizontalPosition: "center",
            panelClass: ["green-notification"],
          });

          this.close(response.result.user);
        }
      },
      error: (error) => console.log(error)
    });
  }

  ngOnDestroy(): void {
    this.location.replaceState(
      this.router.url.replace("/add-manually", "")
    );
  }

  close(data?: any) {
    this.location.replaceState(
      this.router.url.replace("/add-manually", "")
    );
    this.dialogRef.close(data);
  }
}
