import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { SocialAuthService } from "@abacritt/angularx-social-login";
import { cloneDeep, forEach } from "lodash";
import { Observable, catchError, map, of, throwError } from "rxjs";
import { Store } from "@ngrx/store";
import { ActivatedRoute } from "@angular/router";
import { detect } from "detect-browser";

import { StripeService } from "src/app/core/core-services/services/stripe.service";
import { environment } from "src/environments/environment";
import { DataService } from "./dataservices/data.service";
import { ExtensionService } from "./extension.service";
import { LoadingService } from "./loader.service";
import { NavigationService } from "./navigation.service";
import { NotificationService } from "./notification.service";
import { SharedDataService } from "./shared-data.service";
import { NotifyService } from "../../menu/not.service";

import { PlanState } from "src/app/core/store/plan/plan.reducer";
import { loadUserCurrentSubscriptionApi, loadUserCurrentSubscriptionApiFailure, loadUserCurrentSubscriptionApiSuccess } from "src/app/core/store/plan/plan.actions";
import { Role, ROLES } from "../constants/roles.constant";
import { DialogService } from "./dialog.service";
import { SignInErrorComponent } from "src/app/session/signin/sign-in-error/sign-in-error.component";
import { MasqueradeUserResponse } from "src/app/admin/services/users/users-dto";
import { AnalyticsService } from "src/app/analytics.service";

declare var profitwell: any;

export interface UserDetails {
  first_name: string;
  last_name: string;
  email: string;
  avatar: string;
  updated_at: string;
  created_at: string;
  email_verified_at: string;
  last_login: string;
  hubspot_id: string;
  onboardingv2_presented: boolean;
  onboardingv2_step: number;
  onboarding_percentage: number;
  onboarding_step: string;
  onboarding: string;
  referral_key: string;
  referrals_count: number;
  rewardable: number;
  rewards_level: number;
  average_charactors_count: number;
  recently_used_get_updated: boolean;
  team_id: string[];
  company_group_id: string;
  activation_date: string;
  company_id: string;
  status: string;
  instancy_id: string;
  is_beta: boolean;
  role: string;
  company: {
    name: string;
    slug: string;
    updated_at: string;
    created_at: string;
    instancy_id: string;
    id: string;
  };
  id: string;
}

@Injectable()
export class AuthService {
  redirectUrl: string;
  constructor(
    private readonly dialogRef: MatDialog,
    public dialog: DialogService,
    private readonly nav: NavigationService,
    private readonly dataService: DataService,
    private readonly loaderService: LoadingService,
    private readonly sharedData: SharedDataService,
    private readonly socialService: SocialAuthService,
    private readonly notification: NotificationService,
    private readonly extensionService: ExtensionService,
    private readonly stripeService: StripeService,
    public notifyService: NotifyService,
    private readonly route: ActivatedRoute,
    private readonly store: Store<PlanState>,
    private readonly analyticsService: AnalyticsService,
  ) {
    // Setting default route to navigate after login
    this.redirectUrl = "/flyboard";
    this.route.queryParams.subscribe((params) => {
      if (params.redirect) {
        if (params.redirect === "starter") {
          this.redirectUrl = "/new-plan/Starter";
        }
        if (params.redirect == "yearly-starter") {
          this.redirectUrl = "/new-plan/YearlyStarter";
        }

        if (params.redirect == "growth") {
          this.redirectUrl = "/new-plan/Growth";
        }

        if (params.redirect == "yearly-growth") {
          this.redirectUrl = "/new-plan/YearlyGrowth";
        }

        if (params.redirect == "sales-pro") {
          this.redirectUrl = "/new-plan/SalesPro";
        }

        if (params.redirect == "yearly-sales-pro") {
          this.redirectUrl = "/new-plan/YearlySalesPro";
        }

        if (params.redirect == "freemium") {
          this.redirectUrl = "/flyboard";
        }
      }
    });
  }

  getAccessToken() {
    const maskUser = this.masqueradeUser;
    if (maskUser) {
      return maskUser.access_token;
    }

    return this.sharedData.getAttribute("accessToken");
  }

  login(userData: any) {
    if (userData.email) userData.email = userData.email.toLowerCase();
    const url = "v1/user/auth/login";
    this.dataService
      .post({
        url,
        data: { value: userData, noCredentials: true },
        isLoader: true,
      })
      .subscribe({
        next: async (response: any) => {
          const result = response.result;
          if (result) {
            this.analyticsService.track("login_email", {
              email: userData.email,
            });
            
            //Check if user with temporary password hasn't changed it
            const hasTemporaryPassword =
              result.user_details.hasOwnProperty("temp_password");

            if (hasTemporaryPassword) {
              await this.saveTemporaryUserDetail(result);
              if (result.user_details.is_poc) {
                this.redirectUrl = `${result.user_details.company.slug}/admin/users`;
              }
              this.nav.navigateTo(["/session/sign-in/set-new-password"]);
              return;
            }

            await this.saveUserDetail(result);
            this.extensionService.sendLoginMessage(url, result);

            if (this.redirectUrl.includes("instancyssorequested=true")) {
              this.nav.navigateTo(["/flyboard"], {
                queryParams: { instancyssorequested: "true" },
              });
              return;
            }

            if (
              result.user_details?.onboarding == "pending" ||
              !result?.user_details?.onboarding
            ) {
              if (this.redirectUrl.includes("activate_trial=true")) {
                this.stripeService.startSalesProTrial().subscribe({
                  next: (response: any) => {
                    this.nav.navigateTo([`/onboarding`]);
                    return;
                  },
                  error: (error) => {
                    console.log(error);
                  },
                });
              }
              this.nav.navigateTo([`/onboarding`]);
            } else {
              if (this.redirectUrl.includes("activate_trial=true")) {
                this.nav.navigateTo(["/new-plan/SalesPro"], {
                  queryParams: { activate_trial: "true" },
                });
                return;
              }
              this.nav.navigateTo([this.redirectUrl]);
            }

            profitwell("start", { user_email: result.user_details.email });
          } else {
            if (response.error) {
              this.notification.toast(response.error, "danger");
            }
          }
        },
        error: (error) => {
          if (error.status == 422) {
            if (error?.error?.code == "EXPIRED_LOGIN") {
              this.nav.navigateTo(["/session/expired-login"], {
                queryParams: { email: userData.email },
              });
              return;
            }

            if (error?.error?.code == "DEACTIVATED") {
              this.dialog.openDialogComponent(
                SignInErrorComponent,
                {
                  admin_email: error?.error?.admin_email,
                },
                "500px"
              );
            }
          }

          this.notification.toast(error?.error?.error, "danger");
        },
      });
  }

  isLoggedIn() {
    return Boolean(this.accessToken && this.userDetails);
  }

  refreshAccessToken(): Observable<any> {
    const data = { refresh_token: this.getRefreshToken() };
    return this.dataService.post({
      url: "v1/user/auth/refresh",
      data,
      isLoader: false,
    });
  }

  getRefreshToken() {
    return this.sharedData.getAttribute("refreshToken");
  }

  redirectAfterLogout() {
    var browser = detect();
    var browser_type;
    if (
      browser &&
      (browser?.name == "edge" || browser?.name == "edge-chromium")
    ) {
      browser_type = "edge";
    } else {
      browser_type = browser?.name;
    }
    this.dialogRef.closeAll();
    this.extensionService.sendLogoutMessage();
    this.sharedData.clear();
    this.stripeService.removePlanInformation();
    // Store browser type

    if (browser_type) {
      this.sharedData.setAttribute("browser_type", browser_type);
    }
    this.nav.navigateTo(["/session/signin"]);
    if (environment.production) {
      // Reset identity on heap before logout
      window.heap.resetIdentity();
    }
  }

  getUserRole(): Role[] {
    const userRoles = this.userDetails?.user_details?.role;

    const roles: Role[] = [];

    if (userRoles?.length > 0) {
      userRoles.split(",").forEach((role: Role) => {
        if (ROLES.includes(role)) {
          roles.push(role);
        }
      });
    }

    if (roles.length === 0) {
      roles.push("User");
    }

    return roles;
  }

  get companyId(): string {
    const sessionCompanyId = sessionStorage.getItem(
      "fms-cmc-masquerading-company-id"
    );
    if (sessionCompanyId) {
      return sessionCompanyId;
    }

    const user_details = this.userDetails.user_details;

    return user_details.company_id;
  }

  getUserCompanySlug(): string {
    const sessionCompanySlug = sessionStorage.getItem(
      "fms-cmc-masquerading-company-slug"
    );
    if (sessionCompanySlug) {
      return sessionCompanySlug;
    }

    const { company, user_details } = this.userDetails;

    return company ?? user_details.company.slug ?? "company";
  }

  showVengresoAdminSection(): boolean {
    const roles = this.getUserRole();

    return roles.includes("Vengreso Admin");
  }

  showAdminSection(): boolean {
    const roles = this.getUserRole();

    return roles.some((role) =>
      ["Group Admin", "Reporting Admin", "Global Admin"].includes(role)
    ) && this.userDetails.user_details.status !== "Invited";
  }

  logout(logoutReason: any = {}) {
    const userDetail = this.userDetails;
    if (userDetail.provider) {
      this.socialService.signOut().then((response: any) => {});
    }
    this.sharedData.deleteAttribute("email_to_verify");
    this.sharedData.deleteAttribute("timer_to_resend");

    this.dataService
      .post({ url: "v1/user/auth/logout", data: logoutReason })
      .subscribe((response: any) => {
        if (response && response.result) {
          this.redirectAfterLogout();
        }
      });

    this.redirectUrl = "/flyboard";
  }

  getNewTemporaryPassword(email: string) {
    return this.dataService.post({
      url: "v1/users/resend-temporary-password",
      data: { email },
      isLoader: true,
    });
  }

  resetTemporaryPassword(data: any) {
    return this.dataService.post({
      url: "v1/users/reset-invited-user-password",
      data: data,
      isLoader: true,
    });
  }

  resetPassword(userData: any) {
    const data = {
      value: userData,
      noCredentials: true,
    };

    this.dataService.post({ url: "password/email", data }).subscribe(
      (result: any) => {
        this.nav.navigateTo(["/login"]);
        this.notification.toast("Check Your Mail! Click on reset link.");
      },
      (error) => {
        console.log(error);
      }
    );
  }

  registration(val: any) {
    if (val.email) val.email = val.email.toLowerCase();
    this.loaderService.start();
    this.dataService
      .post({ url: "v1/user/auth/register", data: val, isLoader: false })
      .subscribe({
        next: async (response: any) => {
          // The user is logged in after the sign up.
          await this.saveUserDetail(response.result);
          if (this.redirectUrl.includes("activate_trial=true")) {
            this.stripeService.startSalesProTrial().subscribe({
              next: (response: any) => {
                this.nav.navigateTo([`/onboarding`]);
                return;
              },
              error: (error) => {
                console.log(error);
              },
            });
          }
          // Track the affiliate signup event
          if (typeof (window as any).gr === "function") {
            console.log("✅ gr function is defined");
            (window as any).gr("track", "conversion", {
              email: val.email.toLowerCase(),
            });
          } else {
            // Normally this means the gr function wasn't declared here,
            // try and look why the Tracking Script isn't running on this page
            console.log("⛔️ gr function is NOT defined");
          }

          if (response.result?.is_company_poc === true) {
            const companySlug = this.getUserCompanySlug();
            this.nav.navigateTo([`/${companySlug}/admin/users`]);
            this.loaderService.stop();
            return;
          }

          this.nav.navigateTo([`/onboarding`]);
          this.loaderService.stop();
        },
        error: ({ error }: any) => {
          this.loaderService.stop();
          let errors = [];
          forEach(error?.error_list, (array, key) => {
            errors = errors.concat(array);
          });
          if (
            errors.length === 1 &&
            errors[0] === "The email has already been taken."
          ) {
            this.notification.toast(
              "You already have an account under this email address. Please sign-in instead.",
              "ok",
              4000
            );
            return;
          }
          this.notification.toast(
            error?.result?.message ?? "Something went wrong",
            "ok",
            4000
          );
        },
      });
  }

  async saveTemporaryUserDetail(result: any) {
    this.sharedData.setAttribute("temporaryUserDetails", result);
  }

  async saveUserDetail(result: any) {
    this.sharedData.setAttribute("userDetails", result);
    if (result.access_token) {
      this.sharedData.setAttribute("accessToken", result.access_token);
    }
    if (result.refresh_token) {
      this.sharedData.setAttribute("refreshToken", result.refresh_token);
    }
    if (this.masqueradeUser) {
      this.sharedData.setAttribute(this.masqueradeUser.user_details.id, result);
    }
    this.store.dispatch(loadUserCurrentSubscriptionApi({}));
  }

  updateUserDetail() {
    this.dataService
      .get({ url: "v1/user", isLoader: false })
      .subscribe((response: any) => {
        if (response?.result) {
          let previousUserDetail = this.userDetails;
          let { is_password_set, ...userDetails } = response.result;
          let updatedUserDetail = {
            ...previousUserDetail,
            user_details: userDetails,
          };

          this.saveUserDetail(updatedUserDetail);
          this.notifyService.notifyAboutChange();
        }
      });
  }

  verifyEmail(data: any) {
    return this.dataService.post({
      url: "v1/user/auth/email/verify",
      data: data,
      isLoader: true,
      ignoreErrors: true,
    });
  }

  signInWithGoogle(idToken: string, referral_key?: string): void {
    const url = "v1/user/auth/login/google";

    this.loaderService.start();

    let data: any = { token: idToken };
    if (referral_key) {
      data.referral_key = referral_key;
    }

    this.dataService
      .post({
        url,
        data,
        isLoader: false,
      })
      .subscribe({
        next: async (response: any) => {
          await this.saveUserDetail(response.result);
          this.extensionService.sendLoginMessage(url, response.result);

          if (response.result?.is_company_poc === true) {
            const companySlug = this.getUserCompanySlug();
            this.nav.navigateTo([`/${companySlug}/admin/users`]);
            this.loaderService.stop();
            return;
          }

          if (this.redirectUrl.includes("instancyssorequested=true")) {
            this.nav.navigateTo(["/flyboard"], {
              queryParams: { instancyssorequested: "true" },
            });
            this.loaderService.stop();
            return;
          }

          if (
            response.result.user_details?.onboarding == "pending" ||
            !response.result.user_details?.onboarding
          ) {
            if (this.redirectUrl.includes("activate_trial=true")) {
              this.stripeService.startSalesProTrial().subscribe({
                next: (response: any) => {
                  this.nav.navigateTo([`/onboarding`]);
                  this.loaderService.stop();
                  return;
                },
                error: (error) => {
                  console.log(error);
                },
              });
            }
            this.nav.navigateTo([`/onboarding`]);
          } else {
            if (this.redirectUrl.includes("activate_trial=true")) {
              this.nav.navigateTo(["/new-plan/SalesPro"], {
                queryParams: { activate_trial: "true" },
              });
              this.loaderService.stop();
              return;
            }
            this.nav.navigateTo([this.redirectUrl]);
          }

          this.loaderService.stop();
        },
        error: (error) => {
          this.loaderService.stop();
          if (error.status == 422) {
            if (error?.error?.code == "DEACTIVATED") {
              this.dialog.openDialogComponent(
                SignInErrorComponent,
                {
                  admin_email: error?.error?.admin_email,
                },
                "500px"
              );
            }
          }
          this.notification.toast(error?.error?.error, "danger");
        },
      });
  }

  signInWithLinkedIn(): void {
    window.location.href = this.apiURL + "v1/linkedin/redirect";
  }

  acceptCompanyInvitation(company_id: string, user_id: string) {
    return this.dataService
      .post({
        url: `v1/users/${user_id}/accept-company-invitation`,
        data: { company_id },
        isLoader: true,
      })
      .pipe(
        map((response: any) => {
          this.store.dispatch(
            loadUserCurrentSubscriptionApi({ refetchFromApi: true })
          );
          let user = this.userDetails;
          let user_details = user.user_details;

          delete user_details.invited_to_company;
          delete user_details.invited_to_company_by_admin;

          user_details = { ...user_details, ...response?.result };

          user.user_details = user_details;

          this.saveUserDetail(user);
          this.notifyService.notifyAboutChange({
            planObj: response?.result?.plan,
          });

          if (user.user_details.is_poc) {
            this.nav.navigateTo([
              `${user.user_details.company.slug}/admin/users`,
            ]);
          } else {
            location.reload();
          }

          return response;
        }),
        catchError((error: any) => {
          // This catchError handles errors that occurred before the map.
          console.error("An error occurred:", error);
          return throwError(() => error);
        })
      );
  }

  patchOnboarding(
    user_id: string,
    step: number,
    maxStep: number,
    tooltip_learning_resources_showed?: boolean,
    isLoader = true,
    onboardingv2_presented?: boolean
  ) {
    return this.dataService
      .post({
        url: `v1/users/${user_id}/update-onboarding`,
        data: { step, tooltip_learning_resources_showed, max_step: maxStep - 1, onboardingv2_presented },
        isLoader,
      })
      .pipe(
        map((response: any) => {
          const currentUser = this.userDetails;
          currentUser.user_details.onboardingv2_step = step;
          currentUser.user_details.onboardingv2_completed = step > maxStep;

          if (tooltip_learning_resources_showed !== undefined) {
            currentUser.user_details.tooltip_learning_resources_showed = tooltip_learning_resources_showed;
          }

          if (onboardingv2_presented !== undefined) {
            currentUser.user_details.onboardingv2_presented = onboardingv2_presented;
          }

          this.saveUserDetail(currentUser);
          return response;
        }),
        catchError((error: any) => {
          // This catchError handles errors that occurred before the map.
          console.error("An error occurred:", error);
          return throwError(() => error);
        })
      );
  }

  rejectCompanyInvitation(company_id: string, user_id: string) {
    return this.dataService
      .post({
        url: `v1/users/${user_id}/reject-company-invitation`,
        data: { company_id },
        isLoader: true,
      })
      .pipe(
        map((response: any) => {
          this.store.dispatch(
            loadUserCurrentSubscriptionApi({ refetchFromApi: true })
          );
          let user = this.userDetails;
          let user_details = user.user_details;

          delete user_details.invited_to_company;
          delete user_details.invited_to_company_by_admin;

          user_details = { ...user_details, ...response?.result };

          user.user_details = user_details;

          this.saveUserDetail(user);
          this.notifyService.notifyAboutChange({
            planObj: response?.result?.plan,
          });

          return response;
        }),
        catchError((error: any) => {
          // This catchError handles errors that occurred before the map.
          console.error("An error occurred:", error);
          return throwError(() => error);
        })
      );
  }

  masqueradeAsCompany({
    company_id,
    slug,
    name,
  }: {
    company_id: string;
    slug: string;
    name: string;
  }) {
    this.sharedData.setAttribute(company_id, {
      company_id,
      slug,
      name,
    });

    this.nav.navigateToUrl(`/${slug}/admin/users?session=${company_id}`);
  }

  masqueradeAsUser(data: MasqueradeUserResponse) {
    const userId = data.user_details.id;
    this.sharedData.setAttribute(userId, data);
    this.nav.navigateToUrl(`/flyboard?session=${userId}`);
  }

  reloadUserPlanDetailsOnFirstMasqueradeAccess() {
    this.stripeService.getCurrentPlan().subscribe((planInfo: any) => {
      sessionStorage.setItem("fms-cmc-masquerading-user-current-reloaded", "true");

      return loadUserCurrentSubscriptionApiSuccess({ payload: planInfo })
    });
  }

  get onUserMasqueradeSession(): boolean {
    const masqueradeUserId = sessionStorage.getItem(
      "fms-cmc-masquerading-user-id"
    );

    return !!masqueradeUserId;
  }

  get onUserMasqueradeSessionCurrentReloaded(): boolean {
    return !!sessionStorage.getItem(
      "fms-cmc-masquerading-user-current-reloaded"
    );
  }

  get onCompanyMasqueradeSession(): string {
    return sessionStorage.getItem("fms-cmc-masquerading-company-slug");
  }

  get getMasqueradeCompanyName(): string {
    return sessionStorage.getItem("fms-cmc-masquerading-company-name");
  }

  get masqueradeUser(): MasqueradeUserResponse {
    return sessionStorage.getItem("fms-cmc-masquerading-user-details")
      ? (JSON.parse(
          sessionStorage.getItem("fms-cmc-masquerading-user-details")
        ) as unknown as MasqueradeUserResponse)
      : null;
  }

  get accessToken(): string {
    if (this.masqueradeUser) {
      return this.masqueradeUser.access_token;
    }

    return this.sharedData.getAttribute("accessToken");
  }

  get refreshToken(): string | null {
    if (this.masqueradeUser) {
      return null;
    }

    return this.sharedData.getAttribute("refreshToken");
  }

  get userDetails() {
    if (this.masqueradeUser) {
      return this.masqueradeUser;
    }

    return this.sharedData.getAttribute("userDetails");
  }

  endMasqueradeSession() {
    const id = sessionStorage.getItem("fms-cmc-masquerading-company-id");
    if (id) {
      this.sharedData.deleteAttribute(id);
    }

    sessionStorage.removeItem("fms-cmc-masquerading-company-id");
    sessionStorage.removeItem("fms-cmc-masquerading-company-slug");
    sessionStorage.removeItem("fms-cmc-masquerading-company-name");
  }

  private get apiURL(): string {
    return environment.API_URL;
  }
}
