import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { DashboardComponent } from './dashboard/dashboard.component';
import { GlobalOverlayComponent } from './global-overlay/global-overlay.component';
import { AuthService, UserDetails } from './core/core-services/services/auth.service';
import { MenuComponent } from './core';
import { TopSearchBarComponent } from './core/header/top-search-bar/top-search-bar.component';

@Injectable({
  providedIn: 'root',
})
export class GlobalHookService {
  public onboardingActive = false;
  private readonly functionCallSource = new Subject<{ functionName: string, args: any[] }>();
  private registeredCallbacks: { [key: string]: Function } = {};
  public registeredComponents: { [key: string]: unknown } = {};
  public components: { 
    dashboard?: DashboardComponent;
    menu?: MenuComponent;
    global?: GlobalOverlayComponent;
    topBar?: TopSearchBarComponent;
    [name: string]: any;
  } = {};

  functionCalled$ = this.functionCallSource.asObservable();
  user: UserDetails;

  constructor(
    private readonly authService: AuthService,
  ) {
    this.user = this.authService.userDetails?.user_details;
  }

  showLearningResourcesTooltip() {
    if (this.components.topBar) {
      this.components.topBar.showLearningResourcesTooltip();
    }
  }

  registerFunction(functionName: string, callback: Function) {
    this.registeredCallbacks[functionName] = callback;
  }

  registerComponent(name: string, component: any) {
    this.components[name] = component;
  }

  callFunction(functionName: string, ...args: any[]) {
    if (this.registeredCallbacks[functionName]) {
      this.registeredCallbacks[functionName](...args);
    } else {
      console.warn(`Function "${functionName}" not registered.`);
    }
  }
}