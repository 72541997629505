import { Component, Inject, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AuthService } from "src/app/core/core-services/services/auth.service";
import { NotificationService } from "src/app/core/core-services/services/notification.service";
import { AddGroupComponent } from "../../add-group/add-group.component";

import { Option } from "../../models/option.model";
import { ROLES } from "src/app/core/core-services/constants/roles.constant";
import { NgbPopoverConfig } from "@ng-bootstrap/ng-bootstrap";
import { DialogService } from "src/app/core/core-services/services/dialog.service";
import { SuperAdminApiService } from "../../services/super-admin-api.service";
import { AddNewCompanyComponent } from "../../admin-companies/add-new-company/add-new-company.component";
import { CompaniesApiService } from "../../services/company/companies-api.service";
import { AssignMoveUsersToCompanyRequest, CompanyLicense, CompanyLicenseDetails } from "../../services/company/companies-dto";

interface GroupWithSubgroups extends Option {
  subgroups: Option[];
}

@Component({
  selector: "app-assign-move-to-company",
  templateUrl: "./assign-move-to-company.component.html",
  styleUrls: ["./assign-move-to-company.component.scss"],
})
export class AssignMoveToCompanyComponent implements OnInit {
  action: string;
  user: any;
  role: any;
  assignMoveToForm: FormGroup;
  confirmAssignMoveTo: boolean = false;
  subGroups: string[] = [];
  initialFormValues: any;

  selectedGroupName: string = "";
  companySelectOpened: boolean = false;
  selectedCompanyName: string = "";
  loadingGroups: boolean = false;

  groupCtrl = new FormControl({
    value: null,
    disabled: this.loadingGroups,
  });
  subgroupCtrl = new FormControl({
    value: null,
    disabled: this.loadingGroups,
  });

  groups: GroupWithSubgroups[] = [];
  subgroups: Option[] = [];

  roles: any[] = ROLES;
  companies: CompanyLicenseDetails[] = [];
  plans: CompanyLicense[] = [];

  constructor(
    private readonly authService: AuthService,
    public dialogRef: MatDialogRef<AssignMoveToCompanyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly fb: FormBuilder,
    private readonly notification: NotificationService,
    private readonly apiService: SuperAdminApiService,
    private readonly companiesApiService: CompaniesApiService,
    public dialog: DialogService,
    config: NgbPopoverConfig,
  ) {
    this.user = data.user;
    config.triggers = "hover";
    config.container = "body";
  }

  ngOnInit(): void {
    this.initialFormValues = this.user;

    this.assignMoveToForm = this.fb.group({
      company_id: [null, [Validators.required]],
      role_name: ["User", [Validators.required]],
      plan_identifier: [null, [Validators.required]],
      group: this.groupCtrl,
      subgroup: this.subgroupCtrl,
    });

    this.setupConditionalValidation();
    
    this.companiesApiService.getCompaniesLicenses().subscribe({
      next: (response) => {
        if (response.result.success) {
          this.companies = response.result.data.map((company) => ({
            ...company,
            isSelected: false,
          }));
        }
      },
      error: (err) => console.log(err),
    });

    this.groupCtrl.valueChanges.subscribe((selectedGroupValue) => {
      this.assignMoveToForm.patchValue({
        subgroup: null,
      });
      const selectedGroup = this.groups.find(
        (group) => group.value === selectedGroupValue
      );
      this.selectedGroupName = selectedGroup?.displayValue;
      this.subgroups = selectedGroup ? selectedGroup.subgroups : [];
    });

    this.subgroupCtrl.valueChanges.subscribe((selectedSubGroupValue) => {
      const selectedSubGroup = this.subgroups.find(
        (group) => group.value === selectedSubGroupValue
      );
      this.selectedGroupName = selectedSubGroup?.displayValue;
    });
  }

  private setupConditionalValidation() {
    const roleNameControl = this.assignMoveToForm.get('role_name');
    const groupControl = this.assignMoveToForm.get('group');

    roleNameControl?.valueChanges.subscribe((roleName) => {
      if (roleName === 'Group Admin') {
        groupControl?.setValidators([Validators.required]);
      } else {
        groupControl?.clearValidators();
      }

      groupControl?.updateValueAndValidity();
    });
  }

  get isUserInvite(): boolean {
    return this.user?.is_invite;
  }

  setConfirmAssignMoveTo(value: boolean) {
    if (value) {
      this.confirmAssignMoveTo = true;
      this.dialogRef.componentInstance.dialogRef.updateSize("unset");
    } else {
      this.dialogRef.componentInstance.dialogRef.updateSize("600px");
      this.confirmAssignMoveTo = false;
    }
  }

  addNewGroupDialog(): void {
    const dialogHandle = this.dialog.openDialogComponent(
      AddGroupComponent,
      { action: "add-group", class: "portal-admin" },
      "450px"
    );

    dialogHandle.afterClosed().subscribe((group: any) => {
      this.groups = this.groups.concat({
        value: group.group.id,
        displayValue: group.group.name,
        subgroups: [],
      });
    });
  }

  addNewSubGroupDialog(): void {
    const dialogHandle = this.dialog.openDialogComponent(
      AddGroupComponent,
      {
        action: "add-subgroup",
        groupId: this.groupCtrl.value,
        class: "portal-admin",
      },
      "450px"
    );

    dialogHandle.afterClosed().subscribe((subgroup: any) => {
      this.subgroups = this.subgroups.concat({
        value: subgroup.id,
        displayValue: subgroup.name,
      });
    });
  }

  onSelectionChange(id: string): void {
    let company = this.companies.find((company: any) => company.id === id);

    this.assignMoveToForm.patchValue({
      group: null,
    });
    this.assignMoveToForm.patchValue({
      subgroup: null,
    });
    this.roles = ROLES.filter(role => company.name === 'Vengreso' || role !== 'Vengreso Admin');

    this.selectedCompanyName = company?.name;
    this.plans = company?.plans ?? this.plans;

    this.loadingGroups = true;
    this.apiService
      .getCompanyGroups(company.slug)
      .subscribe((response: any) => {
        if (response.result.success) {
          this.groups = response.result.data.groups.map((group: any) => ({
            value: group.id,
            displayValue: group.name,
            subgroups: group.subgroups?.map((subgroup: any) => ({
              value: subgroup.id,
              displayValue: subgroup.name,
            })),
          }));

          if (!this.groups.length && this.assignMoveToForm.get('role_name')?.value === 'Group Admin') {
            this.assignMoveToForm.patchValue({
              role_name: null,
            });
          }
        }

        this.loadingGroups = false;
      });
  }

  assignMoveTo(): void {
    if (!this.confirmAssignMoveTo) {
      this.setConfirmAssignMoveTo(true);
      return;
    }

    const request: AssignMoveUsersToCompanyRequest = {
      company_id: this.assignMoveToForm.value.company_id,
      users: [
        {
          id: this.user.id,
          plan: this.assignMoveToForm.value.plan_identifier,
          role: this.assignMoveToForm.value.role_name,
          group_id: this.assignMoveToForm.value.group,
          subgroup_id: this.assignMoveToForm.value.subgroup,
        },
      ],
    };

    this.apiService
      .assignMoveTo(request)
      .subscribe({
        next: (response: any) => {
          if (response.result.success) {
            this.close(response.result.data);
            const notifyMessage = `User profile updated successfully.`;

            this.notification.toastWithConfig(notifyMessage, null, {
              verticalPosition: "top",
              horizontalPosition: "center",
              panelClass: ["green-notification"],
            });
          }
        },
        error: (err) => console.log(err),
      });
  }

  cancelAssignMoveTo(): void {
    if (this.confirmAssignMoveTo) {
      this.setConfirmAssignMoveTo(false);
      return;
    }

    this.close();
  }

  onAssignLicense(event?: any): void {
    this.plans.forEach((plan: any) => {
      let unassignedLicenses = plan.totalLicenses - 1;
      plan.licensesAvailable = unassignedLicenses > 0 ? unassignedLicenses : 0;
    });
  }

  planLabel(plan: string): string {
    return this.plans.find((planObj: any) => planObj.value === plan)?.label;
  }

  get emailLink(): string {
    const { first_name, last_name, company } = this.authService.userDetails.user_details;
    const body: string = `
    "Hello FlyMSG team"
    I'd like to inquire about adding on _______ more licenses to my account.
    Account name: ${company.name}
    Administrator Requesting: ${first_name} ${last_name}`;

    return `mailto:sales@vengreso.com?subject=${encodeURIComponent(
      "Additional License Inquiry"
    )}&body=${encodeURIComponent(body)}`;
  }

  addNewCompany(): void {
    const dialogRef = this.dialog.openDialogComponent(
      AddNewCompanyComponent,
      { class: ["portal-admin", "cmc-add-companies-modal-container"] },
      "600px"
    );

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.companies = this.companies.concat(result).sort((a, b) => a.name.localeCompare(b.name));
      }
    });
  }

  close(data?: any): void {
    this.dialogRef.close(data);
  }
}
