import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgbPopoverConfig } from "@ng-bootstrap/ng-bootstrap";

import { DataService } from "src/app/core/core-services/services/dataservices/data.service";
import { DialogService } from "src/app/core/core-services/services/dialog.service";
import { ExtensionService } from "src/app/core/core-services/services/extension.service";
import { ListService } from "src/app/core/core-services/services/list.service";
import { NavigationService } from "src/app/core/core-services/services/navigation.service";
import { NotificationService } from "src/app/core/core-services/services/notification.service";
import { ScrollService } from "src/app/core/core-services/services/scroll.service";

import { ApplicationConstants } from "src/app/core/core-services/constants/application.constants";

import { ConfirmDialogComponent } from "src/app/core/core-services/confirm-dialog/confirm-dialog.component";
import { MetaService } from "src/app/core/core-services/services/meta.service";
import { CategoryConstant, getPlanId, getRewards, RewardsConstants } from "../../core/core-services/constants/plans.constants";
import { WarningDialogComponent } from "../../core/core-services/warning-dialog/warning-dialog.component";
import { TemplateCategoryCopyComponent } from "./template-category-copy/template-category-copy.component";
import { TemplateCopyComponent } from "./template-copy/template-copy.component";
import { TourService } from "ngx-ui-tour-md-menu";
import { GlobalHookService } from "src/app/global-hook.service";
import { callback } from "chart.js/dist/helpers/helpers.core";
import { MatExpansionPanel } from "@angular/material/expansion";

@Component({
  selector: "app-templates",
  templateUrl: "./templates.component.html",
  styleUrls: ["./templates.component.scss"],
})
export class TemplatesComponent implements OnInit {
  @Input() searchPlates: any;
  @Input() fromSearch: any;
  @Input() downScroll: boolean;
  @Input() searchValue: string;
  @Output("detectUpdates") detectUpdates: EventEmitter<any> = new EventEmitter();
  @ViewChildren('panels') panels!: QueryList<MatExpansionPanel>;

  categoryId: string;
  currentcategory : string;
  templateList: any[];
  editorConfig: any;
  panelOpenState = {};
  editorApiKey: string;
  scrollDisabled = true;
  searchText = "";

  constructor(
    config: NgbPopoverConfig,
    private route: ActivatedRoute,
    private dialog: DialogService,
    public nav: NavigationService,
    public listService: ListService,
    private dataService: DataService,
    public scrollService: ScrollService,
    private notification: NotificationService,
    private extensionService: ExtensionService,
    private metaService: MetaService,
    private readonly tourService: TourService,
    private readonly globalHookService: GlobalHookService,
  ) {
    config.triggers = "hover";
    config.container = "body";
  }

  ngOnInit() {
    this.templateList = [];
    setTimeout(() => {
      this.scrollDisabled = false;
      this.editorApiKey = ApplicationConstants.TINYMCE_API_KEY;
      this.editorConfig = {
        statusbar: false,
        menubar: false,
        toolbar: false,
        min_height: 100,
        max_height: 200,
        plugins: ["autoresize"],
        body_class: "noselect",
        content_style: getPlanId() != "Growth" ?
          "body {position: relative;background: #F5F5F5;   opacity: 0.5; overflow: hidden;min-height: 5rem !important;   -webkit-touch-callout: none; -webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none; -ms-user-select: none;user-select: none; font-family: Arial;} body::after {margin: 3rem;content: 'PREMIUM';position: absolute;top: 0;z-index: -1; font-size: 4rem; text-align: center;font-style: normal;top: -1.5rem;font-weight: 800;font-size: 4rem; width: 100%; height: ; left: -3%;line-height: 107px;letter-spacing: 1em; opacity: 0.23; color: #000000; }" :
          "",
      };
    }, 0);
    if (!this.fromSearch) {
      this.route.data.subscribe((data) => {
        let categoryName = this.route.snapshot.paramMap.get("categoryName");

        if (
          (data.templateList && data.templateList.result) ||
          this.fromSearch
        ) {
          let config: any = {
            self: this,
            listName: "templateList",
            url:
              "v1/user/template?order_by=shortcut,asc" +
              (this.categoryId === "all"
                ? ""
                : "&category_id=" + this.categoryId) +
              "&",
            callback: () => {
              if (this.isOnboarding) {
                setTimeout(() => {
                  const tf = this.templateList.find(f => f.shortcut === '-tf');
                  this.panelOpenState[tf?.id] = true;
                  
                  const panelArray = this.panels.toArray();
                  if (panelArray[0]) {
                    panelArray[0].open();
                  }

                  this.tourService.initialize([
                    {
                      anchorId: 'step13'+tf?.id,
                      content: 'Click + Add to FlyCuts to add this FlyPlate to your library of messages. ',
                      title: 'Add a FlyPlate',
                      enableBackdrop: true,
                      disablePageScrolling: true,
                      nextOnAnchorClick: true,
                      placement: {
                        xPosition: 'before',
                        yPosition: 'above'
                      }
                    },
                  ]);
              
                  this.tourService.stepShow$.subscribe((step) => {
                    const popover = document.querySelector('.mat-menu-panel.tour-step')?.parentElement;
              
                    if (popover) {
                      popover.classList.remove('step13-onboarding-popover-arrow');
                    }
              
                    setTimeout(() => {
                      const popover = document.querySelector('.mat-menu-panel.tour-step')?.parentElement;

                      if (popover && step.anchorId === ('step13'+tf?.id)) {
                        popover.classList.add('step13-onboarding-popover-arrow');
                      }
                    }, 200);
              
                    if (step.anchorId === ('step13'+tf?.id)) {
                      document.body.classList.add('tour-last-step');
                    } else {
                      document.body.classList.remove('tour-last-step');
                    }
                  });
              
                  this.tourService.end$.subscribe(() => {
                    document.body.classList.remove('tour-last-step');
                  });
              
                  setTimeout(() => {
                    this.tourService.start();
                  }, 1000);
                },50);
              }
            }
          };
          
          if (categoryName) {
            let subCategoryName = this.route.snapshot.paramMap.get("subCategoryName");
            config = {
              self: this,
              listName: "templateList",
              url:
                "v1/user/templatebyname?order_by=shortcut,asc" +
                (categoryName === "ViewAll"
                  ? ""
                  : "&category_name=" + categoryName) +
                (subCategoryName ? "&sub_category_name=" + subCategoryName : "") +
                "&",
              callback: config.callback
            };

            if (subCategoryName) {
              let subSubCategoryName = this.route.snapshot.paramMap.get("subSubCategoryName");
              config = {
                self: this,
                listName: "templateList",
                url:
                  "v1/user/templatebyname?order_by=shortcut,asc" +
                  (categoryName === "ViewAll"
                    ? ""
                    : "&category_name=" + categoryName) + ("&sub_category_name=" + subCategoryName) +
                  (subSubCategoryName ? "&sub_sub_category_name=" + subSubCategoryName : "") +
                  "&",
                callback: config.callback
              };
            }
          }

          this.listService.init(config, data.templateList.result);
          this.categoryId = this.templateList.find(template => !!template.category_id)?.category_id;

        }
      });
    }
    this.metaService.addMetaTag(
      'FlyPlates View all Category - Templates',
      "FlyPlates is a repository of over 200 templates you can choose from. What's your time worth?");
    if (this.fromSearch) {
      this.basicSearchHandle();
      this.searchText = this.searchValue;
    }

    this.route.paramMap.subscribe(params => {
      this.currentcategory = params.get('categoryName') || 'viewall';
      console.log("Current Category:", this.currentcategory); // Debugging
    });
  }

  get isOnboarding() {
    return this.globalHookService.onboardingActive;
  }

  ngOnChanges() {
    if (this.fromSearch) {
      this.basicSearchHandle();
    }
  }

  basicSearchHandle() {
    this.templateList = this.searchPlates;
    if (this.templateList) {

      const config = {
        self: this,
        listName: "templateList",
        url:
          "v1/user/template?order_by=shortcut,asc" +
          (this.categoryId === "all" ? "" : "&category_id=" + this.categoryId) +
          "&",
      };

      this.listService.init(config, this.templateList);
    }
  }

  flycutExistsSearch(data: any, disableClose: boolean = false): any {
    this.dataService
      .flycutExistsSearch({ q: data.shortcut })
      .subscribe((response: any) => {
        if (response?.result) {
          if (response.result.data.exists) {
            this.dialog.openDialogComponent(
              WarningDialogComponent,
              {
                errorMessage: `The FlyCut`,
                errorMessage2: `is being used on another message. Please change your FlyCut and try again`,
                flycut: data.shortcut
              },
              "407px"
            );
          }
          else {
            this.templateCopyToShortcut(data, disableClose)
          }
        }
      });

  }

  addTemplateToShortcut(data: any) {
    this.flycutExistsSearch(data, this.isOnboarding)
    
    if (this.isOnboarding) {
      this.tourService.end();
    }
  }

  templateCopyToShortcut(data: any, disableClose: boolean = false) {
    let planId = getPlanId();
    let rewards = getRewards();
    if (!rewards.length && !rewards.includes(RewardsConstants.ALL_FLYPLATES)) {
      if (planId === "Free" && data?.premium) {
        this.dialog.openDialogComponent(
          WarningDialogComponent,
          { self: this, type: "Flyplates", showTheStarter: planId === "Free" },
          "800px",
        );
        return;
      }
    }

    const ref = this.dialog.openDialogComponent(
      TemplateCopyComponent,
      { self: this, data },
      "400px",
      disableClose
    );

    ref.afterClosed().subscribe((result) => {
      if (this.isOnboarding) {
        this.globalHookService.components.global.startModal(4);
      }
    });
  }

  templateCategoryCopyToShortcut() {
    let planId = getPlanId();
    let rewards = getRewards();

    if (!rewards.includes(RewardsConstants.ALL_FLYPLATES)) {
      if (planId === "Free" && CategoryConstant.GENERAL!=this.currentcategory) {
        this.dialog.openDialogComponent(
          WarningDialogComponent,
          { self: this, type: "Flyplates", showTheStarter: planId === "Free", error: 'flyplatesFree' },
          "800px"
        );
        return;
      }
    }

    this.dialog.openDialogComponent(
      TemplateCategoryCopyComponent,
      { self: this },
      "330px"
    );
  }

  resetTemplate(data: any, isReset = true) {
    //the default title is update text for update button
    let title =
      "Do you want to update your FlyCut with this updated version from FlyPlates? <br /><small>NOTE - This will override whatever you have currently in your FlyCut.</small>";
    if (isReset) {
      //reset title this text for reset button
      title =
        "<small><b>Resetting this Flyplate will restore the original version removing any changes you've previously made.</b></small> <br /><small style='font-size: small; color: gray;'>Would you still want to reset it?</small>";
    }
    this.dialog.openDialogComponent(
      ConfirmDialogComponent,
      {
        self: this,
        data,
        title,
        isDelete: false,
        type: 'Reset',
      },
      "420px"
    );
  }

  update(data: any) {
    const params = {
      template_id: data.id,
    };
    this.dataService
      .put({ url: "v1/user/copy-shortcut/template/single", data: params })
      .subscribe((response: any) => {
        if (response && response.result) {
          this.notification.toast("FlyCut updated successfully.");
          this.listService.getList(true);
          this.extensionService.sendUpdateMessage();
        }
      });
  }
}
