import { Injectable } from "@angular/core";
import { AuthService } from "src/app/core/core-services/services/auth.service";

import { DataService } from "src/app/core/core-services/services/dataservices/data.service";
import { AssignMoveUsersToCompanyRequest } from "./company/companies-dto";

export interface AddUserManually {
  email: string;
  firstName: string;
  lastName: string;
}

export type FilterUsersReportingUsageType =
  | "group"
  | "subgroup"
  | "user"
  | "company"
  | "email"
  | "main";

export interface FilterUsersReportingUsage {
  id: string;
  name: string;
  count: number;
  checked: boolean;
  indeterminate: boolean;
  type: FilterUsersReportingUsageType;
  items?: FilterUsersReportingUsage[];
  disabledAndChecked?: boolean;
}

@Injectable({
  providedIn: "root",
})
export class SuperAdminApiService {
  constructor(
    private dataService: DataService,
    private authService: AuthService
  ) {}

  get baseUrl() {
    return "v1/client-management/";
  }

  private get companySlug() {
    return this.authService.getUserCompanySlug();
  }

  getUsers({
    perPage,
    page,
    deactivated = false,
    categories,
    filter,
    sortBy,
    sortOrder,
    selectedSubscriptionTypes,
    selectedAccountStatus,
  }: {
    perPage: number;
    page: number;
    deactivated?: boolean;
    categories?: string;
    filter?: string;
    sortBy: "name" | "created_at";
    sortOrder: "asc" | "desc";
    selectedSubscriptionTypes?: string;
    selectedAccountStatus?: string;
  }) {
    const data: any = {
      perPage,
      page,
      categories: categories.substring(0, 5100),
      deactivated,
      sort_by: sortBy,
      sort_order: sortOrder,
      selectedSubscriptionTypes: selectedSubscriptionTypes.substring(0, 5100),
      selectedAccountStatus: selectedAccountStatus.substring(0, 5100),
    };

    if (filter) {
      data.filter = filter;
    }

    return this.dataService.get({
      url: `${this.baseUrl}users`,
      data,
    });
  }

  getUserCategories(deactivated: boolean = false) {
    return this.dataService.get({
      url: `${this.baseUrl}users-categories`,
      data: { status: deactivated ? 'inactive' : 'active' },
    });
  }

  addUserManually(data: AddUserManually) {
    return this.dataService.post({
      url: `${this.baseUrl}users/add-user-manually`,
      data: {
        email: data.email,
        first_name: data.firstName,
        last_name: data.lastName,
      },
    });
  }

  addUserByEmail(emails: string[]) {
    return this.dataService.post({
      url: `${this.baseUrl}users/add-user-by-emails`,
      data: {
        emails,
      },
    });
  }

  /**
   * Sends invitations to users.
   * 
   * data = {
      "emails": [
        "user@gmail.com",
        "user2@gmail.com"
      ]
    }
   *
   */
  resendUserInvitation(data: any) {
    return this.dataService.post({
      url: `${this.baseUrl}users/resend-user-invitation`,
      data: data,
    });
  }

  /**
   * user_id = db id
   * data = {
      "email": "john@company.com",
      "first_name": "John",
      "last_name": "Smith"
    }
   */
  updateUser(user_id: string, data: any, is_invite: boolean = false) {
    return this.dataService.put({
      url: `${this.baseUrl}users/${user_id}`,
      data: data,
    });
  }

  /**
   * Assigns role to user
   * 
   * data = {
      "role_name": "Group Admin",
      "groups": [
          "665ecd70f55cb17b6e0296f6",
          "665ecd70f55cb17b6e0296f4"
        ]
      }
   *
   */
  assignRole(user_id: string, data: any) {
    return this.dataService.put({
      url: `${this.baseUrl}users/${user_id}/assign-role`,
      data: data,
    });
  }

  assignMoveTo(data: AssignMoveUsersToCompanyRequest) {
    return this.dataService.post({
      url: `${this.baseUrl}users/move-to`,
      data: data,
    });
  }

  /**
   * Sends Reset password request email to one or more users.
   * 
   * data = {
      "user_ids": [
        "5f8873ec2e8ef41fc007007f",
        "5f88b40f2e8ef41fc0070089",
        "5f8860922e8ef41fc0070071"
      ]
    }
   *
   * @param {any} data - An object containing an array of user IDs.
   */
  resetPassword(data: any) {
    return this.dataService.post<Response>({
      url: `${this.baseUrl}users/reset-password`,
      data: data,
    });
  }

  exportUsersCSV(with_deactivated: boolean = false, selected_users?: any) {
    const urlAppends = selected_users ? `&users=${selected_users}` : "";

    return this.dataService.download(
      {
        url: `${this.baseUrl}users/export-csv?deactivated=${with_deactivated}${urlAppends}`,
      },
      "Users.csv"
    );
  }

  /**
   * Delete users by passing an array of DB ids for one or more users
   * 
   * data = {
      "users": [
        "5f8873ec2e8ef41fc007007f",
        "5f88b40f2e8ef41fc0070089",
        "5f8860922e8ef41fc0070071"
      ]
    }
   *
   * @param   {any}  data
   */
  deleteUsers(data: any) {
    return this.dataService.post({
      url: `${this.baseUrl}users/delete`,
      data: data,
    });
  }

  deactivateUsers(data: any) {
    return this.dataService.post({
      url: `${this.baseUrl}users/deactivate`,
      data: data,
    });
  }

  getCompanies(deactivated: boolean = false) {
    return this.dataService.get({
      url: `${this.baseUrl}companies?deactivated=${deactivated}`,
    });
  }

  /**
   * @param companyData : {
   *  company: any,
   *  pocs: {email: string, first_name: string, last_name: string}[]
   * }
   */
  addCompany(companyData: any) {
    return this.dataService.post({
      url: `${this.baseUrl}companies`,
      data: companyData,
      isLoader: false
    });
  }

  validatePocEmail(email: string) {
    return this.dataService.post({
      url: `${this.baseUrl}validate-poc-email`,
      data: {email},
      isLoader: false
    });
  }

  getCompanyUsers(slug: string, load_admins_only: boolean = false) {
    return this.dataService.get({
      url: `${this.baseUrl}company/${slug}/users?load_admins_only=${load_admins_only}`,
    });
  }

  getCompanyGroups(slug?: string) {
    return this.dataService.get({
      url: `${this.baseUrl}company/${slug ?? this.companySlug}/groups`,
    });
  }

  getCompanyPOCs(slug: string) {
    return this.dataService.get({
      url: `${this.baseUrl}company/${slug}/pocs`,
    });
  }

  getCompanyGroupUsers(companySlug: string, groupSlug: string) {
    return this.dataService.get({
      url: `${this.baseUrl}company/${companySlug}/group-users/${groupSlug}`,
    });
  }

  deactivateCompany(companyIds: string[]) {
    return this.dataService.post({
      url: `${this.baseUrl}companies/deactivate`,
      data: { company_ids: companyIds },
    });
  }

  /**
   *
   * @param {string[]} companyIds
   */
  reactivateCompany(companyIds: string[]) {
    return this.dataService.post({
      url: `${this.baseUrl}companies/reactivate`,
      data: { company_ids: companyIds },
    });
  }

  /**
   *
   * @param companyId
   * @param data {
   *   company_id: string, // Id of the new company
   *   role: string,
   *   selected_plan: string
   *  }
   */
  deleteCompany(companyId: string, data?: any) {
    return this.dataService.post({
      url: `${this.baseUrl}company/${companyId}/delete`,
      data,
    });
  }

  exportCSV(company_ids: any, deactivated: boolean = false) {
    return this.dataService.download(
      {
        url: `${this.baseUrl}companies/export/csv?company_ids=${company_ids}&deactivated=${deactivated}`,
      },
      "Companies.csv"
    );
  }

  reportingGetLicenses() {
    return this.dataService.get({
      url: `${this.baseUrl}reporting/licenses?month_period=12`,
    });
  }

  reportingGetROIspotlight(
    datePeriod?: { fromDate: string; toDate: string },
    generalFilter?: string
  ) {
    const urlAppends = this.getUrlAppends(datePeriod, generalFilter);
    return this.dataService.get({
      url: `${this.baseUrl}reporting/roi-spotlight${urlAppends}`,
    });
  }

  reportingGetFMScoachLevelSpotlight() {
    return this.dataService.get({
      url: `${this.baseUrl}reporting/fms-coach-level-spotlight`,
    });
  }

  reportingGetProductivitySpotlight(
    datePeriod?: { fromDate: string; toDate: string },
    generalFilter?: string
  ) {
    const urlAppends = this.getUrlAppends(datePeriod, generalFilter);
    return this.dataService.get({
      url: `${this.baseUrl}reporting/productivity-spotlight${urlAppends}`,
    });
  }

  reportingGetCharactersTypedSpotlight(
    datePeriod?: { fromDate: string; toDate: string },
    generalFilter?: string
  ) {
    const urlAppends = this.getUrlAppends(datePeriod, generalFilter);
    return this.dataService.get({
      url: `${this.baseUrl}reporting/total-characters-typed-spotlight${urlAppends}`,
    });
  }

  getCompaniesForReportingFilters() {
    const url = `${this.baseUrl}companies/filter`;

    return this.dataService.get({ url });
  }

  private getUrlAppends(datePeriod?: any, generalFilter?: string): string {
    let urlAppends = datePeriod
      ? `?fromDate=${datePeriod.fromDate}&toDate=${datePeriod.toDate}`
      : "";
    if (generalFilter) {
      urlAppends =
        urlAppends.length > 0
          ? `${urlAppends}&${generalFilter}`
          : `?${generalFilter}`;
    }

    return urlAppends;
  }
}
