import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { NgbPopoverConfig } from "@ng-bootstrap/ng-bootstrap";
import { DialogService } from "src/app/core/core-services/services/dialog.service";
import { UsersApiService } from "../services/users/users-api.service";
import { Location } from "@angular/common";
import { MonthlySummary, UserData, YearlySummary } from "../services/users/users-dto";

@Component({
  selector: "app-details-user",
  templateUrl: "./details-user.component.html",
  styleUrls: ["./details-user.component.scss"],
})
export class DetailsUserComponent implements OnInit, OnDestroy {
  public readonly userId: string;
  public user: UserData;
  public interval: NodeJS.Timeout;
  public intervalCounter: NodeJS.Timeout;
  public loading: boolean = true;
  public loadingCounter: number = 10;
  public intervalToReload: number = 20;

  constructor(
    public dialogRef: MatDialogRef<DetailsUserComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private readonly usersApiService: UsersApiService,
    private readonly location: Location,
    public dialog: DialogService,
    config: NgbPopoverConfig,
  ) {
    this.userId = data.data;
    config.triggers = "hover";
    config.container = "body";
  }

  ngOnInit(): void {
    this.location.replaceState(`vengreso-admin/users?userId=${this.userId}`);

    this.reloadNow();
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
    clearInterval(this.intervalCounter);
  }

  reloadNow() {
    clearInterval(this.interval);
    this.interval = setInterval(() => {
      this.loadUserDetails();
    }, this.intervalToReload * 1000);

    this.loadUserDetails();
  }

  loadUserDetails() {
    this.loading = true;
    this.usersApiService.getUserDetails(this.userId)
      .subscribe({
        next: (response) => {
          this.user = response.result.user;
        },
        complete: () => {
          this.loading = false;
          
          clearInterval(this.intervalCounter);
          this.loadingCounter = this.intervalToReload;
          this.intervalCounter = setInterval(() => {
            if (this.loadingCounter === 0) {
              clearInterval(this.intervalCounter);
              return;
            }

            this.loadingCounter--;
          }, 1000);
        }
      });
  }

  close(): void {
    this.dialogRef.close();
  }

  get userDetails() {
    let markdown = ``;
    
    // ========================
    // 1. BASIC INFORMATION
    // ========================
    markdown += `## 🔍 Basic Information  \n`;
    markdown += `|  |  |  \n`;
    markdown += `|----------|-------|  \n`;
    markdown += `| **First Name** | ${this.user.first_name} |  \n`;
    markdown += `| **Last Name** | ${this.user.last_name} |  \n`;
    markdown += `| **Full Name** | ${this.user.full_name} |  \n`;
    markdown += `| **Email** | ${this.user.email} |  \n`;
    markdown += `| **Verified At** | ${this.user.email_verified_at || "Not verified"} |  \n`;
    markdown += `| **User ID** | ${this.user.user_id} |  \n  \n`;

    // ========================
    // 2. PROFESSIONAL DETAILS
    // ========================
    markdown += `## 💼 Professional Details  \n`;
    markdown += `|  |  |  \n`;
    markdown += `|----------|-------|  \n`;
    markdown += `| **Company** | ${this.user.company} |  \n`;
    markdown += `| **Company ID** | ${this.user.company_id} |  \n`;
    markdown += `| **Job Title** | ${this.user.job_title} |  \n`;
    markdown += `| **Job Role** | ${this.user.job_role} |  \n`;
    markdown += `| **Department** | ${this.user.department} |  \n`;
    markdown += `| **Wage/Hour** | $${this.user.wage_per_hour} |  \n  \n`;

    // ========================
    // 3. ACCOUNT INFORMATION
    // ========================
    markdown += `## 🔑 Account Information  \n`;
    markdown += `- **Status:** ${this.user.status} (since ${this.user.status_date})  \n`;
    markdown += `- **Created At:** ${this.user.user_created_at}  \n`;
    markdown += `- **Updated At:** ${this.user.user_updated_at}  \n`;
    markdown += `- **Last Info Update:** ${this.user.updated_at}  \n`;
    markdown += `- **Last Login:** ${this.user.last_login}  \n`;
    markdown += `- **Last Login FlyLearning:** ${this.user.last_login_fly_learning}  \n`;
    markdown += `- **Sign-in Source:** ${this.user.signin_source}  \n`;
    markdown += `- **Sign-up Source:** ${this.user.signup_source || "N/A"}  \n  \n`;

    if (this.user.company_id) {
      // ========================
      // 3. CORPORATE ACCOUNT INFORMATION
      // ========================
      markdown += `## 🔑 Enterprise Information  \n`;
      markdown += `- **Company Name:** ${this.user.company_name}  \n`;
      markdown += `- **Group Name:** ${this.user.group_name}  \n`;
      markdown += `- **Plan:** ${this.user.subscription_type}  \n`;
      markdown += `- **Roles:** \n`;
      markdown += this.user.role_names.map((role) => `  - ${role}`).join('\n') + '\n';
    }

    // ========================
    // 5. PRODUCTIVITY METRICS
    // ========================
    markdown += `## 📊 Productivity Metrics  \n`;
    markdown += `### Typing Statistics  \n`;
    markdown += `- **Typing Speed:** ${this.user.typed_words_per_minute} WPM  \n`;
    markdown += `- **Total Characters Typed:** ${this.user.total___of_characters_typed_by_flymsg_by_user.toLocaleString()}  \n  \n`;

    markdown += `### Time & Cost Savings  \n`;
    markdown += `- **Total Time Saved:** ${this.user.total_time_saved_by_flymsg_by_user} hours  \n`;
    markdown += `- **Total Cost Savings:** $${this.user.total_cost_savings_by_flymsg_by_user.toLocaleString()}  \n  \n`;

    // ========================
    // 6. EXTENSION DETAILS
    // ========================
    markdown += `## ⚙️ Extension Details  \n`;
    markdown += `### Installation Status  \n`;
    markdown += `- **Any Extension Installed:** ${this.user.is_any_extension_installed ? "✅ Yes" : "❌ No"}\n`;
    
    markdown += `- **Chrome Extension:** ${this.user.is_chrome_extension_installed ? "✅ Yes" : "❌ No"} (v${this.user.flymsg_extension_version_for_chrome || "N/A"})\n`;
    if (this.user.flymsg_chrome_extension_installed__date_) {
      markdown += `- **Chrome Extension Installed Date:** ${this.user.flymsg_chrome_extension_installed__date_ || "N/A"}\n`;
    }
    markdown += `- **Edge Extension:** ${this.user.is_edge_extension_installed ? "✅ Yes" : "❌ No"} (v${this.user.flymsg_extension_version_for_edge || "N/A"})\n`;
    if (this.user.flymsg_edge_extension_installed__date_) {
      markdown += `- **Edge Extension Installed Date:** ${this.user.flymsg_edge_extension_installed__date_ || "N/A"}\n`
    }

    markdown += `### Uninstallation Status  \n`;
    markdown += `- **Chrome Extension:** ${this.user.is_chrome_extension_uninstalled ? "✅ Yes" : "❌ No"}\n`;
    if (this.user.flymsg_chrome_extension_uninstalled__date_) {
      markdown += `- **Chrome Uninstalled Date:** ${this.user.flymsg_chrome_extension_uninstalled__date_ || "N/A"}\n`;
    }
    markdown += `- **Edge Extension:** ${this.user.is_edge_extension_uninstalled ? "✅ Yes" : "❌ No"}\n`;
    if (this.user.flymsg_edge_extension_uninstalled__date_) {
      markdown += `- **Edge Uninstalled Date:** ${this.user.flymsg_edge_extension_uninstalled__date_ || "N/A"}\n`
    }
    
    // ========================
    // 8. ACTIVITY LOGS
    // ========================
    markdown += `## 📅 Activity Logs  \n`;
    markdown += `- **Last Flycut Used:** ${this.user.last_date_user_used_a_flycut || "Never"}\n`;
    markdown += `- **Last Flyposts Used:** ${this.user.last_date_user_used_flyposts ? new Date(this.user.last_date_user_used_flyposts * 1000).toLocaleString() : "Never"}\n`;
    markdown += `- **Last Flyengage Used:** ${this.user.last_date_user_used_flyengage ? new Date(this.user.last_date_user_used_flyengage * 1000).toLocaleString() : "Never"}\n`;
    markdown += `- **Help Clicked:** ${this.user.clicked_help_count} times  \n(last on ${this.user.clicked_help_last_date ?? 'Never'})\n`;
    markdown += `- **Settings Clicked:** ${this.user.clicked_settings_count} times  \n(last on ${this.user.clicked_settings_last_date ?? 'Never'})\n`;
    markdown += `- **Download Extension Clicked:** ${this.user.clicked_download_extension_count} times  \n(last on ${this.user.clicked_download_extension_last_date ?? 'Never'})\n`;
    markdown += `- **Contact Sales Clicked:** ${this.user.clicked_contact_sales_count} times  \n(last on ${this.user.clicked_contact_sales_last_date ?? 'Never'})\n`;

    markdown += `## 📈 Summary  \n`;
    markdown += `|  |  |  \n`;
    markdown += `|----------|-------|  \n`;
    markdown += `| **Total Flyposts Used** | ${this.user.total___of_times_flyposts_used__count_}  \n`;
    markdown += `| **Total Flyengage Used** | ${this.user.total___of_times_flyengage_used__count_}  \n`;
    markdown += `| **Total Flycut Used** | ${this.user.total___of_times_flycut_used__count_}  \n`;
    markdown += `| **Total Flyplates Created** | ${this.user.number_of_flyplates_in_flycuts_count}  \n`;
    markdown += `| **Total Categories Created** | ${this.user.number_of_categories_created_count}  \n`;
    markdown += `| **Total Flycuts Created** | ${this.user.number_of_flycuts_created_count}  \n`;

    function countPropertiesByYear(data: YearlySummary, property: keyof MonthlySummary) {
      return Object.values(data).reduce((acc, val) => acc + (val[property] ?? 0), 0);
    }

    if (this.user.summary_2022) {
      markdown += `### 📈 Summary 2022  \n`;
      markdown += `|  |  |  \n`;
      markdown += `|----------|-------|  \n`;
      markdown += `| **Total Characters Used** | ${countPropertiesByYear(this.user.summary_2022, 'of_characters_typed')}  \n`;
      markdown += `| **Total Time Saved** | ${countPropertiesByYear(this.user.summary_2022, 'total_time_saved')} hours  \n`;
      markdown += `| **Total Cost Savings** | $${countPropertiesByYear(this.user.summary_2022, 'total_cost_savings')?.toLocaleString()}  \n  \n`;
      markdown += `|  |  |  |  \n`;
      markdown += `|----------|-------|-------|  \n`;
      markdown += `| **January** | Characters | ${this.user.summary_2022.january.of_characters_typed ?? 0} |  \n`;
      markdown += `|  | Time Saved | ${this.user.summary_2022.january.total_time_saved ?? 0} hours |  \n`;
      markdown += `|  | Cost Saved | $${(this.user.summary_2022.january.total_cost_savings ?? 0)?.toLocaleString()} |  \n`;
      markdown += `| **February** | Characters | ${this.user.summary_2022.february.of_characters_typed ?? 0} |  \n`;
      markdown += `|  | Time Saved | ${this.user.summary_2022.february.total_time_saved ?? 0} hours |  \n`;
      markdown += `|  | Cost Saved | $${(this.user.summary_2022.february.total_cost_savings ?? 0)?.toLocaleString()} |  \n`;
      markdown += `| **March** | Characters | ${this.user.summary_2022.march.of_characters_typed ?? 0} |  \n`;
      markdown += `|  | Time Saved | ${this.user.summary_2022.march.total_time_saved ?? 0} hours |  \n`;
      markdown += `|  | Cost Saved | $${(this.user.summary_2022.march.total_cost_savings ?? 0)?.toLocaleString()} |  \n`;
      markdown += `| **April** | Characters | ${this.user.summary_2022.april.of_characters_typed ?? 0} |  \n`;
      markdown += `|  | Time Saved | ${this.user.summary_2022.april.total_time_saved ?? 0} hours |  \n`;
      markdown += `|  | Cost Saved | $${(this.user.summary_2022.april.total_cost_savings ?? 0)?.toLocaleString()} |  \n`;
      markdown += `| **May** | Characters | ${this.user.summary_2022.may.of_characters_typed ?? 0} |  \n`;
      markdown += `|  | Time Saved | ${this.user.summary_2022.may.total_time_saved ?? 0} hours |  \n`;
      markdown += `|  | Cost Saved | $${(this.user.summary_2022.may.total_cost_savings ?? 0)?.toLocaleString()} |  \n`;
      markdown += `| **June** | Characters | ${this.user.summary_2022.june.of_characters_typed ?? 0} |  \n`;
      markdown += `|  | Time Saved | ${this.user.summary_2022.june.total_time_saved ?? 0} hours |  \n`;
      markdown += `|  | Cost Saved | $${(this.user.summary_2022.june.total_cost_savings ?? 0)?.toLocaleString()} |  \n`;
      markdown += `| **July** | Characters | ${this.user.summary_2022.july.of_characters_typed ?? 0} |  \n`;
      markdown += `|  | Time Saved | ${this.user.summary_2022.july.total_time_saved ?? 0} hours |  \n`;
      markdown += `|  | Cost Saved | $${(this.user.summary_2022.july.total_cost_savings ?? 0)?.toLocaleString()} |  \n`;
      markdown += `| **August** | Characters | ${this.user.summary_2022.august.of_characters_typed ?? 0} |  \n`;
      markdown += `|  | Time Saved | ${this.user.summary_2022.august.total_time_saved ?? 0} hours |  \n`;
      markdown += `|  | Cost Saved | $${(this.user.summary_2022.august.total_cost_savings ?? 0)?.toLocaleString()} |  \n`;
      markdown += `| **September** | Characters | ${this.user.summary_2022.september.of_characters_typed ?? 0} |  \n`;
      markdown += `|  | Time Saved | ${this.user.summary_2022.september.total_time_saved ?? 0} hours |  \n`;
      markdown += `|  | Cost Saved | $${(this.user.summary_2022.september.total_cost_savings ?? 0)?.toLocaleString()} |  \n`;
      markdown += `| **October** | Characters | ${this.user.summary_2022.october.of_characters_typed ?? 0} |  \n`;
      markdown += `|  | Time Saved | ${this.user.summary_2022.october.total_time_saved ?? 0} hours |  \n`;
      markdown += `|  | Cost Saved | $${(this.user.summary_2022.october.total_cost_savings ?? 0)?.toLocaleString()} |  \n`;
      markdown += `| **November** | Characters | ${this.user.summary_2022.november.of_characters_typed ?? 0} |  \n`;
      markdown += `|  | Time Saved | ${this.user.summary_2022.november.total_time_saved ?? 0} hours |  \n`;
      markdown += `|  | Cost Saved | $${(this.user.summary_2022.november.total_cost_savings ?? 0)?.toLocaleString()} |  \n`;
      markdown += `| **December** | Characters | ${this.user.summary_2022.december.of_characters_typed ?? 0} |  \n`;
      markdown += `|  | Time Saved | ${this.user.summary_2022.december.total_time_saved ?? 0} hours |  \n`;
      markdown += `|  | Cost Saved | $${(this.user.summary_2022.december.total_cost_savings ?? 0)?.toLocaleString()} |  \n`;
    }

    if (this.user.summary_2023) {
      markdown += `### 📈 Summary 2023  \n`;
      markdown += `|  |  |  \n`;
      markdown += `|----------|-------|  \n`;
      markdown += `| **Total Characters Used** | ${countPropertiesByYear(this.user.summary_2023, 'of_characters_typed')}  \n`;
      markdown += `| **Total Time Saved** | ${countPropertiesByYear(this.user.summary_2023, 'total_time_saved')} hours  \n`;
      markdown += `| **Total Cost Savings** | $${countPropertiesByYear(this.user.summary_2023, 'total_cost_savings')?.toLocaleString()}  \n  \n`;
      markdown += `|  |  |  |  \n`;
      markdown += `|----------|-------|-------|  \n`;
      markdown += `| **January** | Characters | ${this.user.summary_2023.january.of_characters_typed ?? 0} |  \n`;
      markdown += `|  | Time Saved | ${this.user.summary_2023.january.total_time_saved ?? 0} hours |  \n`;
      markdown += `|  | Cost Saved | $${(this.user.summary_2023.january.total_cost_savings ?? 0)?.toLocaleString()} |  \n`;
      markdown += `| **February** | Characters | ${this.user.summary_2023.february.of_characters_typed ?? 0} |  \n`;
      markdown += `|  | Time Saved | ${this.user.summary_2023.february.total_time_saved ?? 0} hours |  \n`;
      markdown += `|  | Cost Saved | $${(this.user.summary_2023.february.total_cost_savings ?? 0)?.toLocaleString()} |  \n`;
      markdown += `| **March** | Characters | ${this.user.summary_2023.march.of_characters_typed ?? 0} |  \n`;
      markdown += `|  | Time Saved | ${this.user.summary_2023.march.total_time_saved ?? 0} hours |  \n`;
      markdown += `|  | Cost Saved | $${(this.user.summary_2023.march.total_cost_savings ?? 0)?.toLocaleString()} |  \n`;
      markdown += `| **April** | Characters | ${this.user.summary_2023.april.of_characters_typed ?? 0} |  \n`;
      markdown += `|  | Time Saved | ${this.user.summary_2023.april.total_time_saved ?? 0} hours |  \n`;
      markdown += `|  | Cost Saved | $${(this.user.summary_2023.april.total_cost_savings ?? 0)?.toLocaleString()} |  \n`;
      markdown += `| **May** | Characters | ${this.user.summary_2023.may.of_characters_typed ?? 0} |  \n`;
      markdown += `|  | Time Saved | ${this.user.summary_2023.may.total_time_saved ?? 0} hours |  \n`;
      markdown += `|  | Cost Saved | $${(this.user.summary_2023.may.total_cost_savings ?? 0)?.toLocaleString()} |  \n`;
      markdown += `| **June** | Characters | ${this.user.summary_2023.june.of_characters_typed ?? 0} |  \n`;
      markdown += `|  | Time Saved | ${this.user.summary_2023.june.total_time_saved ?? 0} hours |  \n`;
      markdown += `|  | Cost Saved | $${(this.user.summary_2023.june.total_cost_savings ?? 0)?.toLocaleString()} |  \n`;
      markdown += `| **July** | Characters | ${this.user.summary_2023.july.of_characters_typed ?? 0} |  \n`;
      markdown += `|  | Time Saved | ${this.user.summary_2023.july.total_time_saved ?? 0} hours |  \n`;
      markdown += `|  | Cost Saved | $${(this.user.summary_2023.july.total_cost_savings ?? 0)?.toLocaleString()} |  \n`;
      markdown += `| **August** | Characters | ${this.user.summary_2023.august.of_characters_typed ?? 0} |  \n`;
      markdown += `|  | Time Saved | ${this.user.summary_2023.august.total_time_saved ?? 0} hours |  \n`;
      markdown += `|  | Cost Saved | $${(this.user.summary_2023.august.total_cost_savings ?? 0)?.toLocaleString()} |  \n`;
      markdown += `| **September** | Characters | ${this.user.summary_2023.september.of_characters_typed ?? 0} |  \n`;
      markdown += `|  | Time Saved | ${this.user.summary_2023.september.total_time_saved ?? 0} hours |  \n`;
      markdown += `|  | Cost Saved | $${(this.user.summary_2023.september.total_cost_savings ?? 0)?.toLocaleString()} |  \n`;
      markdown += `| **October** | Characters | ${this.user.summary_2023.october.of_characters_typed ?? 0} |  \n`;
      markdown += `|  | Time Saved | ${this.user.summary_2023.october.total_time_saved ?? 0} hours |  \n`;
      markdown += `|  | Cost Saved | $${(this.user.summary_2023.october.total_cost_savings ?? 0)?.toLocaleString()} |  \n`;
      markdown += `| **November** | Characters | ${this.user.summary_2023.november.of_characters_typed ?? 0} |  \n`;
      markdown += `|  | Time Saved | ${this.user.summary_2023.november.total_time_saved ?? 0} hours |  \n`;
      markdown += `|  | Cost Saved | $${(this.user.summary_2023.november.total_cost_savings ?? 0)?.toLocaleString()} |  \n`;
      markdown += `| **December** | Characters | ${this.user.summary_2023.december.of_characters_typed ?? 0} |  \n`;
      markdown += `|  | Time Saved | ${this.user.summary_2023.december.total_time_saved ?? 0} hours |  \n`;
      markdown += `|  | Cost Saved | $${(this.user.summary_2023.december.total_cost_savings ?? 0)?.toLocaleString()} |  \n`;
    }

    if (this.user.summary_2024) {
      markdown += `### 📈 Summary 2024  \n`;
      markdown += `|  |  |  \n`;
      markdown += `|----------|-------|  \n`;
      markdown += `| **Total Characters Used** | ${countPropertiesByYear(this.user.summary_2024, 'of_characters_typed')}  \n`;
      markdown += `| **Total Time Saved** | ${countPropertiesByYear(this.user.summary_2024, 'total_time_saved')} hours  \n`;
      markdown += `| **Total Cost Savings** | $${countPropertiesByYear(this.user.summary_2024, 'total_cost_savings')?.toLocaleString()}  \n  \n`;
      markdown += `|  |  |  |  \n`;
      markdown += `|----------|-------|-------|  \n`;
      markdown += `| **January** | Characters | ${this.user.summary_2024.january.of_characters_typed ?? 0} |  \n`;
      markdown += `|  | Time Saved | ${this.user.summary_2024.january.total_time_saved ?? 0} hours |  \n`;
      markdown += `|  | Cost Saved | $${(this.user.summary_2024.january.total_cost_savings ?? 0)?.toLocaleString()} |  \n`;
      markdown += `| **February** | Characters | ${this.user.summary_2024.february.of_characters_typed ?? 0} |  \n`;
      markdown += `|  | Time Saved | ${this.user.summary_2024.february.total_time_saved ?? 0} hours |  \n`;
      markdown += `|  | Cost Saved | $${(this.user.summary_2024.february.total_cost_savings ?? 0)?.toLocaleString()} |  \n`;
      markdown += `| **March** | Characters | ${this.user.summary_2024.march.of_characters_typed ?? 0} |  \n`;
      markdown += `|  | Time Saved | ${this.user.summary_2024.march.total_time_saved ?? 0} hours |  \n`;
      markdown += `|  | Cost Saved | $${(this.user.summary_2024.march.total_cost_savings ?? 0)?.toLocaleString()} |  \n`;
      markdown += `| **April** | Characters | ${this.user.summary_2024.april.of_characters_typed ?? 0} |  \n`;
      markdown += `|  | Time Saved | ${this.user.summary_2024.april.total_time_saved ?? 0} hours |  \n`;
      markdown += `|  | Cost Saved | $${(this.user.summary_2024.april.total_cost_savings ?? 0)?.toLocaleString()} |  \n`;
      markdown += `| **May** | Characters | ${this.user.summary_2024.may.of_characters_typed ?? 0} |  \n`;
      markdown += `|  | Time Saved | ${this.user.summary_2024.may.total_time_saved ?? 0} hours |  \n`;
      markdown += `|  | Cost Saved | $${(this.user.summary_2024.may.total_cost_savings ?? 0)?.toLocaleString()} |  \n`;
      markdown += `| **June** | Characters | ${this.user.summary_2024.june.of_characters_typed ?? 0} |  \n`;
      markdown += `|  | Time Saved | ${this.user.summary_2024.june.total_time_saved ?? 0} hours |  \n`;
      markdown += `|  | Cost Saved | $${(this.user.summary_2024.june.total_cost_savings ?? 0)?.toLocaleString()} |  \n`;
      markdown += `| **July** | Characters | ${this.user.summary_2024.july.of_characters_typed ?? 0} |  \n`;
      markdown += `|  | Time Saved | ${this.user.summary_2024.july.total_time_saved ?? 0} hours |  \n`;
      markdown += `|  | Cost Saved | $${(this.user.summary_2024.july.total_cost_savings ?? 0)?.toLocaleString()} |  \n`;
      markdown += `| **August** | Characters | ${this.user.summary_2024.august.of_characters_typed ?? 0} |  \n`;
      markdown += `|  | Time Saved | ${this.user.summary_2024.august.total_time_saved ?? 0} hours |  \n`;
      markdown += `|  | Cost Saved | $${(this.user.summary_2024.august.total_cost_savings ?? 0)?.toLocaleString()} |  \n`;
      markdown += `| **September** | Characters | ${this.user.summary_2024.september.of_characters_typed ?? 0} |  \n`;
      markdown += `|  | Time Saved | ${this.user.summary_2024.september.total_time_saved ?? 0} hours |  \n`;
      markdown += `|  | Cost Saved | $${(this.user.summary_2024.september.total_cost_savings ?? 0)?.toLocaleString()} |  \n`;
      markdown += `| **October** | Characters | ${this.user.summary_2024.october.of_characters_typed ?? 0} |  \n`;
      markdown += `|  | Time Saved | ${this.user.summary_2024.october.total_time_saved ?? 0} hours |  \n`;
      markdown += `|  | Cost Saved | $${(this.user.summary_2024.october.total_cost_savings ?? 0)?.toLocaleString()} |  \n`;
      markdown += `| **November** | Characters | ${this.user.summary_2024.november.of_characters_typed ?? 0} |  \n`;
      markdown += `|  | Time Saved | ${this.user.summary_2024.november.total_time_saved ?? 0} hours |  \n`;
      markdown += `|  | Cost Saved | $${(this.user.summary_2024.november.total_cost_savings ?? 0)?.toLocaleString()} |  \n`;
      markdown += `| **December** | Characters | ${this.user.summary_2024.december.of_characters_typed ?? 0} |  \n`;
      markdown += `|  | Time Saved | ${this.user.summary_2024.december.total_time_saved ?? 0} hours |  \n`;
      markdown += `|  | Cost Saved | $${(this.user.summary_2024.december.total_cost_savings ?? 0)?.toLocaleString()} |  \n`;
    }

    if (this.user.summary_2025) {
      markdown += `### 📈 Summary 2025  \n`;
      markdown += `|  |  |  \n`;
      markdown += `|----------|-------|  \n`;
      markdown += `| **Total Characters Used** | ${countPropertiesByYear(this.user.summary_2025, 'of_characters_typed')}  \n`;
      markdown += `| **Total Time Saved** | ${countPropertiesByYear(this.user.summary_2025, 'total_time_saved')} hours  \n`;
      markdown += `| **Total Cost Savings** | $${countPropertiesByYear(this.user.summary_2025, 'total_cost_savings')?.toLocaleString()}  \n  \n`;
      markdown += `|  |  |  |  \n`;
      markdown += `|----------|-------|-------|  \n`;
      markdown += `| **January** | Characters | ${this.user.summary_2025.january.of_characters_typed ?? 0} |  \n`;
      markdown += `|  | Time Saved | ${this.user.summary_2025.january.total_time_saved ?? 0} hours |  \n`;
      markdown += `|  | Cost Saved | $${(this.user.summary_2025.january.total_cost_savings ?? 0)?.toLocaleString()} |  \n`;
      markdown += `| **February** | Characters | ${this.user.summary_2025.february.of_characters_typed ?? 0} |  \n`;
      markdown += `|  | Time Saved | ${this.user.summary_2025.february.total_time_saved ?? 0} hours |  \n`;
      markdown += `|  | Cost Saved | $${(this.user.summary_2025.february.total_cost_savings ?? 0)?.toLocaleString()} |  \n`;
      markdown += `| **March** | Characters | ${this.user.summary_2025.march.of_characters_typed ?? 0} |  \n`;
      markdown += `|  | Time Saved | ${this.user.summary_2025.march.total_time_saved ?? 0} hours |  \n`;
      markdown += `|  | Cost Saved | $${(this.user.summary_2025.march.total_cost_savings ?? 0)?.toLocaleString()} |  \n`;
      markdown += `| **April** | Characters | ${this.user.summary_2025.april.of_characters_typed ?? 0} |  \n`;
      markdown += `|  | Time Saved | ${this.user.summary_2025.april.total_time_saved ?? 0} hours |  \n`;
      markdown += `|  | Cost Saved | $${(this.user.summary_2025.april.total_cost_savings ?? 0)?.toLocaleString()} |  \n`;
      markdown += `| **May** | Characters | ${this.user.summary_2025.may.of_characters_typed ?? 0} |  \n`;
      markdown += `|  | Time Saved | ${this.user.summary_2025.may.total_time_saved ?? 0} hours |  \n`;
      markdown += `|  | Cost Saved | $${(this.user.summary_2025.may.total_cost_savings ?? 0)?.toLocaleString()} |  \n`;
      markdown += `| **June** | Characters | ${this.user.summary_2025.june.of_characters_typed ?? 0} |  \n`;
      markdown += `|  | Time Saved | ${this.user.summary_2025.june.total_time_saved ?? 0} hours |  \n`;
      markdown += `|  | Cost Saved | $${(this.user.summary_2025.june.total_cost_savings ?? 0)?.toLocaleString()} |  \n`;
      markdown += `| **July** | Characters | ${this.user.summary_2025.july.of_characters_typed ?? 0} |  \n`;
      markdown += `|  | Time Saved | ${this.user.summary_2025.july.total_time_saved ?? 0} hours |  \n`;
      markdown += `|  | Cost Saved | $${(this.user.summary_2025.july.total_cost_savings ?? 0)?.toLocaleString()} |  \n`;
      markdown += `| **August** | Characters | ${this.user.summary_2025.august.of_characters_typed ?? 0} |  \n`;
      markdown += `|  | Time Saved | ${this.user.summary_2025.august.total_time_saved ?? 0} hours |  \n`;
      markdown += `|  | Cost Saved | $${(this.user.summary_2025.august.total_cost_savings ?? 0)?.toLocaleString()} |  \n`;
      markdown += `| **September** | Characters | ${this.user.summary_2025.september.of_characters_typed ?? 0} |  \n`;
      markdown += `|  | Time Saved | ${this.user.summary_2025.september.total_time_saved ?? 0} hours |  \n`;
      markdown += `|  | Cost Saved | $${(this.user.summary_2025.september.total_cost_savings ?? 0)?.toLocaleString()} |  \n`;
      markdown += `| **October** | Characters | ${this.user.summary_2025.october.of_characters_typed ?? 0} |  \n`;
      markdown += `|  | Time Saved | ${this.user.summary_2025.october.total_time_saved ?? 0} hours |  \n`;
      markdown += `|  | Cost Saved | $${(this.user.summary_2025.october.total_cost_savings ?? 0)?.toLocaleString()} |  \n`;
      markdown += `| **November** | Characters | ${this.user.summary_2025.november.of_characters_typed ?? 0} |  \n`;
      markdown += `|  | Time Saved | ${this.user.summary_2025.november.total_time_saved ?? 0} hours |  \n`;
      markdown += `|  | Cost Saved | $${(this.user.summary_2025.november.total_cost_savings ?? 0)?.toLocaleString()} |  \n`;
      markdown += `| **December** | Characters | ${this.user.summary_2025.december.of_characters_typed ?? 0} |  \n`;
      markdown += `|  | Time Saved | ${this.user.summary_2025.december.total_time_saved ?? 0} hours |  \n`;
      markdown += `|  | Cost Saved | $${(this.user.summary_2025.december.total_cost_savings ?? 0)?.toLocaleString()} |  \n`;
    }

    return markdown;
  }
  
  get userDetails2() {
    let markdown = ``;

    // ========================
    // 4. SUBSCRIPTION & BILLING
    // ========================
    markdown += `## 💳 Subscription & Billing  \n`;
    markdown += `### Plan Details  \n`;
    markdown += `- **Type:** ${this.user.subscription_type}  \n`;
    markdown += `- **Plan Name:** ${this.user.plan_name}  \n`;
    markdown += `- **Plan ID:** ${this.user.plan_id}  \n  \n`;

    markdown += `### Payment Information  \n`;
    markdown += `|  |  |  \n`;
    markdown += `|----------|-------|  \n`;
    markdown += `| **Stripe ID** | ${this.user.stripe_id} |  \n`;
    markdown += `| **Payment Method** | ${this.user.payment_method} |  \n`;
    markdown += `| **Completed Payments** | ${this.user.number_of_completed_payments} |  \n`;
    markdown += `| **Expected Payments** | ${this.user.number_of_expected_payments} |  \n`;
    if (this.user.coupon_name) {
      markdown += `| **Coupon Name** | ${this.user.coupon_name} |  \n`;
    }
    if (this.user.coupon_code) {
      markdown += `| **Code** | ${this.user.coupon_code} |  \n`;
    }
    if (this.user.coupon_type) {
      markdown += `| **Type** | ${this.user.coupon_type} |  \n`;
    }
    if (this.user.duration) {
      markdown += `| **Duration** | ${this.user.duration} |  \n`;
    }
    if (this.user.coupon_value__discount_) {
      markdown += `| **Discount** | ${this.user.coupon_value__discount_} |  \n`;
    }
    if (this.user.coupon_redemption_limits) {
      markdown += `| **Redemption Limits** | ${this.user.coupon_redemption_limits} |  \n  \n`;
    }

    if (this.user.flymsg_freemium_subscription_status) {
      markdown += `### Freemium Subscription  \n`;
      markdown += `|  |  |  \n`;
      markdown += `|----------|-------|  \n`;
      markdown += `| **Status** | ${this.user.flymsg_freemium_subscription_status} |  \n`;
      markdown += `| **Start Date** | ${this.user.freemium_subscription_start_date} |  \n`;
      markdown += `| **Updated On** | ${this.user.freemium_subscription_status_updated_on} |  \n`;
      markdown += `| **Churn Date** | ${this.user.freemium_subscription_churn_date} |  \n`;
      markdown += `| **Cancel Date** | ${this.user.freemium_cancel_subscription_date} |  \n  \n`;
    }

    if (this.user.flymsg_starter_subscription_status) {
      markdown += `### Starter Subscription  \n`;
      markdown += `|  |  |  \n`;
      markdown += `|----------|-------|  \n`;
      markdown += `| **Status** | ${this.user.flymsg_starter_subscription_status} |  \n`;
      markdown += `| **Updated On** | ${this.user.starter_subscription_status_updated_on} |  \n`;
      markdown += `| **Start Date** | ${this.user.starter_subscription_start_date} |  \n`;
      markdown += `| **Frequency** | ${this.user.flymsg_starter_subscription_frequency} |  \n`;
      markdown += `| **Expiration Date** | ${this.user.starter_subscription_expiration_date} |  \n`;
      markdown += `| **Cancel Date** | ${this.user.starter_cancel_subscription_date} |  \n`;
      markdown += `| **Churn Date** | ${this.user.starter_subscription_churn_date} |  \n`;
      markdown += `| **Payment Status** | ${this.user.starter_payment_status} |  \n`;
      markdown += `| **Monthly Recurring Revenue** | ${this.user.flymsg_starter_subscription_monthly_recurring_revenue} |  \n`;
      markdown += `| **Annual Recurring Revenue** | ${this.user.flymsg_starter_subscription_annual_recurring_revenue} |  \n  \n`;
    }

    if (this.user.flymsg_growth_subscription_status) {
      markdown += `### Growth Subscription  \n`;
      markdown += `|  |  |  \n`;
      markdown += `|----------|-------|  \n`;
      markdown += `| **Status** | ${this.user.flymsg_growth_subscription_status} |  \n`;
      markdown += `| **Updated On** | ${this.user.growth_subscription_status_updated_on} |  \n`;
      markdown += `| **Start Date** | ${this.user.growth_subscription_start_date} |  \n`;
      markdown += `| **Frequency** | ${this.user.flymsg_growth_subscription_frequency} |  \n`;
      markdown += `| **Expiration Date** | ${this.user.growth_subscription_expiration_date} |  \n`;
      markdown += `| **Cancel Date** | ${this.user.growth_cancel_subscription_date} |  \n`;
      markdown += `| **Churn Date** | ${this.user.growth_subscription_churn_date} |  \n`;
      markdown += `| **Payment Status** | ${this.user.growth_payment_status} |  \n`;
      markdown += `| **Monthly Recurring Revenue** | ${this.user.flymsg_growth_subscription_monthly_recurring_revenue} |  \n`;
      markdown += `| **Annual Recurring Revenue** | ${this.user.growth_subscription_annual_recurring_revenue} |  \n  \n`;
    }

    if (this.user.professional_subscription_status) {
      markdown += `### Professional Subscription  \n`;
      markdown += `|  |  |  \n`;
      markdown += `|----------|-------|  \n`;
      markdown += `| **Status** | ${this.user.professional_subscription_status} |  \n`;
      markdown += `| **Updated On** | ${this.user.professional_subscription_status_updated_on} |  \n`;
      markdown += `| **Start Date** | ${this.user.professional_subscription_start_date} |  \n`;
      markdown += `| **Frequency** | ${this.user.professional_subscription_frequency} |  \n`;
      markdown += `| **Expiration Date** | ${this.user.professional_subscription_expiration_date} |  \n`;
      markdown += `| **Cancel Date** | ${this.user.professional_cancel_subscription_date} |  \n`;
      markdown += `| **Churn Date** | ${this.user.professional_subscription_churn_date} |  \n`;
      markdown += `| **Payment Status** | ${this.user.professional_payment_status} |  \n`;
      markdown += `| **Monthly Recurring Revenue** | ${this.user.professional_subscription_monthly_recurring_revenue} |  \n`;
      markdown += `| **Annual Recurring Revenue** | ${this.user.professional_subscription_annual_recurring_revenue} |  \n  \n`;
    }

    if (this.user.trail_period_start_date) {
      markdown += `## Professional Trial Subscription  \n`;
      markdown += `|  |  |  \n`;
      markdown += `|----------|-------|  \n`;
      markdown += `| **Canceled Date** | ${this.user.trail_period_canceled_date} |  \n`;
      markdown += `| **Start Date** | ${this.user.trail_period_start_date} |  \n`;
      markdown += `| **End Date** | ${this.user.trail_period_end_date} |  \n`;
    }
    
    if (this.user.sales_pro_teams_subscription_status) {
      markdown += `## Enterprise Subscription  \n`;
      markdown += `|  |  |  \n`;
      markdown += `|----------|-------|  \n`;
      markdown += `| **Status** | ${this.user.sales_pro_teams_subscription_status} |  \n`;
      markdown += `| **Updated On** | ${this.user.sales_pro_teams_subscription_status_updated_on} |  \n`;
      markdown += `| **Start Date** | ${this.user.sales_pro_teams_subscription_start_date} |  \n`;
      markdown += `| **Frequency** | ${this.user.sales_pro_teams_subscription_frequency} |  \n`;
      markdown += `| **Expiration Date** | ${this.user.sales_pro_teams_subscription_expiration_date} |  \n`;
      markdown += `| **Cancel Date** | ${this.user.sales_pro_teams_cancel_subscription_date} |  \n`;
      markdown += `| **Churn Date** | ${this.user.sales_pro_teams_subscription_churn_date} |  \n`;
      markdown += `| **Plan Type** | ${this.user.sales_pro_teams_subscription_plan_type} |  \n`;
      markdown += `| **User Type** | ${this.user.sales_pro_teams_user_type} |  \n  \n`;
    }

    // ========================
    // 7. ADDITIONAL METADATA
    // ========================
    markdown += `## 📌 Additional Metadata  \n`;
    markdown += `### External IDs  \n`;
    markdown += `- **HubSpot ID:** ${this.user.hubspot_id}  \n`;
    markdown += `- **Instancy ID:** ${this.user.instancy_id}  \n`;
    markdown += `- **Group ID:** ${this.user.group_id}  \n  \n`;

    markdown += `### Contact Information  \n`;
    markdown += `- **Phone:** ${this.user.phone}  \n`;
    markdown += `- **LinkedIn:** [Profile Link](${this.user.linkedin_url})  \n  \n`;

    return markdown;
  }
}
