import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-backdrop',
  templateUrl: './backdrop.component.html',
  styleUrls: ['./backdrop.component.scss']
})
export class BackdropComponent implements OnInit {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    // this.renderer.setStyle(this.el.nativeElement, 'position', 'fixed');
    // this.renderer.setStyle(this.el.nativeElement, 'top', '0');
    // this.renderer.setStyle(this.el.nativeElement, 'left', '0');
    // this.renderer.setStyle(this.el.nativeElement, 'width', '100vw');
    // this.renderer.setStyle(this.el.nativeElement, 'height', '100vh');
    // this.renderer.setStyle(this.el.nativeElement, 'background', 'rgba(0, 0, 0, 0.5)');
    // this.renderer.setStyle(this.el.nativeElement, 'z-index', '1000');
  }
}
