import { Component, OnInit } from '@angular/core';
import { Location } from "@angular/common";
import { MatDialogRef } from '@angular/material/dialog';
import { AdminApiService } from '../services/admin-api.service';
import { NotificationService } from 'src/app/core/core-services/services/notification.service';
import { AuthService } from 'src/app/core/core-services/services/auth.service';
import { FormBuilder, FormGroup, Validators, } from '@angular/forms';
import { lastValueFrom } from 'rxjs';
import { Router } from '@angular/router';
import { UsersApiService } from '../services/users/users-api.service';
import { CompanyLicense } from '../services/company/companies-dto';
import { CompaniesApiService } from '../services/company/companies-api.service';
import { ValidationConstants } from 'src/app/core/core-services/constants/validation.constants';

@Component({
  selector: 'app-add-user-manually',
  templateUrl: './add-user-manually.component.html',
  styleUrls: ['./add-user-manually.component.scss']
})
export class AddUserManuallyComponent implements OnInit {
  form: FormGroup;
  invalidEmail: boolean = false;
  plans: CompanyLicense[] = [];
  
  constructor(
    public dialogRef: MatDialogRef<AddUserManuallyComponent>,
    private readonly adminApiService: AdminApiService,
    private readonly companiesApiService: CompaniesApiService,
    private readonly usersApiService: UsersApiService,
    private readonly notification: NotificationService,
    private readonly authService: AuthService,
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly location: Location
  ) { }

  ngOnInit(): void {
    let currentUrl = this.router.url;
    if(!currentUrl.includes("add-manually")){
      this.location.replaceState(currentUrl+"/add-manually");
    }

    this.companiesApiService.getCompanyLicenses().subscribe({
      next: (response) => {
        this.plans = response.result.licenses;
      }, 
      error: (error) => console.log(error)
    });

    this.form = this.fb.group({
      first_name: ['', [Validators.required]],
      last_name: [ '',[Validators.required]],
      email: ['',[Validators.required, Validators.email,Validators.pattern(ValidationConstants.EMAIL_EXTRA_REGEX)]],
      selected_plan: ['', [Validators.required]],
    });
  }

  async onAdd() {
    const data = this.form.value;

    const response: any =  await lastValueFrom(this.usersApiService.validateEmailUniqueness({
      emails: [data.email],
      company_id: this.authService.companyId
    }));

    if (response?.result?.exists === true) {
      this.invalidEmail = true;
      this.form.controls['email'].setErrors({ existentEmail: true });
      return;
    } else {
      this.invalidEmail = false;
      this.form.controls['email'].setErrors(null);
    }
    
    this.adminApiService.addUserManually(data).subscribe({
      next: (response: any) => {
        if (response?.result.success) {
          this.notification.toastWithConfig("User added", null, {
            verticalPosition: "top",
            horizontalPosition: "center",
            panelClass: ["green-notification"],
          });

          this.close(response.result.user);
        }
      },
      error: (error) => console.log(error)
    });
  }

  ngOnDestroy(): void {
    this.location.replaceState(
      this.router.url.replace("/add-manually", "")
    );
  }

  close(data?: any) {
    this.location.replaceState(
      this.router.url.replace("/add-manually", "")
    );
    this.dialogRef.close(data);
  }
}
