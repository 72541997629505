import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDatepicker } from "@angular/material/datepicker";
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { Router } from '@angular/router';
import { Location } from "@angular/common";
import { Country, ICountry } from "country-state-city";
import { findIndex } from "lodash";

import { SuperAdminApiService } from '../../services/super-admin-api.service';
import { NotificationService } from 'src/app/core/core-services/services/notification.service';
import moment from 'moment';
import { NotifyService } from 'src/app/core/menu/not.service';
import { NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';

const MY_FORMATS = {
  parse: {
    dateInput: "MM/DD/YYYY",
  },
  display: {
    dateInput: "MM/DD/YYYY",
    monthYearLabel: "MMMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};
@Component({
  selector: 'app-add-new-company',
  templateUrl: './add-new-company.component.html',
  styleUrls: ['./add-new-company.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class AddNewCompanyComponent implements OnInit, OnDestroy {
  addCompanyForm: FormGroup;
  countries: ICountry[] = Country.getAllCountries();
  startDatePicker: MatDatepicker<any>;
  termsOfContract: any[] = [
    {label: "6 months", interval_in_months: 6},
    {label: "1 year", interval_in_months: 12},
    {label: "2 years", interval_in_months: 24},
    {label: "Custom", interval_in_months: 0},
  ];
  licenseTypes: any[] = [
    {type: 'starter', label: 'Starter', min: 0},
    {type: 'growth', label: 'Growth', min: 0},
    {type: 'sales_pro', label: 'Sales Pro', min: 0},
    {type: 'sales_pro_teams_smb', label: 'Sales Pro Teams', min: 1},
  ];
  businessProOptions: any[] = [
    {value: "yes_dedicated", label: "Yes, dedicated training and coaching"},
    {value: "yes_community", label: "Yes, community training and coaching"},
    {value: "no", label: "No"}
  ];
  stepOne: boolean = true;
  stepTwo: boolean = false;
  stepThree: boolean = false;
  pocForms: any[] = [{
    form : new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      first_name: new FormControl('', Validators.required),
      last_name: new FormControl('', Validators.required),
    }), 
    expanded: true
  }];
  loading: boolean = false;


  constructor(
    private router: Router,
    private location: Location,
    private fb: FormBuilder,
    private apiService: SuperAdminApiService,
    public dialogRef: MatDialogRef<AddNewCompanyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notification: NotificationService,
    private cd: ChangeDetectorRef,
    public notifyService: NotifyService,
    private config: NgbPopoverConfig,
  ) {
    config.triggers = "hover";
    config.container = "body";
    let USAIndex = findIndex(this.countries, { name: "United States" });
    [this.countries[0], this.countries[USAIndex]] = [
      this.countries[USAIndex],
      this.countries[0],
    ];
   }

  ngOnInit(): void {
    let currentUrl = this.router.url;
    if(! currentUrl.includes("add-new-company")){
      this.location.replaceState(currentUrl+"/add-new-company");
    }

    this.addCompanyForm = this.fb.group({
      company_name: ['', Validators.required],
      company_address_line1: ['', Validators.required],
      company_address_line2: [''],
      city: [''],
      state: [''],
      country: ['', Validators.required],
      zip_code: [''],
      term_of_contract: [12, Validators.required],
      custom_term_of_contract: [null],
      contract_start_date: ['', Validators.required],
      contract_end_date: [''],
      business_pro_enterpise_plus: [[], [Validators.required, this.validateBusinessProSelection]],
      total_licenses: [1, [Validators.required, Validators.min(1)]],
      starter: [0, Validators.min(0)],
      growth: [0, Validators.min(0)],
      sales_pro: [0, Validators.min(0)],
      sales_pro_teams_smb: [1, Validators.min(1)],
      auto_renewal: [false],
    });

    this.onChanges();
  }

  onChanges(): void {
    this.addCompanyForm.get('term_of_contract')?.valueChanges.subscribe(selectedValue => {
      const customTermOfContractField = this.addCompanyForm.get('custom_term_of_contract');

      if (selectedValue == 0) {
        customTermOfContractField?.setValidators([Validators.required, Validators.min(0)]);
      } else {
        customTermOfContractField?.clearValidators();
      }

      // Re-evaluate the validation status
      customTermOfContractField?.updateValueAndValidity();
      this.calculateEndDate();
    });

    this.addCompanyForm.get('contract_start_date')!.valueChanges.subscribe(() => {
      this.calculateEndDate();
    });

    this.addCompanyForm.get('custom_term_of_contract')!.valueChanges.subscribe(() => {
      this.calculateEndDate();
    });
  }

  private calculateEndDate() {
    const startDate = this.addCompanyForm.get('contract_start_date')?.value;
    const months = this.addCompanyForm.get('term_of_contract')?.value !== 0 ?
                   this.addCompanyForm.get('term_of_contract')?.value :
                   this.addCompanyForm.get('custom_term_of_contract')?.value;

    if (startDate && months) {
      const end = moment(startDate).add(Number(months), 'months');

      this.addCompanyForm.get('contract_end_date')?.setValue(end.format('MM/DD/YYYY'));
    }
  }  

  validateBusinessProSelection(control: any) {
    const selectedOptions = control.value;

    if (selectedOptions.includes('no') && (selectedOptions.includes('yes_dedicated') || selectedOptions.includes('yes_community'))) {
      return { invalidSelection: true };
    }

    return null;
  }

  addCompany(): void {
    if(this.stepOne) {
      this.moveToStep(2);
      return;
    }

    if (this.stepTwo) {
      this.moveToStep(3);
      return;
    }

    this.loading = true;
    const apiData = {
      company: {
        ...this.addCompanyForm.value, 
        contract_start_date: moment(this.addCompanyForm.get('contract_start_date')?.value).format('MM/DD/YY')
      },
      pocs: this.pocForms.map((pocForm: any) => pocForm.form.value)
    }
    this.apiService.addCompany(apiData).subscribe({
      next: (response: any) => {
        this.dialogRef.close(response.result.data);
        this.notifyService.notifyAboutChange({action: 'company-added'});
        this.notification.toastWithConfig("New company added successfully", null, {
          verticalPosition: "top",
          horizontalPosition: "center",
          panelClass: ["green-notification"],
        });
      },
      error: (error: any) => {
        console.log(error),
        this.loading = false
      },
      complete: () => this.loading = false
    })
  }

  increment(field: string): void {
    const currentValue = this.addCompanyForm.get(field)?.value || 0;
    this.addCompanyForm.get(field)?.setValue(currentValue + 1);
    this.addCompanyForm.updateValueAndValidity();
  }

  decrement(field: string): void {
    const currentValue = this.addCompanyForm.get(field)?.value || 0;
    const min = this.licenseTypes.find(license => license.type === field)?.min || 0;
    if (currentValue > min) {
      this.addCompanyForm.get(field)?.setValue(currentValue - 1);
    }
    this.addCompanyForm.updateValueAndValidity();
  }

  get licenseExceedError(): boolean {
    const totalLicenseInputted = this.licenseTypes.reduce((accumulator, currentItem) => {
      return accumulator + this.addCompanyForm.get(currentItem.type)?.value || 0;
    }, 0)

    const totalLicenses = this.addCompanyForm.get('total_licenses')?.value || 0
    
    return totalLicenseInputted !== totalLicenses;
  }

  moveToStep(step: number) {
    this.stepOne = step === 1;
    this.stepTwo = step === 2;
    this.stepThree = step === 3;
    if (this.stepThree) {
      this.dialogRef.updateSize('400px');
    } else {
      this.dialogRef.updateSize('600px');
    }
  }

  addPocForm(): void {
    if (this.pocForms.length < 5) {
      let newForm = new FormGroup({
        email: new FormControl('', [Validators.required, Validators.email]),
        first_name: new FormControl('', Validators.required),
        last_name: new FormControl('', Validators.required),
      });
      this.pocForms.push({form : newForm, expanded: true});
    }
  }
 
  togglePocForm(index: number): void {
    this.pocForms[index].expanded = ! this.pocForms[index].expanded;
    this.cd.detectChanges();
  }

  removePocForm(index: number): void {
    if (this.pocForms.length > 1){
      this.pocForms.splice(index, 1);
    }
  }

  get invalidPocForm(): boolean {
    return this.pocForms.some((pocForm: any) => pocForm.form.invalid);
  }

  ngOnDestroy(): void {
    this.location.replaceState(
      this.router.url.replace("/add-new-company", "")
    );
  }

  close(data?: any): void {
    if(this.stepThree) {
      this.moveToStep(2);
      return;
    }

    if(this.stepTwo) {
      this.moveToStep(1);
      return;
    }
    this.location.replaceState(
      this.router.url.replace("/add-new-company", "")
    );
    this.dialogRef.close(data);
  }
}
