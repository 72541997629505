import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";

import { AuthService } from "./auth.service";
import { NavigationService } from './navigation.service';
import { LoadingService } from './loader.service';
import { SharedDataService } from './shared-data.service';

@Injectable()
export class AdminGuardService implements CanActivate {
  constructor(
    private readonly authService: AuthService,
    private readonly loadingService: LoadingService,
    private readonly sharedData: SharedDataService,
    private readonly nav: NavigationService,
    private readonly titleService: Title
  ) {
    this.loadingService.start();
    setTimeout(() => {
      this.loadingService.stop();
    }, 1000);
  }

  validate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.authService.showAdminSection() && !this.authService.showVengresoAdminSection()) {
      this.nav.navigateTo(["/flyboard"]);
      return false;
    }

    const url: string = state.url;
    const roles = this.authService.getUserRole();

    if (roles.some(role => ["Vengreso Admin", "Global Admin", "Reporting Admin", "Group Admin"].includes(role))) {
      const companySlug = this.authService.getUserCompanySlug();
      const companySlugRoute = state.url.split('/').filter(a => a)[0];
      
      if (this.authService.onCompanyMasqueradeSession) {
        const companyName = this.authService.getMasqueradeCompanyName;
        this.titleService.setTitle(`Admin - ${companyName}`);

        if (companySlug !== companySlugRoute) {
          this.nav.navigateTo(["/"+companySlug+"/admin/users"]);
          return false;
        }
      }

      if (!url.includes("/admin") || (!this.authService.onCompanyMasqueradeSession && companySlug !== companySlugRoute)) {
        this.nav.navigateTo(["/"+companySlug+"/admin/users"]);
        return false;
      }
    } else {
      this.nav.navigateTo(["/flyboard"]);
      return false;
    }

    return true;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
    const { session } = route.queryParams;
    const currentId = sessionStorage.getItem('fms-cmc-masquerading-company-id');
    if (session || currentId) {
      const company = this.sharedData.getAttribute(session ?? currentId);

      if (company) {
        sessionStorage.setItem('fms-cmc-masquerading-company-id', company.company_id);
        sessionStorage.setItem('fms-cmc-masquerading-company-slug', company.slug);
        sessionStorage.setItem('fms-cmc-masquerading-company-name', company.name);
        this.sharedData.deleteAttribute(session ?? currentId);
        // remove the query param
        this.nav.navigateTo([state.url.split('?')[0]]);
      }
    }
    
    return this.validate(route, state);
  }
}
