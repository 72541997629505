import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject, throwError } from "rxjs";
import { catchError, filter, take, switchMap } from "rxjs/operators";
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpEvent,
} from "@angular/common/http";

import { AuthService } from "../services/auth.service";
import { SharedDataService } from "./../services/shared-data.service";
import { ApplicationConstants } from "./../constants/application.constants";
import { detect } from "detect-browser";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  headers: any;
  private refreshTokenInProgress = false;
  // Refresh Token Subject tracks the current token, or is null if no token is currently
  // available (e.g. refresh pending).
  private readonly refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(
    private readonly auth: AuthService,
    private readonly sharedData: SharedDataService
  ) {}

  addAccessToken(req: HttpRequest<any>, res?: any) {
    let ulrHeards = req.url.includes("v1/user/tracking-extension");
    let hasImportCsvUrl = req.url.includes("v1/admin-center") && req.url.includes("users/import-csv");
    this.headers = {
      accept:
          req.body && req.body.isExternalRequest
            ? "*/*"
            : ApplicationConstants.ACCEPT,
    }

    if (ulrHeards) {
      this.headers["Content-Type"] = "application/x-www-form-urlencoded; charset=UTF-8";
    } else if (!hasImportCsvUrl) {
      this.headers["Content-Type"] = ApplicationConstants.CONTENT_TYPE;
    }


    // Get the auth token from the service.
    const accessToken =res?.access_token ? res.access_token : this.auth.getAccessToken();

    // conditionally add Authorization header
    // if noCredentials is true means we do not send the accessToken with API.
    if (accessToken && (!req.body || (req.body && !req.body.noCredentials))) {
      this.headers.Authorization = "Bearer " + accessToken;
    }

    if (req.body) {
      delete req.body.noCredentials;
    }

    // Clone the request and replace the original headers with
    // cloned headers, updated with the authorization.
    return req.clone({
      setHeaders: this.headers,
      body: req.body && req.body.value ? req.body.value : req.body,
    });
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const request = this.addAccessToken(req);

    // send cloned request with header to the next handler.
    return next.handle(request).pipe(
      catchError((error: any) => {
        // We don't want to refresh token for some requests like login or refresh token itself
        // So we verify url and we throw an error if it's the case
        if (
          request.url.includes("auth/refresh") ||
          request.url.includes("auth/login")
        ) {
          // We do another check to see if refresh token failed
          // In this case we want to logout user and to redirect it to login page
          if (request.url.includes("refresh")) {
            console.log("refresh failed", this.refreshTokenInProgress, this);
            this.refreshTokenInProgress = false;
            this.auth.redirectAfterLogout();
          }

          if (error.status === 422) {
            error.error.error = error.error.message ?? error.error.error_description
          }
          
          return throwError(error);
        }

        // If error status is different than 401 we want to skip refresh token
        // So we check that and throw the error if it's the case
        if (error.status === 401) {
          console.log("refreshTokenInProgress", this.refreshTokenInProgress);
          if (this.refreshTokenInProgress) {
            // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
            // – which means the new token is ready and we can retry the request again
            return this.refreshTokenSubject.pipe(
              filter((result) => result !== null),
              take(1),
              switchMap((result) =>
                next.handle(this.addAccessToken(request, result))
              )
            );
          } else {
            this.refreshTokenInProgress = true;
            console.log(
              "refreshTokenInProgress true",
              this.refreshTokenInProgress,
              this
            );

            // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
            this.refreshTokenSubject.next(null);

            // Call auth.refreshAccessToken(this is an Observable that will be returned)
            return this.auth.refreshAccessToken().pipe(
              switchMap((response: any) => {
                const result = response ? response.result : undefined;
                if (result?.access_token) {
                  console.log(
                    "success before",
                    this.refreshTokenInProgress,
                    this
                  );
                  this.refreshTokenInProgress = false;
                  console.log(
                    "success after",
                    this.refreshTokenInProgress,
                    this
                  );
                  this.refreshTokenSubject.next(result);

                  this.sharedData.setAttribute(
                    "accessToken",
                    result.access_token
                  );
                  this.sharedData.setAttribute(
                    "refreshToken",
                    result.refresh_token
                  );

                  return next.handle(this.addAccessToken(request, result));
                } else {
                  console.log(
                    "no access_token in result before",
                    this.refreshTokenInProgress,
                    this
                  );
                  this.refreshTokenInProgress = false;
                  console.log(
                    "no access_token in result after",
                    this.refreshTokenInProgress,
                    this
                  );

                  this.auth.redirectAfterLogout();
                  return throwError(error);
                }
              }),
              catchError((err: any) => {
                this.refreshTokenInProgress = false;
                return throwError(err);
              })
            );
          }
        } else {
          return throwError(error);
        }
      })
    );
  }
}
