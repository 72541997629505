import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbPopoverConfig } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";
import moment from "moment";

import { ROLES } from "src/app/core/core-services/constants/roles.constant";
import { DialogService } from "src/app/core/core-services/services/dialog.service";
import { AddUserManuallyComponent } from "../add-user-manually/add-user-manually.component";
import { AddUserByEmailComponent } from "../add-user-by-email/add-user-by-email.component";
import { AddUserByCsvComponent } from "../add-user-by-csv/add-user-by-csv.component";
import { EditUserComponent } from "../edit-user/edit-user.component";
import { NotificationService } from "src/app/core/core-services/services/notification.service";
import { AuthService } from "src/app/core/core-services/services/auth.service";
import { AdminApiService } from "../services/admin-api.service";
import { ClipboardService } from "ngx-clipboard";
import { DataService } from "src/app/core/core-services/services/dataservices/data.service";
import { MatMenuTrigger } from "@angular/material/menu";
import { NotifyService } from "src/app/core/menu/not.service";

@Component({
  selector: "app-global-admin-users",
  templateUrl: "./global-admin-users.component.html",
  styleUrls: ["./global-admin-users.component.scss"]
})
export class GlobalAdminUsersComponent implements OnInit, OnDestroy {
  companySlug: string;
  urlPath: string;
  dateAvailable: string = moment().subtract(1, "day").format("MMM, DD YYYY");
  dialogRef: any;
  dataSource: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  usersPerPage = 100;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = [
    "select",
    "name",
    "group_subgroup",
    "licenseType",
    "status",
    "role",
    "actions",
  ];
  downloadingCsv: boolean = false;
  roles: any;
  roleColors: {
    [role: string]: string;
  } = {
    "Vengreso Admin": "#d3aae5",
    "Global Admin": "#FEEFEA",
    "Group Admin": "#FEF9EA",
    "Reporting Admin": "#EEEEEE",
    User: "#FFF",
  };
  allChecked: boolean = false;
  subscriptions = new Subscription();
  search: string = '';
  allRoleFilterChecked: boolean = true;
  users: any[] = [];
  licenseTypes: any[] = [];
  allLicenseFilterChecked: boolean = true;
  statuses: any = [
    {label: "Active", checked: true},
    {label: "Invited", checked: true}
  ];
  groups: any = [{label: 'Not Assigned', checked: true}];
  allGroupsFilterChecked: boolean = true;
  selectedSort: string = 'last_added';
  @ViewChild('licenseMenuTrigger') licenseMenuTrigger: MatMenuTrigger;

  with_deactivated: boolean = false;
  loadingGetUsers: boolean = false;
  
  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    public notifyService: NotifyService,
    private readonly authService: AuthService,
    public dialog: DialogService,
    private readonly notification: NotificationService,
    private readonly config: NgbPopoverConfig,
    private readonly adminApiService: AdminApiService,
    private readonly clipboardService: ClipboardService,
    private readonly dataService: DataService
  ) {
    this.companySlug = this.authService.getUserCompanySlug();

    const roleToolTips = {
      "Global Admin": "Manages all user groups and has system-wide permissions at a company level.",
      "Group Admin": "Administers a specific user group and/or subgroup, managing its members and permissions within that group and/or subgroup.",
      "Reporting Admin": "Has access to generate and view reports across the system but cannot modify user data or functionalities.",
      "User": "Basic user with no access to admin functionalities.",
    };
    this.roles = ROLES.filter(role => role !== 'Vengreso Admin').map(role => ({ name: role, checked : true, tooltip : roleToolTips[role]}));
    config.triggers = "hover";
    config.container = "body";
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.route.paramMap.subscribe((params) => {
        this.urlPath = params.get("path");

        switch (this.urlPath) {
          case "add-manually":
            this.addUserManually();
            break;
          case "add-by-email":
            this.addUserByEmail();
            break;
          case "add-csv":
            this.addUserByCsv();
            break;
          case "deactivated":
            this.with_deactivated = true;
            break;

          default:
            break;
        }
      })
    );

    if (this.isReportingAdmin) {
      this.displayedColumns = this.displayedColumns.filter(
        (c) => !["select", "actions"].includes(c)
      );
    }

    if(!this.dataSource){
      this.dataSource = new MatTableDataSource();
    }

    try {
      this.loadingGetUsers = true;
      this.adminApiService.getUsers(this.with_deactivated, false).subscribe((response: any) => {
        if (response && response.result && response.result.success == true) {
          this.users = response.result.users;
          this.dataSource = new MatTableDataSource(this.users);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string | number => {
            return sortHeaderId === 'first_name' ? `${data['first_name']} ${data['last_name']}` : data[sortHeaderId];
          };
          this.licenseTypes = [...new Map(this.users.map(user => [user.licenseType, {label: user.licenseType, checked: true}])).values()];
        }
        this.loadingGetUsers = false
      });
    } catch (error) {
      this.loadingGetUsers = false
      console.log(error);
    }

    try {
      this.adminApiService.getGroups(false, false).subscribe((response: any) => {
        if (response && response.result && response.result.success == true) {
          let tempGroups: any[] = [];
          response.result.groups.forEach((group: any) => {
            tempGroups.push({label: group.group.name, checked: true});
          })
          this.groups = tempGroups.concat(this.groups);
        }
      });
    } catch (error) {
      console.log(error);
    }

    this.dataService.downloadComplete$.subscribe(filename => {
      if(filename == "users.csv")
      this.notification.toastWithConfig("The CSV has compiled and downloaded to your computer", null, {
        verticalPosition: "top",
        horizontalPosition: "center",
        panelClass: ["green-notification"],
      });
    });
  }

  ngAfterViewInit() {
    this.disableHeaderSorting();
  }

  sortBy(sortKey: string, direction: string = 'asc') {
    this.dataSource.sort.active = sortKey;
    this.dataSource.sort.direction = direction;
    this.dataSource.sort.sortChange.emit();

    if (sortKey === 'first_name' && direction === 'asc') {
      this.selectedSort = 'a_z';
    } else if (sortKey === 'first_name' && direction === 'desc') {
      this.selectedSort = 'z_a';
    } else if (sortKey === 'created_at' && direction === 'asc') {
      this.selectedSort = 'first_added';
    } else if (sortKey === 'created_at' && direction === 'desc') {
      this.selectedSort = 'last_added';
    }
  }

  disableHeaderSorting() {
    const matSortHeaderElements = document.querySelectorAll('.mat-sort-header-container');
    matSortHeaderElements.forEach(element => {
      (element as HTMLElement).style.cursor = 'default';
      element.addEventListener('click', (event) => {
        event.stopPropagation();
        event.preventDefault();
      });
    });
  }

  get isGlobalOrVengresoAdmin(): boolean {
    return this.authService.getUserRole().some(role => ["Global Admin", "Vengreso Admin"].includes(role));
  }

  get isReportingAdmin(): boolean {
    return this.authService.getUserRole().includes("Reporting Admin");
  }

  get isGroupAdmin(): boolean {
    return this.authService.getUserRole().includes("Group Admin");
  }

  get showActionButton(): boolean {
    return (
      this.dataSource.connect().value.filter((t: any) => t.checked).length > 0
    );
  }

  private reloadUsers() {
    this.loadingGetUsers = true;
    this.adminApiService.getUsers(this.with_deactivated).subscribe((response: any) => {
      if (response && response.result && response.result.success == true) {
        this.users = response.result.users;
        this.dataSource = new MatTableDataSource(this.users);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.licenseTypes = [...new Map(this.users.map(user => [user.licenseType, {label: user.licenseType, checked: true}])).values()];
      }
      this.loadingGetUsers = false
    });
  }

  addUserByEmail(){
    const dialogRef = this.dialog.openDialogComponent(
      AddUserByEmailComponent,
      { class: "portal-admin" },
      "640px"
    );

    dialogRef.afterClosed().subscribe((users: any) => {
      if(users) {
        this.dataSource.data = users.concat(this.dataSource.data);
        this.reloadUsers();
      }
    })
  }

  addUserByCsv(){
    const dialogRef = this.dialog.openDialogComponent(
      AddUserByCsvComponent,
      { class: "portal-admin" },
      "640px"
    );

    dialogRef.afterClosed().subscribe((res: any) => {
      if(!!res){
        setTimeout(() => location.reload(), 200);
      }
    });
  }

  addUserManually(){
    const dialogRef = this.dialog.openDialogComponent(
      AddUserManuallyComponent,
      { class: "portal-admin" },
      "640px"
    );

    dialogRef.afterClosed().subscribe((user: any) => {
      if(user){
        this.dataSource.data = [user].concat(this.dataSource.data);
        this.reloadUsers();
      }
    })
  }

  updateAllChecked() {
    this.allChecked =
      this.dataSource.connect().value != null &&
      this.dataSource.connect().value.every((t: any) => t.checked);
  }

  someChecked(): boolean {
    if (this.dataSource.connect().value == null) {
      return false;
    }
    return (
      this.dataSource.connect().value.filter((t: any) => t.checked).length >
        0 && !this.allChecked
    );
  }

  checkAll(checked: boolean) {
    this.allChecked = checked;
    if (this.dataSource.connect().value == null) {
      return;
    }
    this.dataSource.connect().value.forEach((t: any) => (t.checked = checked));
  }

  someRoleFilterChecked() {
    return this.roles.filter((t:any) => t.checked).length > 0 && !this.allRoleFilterChecked;
  }

  checkRoleFilterAll(checked: boolean) {
    this.allRoleFilterChecked = checked;
    this.roles.forEach((t:any) => (t.checked = checked));

    this.search = ''; 
    this.searchUsers();
  }

  updateRoleFilterAllChecked() {
    this.allRoleFilterChecked = this.roles.every((t:any) => t.checked);
    const filter = this.roles.filter((t:any) => t.checked).map((t:any) => t.name);

    if (this.allRoleFilterChecked || filter.length < 1) {
      this.search = '';
      this.searchUsers();
      return;
    }

    this.dataSource.filterPredicate = (data: any, filter: any) => {
      return filter.includes(data['role']);
    }
    
    this.dataSource.filter = filter;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  someLicenseFilterChecked() {
    return this.licenseTypes.filter((t:any) => t.checked).length > 0 && !this.allLicenseFilterChecked;
  }

  someGroupsFilterChecked() {
    return this.groups.filter((t:any) => t.checked).length > 0 && !this.allGroupsFilterChecked;
  }

  checkLicenseFilterAll(checked: boolean) {
    this.allLicenseFilterChecked = checked;
    this.licenseTypes.forEach((t:any) => (t.checked = checked));

    this.search = ''; 
    this.searchUsers();
  }

  checkGroupsFilterAll(checked: boolean) {
    this.allGroupsFilterChecked = checked;
    this.groups.forEach((t:any) => (t.checked = checked));

    this.search = ''; 
    this.searchUsers();
  }

  updateLicenseFilterAllChecked() {
    this.allLicenseFilterChecked = this.licenseTypes.every((t:any) => t.checked);
    const filter = this.licenseTypes.filter((t:any) => t.checked).map((t:any) => t.label);

    if (this.allLicenseFilterChecked || filter.length < 1) {
      this.search = '';
      this.searchUsers();
      return;
    }

    this.dataSource.filterPredicate = (data: any, filter: any) => {
      return filter.includes(data['licenseType']);
    }
    
    this.dataSource.filter = filter;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }

    setTimeout(() => {
      this.licenseMenuTrigger.updatePosition();
    }, 50);
  }

  filterByStatus() {
    const filter = this.statuses.filter((t:any) => t.checked).map((t:any) => t.label);

    if (filter.length < 1) {
      this.search = '';
      this.searchUsers();
      return;
    }

    this.dataSource.filterPredicate = (data: any, filter: any) => {
      return filter.includes(data['status']);
    }
    
    this.dataSource.filter = filter;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  filterByGroup() {
    this.allGroupsFilterChecked = this.groups.every((t:any) => t.checked);
    const filter = this.groups.filter((t:any) => t.checked).map((t:any) => t.label);

    if (filter.length < 1) {
      this.search = '';
      this.searchUsers();
      return;
    }

    this.dataSource.filterPredicate = (data: any, filter: any) => {
      return filter.includes(data['group_subgroup']);
    }
    
    this.dataSource.filter = filter;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  assignRole(user:any, role:  {
    checked: boolean;
    name: string;
    tooltip: string;
  }): void {
    let downgradeRole = false;
    let confirmAssignRole = false;
    let modalWidth = "620px";
        
    if (['Global Admin', 'User'].includes(role.name)) {
      confirmAssignRole = true;
      modalWidth = "500px";
      downgradeRole = user.role && user.role !== 'User' && role.name === 'User';
    }

    let dialogRef = this.dialog.openDialogComponent(
      EditUserComponent,
      { 
        data: user, 
        users: this.users,
        role,
        confirmAssignRole, 
        downgradeRole, 
        action: "assignRole", 
        class: "portal-admin",
      },
      modalWidth
    );

    dialogRef.afterClosed().subscribe((updatedUser: any) => {
      if (updatedUser) {
        if (Array.isArray(updatedUser)) {
          updatedUser.forEach((user: any) => {
            this.dataSource.data = this.dataSource.data.map(
              (u: any) => u.id === user.id ? { ...u, ...user } : u
            );
          });
        } else {
          this.dataSource.data = this.dataSource.data.map(
            (user: any) => user.id === updatedUser.id ? { ...user, ...updatedUser } : user
          );
        }
      }
    });
  }

  editProfile(event: MouseEvent, user: any) {
    event.stopPropagation();
    const dialogRef = this.dialog.openDialogComponent(
      EditUserComponent,
      { data: user, action: "editProfile", class: "portal-admin" },
      "600px"
    );

    dialogRef.afterClosed().subscribe((updatedUser: any) => {
      if(!!updatedUser)
      this.dataSource.data = this.dataSource.data.map(
        (user: any) => user.id === updatedUser.id ? { ...user, ...updatedUser } : user
      );
    });
  }
  resetPassword(event: MouseEvent, data: any) {
    if (event) event.stopPropagation();
    const dialogRef = this.dialog.openDialogComponent(
      EditUserComponent,
      { data, action: "resetPassword", class: "portal-admin" },
      Array.isArray(data) ? "600px" : "500px"
    );

    dialogRef.afterClosed().subscribe((data: any) => {
      if(data == true) {
        this.dataSource.data = this.dataSource.data.map(
          (user: any) =>  ({ ...user, checked: false})
        );
      }
    });
  }
  moveTo(event: MouseEvent, data: any) {
    if (event) event.stopPropagation();
    const dialogRef = this.dialog.openDialogComponent(
      EditUserComponent,
      { data, action: "moveTo", class: "portal-admin" },
      "450px"
    );

    dialogRef.afterClosed().subscribe((data: { group_id?: string, subgroup_id?:string, users: any[]}) => {
      if(data.users) {
        const movedUsersMap = Object.fromEntries(data.users.map(obj => [obj.id, obj]));

        this.dataSource.data = this.dataSource.data.map(
          (user: any) => movedUsersMap[user.id] ? { ...user, ...movedUsersMap[user.id], checked: false } : {...user, checked: false}
        );
      }
    });
  }
  
  deactivateUser(event: MouseEvent, data: any) {
    if (event) event.stopPropagation();
    const dialogRef = this.dialog.openDialogComponent(
      EditUserComponent,
      { data, action: "deactivateUser", class: "portal-admin" },
      Array.isArray(data) ? "600px" : "unset"
    );

    dialogRef.afterClosed().subscribe((deactivatedUsersIds: any[]) => {
      if(Array.isArray(deactivatedUsersIds) && deactivatedUsersIds.length > 0){
        this.dataSource.data = this.dataSource.data.filter((user: any) => !deactivatedUsersIds.includes(user.id));

        this.router.navigate(["/"+this.companySlug+"/admin/users/deactivated"]);
      }
    });
  }

  reactivateUser(event: MouseEvent, data: any) {
    if (event) event.stopPropagation();
    //Make one user show table dialog for adding of plan sake
    const users =  Array.isArray(data) ? data : [data];
    const dialogRef = this.dialog.openDialogComponent(
      EditUserComponent,
      { data: users, action: "reactivateUser", class: "portal-admin" },
      Array.isArray(users) ? "720px" : "500px"
    );

    dialogRef.afterClosed().subscribe((reactivatedUsersIds: any[]) => {
      if(Array.isArray(reactivatedUsersIds) && reactivatedUsersIds.length > 0){
        this.dataSource.data = this.dataSource.data.filter((user: any) => !reactivatedUsersIds.includes(user.id));

        this.router.navigate(["/"+this.companySlug+"/admin/users"]);
      }
    });
  }

  bulkResetPassword(): void {
    this.resetPassword(
      null,
      this.dataSource.connect().value.filter((t: any) => t.checked)
    );
  }

  bulkMoveTo(): void {
    this.moveTo(null, this.dataSource.connect().value.filter((t: any) => t.checked));
  }

  bulkResendInvite(): void {
    this.resendInviteEmail(
      this.dataSource.connect().value.filter((t: any) => t.checked)
    );
  }

  bulkDeactivateUser(): void {
    this.deactivateUser(
      null,
      this.dataSource.connect().value.filter((t: any) => t.checked)
    );
  }

  bulkReactivateUsers(): void {
    this.reactivateUser(
      null,
      this.dataSource.connect().value.filter((t: any) => t.checked)
    );
  }

  resendInviteEmail(data: any[]|any) {
    const isBulkOperation = Array.isArray(data) && data.length > 0;
    
    if (isBulkOperation) {
      const dialogRef = this.dialog.openDialogComponent(
        EditUserComponent,
        { data, action: "resendInvite", class: "portal-admin" },
        Array.isArray(data) ? "600px" : "500px"
      );

      dialogRef.afterClosed().subscribe((data: any) => {
        if(data == true) {
          this.dataSource.data = this.dataSource.data.map(
            (user: any) =>  ({ ...user, checked: false})
          );
        }
      });
    } else {
      this.adminApiService.resendUserInvitation({"email": data.email}).subscribe({
        next: () => {
          this.notification.toastWithConfig("Invite email resent successfully", null, {
            verticalPosition: "top",
            horizontalPosition: "center",
            panelClass: ["green-notification"],
          });
        },
        error: (error) => console.log(error)
      });
    }
    
  }

  copyInviteLink(user) {
    this.clipboardService.copyFromContent(
      user.invitation_link
    );
    this.notification.toastWithConfig("Invite link copied successfully", null, {
      verticalPosition: "top",
      horizontalPosition: "center",
      panelClass: ["green-notification"],
    });
  }

  searchUsers() {
    this.resetFilterPredicate();
    this.dataSource.filter = this.search.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  get filtersClear() {
    return this.search.trim().length < 1 && this.selectedSort === 'last_added' &&
    this.groups.every((t:any) => t.checked === true) &&
    this.statuses.every((t:any) => t.checked === true) &&
    this.licenseTypes.every((t:any) => t.checked === true) &&
    this.roles.every((t:any) => t.checked === true)
  }

  clearFilters() {
    this.search = '';
    this.searchUsers();

    this.sortBy('created_at', 'desc');

    this.groups.forEach((t:any) => t.checked = true);

    this.allLicenseFilterChecked = true;
    this.licenseTypes.forEach((t:any) => t.checked = true);

    this.statuses.forEach((t:any) => t.checked = true);

    this.allRoleFilterChecked = true;
    this.roles.forEach((t:any) => t.checked = true);
  }

  customFilterApplied(data: any[]) {
    return data.some((g: any) => g.checked === false)
  }

  resetFilterPredicate() {
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      const dataStr = Object.keys(data).reduce((currentTerm, key) => {
        return currentTerm + (data as { [key: string]: any })[key] + ' ';
      }, '').toLowerCase();
      return dataStr.indexOf(filter) !== -1;
    };    
  }

  get enableExportCsv(): boolean {
    return (
      this.dataSource.data.filter((t: any) => t.checked).length > 0
    );
  }

  exportUsers(){
    const selectedUsers = this.dataSource.data.filter((t: any) => t.checked).map((user: any) => user.id);
    
    this.adminApiService.exportCSV(this.with_deactivated, selectedUsers.join(','));
  }

  handlePageChange(event: any){
    const previousPageSize = this.usersPerPage;
    const currentPageSize = event.pageSize;

    this.usersPerPage = currentPageSize;

    if (previousPageSize !== currentPageSize) {
      this.allChecked = false;
      this.dataSource.data.forEach((u:any) => (u.checked = false));
    }
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
