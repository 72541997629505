import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { NotificationService } from "src/app/core/core-services/services/notification.service";
import { MatPaginator } from "@angular/material/paginator";
import { DialogService } from "src/app/core/core-services/services/dialog.service";
import { NgbPopoverConfig } from "@ng-bootstrap/ng-bootstrap";
import { MatTableDataSource } from "@angular/material/table";
import { SuperAdminApiService } from "../../services/super-admin-api.service";
import { CompaniesApiService } from "../../services/company/companies-api.service";
import {
  AssignMoveUsersToCompanyRequest,
  CompanyLicense,
  CompanyLicenseDetails,
} from "../../services/company/companies-dto";
import {
  Role,
  ROLES,
} from "src/app/core/core-services/constants/roles.constant";
import { AddNewCompanyComponent } from "../../admin-companies/add-new-company/add-new-company.component";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-bulk-assign-move-to-company",
  templateUrl: "./bulk-assign-move-to-company.component.html",
  styleUrls: ["./bulk-assign-move-to-company.component.scss"],
})
export class BulkAssignMoveToCompanyComponent implements OnInit {
  companies: CompanyLicenseDetails[] = [];
  roles: Role[] = ROLES.filter(
    (role) => !["Vengreso Admin", "Group Admin"].includes(role)
  );
  plans: CompanyLicense[] = [];
  selectedCompanyName: string = "";

  users: any[] = [];
  confirmationStep: boolean = false;

  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  displayedColumns: string[] = [
    "select",
    "name",
    "company",
    "role",
    "plan",
    "actions",
  ];
  allChecked: boolean = false;
  usersPerPage = 10;
  assignMoveToForm: FormGroup;
  step: number = 1;
  roleColors: {
    [role: string]: string;
  } = {
    "Vengreso Admin": "#d3aae5",
    "Global Admin": "#FEEFEA",
    "Group Admin": "#FEF9EA",
    "Reporting Admin": "#EEEEEE",
    User: "#FFF",
  };

  constructor(
    public dialogRef: MatDialogRef<BulkAssignMoveToCompanyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly fb: FormBuilder,
    private readonly notification: NotificationService,
    private readonly apiService: SuperAdminApiService,
    private readonly companiesApiService: CompaniesApiService,
    public dialog: DialogService,
    config: NgbPopoverConfig
  ) {
    this.users = data.data.map((user) => ({
      ...user,
      checked: !user.is_invite,
      disabled: user.is_invite,
      role_name: 'User'
    }));
    config.triggers = "hover";
    config.container = "body";
  }

  ngOnInit(): void {
    this.goToStep(1);
    this.assignMoveToForm = this.fb.group({
      company_id: [null, [Validators.required]],
    });
    this.dataSource = new MatTableDataSource([]);

    this.dataSource.data = this.users;

    this.companiesApiService.getCompaniesLicenses().subscribe({
      next: (response) => {
        if (response.result.success) {
          this.companies = response.result.data.map((company) => ({
            ...company,
            isSelected: false,
          }));

          this.companies.unshift({
            id: "-1",
            name: "Individuals",
            slug: "individuals",
            licensesAvailable: -1,
            plans: [{
              label: "Freemium",
              value: "Freemium",
              licensesAvailable: "Unlimited",
              plan_id: "Freemium",
              stripe_id: "Freemium",
              totalLicenses: "Unlimited",
            }],
          });
        }
      },
      error: (err) => console.log(err),
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  goToStep(step: number): void {
    switch (step) {
      case 1:
        this.step = 1;
        this.dialogRef.componentInstance.dialogRef.updateSize("400px");
        break;
      case 2:
        this.step = 2;
        setTimeout(() => {
          if (this.paginator) {
            this.dataSource.paginator = this.paginator;
          }
        });

        this.dialogRef.componentInstance.dialogRef.updateSize("850px");
        break;
      default:
        this.step = 3;
        this.dialogRef.componentInstance.dialogRef.updateSize("unset");
        break;
    }
  }

  updateAllChecked() {
    this.allChecked = this.dataSource
      ?.connect()
      .value?.every((t: any) => t.checked);
  }

  assignMoveTo(): void {
    const users = this.dataSource.data
      .filter((user) => user.checked)
      .map((user) => ({
        id: user.id,
        plan: user.plan_identifier,
        role: user.role_name,
      }));

    const request: AssignMoveUsersToCompanyRequest = {
      company_id: this.assignMoveToForm.value.company_id,
      users,
    };

    this.apiService.assignMoveTo(request).subscribe({
      next: (response: any) => {
        if (response.result.success) {
          this.close(response.result.data);
          const notifyMessage = `${response.result.data.length} users updated successfully.`;

          this.notification.toastWithConfig(notifyMessage, null, {
            verticalPosition: "top",
            horizontalPosition: "center",
            panelClass: ["green-notification"],
          });
        }
      },
      error: (err) => console.log(err),
    });
  }

  removeInConfirmationView(element: any): void {
    this.dataSource.data = this.dataSource
      .connect()
      .value.filter((user: any) => user.id !== element.id);

    this.users = this.users.filter((user) => user.id !== element.id);

    if (this.dataSource.data.length === 0) {
      this.close();
      return;
    }

    if (this.paginator) {
      this.dataSource.paginator = this.paginator;
    }
  }

  close(data?: any): void {
    this.dialogRef.close(data);
  }

  get selectedUsersCount(): number {
    return this.dataSource.data.filter((user) => user.checked).length;
  }

  get hasSelected(): boolean {
    const selectable = this.dataSource.data.filter((user) => !user.disabled);

    if (selectable.length === 0) return false;

    const checked = selectable.filter((user) => user.checked);

    return !checked.some(user => this.assignMoveToForm.value.company_id !== "-1" &&
      (!user.role_name || !user.plan_identifier));
  }

  addNewCompany(): void {
    const dialogRef = this.dialog.openDialogComponent(
      AddNewCompanyComponent,
      { class: ["portal-admin", "cmc-add-companies-modal-container"] },
      "600px"
    );

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.companies = this.companies
          .concat(result)
          .sort((a, b) => a.name.localeCompare(b.name));
      }
    });
  }

  onSelectionChange(id: string): void {
    let company = this.companies.find((company: any) => company.id === id);

    this.roles = ROLES.filter(
      (role) =>
        (company.name === "Vengreso" || role !== "Vengreso Admin") &&
        role !== "Group Admin"
    );

    this.selectedCompanyName = company?.name;
    this.plans = company?.plans ?? this.plans;

    if (company.id === "-1") {
      this.users = this.users.map((user) => ({
        ...user,
        role_name: "User",
        plan_identifier: "Freemium",
      }));
    } else {
      this.users = this.users.map((user) => ({
        ...user,
        role_name: "User",
        plan_identifier: null,
      }));
    }
    this.dataSource.data = this.users;
  }

  checkAll(checked: boolean) {
    this.allChecked = checked;
    if (this.dataSource?.connect().value == null) {
      return;
    }
    this.dataSource?.connect().value.forEach((t: any) => (t.checked = checked));
  }

  someChecked(): boolean {
    if (this.dataSource?.connect().value == null) {
      return false;
    }
    return (
      this.dataSource?.connect().value.filter((t: any) => t.checked).length >
        0 && !this.allChecked
    );
  }

  assignedRoleDisabled(dropdownRole: string, user: any): boolean {
    if (dropdownRole == "Group Admin" && !user.company_has_groups) {
      return true;
    }

    return (
      (dropdownRole === user.role &&
        !["Reporting Admin", "Group Admin"].includes(dropdownRole)) ||
      (dropdownRole == "Vengreso Admin" && user.company_slug !== "vengreso")
    );
  }

  handlePageChange(event: any) {
    const previousPageSize = this.usersPerPage;
    const currentPageSize = event.pageSize;

    this.usersPerPage = currentPageSize;

    if (previousPageSize !== currentPageSize) {
      this.allChecked = false;
      this.dataSource.data.forEach((u: any) => (u.checked = false));
    }
  }

  onAssignLicense(event?: any): void {
    this.plans.forEach((plan) => {
      let assignedLicenses =
        this.dataSource.data.filter(
          (user: any) => user.plan_identifier === plan.stripe_id
        )?.length ?? 0;

      if (plan.licensesAvailable !== "Unlimited") {
        let unassignedLicenses = plan.licensesAvailable - assignedLicenses;
        plan.licensesAvailable =
          unassignedLicenses > 0 ? unassignedLicenses : 0;
      }
    });
  }
  
  planLabel(plan: string): string {
    return this.plans.find((planObj) => planObj.stripe_id === plan)?.label;
  }
}
