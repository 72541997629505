import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DataService } from "src/app/core/core-services/services/dataservices/data.service";
import { MetaService } from "src/app/core/core-services/services/meta.service";
import { NotificationService } from "src/app/core/core-services/services/notification.service";
import { NotifyService } from "src/app/core/menu/not.service";
import { StripeService } from "src/app/core/core-services/services/stripe.service";
import { SubscriptionPlanType } from "src/app/core/models/stripe.models";
import { SharedDataService } from "src/app/core/core-services/services/shared-data.service";

@Component({
  selector: "app-new-plan",
  templateUrl: "./new-plan.component.html",
  styleUrls: ["./new-plan.component.scss"],
})
export class NewPlanComponent implements OnInit {
  public planType: SubscriptionPlanType;
  public subType = SubscriptionPlanType;
  checked;
  pricing: any = {};
  userPlanId: string;
  usedTrial: any;
  clickedFreeTrial: boolean = false;
  constructor(
    private readonly dataService: DataService,
    private readonly stripeService: StripeService,
    private readonly route: ActivatedRoute,
    private readonly _router: Router,
    private readonly metaService: MetaService,
    private readonly notification: NotificationService,
    private readonly notifyService: NotifyService,
    private readonly sharedData: SharedDataService,
  ) {
    this.getPricing();
  }

  ngOnInit(): void {
    this.planType = SubscriptionPlanType[this.route.snapshot.paramMap.get("id")];
    if (
      this.planType === this.subType.YearlyStarter ||
      this.planType === this.subType.YearlyGrowth ||
      this.planType === this.subType.YearlySalesPro
    ) {
      this.checked = false;
    } else {
      this.checked = true;
    }
    this.userPlanId = this.sharedData.getAttribute("planId");
    this.usedTrial = this.sharedData.getAttribute("usedTrial");
    this.addMetaTags();

    this.route.queryParams.subscribe((param) => {
      // this.startFreeTrial();
      if(param.activate_trial == 'true' && this.showStartTrial()){
        this.startFreeTrial();
      }
    });
  }

  showStartTrial(){
    return (
     (this.planType == this.subType.SalesPro || this.planType == this.subType.YearlySalesPro) &&
     this.userPlanId == 'freemium' && 
     this.usedTrial !== 'true'
    )
  }

  addMetaTags() {
    if (this.planType === this.subType.YearlyStarter) {
      this.metaService.addMetaTag(
        'FlyMSG Starter Annual Plan Page',
        "You can purchase the FlyMSG Starter Annual Plan now and begin saving 1-hour a day in wasted admin time. What's your time worth?");
    }if(this.planType === this.subType.Starter){
      this.metaService.addMetaTag(
        'FlyMSG Starter Monthly Plan Page',
        "You can purchase the FlyMSG Starter Monthly Plan now and begin saving 1-hour a day in wasted admin time. What's your time worth?");
    }if(this.planType === this.subType.Growth){
      this.metaService.addMetaTag(
        'FlyMSG Growth Monthly Plan Page',
        "You can purchase the FlyMSG Growth Monthly Plan now and begin saving 1-hour a day in wasted admin time. What's your time worth?");
    }if(this.planType === this.subType.YearlyGrowth){
      this.metaService.addMetaTag(
        'FlyMSG Growth Annual Plan Page',
        "You can purchase the FlyMSG Growth Annual Plan now and begin saving 1-hour a day in wasted admin time. What's your time worth?");
    }if(this.planType === this.subType.SalesPro){
      this.metaService.addMetaTag(
        'FlyMSG Sales Pro Monthly Plan Page',
        "You can purchase the FlyMSG Sales Pro Monthly Plan now and begin saving 1-hour a day in wasted admin time. What's your time worth?");
    }if(this.planType === this.subType.YearlySalesPro){
      this.metaService.addMetaTag(
        'FlyMSG Sales Pro Annual Plan Page',
        "You can purchase the FlyMSG Sales Pro Annual Plan now and begin saving 1-hour a day in wasted admin time. What's your time worth?");
    }
  }
  public confirmPlan() {
    let plan: any = this.planType;

    plan == "starter-yearly"
      ? (plan = "YearlyStarter")
      : plan == "growth-yearly"
        ? (plan = "YearlyGrowth")
        : plan == "sales-pro-yearly"
          ? (plan = "YearlySalesPro")
          : (plan = plan);

    this._router.navigate(["payment-details", plan]);
  }

  backClicked() {
    this._router.navigate(["pricing/"]);
  }

  setValue(e) {
    this.swichPlanType();

    this.navigateToPlan()
    if (e.checked) {
      this.checked = e.checked;
    } else {
      this.checked = e.checked;
    }
  }

  navigateToPlan() {
    switch (this.planType) {
      case "Starter":
        this._router.navigate(["new-plan/Starter"]);
        break;
      case "starter-yearly":
        this._router.navigate(["new-plan/YearlyStarter"]);
        break;
      case "Growth":
        this._router.navigate(["new-plan/Growth"]);
        break;
      case "growth-yearly":
        this._router.navigate(["new-plan/YearlyGrowth"]);
        break;
      case "SalesPro":
        this._router.navigate(["new-plan/SalesPro"]);
        break;
      case "sales-pro-yearly":
        this._router.navigate(["new-plan/YearlySalesPro"]);
        break;

      default:
        break;
    }
  }

  swichPlanType() {
    if (this.planType === this.subType.YearlyStarter) {
      this.planType = SubscriptionPlanType[this.subType.Starter];
      return;
    }
    if (this.planType === this.subType.YearlyGrowth) {
      this.planType = SubscriptionPlanType[this.subType.Growth];
      return;
    }
    if (this.planType === this.subType.YearlySalesPro) {
      this.planType = SubscriptionPlanType[this.subType.SalesPro];
      return;
    }
    if (this.planType === this.subType.Starter) {
      this.planType = "starter-yearly" as SubscriptionPlanType;
      return;
    }
    if (this.planType === this.subType.Growth) {
      this.planType = "growth-yearly" as SubscriptionPlanType;
      return;
    }
    if (this.planType === this.subType.SalesPro) {
      this.planType = "sales-pro-yearly" as SubscriptionPlanType;
      return;
    }
  }

  getPricing() {
    this.dataService
      .get({ url: "v1/subscriptions/plans", isLoader: false })
      .subscribe({
        next: (response: any) => {
          response.result.forEach(({ identifier, unit_amount }) => {
            this.pricing[identifier] = unit_amount / 100
          });
        },
        error: (error) => {
          console.log(error)
        }
      });
  }

  displayedPrice(amount: any): string | number {
    return amount ? Math.trunc(Number(amount) * 100) / 100 : 0.00
  }

  twoDecimal(amount: any): string | number {
    return amount ? Number(amount).toFixed(2) : 0.00
  }

  startFreeTrial(): void {
    this.clickedFreeTrial = true
    this.stripeService.startSalesProTrial().subscribe({
      next: (response: any) => {
        this.sharedData.setAttribute("shownLearningIconIndicator", "false");

        this._router.navigate(["/settings"], {
          queryParams: { tab: "subscription" },
        });
      },
      error: (error) => {
        this.clickedFreeTrial = false
        console.log(error)
      }
    });
  }
}
