import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { DataService } from "../../core/core-services/services/dataservices/data.service";
import { LoadingService } from "src/app/core/core-services/services/loader.service";
import { CONTENT_VIEW_LOADER } from "src/app/core";
import { SharedDataService } from "src/app/core/core-services/services/shared-data.service";

@Component({
  selector: "app-advanced-search-result",
  templateUrl: "./advanced-search-result.component.html",
  styleUrls: ["./advanced-search-result.component.css"],
})
export class AdvancedSearchResultComponent implements OnInit {
  params: any;
  shortcuts: any;
  plates: any;
  downScroll: boolean = true;
  upScroll: boolean = true;
  fromBasicSearch: boolean = true;
  noResults: boolean;
  searchValue: any;
  searchCache: any = {
    type: '',
    params: {}
  };

  constructor(
    private readonly dataService: DataService,
    private readonly sharedData: SharedDataService,
    private readonly router: Router,
    private readonly loaderService: LoadingService
  ) {
    let nav = this.router.getCurrentNavigation();

    this.fromBasicSearch =
      nav && nav?.extras && nav?.extras?.state ? true : false;

    if (this.fromBasicSearch) {
      let data = this.router.getCurrentNavigation()?.extras?.state;

      this.searchValue = data.searchValue;
      this.searchCache = {
        type: 'basic',
        params: { q: this.searchValue.shortcut || this.searchValue }
      }
      this.loaderService.start(CONTENT_VIEW_LOADER);
      this.dataService
        .searchTrack({ q: this.searchValue.shortcut || this.searchValue })
        .subscribe({
          next: (res) => {
            if (res.result) {

              for (let item of res.result.data) {
                if (item.type == "Shortcuts") {
                  this.shortcuts = item.items ? item.items : this.shortcuts;
                }
                if (item.type == "FlyPlates") {
                  this.plates = item.items ? item.items : this.plates;
                }
              }

              if (this.shortcuts && this.plates) {
                this.downScroll = this.upScroll = false;
              }

              if (
                (!this.plates || this.plates.length == 0) &&
                (!this.shortcuts || this.shortcuts.length == 0)
              ) {
                this.noResults = true;
              } else {
                this.noResults = false;
              }
              this.loaderService.stop(CONTENT_VIEW_LOADER);
            }
          }, 
          error: () => {
            this.loaderService.stop(CONTENT_VIEW_LOADER);
          }
        });
    }
  }
  async ngOnInit() {
    
    if (!this.fromBasicSearch) {
      const item = this.sharedData.getAttribute("advancedSearchData");
      this.params = JSON.parse(item);
      if (this.params.bySearch == "all-flymsg") {
        this.params.bySearch = "";
      }
      if (this.params.createdWithin == "any-time") {
        this.params.createdWithin = "";
      }
      
      await this.getResult(this.params);
      
    } else if (
      typeof this.searchValue === "object" &&
      this.searchValue !== null
    ) {
      if (this.searchValue.user_id) {
        this.shortcuts = [this.searchValue];
        return;
      }
      this.plates = [this.searchValue];
    }
    
  }

  detectUpdates(){
    if (this.searchCache.type == 'basic') {
      this.loaderService.start(CONTENT_VIEW_LOADER);
      this.dataService
        .searchTrack(this.searchCache.params)
        .subscribe({
          next: (res) => {
            if (res.result) {

              for (let item of res.result.data) {
                if (item.type == "Shortcuts") {
                  this.shortcuts = item.items ? item.items : this.shortcuts;
                }
                if (item.type == "FlyPlates") {
                  this.plates = item.items ? item.items : this.plates;
                }
              }

              if (this.shortcuts && this.plates) {
                this.downScroll = this.upScroll = false;
              }

              if (
                (!this.plates || this.plates.length == 0) &&
                (!this.shortcuts || this.shortcuts.length == 0)
              ) {
                this.noResults = true;
              } else {
                this.noResults = false;
              }
            }
            this.loaderService.stop(CONTENT_VIEW_LOADER);
          },
          error: () => {
            this.loaderService.stop(CONTENT_VIEW_LOADER);
          }
        });
      return
    }
    if (this.searchCache.type == 'advanced') {
      let params = this.searchCache.params
      this.getResult(params)
      return
    }
  }

  getResult(params) {
    this.loaderService.start(CONTENT_VIEW_LOADER);
    let queryParams = "?flycut=" + params.flycut;
    queryParams += "&title=" + params.title;
    queryParams += "&hasTheWords=" + params.hasTheWords;
    queryParams += "&doesntHave=" + params.doesntHave;
    queryParams += "&createdWithin=" + params.createdWithin;
    queryParams += "&date=" + params.date;
    queryParams += "&bySearch=" + params.bySearch;
    queryParams += "&hasAttachment=" + params.hasAttachment;
    queryParams += "&hasHyperlink=" + params.hasHyperlink;

    this.searchCache = {
      type: 'advanced',
      params
    }
    return this.dataService
      .getAdvancedSearchResult(queryParams)
      .subscribe({
        next: (res) => {
          if (this.params.bySearch == "flyplates") {
            this.shortcuts = [];
          } else {
            typeof res.result.data == "object"
              ? (this.shortcuts = Object.values(res.result.data))
              : (this.shortcuts = res.result.data);
          }
          if (this.params.bySearch == "flycuts") {
            this.plates = [];
          } else {
            typeof res.result.data2 == "object"
              ? (this.plates = Object.values(res.result.data2))
              : (this.plates = res.result.data2);
          }

          if (this.shortcuts && this.plates) {
            this.downScroll = this.upScroll = false;
          }

          if (
            (!this.plates || this.plates.length == 0) &&
            (!this.shortcuts || this.shortcuts.length == 0)
          ) {
            this.noResults = true;
          } else {
            this.noResults = false;
          }
          this.loaderService.stop(CONTENT_VIEW_LOADER);
          this.searchRegex(params);
        }, 
        error: () => {
          this.loaderService.stop(CONTENT_VIEW_LOADER);
        }
    });
  }

  searchRegex(params) {
    let d = [params.flycut, params.title, params.hasTheWords];
    let m = d.filter((n) => n);
    if (m) {
      this.searchValue = m.join("|");
    }
  }
}
