import {
  Component,
  AfterViewChecked,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Location } from "@angular/common";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { TranslateService } from "@ngx-translate/core";
import { cloneDeep } from "lodash";
import { Subscription, filter } from "rxjs";
import { NgbPopoverConfig } from "@ng-bootstrap/ng-bootstrap";

import { openClose } from "src/app/animations";
import { getPlanId } from "src/app/core/core-services/constants/plans.constants";
import { DataService } from "src/app/core/core-services/services/dataservices/data.service";
import { EventService } from "src/app/core/core-services/services/event.service";
import { trackService } from "src/app/core/core-services/services/track.service";
import { MenuService } from "./menu.service";
import { NotifyService } from "./not.service";
import {
  ShortcutCategoryService,
  SHORTCUT_CATEGORIES,
} from "src/app/core/core-services/services/shortcut-category.service";

import {
  FLYSHARE_ALL_ROUTE,
  FLYSHARE_OTHERS_ROUTE,
  FLYSHARE_SHARED_ROUTE,
} from "src/app/vengreso/share/flyshare/flyshare.config";
import { AdminLayoutMenuService } from "../admin-layout/admin-layout-menu.service";
import { AuthService } from "../core-services/services/auth.service";
import { LoadingService } from "../core-services/services/loader.service";
import { WarningDialogComponent } from "src/app/core/core-services/warning-dialog/warning-dialog.component";
import { DialogService } from "../core-services/services/dialog.service";
import { SharedDataService } from "../core-services/services/shared-data.service";
import { DynamicStorage } from "../core-services/decorators/dynamic-storage";
import { GlobalHookService } from "src/app/global-hook.service";
import { TourService } from "ngx-ui-tour-md-menu";

@Component({
  selector: "app-menu",
  animations: [openClose],
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
})
export class MenuComponent
  implements AfterViewChecked, OnChanges, OnInit, OnDestroy
{
  @Input() direction: string;
  private langChangeSubscription!: Subscription;
  @DynamicStorage(SHORTCUT_CATEGORIES) shortcutCategoryList: any[];
  currentLang = "en";
  menuItems = [];
  submenu: any;
  planId: string;
  user: any;

  viewAll = {
    id: "all",
    name: "View All",
  };

  config = {
    classname: "custom-icon",
    paddingAtStart: false,
    interfaceWithRoute: true,
    collapseOnSelect: true,
    highlightOnSelect: true,
    rtlLayout: false,
    selectedListFontColor: "#3f51b5",
  };

  subscriptions = new Subscription();
  SSO_URL = "";
  userPlan: string;

  flyLearningShow = false;
  notifierSubscription: Subscription =
    this.notifyService.subjectNotifier.subscribe((notified) => {
      // Check if notification contains message to show
      // FlyLearning icon immediately and remove premium button
      if (notified && notified.plan) {
        this.userPlan = notified.plan.toLowerCase();
        if (notified.plan.includes("pro") || notified.plan.includes("Pro")) {
          this.flyLearningShow = true;
        }
      }

      if (notified && notified.planObj) {
        this.userPlan = notified.planObj?.identifier;

        if (notified.planObj?.identifier?.includes("pro")) {
          this.flyLearningShow = true;
        }
      }
      this.user = this.authService.userDetails.user_details;
      this.planId = getPlanId();
      this.getShortcutCategoryList(true);
      this.getTemplateCategory();
    });

  @Output() closeSideNav = new EventEmitter();
  @ViewChild("menuHeight") menuHeight: ElementRef;
  selectedMenuIndex: number | null = null;

  constructor(
    public dialog: DialogService,
    private readonly router: Router,
    public menuService: MenuService,
    public notifyService: NotifyService,
    private readonly dataService: DataService,
    public translate: TranslateService,
    private readonly eventService: EventService,
    public trackService: trackService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly adminLayoutMenuService: AdminLayoutMenuService,
    private readonly loaderService: LoadingService,
    private readonly location: Location,
    private readonly shortcutCategoryService: ShortcutCategoryService,
    private readonly sharedData: SharedDataService,
    config: NgbPopoverConfig,
    private readonly globalHookService: GlobalHookService,
    private readonly tourService: TourService
  ) {
    this.user = this.authService.userDetails.user_details;
    this.menuItems = this.menuService.getAll();

    this.submenu = {
      FlyCuts: [],
      FlyPlates: [],
      FlyShare: [],
    };

    if (
      this.authService.showAdminSection() ||
      this.authService.showVengresoAdminSection()
    ) {
      this.submenu.Administration = [
        { id: "settings", name: "Personal Settings" },
      ];

      if (this.authService.showAdminSection()) {
        this.submenu.Administration.push({
          id: this.menuItems.find(
            (menu) =>
              menu.label == "Administration" ||
              menu.label == this.translate.instant("ADMIN")
          ).sublink_two,
          name: "Account Center",
        });
      }

      if (this.authService.showVengresoAdminSection()) {
        this.submenu.Administration.push({
          id: this.menuItems.find(
            (menu) =>
              menu.label == "Administration" ||
              menu.label == this.translate.instant("ADMIN")
          ).sublink_three,
          name: "Client Management Center",
        });
      }
    }

    let planIdType = this.sharedData.getAttribute("planIdType");
    if (
      planIdType &&
      (planIdType == "sales-pro-yearly" ||
        planIdType == "sales-pro-monthly" ||
        planIdType == "pro-plan-teams-smb" ||
        planIdType == "pro-plan-teams-ent")
    ) {
      this.flyLearningShow = true;
    }
    this.planId = getPlanId();

    this.subscriptions.add(
      this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe((route: any) => {
          if (route.url.includes("instancyssorequested=true")) {
            this.loaderService.start();
            this.location.replaceState(`flyboard`);
            this.openlearning(this.menuItems.find((item) => item.sso));
            this.loaderService.stop();
          }

          if (route.url.includes("/flyshare")) {
            let flyshareIndex = this.menuItems.findIndex(
              (item) => item.sublink === "/flyshare"
            );
            this.menuItems[flyshareIndex].isOpen = true;
          }
          if (route.url.includes("/shortcuts")) {
            let shortcutsIndex = this.menuItems.findIndex(
              (item) => item.sublink === "/shortcuts"
            );
            this.menuItems[shortcutsIndex].isOpen = true;
          }
          if (route.url.includes("/settings")) {
            let shortcutsIndex = this.menuItems.findIndex(
              (item) => item.sublink === "/settings"
            );
            this.menuItems[shortcutsIndex].isOpen = true;
          }
        })
    );

    config.triggers = "hover";
    config.container = "body";
  }

  get isCompanyUser() {
    const user = this.authService.userDetails.user_details;

    return user?.company_id && user?.status !== "Invited";
  }

  startWalkMeStepTree() {
    const popover = document.querySelector(
      ".mat-menu-panel.tour-step"
    )?.parentElement;

    if (popover) {
      popover.classList.remove("step11FlyPlates-onboarding-popover-arrow");
    }

    this.menuService.openMenu.emit("");
    // this.menuService.openMenu.emit('/flyplates');

    this.tourService.initialize([
      {
        anchorId: "step11FlyPlates",
        content:
          "FlyPlates are templates that you can borrow to use as your FlyCuts.",
        title: "Let’s try adding a FlyPlate",
        enableBackdrop: true,
        disablePageScrolling: true,
        placement: {
          yPosition: "above",
        },
        nextBtnTitle: "Got it!",
        prevBtnTitle: "Later",
        prevStep: "step11FlyPlates-cancel",
        endBtnTitle: "Got it!",
      },
      {
        anchorId: "step11FlyPlates-ok",
        content: "",
      },
      {
        anchorId: "step11FlyPlates-cancel",
        content: "",
      },
    ]);

    this.tourService.stepShow$.subscribe((step) => {
      if (step.anchorId === "step11FlyPlates") {
        setTimeout(() => {
          const popover = document.querySelector(
            ".mat-menu-panel.tour-step"
          )?.parentElement;

          if (popover) {
            popover.classList.add("step11FlyPlates-onboarding-popover-arrow");

            setTimeout(() => {
              const popover = document.querySelector(
                ".mat-menu-panel.tour-step"
              )?.parentElement;

              if (!popover) {
                return;
              }

              const prevButton = popover.querySelector(".mat-button-base.prev");
              const nextButton = popover.querySelector(".mat-button-base.next");

              if (prevButton) {
                prevButton.setAttribute(
                  "data-fms-heap",
                  `walk-me-modal-add-flyplate-step-11-prev`
                );
              }

              if (nextButton) {
                nextButton.setAttribute(
                  "data-fms-heap",
                  `walk-me-modal-add-flyplate-step-11-next`
                );
              }
            }, 200);
          }
        }, 200);
      } else {
        document.body.classList.remove("tour-last-step");
      }

      if (step.anchorId === "step11FlyPlates-ok") {
        this.router.navigateByUrl("/flyplates/viewall");
        this.tourService.end();
      }

      if (step.anchorId === "step11FlyPlates-cancel") {
        this.router.navigateByUrl("/flyplates/viewall");

        this.tourService.end();
        this.globalHookService.onboardingActive = false;
        alert("congrats");
      }
    });

    this.tourService.end$.subscribe(() => {
      document.body.classList.remove("tour-last-step");
    });

    setTimeout(() => {
      this.tourService.start();
    }, 500);
  }

  get isOnboarding() {
    return this.globalHookService.onboardingActive;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.config = {
      ...this.config,
      rtlLayout: this.direction === "rtl" ? true : false,
    };
  }

  ngOnInit() {
    this.subscriptions.add(this.notifierSubscription);

    this.subscriptions.add(
      this.menuService.openMenu.subscribe((item) => {
        this.menuItems.forEach((i, index) => {
          if (i.sublink === item) {
            i.isOpen = true;
            this.selectedMenuIndex = index;
          } else {
            i.isOpen = false;
          }
        });
        setTimeout(() => this.closeSideNav.emit(), 50);
      })
    );

    this.subscriptions.add(
      (this.langChangeSubscription = this.translate.onLangChange.subscribe(
        () => {
          const updatedMenu = this.menuService.getAll();
          this.menuItems = updatedMenu;
        }
      ))
    );

    this.setFlylearning();

    this.subscriptions.add(
      this.eventService.getShortcutCategories().subscribe((data) => {
        this.updateShortcutCategoryList(cloneDeep(data));
      })
    );

    this.setFlyShareList();
    this.getShortcutCategoryList();
    this.getTemplateCategory();

    this.sharedData.storage.observe(SHORTCUT_CATEGORIES).subscribe((value) => {
      this.updateShortcutCategoryList(value);
      this.adminLayoutMenuService._flyCutsSubMenu.next(value);
    });

    this.updateShortcutCategoryList(this.shortcutCategoryList);
    this.adminLayoutMenuService._flyCutsSubMenu.next(this.shortcutCategoryList);
    this.userPlan = this.sharedData.getAttribute("planId")?.toLowerCase();

    this.globalHookService.registerComponent("menu", this);
  }

  ngAfterViewChecked() {
    if (this.selectedMenuIndex !== null) {
      this.scrollToItem(this.selectedMenuIndex);
    }
  }

  private scrollToItem(index: number) {
    const element = document.getElementById("item-" + index);
    if (element) {
      // Ensuring the element is fully rendered before attempting to scroll
      setTimeout(() => {
        element.scrollIntoView({ behavior: "smooth" });
        this.selectedMenuIndex = null;
      }, 50);
    }
  }

  get invitedToCompany(): boolean {
    return (
      this.user.invited_to_company && this.user.invited_to_company_by_admin
    );
  }

  goToDashboard() {
    this.router.navigate(["/flyboard"]);
  }

  goToProfile() {
    this.router.navigate(["/settings"], { queryParams: { tab: "Profile" } });
  }

  setFlyShareList() {
    this.submenu.FlyShare = [
      { id: FLYSHARE_ALL_ROUTE, name: "View All" },
      { id: FLYSHARE_SHARED_ROUTE, name: "Shared with Me" },
      { id: FLYSHARE_OTHERS_ROUTE, name: "Shared with Others" },
    ];
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  setQueryParam() {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { add: "true" },
      queryParamsHandling: "merge",
    });
  }

  goToAddCategory() {
    if (this.router.url.includes("/settings")) {
      this.eventService.updateAddCategoryListener(true);
    } else {
      this.router.navigate(["/settings"], { queryParams: { add: "true" } });
    }
  }

  goToAddShortcut() {
    if (this.router.url.includes("/shortcuts/")) {
      this.eventService.updateAddShortcutListener(true);
    } else {
      this.router.navigate(["/shortcuts/all"], {
        queryParams: { add: "true" },
      });
    }
  }

  updateShortcutCategoryList(result: any) {
    result = result?.map((item) => {
      item.sub_category_lv1 = item?.sub_category_lv1?.map((sub) => {
        return { ...sub, isSubMenu: true, isOpen: false };
      });
      return { ...item, isSubMenu: true, isOpen: false };
    });
    this.submenu.FlyCuts = result;
    this.submenu.FlyCuts?.unshift(this.viewAll);
  }

  getShortcutCategoryList(reloadFromApi = false) {
    this.shortcutCategoryService.loadShortcutCategory({ reloadFromApi });
  }

  getTemplateCategory() {
    this.dataService
      .get({ url: "v1/user/template-category", isLoader: false })
      .subscribe((response: any) => {
        if (response && response.result) {
          const result = response.result.map((item) => {
            item.subcategory_lv1 = item?.subcategory_lv1?.map((sub) => {
              return { ...sub, isSubMenu: true, isOpen: false };
            });
            return { ...item, isSubMenu: true, isOpen: false };
          });

          this.submenu.FlyPlates = result;
          this.submenu.FlyPlates.unshift(this.viewAll);
          this.adminLayoutMenuService._flyPlatesSubMenu.next(response.result);
        }
      });
  }

  updateShortcutCategoryPosition(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.submenu.FlyCuts,
      event.previousIndex,
      event.currentIndex
    );

    const data = {
      positions: [],
      // type:"categorylv1"   // changing a position of categorylv1
      //  type:"categorylv1"  //changing a position of categorylv2
    };

    this.submenu.FlyCuts.forEach((shortcut: any) => {
      if (shortcut.id !== "all") {
        data.positions.push(shortcut.id);
      }
    });

    this.dataService
      .put({ url: "v1/user/shortcut-category-position", data, isLoader: false })
      .subscribe((response: any) => {
        if (response && response.result) {
          this.getShortcutCategoryList(true);
        }
      });
  }

  navigateTo(site: any) {
    window.open(site, "_blank");
  }

  openlearning(item) {
    if (item.sso && item.sso == "flylearning") {
      let user = this.authService.userDetails;
      let email = user?.user_details?.email;

      let planIdType = this.sharedData.getAttribute("planIdType");
      const plans = [
        "freemium",
        "starter",
        "growth",
        "Lifetime",
        "dealfuel-growth-lifetime",
      ];

      if (
        planIdType &&
        plans.some((s) => s.toLowerCase() === planIdType.toLowerCase())
      ) {
        this.dialog.openDialogComponent(
          WarningDialogComponent,
          {
            self: this,
            type: "flyLearningWarning",
            pricing: "salesPro",
            selectedPlan: "salesPro",
          },
          "800px"
        );
        return;
      } else if (email) {
        //const data = { Email: encodeURIComponent(email) };
        // Encoded on the backend
        const data = { Email: email };
        const url = "v1/authenticateUser";
        this.dataService
          .post({ url, data, isLoader: true })
          .subscribe(async (response: any) => {
            if (response?.result) {
              let redirect_url = response.result.redirect_url;
              if (redirect_url) {
                this.SSO_URL = redirect_url;
                window.open(redirect_url, "_blank").focus();
              }
            }
          });
      }
    }
  }

  setFlylearning() {
    let planIdType =
      this.sharedData.getAttribute("planIdType") ??
      this.sharedData.getAttribute("planId");

    this.flyLearningShow =
      !!planIdType &&
      [
        "sales-pro-yearly",
        "sales-pro-monthly",
        "pro-plan-teams-smb",
        "pro-plan-teams-ent",
      ].includes(planIdType);
  }

  handleScrollBar() {
    this.menuHeight.nativeElement.style.overflowY = "hidden";
    setTimeout(() => {
      this.menuHeight.nativeElement.style.overflowY = "scroll";
    }, 1000);
  }

  collapseSideNav(item) {
    if (item.label.toLowerCase() === "flyboard") {
      this.menuService.closeSideNavAfterNavigation.next(true);
    }
  }

  handleCloseSideNav(item) {
    this.menuService.closeSideNavAfterNavigation.next(false);
  }

  handleAccountOpening(url: string | URL) {
    window.open(url, "_blank");
  }
}
