import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-confirm-dialog",
  templateUrl: "./confirm-dialog.component.html",
  styleUrls: ["./confirm-dialog.component.scss"]
})
export class ConfirmDialogComponent implements OnInit {

  title: string;
  type: string;
  isDelete: any;
  callback: string

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.type = this.data.type || "";
    this.callback = this.data.callback || "";
    this.title = this.data.title || "Do you want to <strong> delete </strong> this?";
    this.isDelete = this.data.isDelete === undefined ? true : false;
  }

  cancel(): void {
    this.dialogRef.close();
  }

  save(): void {
    if (this.callback) {
      this.data.self[this.callback](this.data.data);
    } else {
      this.data.self[this.isDelete ? "delete" : "update"](this.data.data);
    }
    
    this.data.self?.detectUpdates?.emit();
    this.cancel();
  }
}
