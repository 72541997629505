import { Component, Inject } from "@angular/core";
import { NgbPopoverConfig } from "@ng-bootstrap/ng-bootstrap";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DialogService } from "src/app/core/core-services/services/dialog.service";
import { Router } from "@angular/router";
import { AuthService } from "src/app/core/core-services/services/auth.service";

@Component({
  selector: "profile-updated-successfully",
  templateUrl: "./profile-updated-successfully.component.html",
  styleUrls: ["./profile-updated-successfully.component.scss"],
})
export class ProfileUpdatedSuccessfullyComponent {
  confirmed = false
  formValue: any
  user: any

  constructor(
    config: NgbPopoverConfig,
    private readonly authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ProfileUpdatedSuccessfullyComponent>,
    public dialog: DialogService,
    private readonly router: Router
  ) {
    config.triggers = "hover";
    config.container = "body";
    this.formValue = data.self.form.value
    this.user = this.authService.userDetails;
  }

  update(): void {
    this.confirmed = true
  }

  close(): void {
    this.dialogRef.close(this.confirmed);
    if (this.confirmed) {
      this.data.self.nextStep()
    }
  }
}
