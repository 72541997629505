import { Injectable } from "@angular/core";
import { DataService } from "src/app/core/core-services/services/dataservices/data.service";
import { AuthService } from "src/app/core/core-services/services/auth.service";
import { Observable } from "rxjs";
import { MasqueradeUserResponse, ReactivateUsersRequest, UserData } from "./users-dto";

export interface Result<T = any> {
  result: T;
}

export interface ValidateEmailUniquenessRequest {
  emails: string[];
  company_id?: string;
}

export interface ValidateEmailUniquenessResponse {
  success: boolean;
  exists: boolean;
  message: string;
}

@Injectable({
  providedIn: "root", // This makes the service available application-wide
})
export class UsersApiService {
  constructor(
    private readonly dataService: DataService,
    public authService: AuthService,
  ) {}

  private get baseUrl() {
    return `v2/admin/user`;
  }

  getUsers({
    perPage,
    page,
    deactivated = false,
    categories,
    filter,
    sortBy,
    sortOrder,
    selectedSubscriptionTypes,
    selectedAccountStatus,
    selectedExtensions,
  }: {
    perPage: number;
    page: number;
    deactivated?: boolean;
    categories?: string;
    filter?: string;
    sortBy: "name" | "created_at";
    sortOrder: "asc" | "desc";
    selectedSubscriptionTypes?: string;
    selectedAccountStatus?: string;
    selectedExtensions?: string;
  }) {
    const data: any = {
      perPage,
      page,
      categories: categories.substring(0, 5100),
      deactivated,
      sort_by: sortBy,
      sort_order: sortOrder,
      selectedSubscriptionTypes: selectedSubscriptionTypes.substring(0, 5100),
      selectedAccountStatus: selectedAccountStatus.substring(0, 5100),
      selectedExtensions: selectedExtensions.substring(0, 5100),
    };

    if (filter) {
      data.filter = filter;
    }

    return this.dataService.get({
      url: `${this.baseUrl}`,
      data,
    });
  }

  getUserDetails(userId: string): Observable<Result<{ user: UserData }>> {
    return this.dataService.get({
      url: `${this.baseUrl}/${userId}/details`
    });
  }

  private get companySlug() {
    return this.authService.getUserCompanySlug();
  }

  validateEmailUniqueness(data: ValidateEmailUniquenessRequest): Observable<Result<ValidateEmailUniquenessResponse>> {
    const url = `${this.baseUrl}/exists`;

    return this.dataService.post({
      isLoader: false,
      url,
      data
    });
  }
  
  masqueradeUser(userId: string): Observable<Result<MasqueradeUserResponse>> {
    return this.dataService.post({
      url: `${this.baseUrl}/${userId}/masquerade`,
    });
  }
  
  pushItToHubspot(userId: string): Observable<Result<void>> {
    return this.dataService.post({
      url: `${this.baseUrl}/${userId}/push-it-to-hubspot`,
    });
  }
  
  
  recalculateUserInfo(userId: string): Observable<Result<void>> {
    return this.dataService.post({
      url: `${this.baseUrl}/${userId}/recalculate-user-info`,
    });
  }
  
  reactivateUsers(data: ReactivateUsersRequest) {
    return this.dataService.post({
      url: `${this.baseUrl}/reactivate`,
      data,
    });
  }
}
