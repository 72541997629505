import { NgImageSliderModule } from "ng-image-slider";
import {
  AdminLayoutComponent,
  AuthLayoutComponent,
  HeaderComponent,
  LayoutComponent,
  MenuComponent,
  OptionsComponent,
  SidebarComponent,
} from "./core";

import { HttpClient, HttpClientModule } from "@angular/common/http";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";

import { BidiModule } from "@angular/cdk/bidi";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppComponent } from "./app.component";
import { AppRoutes } from "./app.routing";

import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSliderModule } from "@angular/material/slider";
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from "@angular/material/stepper";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatTreeModule } from '@angular/material/tree';
import { ClipboardModule } from "ngx-clipboard";
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { planReducer } from '../app/core/store/plan/plan.reducer';
import { PlanEffects } from '../app/core/store/plan/plan.effects';

import { NgModule } from "@angular/core";
import { RouterModule, RouteReuseStrategy } from "@angular/router";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { NgxGaugeModule } from "ngx-gauge";
import {
  NgxUiLoaderConfig,
  NgxUiLoaderModule,
  POSITION,
  SPINNER,
} from "ngx-ui-loader";
import { NgxWebstorageModule } from "ngx-webstorage";
import { CoreModule } from "./core/core-services/core.module";

import { MatPasswordStrengthModule } from "@angular-material-extensions/password-strength";

import { EditorModule } from "@tinymce/tinymce-angular";

import { AuthGuardService } from "./core/core-services/services/auth-guard.service";
import { GuestGuardService } from "./core/core-services/services/guest-guard.service";
import { AdminGuardService } from "./core/core-services/services/admin-guard.service";

import { ProfileResolver } from "./vengreso/profile/profile.resolver";
//import { ImportShortcutsComponent } from "./vengreso/settings/import-shortcuts/import-shortcuts.component";
import { SettingsComponent } from "./vengreso/settings/settings.component";
import { SettingsResolver } from "./vengreso/settings/settings.resolver";
import { EmulatorPreviewComponent } from "./vengreso/shortcuts/actions/emulator-preview/emulator-preview.component";
import { StatisticsComponent } from "./vengreso/shortcuts/actions/statistics/statistics.component";
//import { AddShortcutCategoryComponent } from "./vengreso/shortcuts/add-shortcut-category/add-shortcut-category.component";
import { CategoryDependencyComponent } from "./vengreso/shortcuts/add-shortcut-category/category-dependency/category-dependency.component";
import { AddShortcutComponent } from "./vengreso/shortcuts/add-shortcut/add-shortcut.component";
import { DuplicateShortcutComponent } from "./vengreso/shortcuts/duplicate-shortcut/duplicate-shortcut.component";
import { ShortcutCategoryListResolver } from "./vengreso/shortcuts/shortcut-category-list.resolver";
import { ShortcutListResolver } from "./vengreso/shortcuts/shortcut-list.resolver";
import { ShortcutsComponent } from "./vengreso/shortcuts/shortcuts.component";
import { DeleteVersionComponent } from "./vengreso/shortcuts/version-history/delete-version/delete-version.component";
import { ConfirmVersionComponent } from "./vengreso/shortcuts/version-history/preview/confirm-version-dialog/confirm-version-dialog.component";
import { PreviewComponent } from "./vengreso/shortcuts/version-history/preview/preview.component";
import { VersionHistoryComponent } from "./vengreso/shortcuts/version-history/version-history.component";
import { TemplateCategoryCopyComponent } from "./vengreso/templates/template-category-copy/template-category-copy.component";
import { TemplateCopyComponent } from "./vengreso/templates/template-copy/template-copy.component";
import { TemplatesCategoryListResolver } from "./vengreso/templates/templates-category-list.resolver";
import { TemplatesComponent } from "./vengreso/templates/templates.component";
import { TemplatesResolver } from "./vengreso/templates/templates.resolver";
// import {TemplateSubscriptionComponent} from "./vengreso/templates/template-subscription/template-subscription.component";
// import {TemplateSuccessComponent} from "./vengreso/templates/template-success/template-success.component";
// import {TemplatePaymentComponent} from "./vengreso/templates/template-payment/template-payment.component";
import { ProfileComponent } from "./vengreso/profile/profile.component";
import { CategoryRenameComponent } from "./vengreso/shortcuts/add-shortcut-category/category-rename/category-rename.component";
import { ChangeShortcutCategoryComponent } from "./vengreso/shortcuts/change-shortcut-category/change-shortcut-category.component";

import { DragDropModule } from "@angular/cdk/drag-drop";
import { MatRippleModule } from "@angular/material/core";
import {
  GoogleLoginProvider,
  SocialAuthServiceConfig
} from "@abacritt/angularx-social-login";
import { IconSpriteModule } from "ng-svg-icon-sprite";
import { environment } from "src/environments/environment";
// import {ConvertIntoCentsPipe} from "./vengreso/templates/template-subscription/template-subscription.pipe";

// search module
import { Ng2SearchPipeModule } from "ng2-search-filter";
// import {PopupComponent} from "./core/core-services/services/popup/popup.component";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { AdvancedSearchDialogComponent } from "./core/header/advanced-search-dialog/advanced-search-dialog.component";
import { TopSearchBarComponent } from "./core/header/top-search-bar/top-search-bar.component";

import { MomentDateModule } from "@angular/material-moment-adapter";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { NgbTooltipModule, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgChartsModule } from "ng2-charts";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { NgxPopperModule } from 'ngx-popper';
import { SignOutMessageComponent } from "./components/sign-out-message/sign-out-message.component";
import { SignOutMessageService } from "./components/sign-out-message/sign-out-message.service";
import { HighlighterPipe } from "./core/core-services/highlighter/highlighter.pipe";
import { MenuService } from "./core/menu/menu.service";
import { ProfileUpdatedSuccessfullyComponent } from "./onboarding/dialogs/profile-updated-successfully/profile-updated-successfully.component";
import { AdvancedSearchResultComponent } from "./vengreso/advanced-search-result/advanced-search-result.component";
import { FlyLearningComponent } from "./vengreso/flylearning/fly-learning.component";
import { BillingHistoryComponent } from "./vengreso/settings/billing-history/billing-history.component";
import { DowngradeOptionComponent } from "./vengreso/settings/downgrade-option/downgrade-option.component";
import { DowngradePlanOneComponent } from "./vengreso/settings/downgrade-plan-one/downgrade-plan-one.component";
import { DowngradePlanThreeComponent } from "./vengreso/settings/downgrade-plan-three/downgrade-plan-three.component";
import { DowngradePlanTwoComponent } from "./vengreso/settings/downgrade-plan-two/downgrade-plan-two.component";
import { DowngradedComponent } from "./vengreso/settings/downgraded/downgraded.component";
import { EditPlanComponent } from "./vengreso/settings/edit-plan/edit-plan.component";
import { FlyshareRewardsComponent } from "./vengreso/settings/flyshare-rewards/flyshare-rewards.component";
import { HistoryUsageComponent } from "./vengreso/settings/history-usage/history-usage.component";
import { NewPlanComponent } from "./vengreso/settings/new-plan/new-plan.component";
import { PausedPlanComponent } from "./vengreso/settings/paused-plan/paused-plan.component";
import { PaymentDetailsComponent } from "./vengreso/settings/payment-details/payment-details.component";
import { AddPaymentMethodComponent } from "./vengreso/settings/payment-method-details/add-payment-method/add-payment-method.component";
import { PaymentMethodDetailsComponent } from "./vengreso/settings/payment-method-details/payment-method-details.component";
import { PrimaryMethodConfirmationComponent } from "./vengreso/settings/payment-method-details/primary-method-confirmation/primary-method-confirmation.component";
import { RemovalConfirmationComponent } from "./vengreso/settings/payment-method-details/removal-confirmation/removal-confirmation.component";
import { PlanDetailsModule } from "./vengreso/settings/plans/plan-details.module";
import { AddToFlycutsComponent } from "./vengreso/share/add-to-flycuts/add-to-flycuts.component";
import { FeatureNotInPlanComponent } from "./vengreso/share/feature-not-in-plan/feature-not-in-plan.component";
import { FlyCutInviteComponent } from "./vengreso/share/fly-cut-invite/fly-cut-invite.component";
import { FlyCutNotifyComponent } from "./vengreso/share/fly-cut-notify/fly-cut-notify.component";
import { FlyCutShareComponent } from "./vengreso/share/fly-cut-share/fly-cut-share.component";
import { FlyshareComponent } from "./vengreso/share/flyshare/flyshare.component";
import { ResetInFlycutsComponent } from "./vengreso/share/reset-in-flycuts/reset-in-flycuts.component";
import { RewardNotifyComponent } from "./vengreso/share/reward-notify/reward-notify.component";
import { ShareHistoryComponent } from "./vengreso/share/share-history/share-history.component";
import { UpdateProfileComponent } from "./vengreso/share/update-profile/update-profile.component";
import { CookieService } from 'ngx-cookie-service';
import { AdminUsersComponent } from './admin/admin-users/admin-users.component';
import { AdminCompaniesComponent } from './admin/admin-companies/admin-companies.component';
import { GlobalAdminUsersComponent } from './admin/global-admin-users/global-admin-users.component';
import { GroupComponent } from './admin/group/group.component';
import { ReportingComponent } from './admin/reporting/reporting.component';
import { AddUserManuallyComponent } from './admin/add-user-manually/add-user-manually.component';
import { AddUserByEmailComponent } from './admin/add-user-by-email/add-user-by-email.component';
import { AddUserByCsvComponent } from './admin/add-user-by-csv/add-user-by-csv.component';
import { EditUserComponent } from './admin/edit-user/edit-user.component';
import { EditGroupComponent } from './admin/edit-group/edit-group.component';
import { EditReportingComponent } from './admin/edit-reporting/edit-reporting.component';
import { AddGroupComponent } from './admin/add-group/add-group.component';
import { AccountCenterPaginatorDirective } from './admin/account-center-paginator.directive';
import { HorizontalScrollDirective } from "./admin/reporting/horizontal-scrolling.directive";
import { ConfirmationComponent } from './admin/edit-group/confirmation/confirmation.component';
import { CustomReuseStrategy } from './custom-reuse-strategy';
import { GroupsComponent } from './admin/groups/groups.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader'
import { LoadingChartComponent } from "./admin/reporting/loading-chart/loading-chart.component";
import { LoadingTopFiveComponent } from "./admin/reporting/loading-top-five/loading-top-five.component";
import { AddNewCompanyComponent } from './admin/admin-companies/add-new-company/add-new-company.component';
import { CompanyComponent } from './admin/admin-companies/company/company.component';
import { ConfirmChangesComponent } from './admin/admin-companies/confirm-changes/confirm-changes.component';
import { DeleteCompanyComponent } from './admin/admin-companies/delete-company/delete-company.component';
import { AdminGroupComponent } from './admin/admin-companies/admin-group/admin-group.component';
import { CompanyFiltersComponent } from './admin/admin-companies/company-filters/company-filters.component';
import { ReactivateComponent } from './admin/admin-users/reactivate/reactivate.component';
import { SelectNewGlobalAdminComponent } from "./admin/select-new-global-admin/select-new-global-admin.component";
import { VengresoAddUserByEmailComponent } from "./admin/vengreso/add-user-by-email/add-user-by-email.component";
import { VengresoAddUserManuallyComponent } from "./admin/vengreso/add-user-manually/add-user-manually.component";
import { VengresoReactivateUserComponent } from "./admin/vengreso/reactivate-user/reactivate-user.component";
import { VengresoReportingComponent } from "./admin/vengreso/reporting/reporting.component";
import { BulkDeactivateCompaniesComponent } from "./admin/admin-companies/bulk-deactivate-companies/bulk-deactivate-companies.component";
import { CustomizeColumnsComponent } from "./admin/admin-companies/customize-columns/customize-columns.component";
import { SuperAdminGuardService } from "./core/core-services/services/super-admin-guard.service";
import { AccountCenterComponent } from "./admin/account-center/account-center.component";
import { CMCComponent } from "./admin/cmc/cmc.component";
import { AssignRoleComponent } from "./admin/vengreso/assign-role/assign-role.component";
import { ConfirmationModalComponent } from "./components/confirmation-modal/confirmation-modal.component";
import { AssignMoveToCompanyComponent } from "./admin/vengreso/assign-move-to-company/assign-move-to-company.component";
import { BulkAssignMoveToCompanyComponent } from "./admin/vengreso/bulk-assign-move-to-company/bulk-assign-move-to-company.component";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: "#F05A28",
  bgsPosition: POSITION.centerCenter,
  bgsSize: 80,
  bgsType: SPINNER.ballScaleMultiple, // background spinner type
  fgsColor: "#F05A28",
  fgsType: SPINNER.ballScaleMultiple, // foreground spinner type
  fgsSize: 80,
  overlayColor: "rgb(255,255,255)",
  hasProgressBar: false,
  maxTime: -1,
  minTime: 500,
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    // NotificationComponent,
    OptionsComponent,
    MenuComponent,
    AdminLayoutComponent,
    LayoutComponent,
    AuthLayoutComponent,
    TemplatesComponent,
    ShortcutsComponent,
    SettingsComponent,
    NewPlanComponent,
    AddShortcutComponent,
    VersionHistoryComponent,
    PreviewComponent,
    StatisticsComponent,
    EmulatorPreviewComponent,
    ConfirmVersionComponent,
    DeleteVersionComponent,
    DuplicateShortcutComponent,
    //AddShortcutCategoryComponent,
    //ImportShortcutsComponent,
    CategoryDependencyComponent,
    TemplateCopyComponent,
    TemplateCategoryCopyComponent,
    // TemplateSubscriptionComponent,
    // TemplateSuccessComponent,
    // TemplatePaymentComponent,
    ChangeShortcutCategoryComponent,
    ProfileComponent,
    CategoryRenameComponent,
    // ConvertIntoCentsPipe,
    // PopupComponent,
    TopSearchBarComponent,
    AdvancedSearchDialogComponent,
    AdvancedSearchResultComponent,
    HighlighterPipe,
    PaymentDetailsComponent,
    EditPlanComponent,
    DowngradePlanOneComponent,
    DowngradePlanTwoComponent,
    DowngradePlanThreeComponent,
    DowngradedComponent,
    EditPlanComponent,
    PausedPlanComponent,
    DowngradeOptionComponent,
    PaymentMethodDetailsComponent,
    AddPaymentMethodComponent,
    BillingHistoryComponent,
    PrimaryMethodConfirmationComponent,
    RemovalConfirmationComponent,
    HistoryUsageComponent,
    FlyLearningComponent,
    FlyCutShareComponent,
    FlyCutInviteComponent,
    FlyCutNotifyComponent,
    UpdateProfileComponent,
    ProfileUpdatedSuccessfullyComponent,
    FlyshareComponent,
    RewardNotifyComponent,
    FlyshareRewardsComponent,
    AddToFlycutsComponent,
    ResetInFlycutsComponent,
    FeatureNotInPlanComponent,
    ShareHistoryComponent,
    SignOutMessageComponent,
    AccountCenterComponent,
    CMCComponent,
    AdminUsersComponent,
    AdminCompaniesComponent,
    GlobalAdminUsersComponent,
    GroupComponent,
    ReportingComponent,
    AddUserManuallyComponent,
    AddUserByEmailComponent,
    VengresoAddUserByEmailComponent,
    VengresoAddUserManuallyComponent,
    VengresoReactivateUserComponent,
    VengresoReportingComponent,
    AddUserByCsvComponent,
    SelectNewGlobalAdminComponent,
    EditUserComponent,
    EditGroupComponent,
    EditReportingComponent,
    AddGroupComponent,
    AccountCenterPaginatorDirective,
    HorizontalScrollDirective,
    ConfirmationComponent,
    GroupsComponent,
    LoadingChartComponent,
    LoadingTopFiveComponent,
    AddNewCompanyComponent,
    CompanyComponent,
    ConfirmChangesComponent,
    DeleteCompanyComponent,
    AdminGroupComponent,
    CompanyFiltersComponent,
    ReactivateComponent,
    BulkDeactivateCompaniesComponent,
    CustomizeColumnsComponent,
    AssignRoleComponent,
    ConfirmationModalComponent,
    AssignMoveToCompanyComponent,
    BulkAssignMoveToCompanyComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes, { useHash: false }),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    MatSidenavModule,
    MatCardModule,
    MatMenuModule,
    MatCheckboxModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    MatTabsModule,
    MatListModule,
    MatGridListModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatSortModule,
    MatProgressBarModule,
    MatExpansionModule,
    MatTooltipModule,
    BidiModule,
    MatDialogModule,
    CoreModule,
    NgxWebstorageModule.forRoot({
      prefix: "vengreso",
      separator: ".",
      caseSensitive: true,
    }),
    IconSpriteModule.forRoot({
      path: "assets/sprite/svg-set.svg?ts=" + Date.now(),
    }),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    MatFormFieldModule,
    MatInputModule,
    EditorModule,
    MatTableModule,
    MatPaginatorModule,
    MatRadioModule,
    MatPasswordStrengthModule.forRoot(),
    MatSliderModule,
    DragDropModule,
    MatRippleModule,
    Ng2SearchPipeModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatStepperModule,
    MomentDateModule,
    NgxMatSelectSearchModule,
    NgxGaugeModule,
    MatChipsModule,
    ClipboardModule,
    NgImageSliderModule,
    NgChartsModule,
    NgbTooltipModule,
    NgbModule,
    NgxPopperModule.forRoot({ placement: 'bottom' }),
    PlanDetailsModule,
    StoreModule.forRoot({ data: planReducer }),
    EffectsModule.forRoot([PlanEffects]),
    MatTreeModule,
    NgxSkeletonLoaderModule
  ],
  providers: [
    TemplatesResolver,
    ShortcutCategoryListResolver,
    ShortcutListResolver,
    TemplatesCategoryListResolver,
    SettingsResolver,
    ProfileResolver,
    AuthGuardService, 
    MenuService,
    GuestGuardService,
    AdminGuardService,
    SuperAdminGuardService,
    SignOutMessageService,
    {
      provide: "SocialAuthServiceConfig",
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.GOOGLE_CLIENT_ID, {
              oneTapEnabled: false, // default is true
            }),
          },
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    },
    CookieService,
    { provide: RouteReuseStrategy, useClass: CustomReuseStrategy }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
