export const SUBSCRIPTION_TYPES = [
    {id: '62db349e18621419647000d2', label: 'Freemium'},
    {id: '64a73dbc4b48b0d842058075', label: 'Starter - Monthly'},
    {id: '62db34a018621419647000d5', label: 'Starter - Yearly'},
    {id: '64a73ef04b48b0d842058076', label: 'Growth - Monthly'},
    {id: '64a752c6e296966fa6504b80', label: 'Growth - Yearly'},
    {id: '655cde4efa52cf7acf764f13', label: 'Sales Pro Teams - ENT'},
    {id: '655cde4efa52cf7acf764f12', label: 'Sales Pro Teams - SMB'},
    {id: '64a73f7a4b48b0d842058077', label: 'Sales Pro - Monthly'},
    {id: '64a75349e296966fa6504b81', label: 'Sales Pro - Yearly'},
    {id: '64a432e3d14494102d051ef2', label: 'DealFuel Lifetime Growth'},
    {id: '6350323db3129f09194c658e', label: 'AppSumo Lifetime Growth'}
];
