export const TABLE_COLUMN = [
    { key: "first_name", value: "First Name", checked: true },
    { key: "last_name", value: "Last Name", checked: true },
    { key: "email", value: "Email", checked: true },
    { key: "license_type", value: "License Type" },
    { key: "flymsg_account_creation_date", value: "FlyMSG Account Creation Date" },
    { key: "is_the_user_signed_into_flymsg_extension", value: "Is the User Signed into FlyMSG Extension?" },
    { key: "extension_installed_on_any_browser", value: "Extension Installed on Any Browser" },
    { key: "last_login_to_flylearning", value: "Last Login to FlyLearning" },
    { key: "last_login_to_flymsg", value: "Last Login to FlyMSG" },
    { key: "total_no_of_characters_typed_by_flymsg_by_user", value: "Total # of Characters Typed by FlyMSG by User" },
    { key: "total_no_of_characters_typed_summarized_monthly_by_flymsg_by_user", value: "Total # of Characters Typed Summarized Monthly by FlyMSG by User" },
    { key: "total_time_saved_by_flymsg_by_user", value: "Total Time Saved by FlyMSG by User" },
    { key: "total_cost_savings_by_flymsg_by_user", value: "Total Cost Savings by FlyMSG by User" },
    { key: "last_date_user_used_a_flycut", value: "Last Date User Used a FlyCut" },
    { key: "flycuts_created_count", value: "# of FlyCuts Created (Count)" },
    { key: "flycuts_created_last_date", value: "# of FlyCuts Created (Last Date)" },
    { key: "flyplates_added_to_flycuts_count", value: "# of FlyPlates Added to FlyCuts (Count)" },
    { key: "flyplates_added_to_flycuts_last_date", value: "# of FlyPlates Added to FlyCuts (Last Date)" },
    { key: "total_no_of_times_flyengage_ai_used_count", value: "Total # of Times FlyEngage AI Used (Count)" },
    { key: "total_no_of_characters_typed_by_flyengage", value: "Total # of Characters Typed by FlyEngage" },
    { key: "total_no_of_times_flyposts_used_count", value: "Total # of Times FlyPosts Used (Count)" },
    { key: "total_no_of_characters_typed_by_flyposts", value: "Total # of Characters Typed by FlyPosts" },
    { key: "which_browser_has_an_extension_been_installed_on", value: "Which Browser has an extension been installed on" },
    { key: "flymsg_chrome_extension_installed", value: "FlyMSG Chrome Extension Installed" },
    { key: "flymsg_chrome_extension_installed_date", value: "FlyMSG Chrome Extension Installed (Date)" },
    { key: "flymsg_chrome_extension_uninstalled", value: "FlyMSG Chrome Extension UNInstalled" },
    { key: "flymsg_chrome_extension_uninstalled_date", value: "FlyMSG Chrome Extension UNInstalled (Date)" },
    { key: "flymsg_edge_extension_installed", value: "FlyMSG Edge Extension Installed" },
    { key: "flymsg_edge_extension_installed_date", value: "FlyMSG Edge Extension Installed (Date)" },
    { key: "flymsg_edge_extension_uninstalled", value: "FlyMSG Edge Extension UNInstalled" },
    { key: "flymsg_edge_extension_uninstalled_date", value: "FlyMSG Edge Extension UNInstalled (Date)" },
    { key: "other_reason_to_sign_out_free_form_field", value: "\"Other\" Reason to sign out (Free form field)" },
    { key: "flymsg_extension_version_for_edge", value: "FlyMSG Extension Version for Edge" },
    { key: "clicked_help_request_help_from_menu_count", value: "Clicked Help/request Help From Menu (Count)" },
    { key: "typed_words_per_minute", value: "Typed Words Per Minute" }
];

export type CompaniesTableColumn = {
    key: string;
    value: string;
    checked?: boolean;
    required?: boolean;
};

export const COMPANIES_TABLE_COLUMNS: CompaniesTableColumn[] = [
    { key: "select", value: "Select", checked: true, required: true },
    { key: "company", value: "Company", checked: true, required: true },
    { key: "licenses", value: "Licenses", checked: true, required: true },
    { key: "contract_term", value: "Contract term", checked: true, required: true },
    { key: "contract_start_date", value: "Contract start date" },
    { key: "contract_end_date", value: "Contract end date" },
    { key: "auto_renewal", value: "Auto-Renewal" },
    { key: "payment_options", value: "Payment options" },
    { key: "account_status", value: "Account status", checked: true, required: true },
    { key: "last_modified_on", value: "Last modified on" },
    { key: "actions", value: "Actions", checked: true, required: true }
];