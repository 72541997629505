import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class MetaService {
  constructor(
    private readonly titleService: Title,
    private readonly metaService: Meta,
    private readonly authService: AuthService,
  ) { }

  updateTitle(title: string) {
    if (this.authService.onUserMasqueradeSession) {
      const userDetail = this.authService.userDetails;
      const newTitle = `User - ${userDetail.user_details.first_name} ${userDetail.user_details.last_name}`;
      title = newTitle;
    }

    if (title) {
      this.titleService.setTitle(title);
    }
  }

  updateDescription(description: string) {
    if (description) {
      this.metaService.updateTag({ name: 'description', content: description });
    }
  }


  addMetaTag(name, content) {
    this.updateTitle(name);
    this.updateDescription(content);
  }
}
