import { Router } from "@angular/router";
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { DataService } from "src/app/core/core-services/services/dataservices/data.service";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { LoadingService } from "src/app/core/core-services/services/loader.service";
import { ConfirmDialogComponent } from "src/app/core/core-services/confirm-dialog/confirm-dialog.component";
import { DialogService } from "src/app/core/core-services/services/dialog.service";
import { WarningDialogComponent } from "src/app/core/core-services/warning-dialog/warning-dialog.component";
import { NotificationService } from "src/app/core/core-services/services/notification.service";
import { NgbPopoverConfig } from "@ng-bootstrap/ng-bootstrap";

export type UserPersonaQuota = {
  created: number;
  quota: number;
  remaining: number;
}

@Component({
  selector: "app-user-persona",
  templateUrl: "./user-persona.component.html",
  styleUrls: ["./user-persona.component.scss"],
})
export class UserPersonaComponent implements OnInit {
  search: string;
  order?: 'name' | 'type' | 'created_at';
  updating: boolean = false;
  dataSource: any;
  loading: boolean = false;
  loadingGetUsers: boolean = false;
  loadingQuota: boolean = false;
  userPersonaQuota: UserPersonaQuota;
  loaderId: string = "settingsPersonaTable";
  personas: any[] = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ["name", "type", "actions"];
  itemsPerPage = 10;

  orderByOptions: any[] = [
    { label: "Creation Date", order_by: "created_at" },
    { label: "Name", order_by: "name" },
    { label: "Type", order_by: "type" },
  ];

  constructor(
    private readonly router: Router,
    private readonly dataService: DataService,
    private readonly dialog: DialogService,
    private readonly loadingService: LoadingService,
    private readonly notification: NotificationService,
    config: NgbPopoverConfig
  ) {
    config.container = "body";
    config.placement = ["top"];
    config.autoClose = true;
    config.animation = true;

    this.loadingService.start(this.loaderId);
  }

  ngOnInit(): void {
    if (this.router.url == "/persona") {
      this.router.navigate(["/settings"], { queryParams: { tab: "Persona" } });
    }

    if (!this.dataSource) {
      this.dataSource = new MatTableDataSource();
    }

    try {
      this.loadingQuota = true;
      this.dataService
        .get({ url: "v2/user-persona/quota", isLoader: false })
        .subscribe(
          (response: any) => {
            if (response?.result) {
              this.userPersonaQuota = response.result;
              this.loadingQuota = false;
            }
          },
          (error) => {
            this.loadingQuota = false;
          },
          () => {
            this.loadingQuota = false;
          }
        );
    } catch (error) {
      this.loadingService.stop(this.loaderId);
      console.log(error);
    }

    try {
      this.loading = true;
      this.loadingGetUsers = true;
      this.loadingService.start(this.loaderId);
      this.dataService
        .get({ url: "v2/user-persona/", data: { disabled: true }, isLoader: false })
        .subscribe(
          (response: any) => {
            if (response?.result) {
              this.personas = response.result.data;
              this.dataSource = new MatTableDataSource(this.personas);
              this.dataSource.paginator = this.paginator;
              this.orderUserPersona();
            }
            this.loadingGetUsers = false;
          },
          (error) => {
            this.loadingGetUsers = false;
            setTimeout(() => {
              this.loadingService.stop(this.loaderId);
            }, 200);
          },
          () => {
            this.loadingGetUsers = false;
            setTimeout(() => {
              this.loadingService.stop(this.loaderId);
            }, 200);
          }
        );
    } catch (error) {
      this.loadingService.stop(this.loaderId);
      this.loadingGetUsers = false;
      console.log(error);
    }
  }

  handlePageChange(event: any) {
    const currentPageSize = event.pageSize;

    this.itemsPerPage = currentPageSize;
  }

  validatePersonaQuota(): boolean {
    if (this.userPersonaQuota.remaining < 1) {
      switch(this.userPersonaQuota.quota) {
        case 1:
          this.dialog.openDialogComponent(
            WarningDialogComponent,
            {
              type: "persona",
              showTheStarter: false,
              error: "maxNumberOfPersonasFree",
            },
            "800px"
          );
          return false;
        case 3:
          this.dialog.openDialogComponent(
            WarningDialogComponent,
            {
              type: "persona",
              showTheStarter: false,
              pricing: "salesPro",
              selectedPlan: "salesPro",
              error: "maxNumberOfPersonasGrowth",
            },
            "800px"
          );
          return false;
        case 7:
          this.notification.toast(
            "You've hit the maximum number of 7 personas for your current plan. Update or delete any existent persona.",
            "OK",
            7000
          );
          return false;
        default:
          return true;
      }
    }
    
    return true;
  }

  goToNewPersona(): void {
    if (this.validatePersonaQuota()) {
      this.router.navigate(["/persona/new"]);
    }
  }

  goToUserPersona(event: any, userPersona: any): void {
    if (userPersona.disabled && !this.validatePersonaQuota()) {
      return;
    }

    this.router.navigate([`/persona/${userPersona.id}`]);
  }

  duplicatePersona(event: any, userPersona: any): void {
    if (userPersona.disabled && !this.validatePersonaQuota()) {
      return;
    }

    this.router.navigate([`/persona/${userPersona.id}/duplicate`]);
  }

  deletePersona(event: any, userPersona: any): void {
    this.dialog.openDialogComponent(
      ConfirmDialogComponent,
      {
        self: this,
        title: "Do you want to <strong>delete</strong> this persona?",
        data: userPersona,
      },
      "330px"
    );
  }

  setDefaultPersonaConfirm(event: any, userPersona: any): void {
    if (userPersona.disabled && !this.validatePersonaQuota()) {
      return;
    }

    this.dialog.openDialogComponent(
      ConfirmDialogComponent,
      {
        self: this,
        title: `${userPersona.is_default ? 'Unset' : 'Set'} <strong>${userPersona.name}</strong> as the default persona?`,
        data: userPersona,
        callback: 'setDefaultPersona',
        isDelete: false
      },
      "330px"
    );
  }

  setDefaultPersona(userPersona: any) : void {
    this.dataService
      .put({ 
        url: `v2/user-persona/set-default/${userPersona.id}`,
        isLoader: true,
        data: {
          is_default: !userPersona.is_default
        }
      })
      .subscribe((response: any) => {
        this.personas = this.personas.map(
          (persona) => ({
            ...persona,
            is_default: userPersona.is_default ? false : persona.id === userPersona.id
          })
        );
        this.dataSource = new MatTableDataSource(this.personas);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
      () => {
        this.updating = false;
      });
  }
  
  delete(userPersona: any) : void {
    this.dataService
      .delete({ url: `v2/user-persona/${userPersona.id}`, isLoader: true })
      .subscribe((response: any) => {
        this.personas = this.personas.filter(
          (persona) => persona.id !== userPersona.id
        );
        this.dataSource = new MatTableDataSource(this.personas);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
      () => {
        this.updating = false;
      });
  }
  
  searchUserPersona() {
    this.resetFilterPredicate();
    this.dataSource.filter = this.search.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  resetFilterPredicate() {
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      const dataStr = Object.keys(data).reduce((currentTerm, key) => {
        return currentTerm + (data as { [key: string]: any })[key] + ' ';
      }, '').toLowerCase();
      return dataStr.indexOf(filter) !== -1;
    };    
  }

  orderUserPersona() {
    this.sort.active = this.order ?? 'created_at';
    this.sort.direction = "asc";
    this.dataSource.sort = this.sort;
  }
}
