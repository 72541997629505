export const environment = {
  production: false,
  STRIPE_KEY: "pk_test_DJw9jM95IFwJCV9D7AeUsT93",
  EXTENSION_ID: "mpjnnhbhmhjknjjfccdmndkifkokemdl",
  EXTENSION_ID_TESTING: "mpjnnhbhmhjknjjfccdmndkifkokemdl",
  EDGE_EXTENSION_ID: "mpjnnhbhmhjknjjfccdmndkifkokemdl",
  OPERA_EXTENSION_ID: "mpjnnhbhmhjknjjfccdmndkifkokemdl",
  //API_URL: "https://www.api-staging.vengreso.com/romeo/api/",
  API_URL_BETA: "https://www.api-staging.vengreso.com/romeo/api/",
  //API_URL: "http://localhost:8000/romeo/api/",
  API_URL:"https://staging-synapze2.vengreso.com/romeo/api/",
  //API_URL:"http://flymsg-be-test5.us-east-1.elasticbeanstalk.com/romeo/api/",
  AWS_S3_DOMAIN: "flymsg-vengreso.s3-accelerate.amazonaws.com",
  GOOGLE_CLIENT_ID: "309549483708-003sqr6s6uln0d458vlb5tsl8k8tpl45.apps.googleusercontent.com",
  SSO_URL: "https://ondemand-admin.vengreso.com/SSO.ASPX?USERSESSIONID=",
  //FRONT_END_URL: "http://localhost:4200",
  FRONT_END_URL: "https://staging-app.vengreso.com",
  TINYMCE_API_KEY: "o2cs030sqvbkyh0eluohjjsjrze7v7f64hzymgrhdfq0iasu",
  GOOGLE_CLIENT_SECRET: "AIzaSyA_qETvKi4WJz-woVqiir_Du6EEFK92ixk",
  GOOGLE_REDIRECT_URI: "flyboard",
  TINYDRIVE_DROPBOX_APP_KEY: "2i010g288jv4fr3",
  GIPHY_API_KEY: "M008YKpaM2Yi4452x0ha1an7ophODy9H",
  FLYMSG_DOMAIN: "vengreso.com"
};
