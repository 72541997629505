import { Injectable } from "@angular/core";
import { DataService } from "src/app/core/core-services/services/dataservices/data.service";
import { AuthService } from "src/app/core/core-services/services/auth.service";
import { Observable } from "rxjs";
import { MasqueradeUserResponse, ReactivateUsersRequest } from "./users-dto";

export interface Result<T = any> {
  result: T;
}

export interface ValidateEmailUniquenessRequest {
  emails: string[];
  company_id?: string;
}

export interface ValidateEmailUniquenessResponse {
  success: boolean;
  exists: boolean;
  message: string;
}

@Injectable({
  providedIn: "root", // This makes the service available application-wide
})
export class UsersApiService {
  constructor(
    private readonly dataService: DataService,
    public authService: AuthService,
  ) {}

  private get baseUrl() {
    return `v2/admin/user`;
  }

  private get companySlug() {
    return this.authService.getUserCompanySlug();
  }

  validateEmailUniqueness(data: ValidateEmailUniquenessRequest): Observable<Result<ValidateEmailUniquenessResponse>> {
    const url = `${this.baseUrl}/exists`;

    return this.dataService.post({
      isLoader: false,
      url,
      data
    });
  }
  
  masqueradeUser(userId: string): Observable<Result<MasqueradeUserResponse>> {
    return this.dataService.post({
      url: `${this.baseUrl}/${userId}/masquerade`,
    });
  }
  
  reactivateUsers(data: ReactivateUsersRequest) {
    return this.dataService.post({
      url: `${this.baseUrl}/reactivate`,
      data,
    });
  }
}
