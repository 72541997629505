import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { DialogService } from "src/app/core/core-services/services/dialog.service";
import { AddNewCompanyComponent } from "./add-new-company/add-new-company.component";
import { Subscription } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import moment from "moment";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort, SortDirection } from "@angular/material/sort";
import { NgbPopoverConfig } from "@ng-bootstrap/ng-bootstrap";

import { NotificationService } from "src/app/core/core-services/services/notification.service";
import { SuperAdminApiService } from "../services/super-admin-api.service";
import { NavigationService } from "src/app/core/core-services/services/navigation.service";
import { ConfirmChangesComponent } from "./confirm-changes/confirm-changes.component";
import { LoadingService } from "src/app/core/core-services/services/loader.service";
import { DeleteCompanyComponent } from "./delete-company/delete-company.component";
import { CompanyFiltersComponent } from "./company-filters/company-filters.component";
import { AuthService } from "src/app/core/core-services/services/auth.service";
import { DataService } from "src/app/core/core-services/services/dataservices/data.service";
import { BulkDeactivateCompaniesComponent } from "./bulk-deactivate-companies/bulk-deactivate-companies.component";
import { CustomizeColumnsComponent } from "./customize-columns/customize-columns.component";
import { COMPANIES_TABLE_COLUMNS, CompaniesTableColumn } from "../admin.data";
import { SharedDataService } from "src/app/core/core-services/services/shared-data.service";
import { ConfirmationDialogData, ConfirmationModalComponent } from "src/app/components/confirmation-modal/confirmation-modal.component";
import { CompaniesApiService } from "../services/company/companies-api.service";

export interface CMCCompany {
  account_status: string;
  auto_renewal: "No" | "Yes";
  checked: boolean;
  contract_end_date: string;
  contract_start_date: string;
  contract_term: string;
  created_at: string;
  groups: Array<{
    name: string;
    users: number;
    subgroups: Array<{
      name: string;
      users: number;
      is_group: boolean;
    }>;
    is_group: boolean;
  }>;
  id: string;
  last_modified_on: string;
  licenses: number;
  name: string;
  payment_options: string;
  slug: string;
  status_date: string;
}

@Component({
  selector: "app-admin-companies",
  templateUrl: "./admin-companies.component.html",
  styleUrls: ["./admin-companies.component.scss"],
})
export class AdminCompaniesComponent implements OnInit {
  deactivatedTab: boolean = false;
  subscriptions = new Subscription();
  search: string;
  dateAvailable: string = moment().subtract(1, "day").format("MMM, DD YYYY");
  downloadingCsv: boolean = false;
  visibleColumns: CompaniesTableColumn[] = COMPANIES_TABLE_COLUMNS;
  groupsDisplayedColumns: string[] = [
    "select",
    "company",
    "users",
    "contract_term",
    "contract_start_date",
    "contract_end_date",
    "auto_renewal",
    "payment_options",
    "account_status",
    "last_modified_on",
    "actions",
  ];
  allChecked: boolean = false;
  selectedSort: string = "last_added";
  @ViewChild(MatSort) sort: MatSort;
  paymentOptions: any[] = [];
  statuses: any[] = [];
  dataSource: any;
  expandedElement: any | null;
  loadingCompanies: boolean = false;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly nav: NavigationService,
    private readonly apiService: SuperAdminApiService,
    public dialog: DialogService,
    private readonly notification: NotificationService,
    private readonly cd: ChangeDetectorRef,
    private readonly loadingService: LoadingService,
    public authService: AuthService,
    private readonly dataService: DataService,
    private readonly sharedData: SharedDataService,
    private readonly companiesApiService: CompaniesApiService,
    private readonly router: Router,
    config: NgbPopoverConfig
  ) {
    config.triggers = "hover";
    config.container = "body";
  }

  ngOnInit(): void {
    this.visibleColumns =
      this.sharedData.getAttribute("cmc_companies_visible_columns") ||
      this.visibleColumns;

    this.subscriptions.add(
      this.route.paramMap.subscribe((params) => {
        switch (params.get("path")) {
          case "add-new-company":
            this.addNewCompany();
            break;
          case "deactivated":
            this.deactivatedTab = true;
            break;

          default:
            this.deactivatedTab = false;
            break;
        }
      })
    );

    this.loadCompanies(this.deactivatedTab);

    this.dataService.downloadComplete$.subscribe((filename) => {
      if (["Companies.csv"].includes(filename))
        this.notification.toastWithConfig(
          "The CSV has compiled and downloaded to your computer",
          null,
          {
            verticalPosition: "top",
            horizontalPosition: "center",
            panelClass: ["green-notification"],
          }
        );
    });
  }

  loadCompanies(deactivated: boolean = false): void {
    if (!this.dataSource) {
      this.dataSource = new MatTableDataSource();
    }

    this.loadingCompanies = true;
    this.apiService.getCompanies(deactivated).subscribe({
      next: (response: any) => {
        if (response?.result?.success == true) {
          let companies = response.result.data;

          companies.forEach((company: any) => {
            let flattenedGroups = [];
            let groups = company?.groups;

            if (Array.isArray(groups) && groups.length > 0) {
              groups.forEach((group: any) => {
                flattenedGroups.push({ ...group, is_group: true });
                if (
                  Array.isArray(group?.subgroups) &&
                  group?.subgroups?.length > 0
                ) {
                  flattenedGroups = flattenedGroups.concat(group.subgroups);
                }
              });
              company.groups = new MatTableDataSource(flattenedGroups);
            }
          });

          this.dataSource = new MatTableDataSource(companies);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.paymentOptions = [
            ...new Map(
              companies.map((company: any) => [
                company.payment_options,
                { label: company.payment_options, checked: true },
              ])
            ).values(),
          ];
          this.statuses = [
            ...new Map(
              companies.map((company: any) => [
                company.account_status,
                { label: company.account_status, checked: true },
              ])
            ).values(),
          ];

          const queryParams = this.route.snapshot.queryParams;

          if (queryParams?.["deactivate_company"]) {
            const company = companies.find(
              (company: any) =>
                company.slug == queryParams["deactivate_company"]
            );

            if (company) {
              this.deactivate(null, company);
            }
          }
        }
      },
      error: (error: any) => console.log(error),
      complete: () => (this.loadingCompanies = false),
    });
  }

  ngAfterViewInit() {
    this.disableHeaderSorting();
  }

  get showActionButton(): boolean {
    return (
      this.dataSource.connect().value.filter((t: any) => t.checked).length > 0
    );
  }

  addNewCompany(): void {
    const dialogRef = this.dialog.openDialogComponent(
      AddNewCompanyComponent,
      { class: ["portal-admin", "cmc-add-companies-modal-container"] },
      "600px"
    );

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.dataSource.data = [{
          ...result,
          highlight: true
        }].concat(this.dataSource.data);
        setTimeout(() => {
          this.dataSource.data = this.dataSource.data.map((company) => {
            if (company.highlight) {
              delete company.highlight;
            }
            return company;
          });
        }, 5000);
      }
    });
  }

  bulkDeactivateCompany(): void {
    let companies = this.dataSource.connect().value.filter((c: any) => c.checked)

    const dialogRef = this.dialog.openDialogComponent(
      BulkDeactivateCompaniesComponent,
      {
        data: companies,
        self: this,
        callback: "deactivate",
        class: "portal-admin"
      },
      "750px"
    );

    dialogRef
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.dataSource.data = this.dataSource.data.filter(
            (c: any) => !result.includes(c.id)
          );

          this.nav.navigateTo(["/vengreso-admin/companies/deactivated"]);
        }
      });
  }

  bulkDeleteCompany(): void {
    const dialogRef = this.dialog.openDialogComponent(
      DeleteCompanyComponent,
      { data: this.dataSource?.connect().value.filter((t: any) => t.checked), class: "portal-admin" },
      "750px"
    );

    dialogRef
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.dataSource.data = this.dataSource.data.filter(
            (c: any) => !result.includes(c.id)
          );
        }
      });
  }

  searchCompanies(): void {
    this.resetFilterPredicate();
    this.dataSource.filter = this.search.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  filterByCompany(): void {
    const dialogRef = this.dialog.openDialogComponent(
      CompanyFiltersComponent,
      { companies: this.dataSource.data, filtersClear: this.filtersClear },
      "unset"
    );

    dialogRef.afterClosed().subscribe((selectedCompanies: any) => {
      if (!!selectedCompanies) {
        if (selectedCompanies.length > 0) {
          this.dataSource.filterPredicate = (data: any, filter: any) => {
            return filter.includes(data["name"]);
          };

          this.dataSource.filter = selectedCompanies.map((c: any) => c.name);
          if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
          }
        } else {
          this.resetFilterPredicate();
          this.dataSource.filter = "";
        }
      }
    });
  }

  resetFilterPredicate() {
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      const dataStr = Object.keys(data)
        .reduce((currentTerm, key) => {
          return currentTerm + (data as { [key: string]: any })[key] + " ";
        }, "")
        .toLowerCase();
      return dataStr.indexOf(filter) !== -1;
    };
  }

  get filtersClear(): boolean {
    return (
      (!this.search || this.search?.trim().length < 1) &&
      this.selectedSort === "last_added" &&
      this.statuses.every((t: any) => t.checked === true) &&
      this.paymentOptions.every((t: any) => t.checked === true) &&
      (!this.dataSource?.filter || this.dataSource?.filter?.length < 1)
    );
  }

  clearFilters(): void {
    this.search = "";
    this.searchCompanies();
    this.sortBy("created_at", "desc");

    this.statuses.forEach((t: any) => (t.checked = true));
    this.paymentOptions.forEach((t: any) => (t.checked = true));
  }

  get enableExportCsv(): boolean {
    return this.dataSource.data.filter((t: any) => t.checked).length > 0;
  }

  exportCompanies(): void {
    this.downloadingCsv = true;

    const selectedCompanies = this.dataSource.data
      .filter((t: any) => t.checked)
      .map((company: any) => company.id);

    this.apiService.exportCSV(selectedCompanies.join(","), this.deactivatedTab);

    this.downloadingCsv = false;
  }

  someChecked(): boolean {
    if (this.dataSource.connect().value == null) {
      return false;
    }
    return (
      this.dataSource.connect().value.filter((t: any) => t.checked).length >
        0 && !this.allChecked
    );
  }

  checkAll(checked: boolean): void {
    this.allChecked = checked;
    if (this.dataSource.connect().value == null) {
      return;
    }
    this.dataSource.connect().value.forEach((t: any) => (t.checked = checked));
  }

  updateAllChecked(): void {
    this.allChecked =
      this.dataSource.connect().value != null &&
      this.dataSource.connect().value.every((t: any) => t.checked);
  }

  disableHeaderSorting() {
    const matSortHeaderElements = document.querySelectorAll(
      ".mat-sort-header-container"
    );
    matSortHeaderElements.forEach((element) => {
      (element as HTMLElement).style.cursor = "default";
      element.addEventListener("click", (event) => {
        event.stopPropagation();
        event.preventDefault();
      });
    });
  }

  sortBy(sortKey: string, direction: SortDirection = "asc") {
    this.dataSource.sort.active = sortKey;
    this.dataSource.sort.direction = direction;
    this.dataSource.sort.sortChange.emit();

    if (sortKey === "name" && direction === "asc") {
      this.selectedSort = "a_z";
    } else if (sortKey === "name" && direction === "desc") {
      this.selectedSort = "z_a";
    } else if (sortKey === "created_at" && direction === "asc") {
      this.selectedSort = "first_added";
    } else if (sortKey === "created_at" && direction === "desc") {
      this.selectedSort = "last_added";
    }
  }

  customFilterApplied(data: any[]): boolean {
    return data.some((g: any) => g.checked === false);
  }

  filterByOptions(columnName: "payment_options" | "account_status") {
    const options =
      columnName == "payment_options" ? this.paymentOptions : this.statuses;
    const filter = options
      .filter((t: any) => t.checked)
      .map((t: any) => t.label);

    if (filter.length < 1) {
      this.search = "";
      this.searchCompanies();
      return;
    }

    this.dataSource.filterPredicate = (data: any, filter: any) => {
      return filter.includes(data[columnName]);
    };

    this.dataSource.filter = filter;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  bulkReactivateCompany(): void {
    const data = this.dataSource.connect().value.filter((c: any) => c.checked)
    let companies: any;

    if (Array.isArray(data) && data.length > 0) {
      companies = data;
    } else {
      companies = [data];
    }

    this.dialog.openDialogComponent(
      ConfirmChangesComponent,
      {
        action: "reactivate-company",
        data: companies,
        self: this,
        companyName: companies[0].name,
        callback: "reactivate",
      },
      "500px"
    );
  }

  reactivate(event: MouseEvent, company): void {
    if (event) event.stopPropagation();
    this.loadingService.start();

    this.apiService.reactivateCompany([company.id]).subscribe({
      next: (response: any) => {
        const notificationMessage = company.name + " reactivated successfully";

        this.notification.toastWithConfig(notificationMessage, null, {
          verticalPosition: "top",
          horizontalPosition: "center",
          panelClass: ["green-notification"],
        });

        this.nav.navigateTo(["/vengreso-admin/companies"]);
      },
      error: (err) => console.log(err),
      complete: () => this.loadingService.stop(),
    });
  }

  goToAccountCenter(event: MouseEvent, company: any): void {
    if (event) event.stopPropagation();
    this.authService.masqueradeAsCompany({
      company_id: company.id,
      slug: company.slug,
      name: company.name,
    });
  }

  deactivate(event: MouseEvent, company): void {
    if (event) event.stopPropagation();
    
    const data: ConfirmationDialogData = {
      buttonConfirm: "Confirm",
      title: "Deactivate company",
      message: `Are you sure you want to deactivate <strong>${company.name}</strong>?`,
    };

    const dialogRef = this.dialog.openDialogComponent(
      ConfirmationModalComponent,
      { data, class: "portal-admin" },
      "unset"
    );

    dialogRef.afterClosed().subscribe((success: boolean) => {
      if (success) {
        this.apiService
          .deactivateCompany([company.id])
          .subscribe((response: any) => {
            if (response?.result?.success) {
              const message = `${company.name} deactivated successfully`;

              this.notification.toastWithConfig(message, null, {
                verticalPosition: "top",
                horizontalPosition: "center",
                panelClass: ["red-notification"]
              });

              this.dataSource.data = this.dataSource.data.filter(
                (c: any) => c.id !== company.id
              );
              this.nav.navigateTo(["/vengreso-admin/companies/deactivated"]);
            } else {
              const message = response?.result?.message ?? "Failed to deactivate company";
              this.notification.toastWithConfig(message, null, {
                verticalPosition: "top",
                horizontalPosition: "center",
                panelClass: ["red-notification"]
              });
            }
          });
      }
    });
  }

  goToCompanyUsers(companyId: string): void {
    const url = `vengreso-admin/users`;

    this.router.navigateByUrl(url, {
      state: { companyId },
    });
  }
  
  openDeleteCompanyDialog(event: MouseEvent, company: any): void {
    if (event) event.stopPropagation();
    const data: ConfirmationDialogData = {
      buttonConfirm: "Confirm",
      title: "Delete company",
      subtitle: `Are you sure you want to delete <strong>${company.name}</strong>?`,
      message: `By deleting this organization all users included will be automatically unassigned.
                <br><em><strong style="color: #f55555">Be Careful! </strong>This action cannot be undone.</em>`,
    };

    const dialogRef = this.dialog.openDialogComponent(
      ConfirmationModalComponent,
      { data, class: "portal-admin" },
      "unset"
    );

    dialogRef.afterClosed().subscribe((success: boolean) => {
      if (success) {
        this.companiesApiService
          .deleteCompany(company.id)
          .subscribe((response: any) => {
            if (response?.result?.success) {
              const message = `${company.name} deleted successfully`;

              this.notification.toastWithConfig(message, null, {
                verticalPosition: "top",
                horizontalPosition: "center",
                panelClass: ["red-notification"]
              });

              this.dataSource.data = this.dataSource.data.filter(
                (c: any) => c.id !== company.id
              );
            } else {
              const message = response?.result?.message ?? "Failed to delete company";
              this.notification.toastWithConfig(message, null, {
                verticalPosition: "top",
                horizontalPosition: "center",
                panelClass: ["red-notification"]
              });
            }
          });
      }
    });
  }

  toggleRow(element: any, event: Event): void {
    event.stopPropagation();

    if (element?.groups !== null) {
      this.expandedElement = this.expandedElement === element ? null : element;
    }

    this.cd.detectChanges();
  }

  goToCompany(companySlug: string, event: Event) {
    if (this.deactivatedTab) {
      return;
    }

    if (
      (event.target as HTMLElement).nodeName === "TD" &&
      (event.target as HTMLElement).getAttribute("clickable-cell")
    ) {
      return;
    }

    if (!companySlug) {
      return;
    }

    this.nav.navigateTo([
      `/vengreso-admin/companies/${companySlug}/company-info`,
    ]);
  }

  get displayedColumns(): string[] {
    return this.visibleColumns
      .filter((column) => column.checked)
      .map((column) => column.key);
  }

  openCustomizeColumns(): void {
    const dialogRef = this.dialog.openDialogComponent(
      CustomizeColumnsComponent,
      {
        selectedColumns: this.visibleColumns,
      },
      "none"
    );

    dialogRef.afterClosed().subscribe((columns: any) => {
      if (columns) {
        this.visibleColumns = columns.filter((c: any) => c.checked);
      }
    });
  }
}
