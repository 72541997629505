import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { IAnalyticsService } from "./analytics.service";

declare const gtag: any;

@Injectable({
  providedIn: "root",
})
export class GTMService implements IAnalyticsService {
  private readonly baseURL = `https://www.google-analytics.com/mp/collect?measurement_id=${environment.GA_MEASUREMENT_ID}&api_secret=${environment.GA_API_KEY}`;
  constructor() {}

  async track(name: string, params: any) {
    try {
      const client_id = await this.identity();

      fetch(this.baseURL, {
        method: "POST",
        body: JSON.stringify({
          client_id,
          events: [
            {
              name,
              params
            },
          ]
        })
      });
    } catch (e) {
      console.error('Error tracking event', e);
    }
  }

  async identify(user_id: string) {
    try {
      const client_id = await this.identity();
      fetch(this.baseURL, {
        method: "POST",
        body: JSON.stringify({
          client_id,
          user_id
        })
      });
    } catch (e) {
      console.error('Error tracking event', e);
    }
  }
  
  async addUserProperties(properties: {
    [key: string]: any;
  }) {
    try {
      const clientId = await this.identity();
      fetch(this.baseURL, {
        method: "POST",
        body: JSON.stringify({
          "client_id": clientId,
          "user_properties": Object.entries(properties).reduce((acc, [key, value]) => {
              acc[key] = { value };
              return acc;
            }, {})
        })
      });
    } catch (e) {
      console.error('Error tracking event', e);
    }
  }

  async identity() {
    return String(await this.get('client_id'));
  }

  async userId() {
    return String(await this.get('client_id'));
  }

  private get(param: string) {
    return new Promise(resolve => {
      gtag('get', environment.GA_MEASUREMENT_ID, param, resolve)
    });
  }
}
