import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatDatepicker } from "@angular/material/datepicker";
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { Country, ICountry } from "country-state-city";
import { findIndex, forEach } from "lodash";

import { SuperAdminApiService } from "../../services/super-admin-api.service";
import { NotificationService } from "src/app/core/core-services/services/notification.service";
import moment from "moment";
import { NotifyService } from "src/app/core/menu/not.service";
import { NgbPopoverConfig } from "@ng-bootstrap/ng-bootstrap";
import { LoadingService } from "src/app/core/core-services/services/loader.service";

const MY_FORMATS = {
  parse: {
    dateInput: "MM/DD/YYYY",
  },
  display: {
    dateInput: "MM/DD/YYYY",
    monthYearLabel: "MMMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};
@Component({
  selector: "app-add-new-company",
  templateUrl: "./add-new-company.component.html",
  styleUrls: ["./add-new-company.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class AddNewCompanyComponent implements OnInit, OnDestroy {
  addCompanyForm: FormGroup;
  countries: ICountry[] = Country.getAllCountries();
  filteredCountries: ICountry[] = Country.getAllCountries();
  startDatePicker: MatDatepicker<any>;
  termsOfContract: any[] = [
    { label: "6 months", interval_in_months: 6 },
    { label: "1 year", interval_in_months: 12 },
    { label: "2 years", interval_in_months: 24 },
    { label: "Custom", interval_in_months: 0 },
  ];
  licenseTypes: {
    type: string;
    label: string;
    min: number;
  }[] = [
    { type: "starter", label: "Starter", min: 0 },
    { type: "growth", label: "Growth", min: 0 },
    { type: "sales_pro", label: "Sales Pro", min: 0 },
    { type: "sales_pro_teams_smb", label: "Sales Pro Teams", min: 1 },
  ];
  businessProOptions: any[] = [
    { value: "yes_dedicated", label: "Yes, dedicated training and coaching" },
    { value: "yes_community", label: "Yes, community training and coaching" },
    { value: "no", label: "No" },
  ];
  stepOne: boolean = true;
  stepTwo: boolean = false;
  stepThree: boolean = false;
  loadingValidateEmail: Record<number, boolean> = {};
  pocForms: {
    form: FormGroup,
    expanded: boolean,
  }[] = [
    {
      form: new FormGroup({
        email: new FormControl("", [Validators.required, Validators.email]),
        first_name: new FormControl("", Validators.required),
        last_name: new FormControl("", Validators.required),
      }),
      expanded: true,
    },
  ];
  loading: boolean = false;
  @ViewChild("searchInput") searchInput!: ElementRef;

  constructor(
    private readonly router: Router,
    private readonly location: Location,
    private readonly fb: FormBuilder,
    private readonly apiService: SuperAdminApiService,
    public dialogRef: MatDialogRef<AddNewCompanyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly notification: NotificationService,
    private readonly cd: ChangeDetectorRef,
    public notifyService: NotifyService,
    private readonly loaderService: LoadingService,
    config: NgbPopoverConfig
  ) {
    config.triggers = "hover";
    config.container = "body";
    let USAIndex = findIndex(this.countries, { name: "United States" });
    [this.countries[0], this.countries[USAIndex]] = [
      this.countries[USAIndex],
      this.countries[0],
    ];

    this.filteredCountries = this.countries;
  }

  applyFilter(event): void {
    const filter = event.target.value.toLowerCase();
    this.filteredCountries = this.countries.filter((country) =>
      country.name.toLowerCase().includes(filter)
    );
  }

  onOpenedChange(isOpen: boolean): void {
    if (isOpen) {
      setTimeout(() => this.searchInput.nativeElement.focus(), 0);
    }
    this.searchInput.nativeElement.value = "";
    this.applyFilter({ target: { value: "" } });
  }

  ngOnInit(): void {
    let currentUrl = this.router.url;
    if (!currentUrl.includes("add-new-company")) {
      this.location.replaceState(currentUrl + "/add-new-company");
    }

    this.addCompanyForm = this.fb.group({
      company_name: ["", Validators.required],
      company_address_line1: ["", Validators.required],
      company_address_line2: [""],
      city: [""],
      state: [""],
      country: ["", Validators.required],
      zip_code: [""],
      term_of_contract: [12, Validators.required],
      custom_term_of_contract: [null],
      contract_start_date: ["", Validators.required],
      contract_end_date: [""],
      business_pro_enterprise_plus: [
        [],
        [Validators.required, this.validateBusinessProSelection],
      ],
      total_licenses: [1, [Validators.required, Validators.min(1)]],
      starter: [0, Validators.min(0)],
      growth: [0, Validators.min(0)],
      sales_pro: [0, Validators.min(0)],
      sales_pro_teams_smb: [1, Validators.min(1)],
      auto_renewal: [false],
    });

    this.onChanges();
  }

  onChanges(): void {
    this.addCompanyForm
      .get("term_of_contract")
      ?.valueChanges.subscribe((selectedValue) => {
        const customTermOfContractField = this.addCompanyForm.get(
          "custom_term_of_contract"
        );

        if (selectedValue == 0) {
          customTermOfContractField?.setValidators([
            Validators.required,
            Validators.min(0),
          ]);
        } else {
          customTermOfContractField?.clearValidators();
        }

        // Re-evaluate the validation status
        customTermOfContractField?.updateValueAndValidity();
        this.calculateEndDate();
      });

    this.addCompanyForm
      .get("contract_start_date")!
      .valueChanges.subscribe(() => {
        this.calculateEndDate();
      });

    this.addCompanyForm
      .get("custom_term_of_contract")!
      .valueChanges.subscribe(() => {
        this.calculateEndDate();
      });
  }

  private calculateEndDate() {
    const startDate = this.addCompanyForm.get("contract_start_date")?.value;
    const months =
      this.addCompanyForm.get("term_of_contract")?.value !== 0
        ? this.addCompanyForm.get("term_of_contract")?.value
        : this.addCompanyForm.get("custom_term_of_contract")?.value;

    if (startDate && months) {
      const end = moment(startDate).add(Number(months), "months");

      this.addCompanyForm
        .get("contract_end_date")
        ?.setValue(end.format("MM/DD/YYYY"));
    }
  }

  validateBusinessProSelection(control: any) {
    const selectedOptions = control.value;

    if (
      selectedOptions.includes("no") &&
      (selectedOptions.includes("yes_dedicated") ||
        selectedOptions.includes("yes_community"))
    ) {
      return { invalidSelection: true };
    }

    return null;
  }

  addCompany(): void {
    if (this.stepOne) {
      this.moveToStep(2);
      return;
    }

    if (this.stepTwo) {
      this.moveToStep(3);
      return;
    }

    this.loading = true;
    const apiData = {
      company: {
        ...this.addCompanyForm.value,
        contract_start_date: moment(
          this.addCompanyForm.get("contract_start_date")?.value
        ).format("MM/DD/YY"),
      },
      pocs: this.pocForms.map((pocForm: any) => pocForm.form.value),
    };

    this.loaderService.start();
    this.apiService.addCompany(apiData).subscribe({
      next: (response: any) => {
        this.loaderService.stop();
        this.dialogRef.close(response.result.data);
        this.notifyService.notifyAboutChange({ action: "company-added" });
        this.notification.toastWithConfig(
          "New company added successfully",
          null,
          {
            verticalPosition: "top",
            horizontalPosition: "center",
            panelClass: ["green-notification"],
          }
        );
      },
      error: ({ error }: any) => {
        this.loaderService.stop();
        this.loading = false;
        let errors = [];
        forEach(error?.error_list, (array, key) => {
          errors = errors.concat(array);
        });
        if (
          errors.length === 1 &&
          String(errors[0]).includes("email has already been taken.")
        ) {
          this.notification.toast(
            "One of POC's email has already been taken",
            "ok",
            4000
          );
          return;
        }
        this.notification.toast(
          error?.result?.message ?? "Something went wrong",
          "ok",
          4000
        );
      },
      complete: () => {
        this.loading = false;
        this.loaderService.stop();
      },
    });
  }

  get isValidatingEmail() {
    return Object.keys(this.loadingValidateEmail).some(i => this.loadingValidateEmail[i]);
  }

  validatePocEmail(index: number) {
    this.loadingValidateEmail[index] = true;

    const email = this.pocForms[index].form.get("email")?.value;

    const duplicatedEmail = this.pocForms.some((form, i) => {
      return form.form.get("email")?.value === email && i !== index;
    });

    if (duplicatedEmail) {
      this.loadingValidateEmail[index] = false;
      const control = this.pocForms[index].form.get("email");
      control?.setErrors({ alreadyTaken: true });
      return;
    }

    this.apiService.validatePocEmail(email).subscribe({
      next: (response: any) => {
        this.loaderService.stop();
        this.loadingValidateEmail[index] = false;
        const control = this.pocForms[index].form.get("email");
        
        if (response.result.success) {
          if (control) {
            const errors = control.errors;
            if (errors) {
              delete errors['alreadyTaken'];
              control.setErrors(Object.keys(errors).length > 0 ? errors : null);
            }
          }
        } else if (control) {
          control.setErrors({ alreadyTaken: true });
        }
      },
      error: ({ error }: any) => {
        this.loadingValidateEmail[index] = false;
        const control = this.pocForms[index].form.get("email");
        control?.setErrors({ alreadyTaken: true });
      },
      complete: () => {
        this.loadingValidateEmail[index] = false;
      },
    });
  }

  increment(field: string): void {
    const currentValue = this.addCompanyForm.get(field)?.value || 0;
    this.addCompanyForm.get(field)?.setValue(currentValue + 1);
    this.addCompanyForm.updateValueAndValidity();
  }

  decrement(field: string): void {
    const currentValue = this.addCompanyForm.get(field)?.value || 0;
    const min =
      this.licenseTypes.find((license) => license.type === field)?.min || 0;
    if (currentValue > min) {
      this.addCompanyForm.get(field)?.setValue(currentValue - 1);
    }
    this.addCompanyForm.updateValueAndValidity();
  }

  get licenseExceedError(): string {
    const totalLicenses = this.addCompanyForm.get("total_licenses")?.value || 0;

    // Validate licenses in a single loop
    const totalLicenseInputted = this.licenseTypes.reduce(
      (acc, license) => {
        const licenseValue = this.addCompanyForm.get(license.type)?.value || 0;

        // Check for minimum requirement
        if (licenseValue < license.min) {
          return {
            error: `The number of ${license.label} licenses cannot be less than ${license.min}`,
            total: 0,
          };
        }

        return { total: acc.total + licenseValue };
      },
      { total: 0 } as { total: number; error?: string }
    );

    if ("error" in totalLicenseInputted) {
      return totalLicenseInputted.error;
    }

    if (totalLicenseInputted.total !== totalLicenses) {
      return "The number of the licenses cannot exceed or be less than the total license quota";
    }

    return null;
  }

  moveToStep(step: number) {
    this.stepOne = step === 1;
    this.stepTwo = step === 2;
    this.stepThree = step === 3;
    if (this.stepThree) {
      this.dialogRef.updateSize("400px");
    } else {
      this.dialogRef.updateSize("600px");
    }
  }

  addPocForm(): void {
    if (this.pocForms.length < 5) {
      let newForm = new FormGroup({
        email: new FormControl("", [Validators.required, Validators.email]),
        first_name: new FormControl("", Validators.required),
        last_name: new FormControl("", Validators.required),
      });
      this.pocForms.push({ form: newForm, expanded: true });
    }
  }

  togglePocForm(index: number): void {
    this.pocForms[index].expanded = !this.pocForms[index].expanded;
    this.cd.detectChanges();
  }

  removePocForm(index: number): void {
    if (this.pocForms.length > 1) {
      this.pocForms.splice(index, 1);
    }
  }

  get invalidPocForm(): boolean {
    return this.pocForms.some((pocForm: any) => pocForm.form.invalid);
  }

  ngOnDestroy(): void {
    this.location.replaceState(this.router.url.replace("/add-new-company", ""));
  }

  close(data?: any): void {
    if (this.stepThree) {
      this.moveToStep(2);
      return;
    }

    if (this.stepTwo) {
      this.moveToStep(1);
      return;
    }
    this.location.replaceState(this.router.url.replace("/add-new-company", ""));
    this.dialogRef.close(data);
  }
}
