import { HttpClient } from "@angular/common/http";
import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { cloneDeep, get } from "lodash";
import { PlansConstants, getCategoriesReward, getPlanId } from "src/app/core/core-services/constants/plans.constants";
import { environment } from "src/environments/environment";
import { SharedDataService } from "../services/shared-data.service";
const warnings = {
  persona: {
    title:
      "You’ve reached the limits of your current plan. \nPlease upgrade your subscription.",
    message:
      "You've unlocked a premium feature, upgrade now.",
    salesProPlanDesc: ["7 Personas", "Unlimited AI Emulation", "Copy AI Emulation"],
    growthPlanDesc: ["3 Personas"],
  },
  editor: {
    title:
      "You’ve reached the limits of your current plan. \nPlease upgrade your subscription.",
    starterTitle:
    "You’ve reached the limits of your current plan. \nPlease upgrade your subscription.",
    message:
      "Select which plan fits your needs!",
    starterPlanDesc: ["Most Text Editor Functions", "Add Images"],
    growthPlanDesc: ["All Text Editor Functions", "Add Any Media Type"],
  },
  images: {
    title:
      "You’ve reached the limits of your current plan. \nPlease upgrade your subscription.",
    starterTitle:
    "You’ve reached the limits of your current plan. \nPlease upgrade your subscription.",
    message:
      "Select which plan fits your needs!",
    starterPlanDesc: ["Image upload size up to 5MB"],
    growthPlanDesc: ["Image upload size up to 20MB"],
  },
  editorFonts: {
    title:
      "You’ve reached the limits of your current plan. \nPlease upgrade your subscription.",
    starterTitle:
    "You’ve reached the limits of your current plan. \nPlease upgrade your subscription.",
    message:
      "Select which plan fits your needs!",
    starterPlanDesc: ["3 Fonts"],
    growthPlanDesc: ["Unlimited Fonts"],
  },
  numOfFlycuts: {
    title:
      "You’ve reached the limits of your current plan. \nPlease upgrade your subscription.",
    message: "Select which plan fits your needs!",
    starterPlanDesc: ["300 FlyCuts"],
    growthPlanDesc: ["Unlimited FlyCuts"],
  },
  numOfChar: {
    title:
      "You’ve reached the limits of your current plan. \nPlease upgrade your subscription.",
    message: "Select which plan fits your needs!",
    starterPlanDesc: ["25,000 Characters"],
    growthPlanDesc: ["Unlimited Characters"],
  },
  flyLearningWarning: {
    title:
      "You’ve reached the limits of your current plan. \nPlease upgrade your subscription.",
    message: "Your current plan doesn't have access to On Demand content",
    salesProPlanDesc: ["Unlimited On-Demand Access", "14+ Hours of Prospecting Training", "50+ Prospecting Scripts", "13 Messaging Playbooks"],
  },
  advancedSearch: {
    title:
      "You’ve reached the limits of your current plan. \nPlease upgrade your subscription.",
    message: "",
    starterPlanDesc: ["Advanced Search"],
    growthPlanDesc: ["Advanced Search"],
  },
  import: {
    title:
      "You’ve discovered some features that are only available with the Starter and/or Growth plan. ",
    message: "Please upgrade your plan to import all your FlyCuts.",
    starterPlanDesc: [
      "• Up to 300 FlyCuts",
      "• 25,000 Characters per FlyCut",
      "• Up to 7 Categories & 14 Subcategories",
      "• Rich text editor",
    ],
    growthPlanDesc: [
      "• Unlimited FlyCuts",
      "• Unlimited Characters Per FlyCut",
      "• Unlimited Categories",
      "• Rich text editor",
    ],
  },
  categories: {
    title:
      "You’ve reached the limits of your current plan. Please upgrade your subscription.",
    message: "Select which plan fits your needs!",
    starterPlanDesc: [
      "7 Categories",
    ],
    growthPlanDesc: [
      "Unlimited Categories",
    ],
  },
  Flyplates: {
    title:
      "You’ve reached the limits of your current plan. Please upgrade your subscription.",
    starterTitle:
      "You’ve reached the limits of your current plan. Please upgrade your subscription.",
    message: "",
    starterPlanDesc: ["Up to 50 FlyPlates"],
    growthPlanDesc: ["Unlimited FlyPlates"],
  },
  versionHistory: {
    title:
      "You need to upgrade to Starter or Growth Plan to use the version history",
    starterTitle:
      "You need to upgrade to Starter or Growth Plan to use the version history",
    message: "Select which plan fits your needs!",
    starterPlanDesc: ["Leverage 3 of the Latest Versions"],
    growthPlanDesc: ["Leverage up to 999 Versions"],
  },
  versionHistoryForStarter: {
    title:
      "Upgrade to Growth Plan to restore any of the 999 versions",
    starterTitle:
      "Upgrade to Growth Plan to restore any of the 999 versions",
    message: "Select which plan fits your needs!",
    starterPlanDesc: ["Unlimited FlyCuts", "Unlimited Characters Per FlyCut", "Unlimited Categories", "Rich text editor"],
    growthPlanDesc: ["Unlimited FlyCuts", "Unlimited Characters Per FlyCut", "Unlimited Categories", "Rich text editor"],
  },
  FlyplatesForStarter: {
    title: "Upgrade your account to add more FlyPlates",
    message: "",
    starterPlanDesc: [""],
    growthPlanDesc: ["Unlimited FlyPlates"],
    upgrade: "Upgrade to Growth",
  },
};

const errors = {
  editor: {
    blockStyle: "Your current plan does not allow the block styling feature within a FlyMSG",
    italic: "Your current plan does not allow the italic feature within FlyMSG",
    strikethrough: "Your current plan does not allow the strikethrough feature within FlyMSG",
    underline: "Your current plan does not allow the underline feature within FlyMSG",
    bulletPoint: "Your current plan does not allow the bullet point feature within a FlyMSG",
    numberedList: "Your current plan does not allow the numbered list feature within a FlyMSG",
    preview: "Your current plan does not allow the preview feature within a FlyMSG",
    hyperlink: "Your current plan does not allow to hyperlink text within a FlyMSG",
    clearFormatting: "Your current plan does not allow the clear formatting feature within a FlyMSG",
    centerAlignment: "Your current plan does not allow the center-alignment feature within a FlyMSG",
    rightAlignment: "Your current plan does not allow the right-alignment feature within a FlyMSG",
    leftAlignment: "Your current plan does not allow the left-alignment feature within a FlyMSG",
    increaseIndent: "Your current plan does not allow to increase indent within a FlyMSG",
    decreaseIndent: "Your current plan does not allow to decrease indent within a FlyMSG",
    fontColor: "Your current plan does not allow to change font color within a FlyMSG",
    backgroundColor: "Your current plan does not allow you to change background color within a FlyMSG",
    fontType: "Your current plan does not allow to change font type within a FlyMSG",
    fontSize: "Your current plan does not allow to change font size within a FlyMSG",
    spellchecker: "Your current plan does not allow you to check spelling for any language except English",
  },
  flycut: {
    maxFree: "Your current plan allows a limit of 20 FlyCuts",
    maxStarter: "Your current plan allows a limit of 300 FlyCuts",
    maxCharFree: "Your current plan allows a limit of 2,500 characters per FlyMSG",
    maxCharStarter: "Your current plan allows a limit of 25,000 characters per FlyMSG",
  },
  advancedSearch: {
    advancedSearch: "Your current plan does not have access to advanced search.",
  },
  categories: {
    maxFree: `Your current plan allows a limit of ${PlansConstants.FREE.CATEGORIES + getCategoriesReward()} categories`,
    maxStarter: `Your current plan allows a limit of ${PlansConstants.STARTER.CATEGORIES + getCategoriesReward()} categories`,
  },
  subCategories: {
    maxFree: "Your current plan allows a limit of 14 subcategories",
    maxStarter: "Your current plan allows a limit of 28 subcategories",
  },
  flyplates: {
    maxFree: "Your current plan allows you access only to FlyPlates in the GENERAL category",
    maxStarter: "Your current plan allows a limit of 50 FlyPlates from any category",
  },
  images: {
    maxFree: "Your current plan does not allow you to upload an image larger than 1MB",
    maxStarter: "Your current plan does not allow you to upload an image larger than 5MB",
  },
  persona: {
    maxNumberOfPersonas: "You've unlocked a premium feature, upgrade now.",
    maxNumberOfPersonasFree: "Your current plan does not allow more than 1 persona",
    maxNumberOfPersonasGrowth: "Your current plan does not allow more than 3 persona",
    maxNumberOfPersonasPro: "Your current plan does not allow more than 7 persona",
  }
}
@Component({
  selector: "app-warning-dialog",
  templateUrl: "./warning-dialog.component.html",
  styleUrls: ["./warning-dialog.component.scss"],
})
export class WarningDialogComponent implements OnInit {
  title: string;
  message: string;
  starterPlanDesc: string[];
  growthPlanDesc: string[];
  salesProPlanDesc: string[]; 
  showTheStarter: string;
  errorMsg: string;
  upgrade: string;
  pricing: any = {};
  selectedPlan: string;

  constructor(
    public dialogRef: MatDialogRef<WarningDialogComponent>,
    private readonly router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly http: HttpClient,
    private sharedData: SharedDataService
  ) {
    this.selectedPlan = data.selectedPlan;
    this.getPricing();
    let planId = getPlanId();
    if (planId == "Starter" && warnings[data.type]?.starterTitle) {
      this.title = warnings[data.type]?.starterTitle;
    } else {
      this.title = warnings[data.type]?.title;
    }
    this.message = warnings[data.type]?.message;
    if (data.showTheStarter == false) this.message = ''
    this.starterPlanDesc = warnings[data.type]?.starterPlanDesc;
    this.growthPlanDesc = warnings[data.type]?.growthPlanDesc;
    this.salesProPlanDesc = warnings[data.type]?.salesProPlanDesc;
    this.errorMsg = get(errors, this.mapTheError(data.error));
    if (data.error == 'spellchecker') {
      this.starterPlanDesc = ['Most Text Editor Functions', 'Add Images']
      this.growthPlanDesc = ['Most Text Editor Functions', 'Add Any Media Type']
    }
    this.showTheStarter = data.showTheStarter;
    this.upgrade = warnings[data.type]?.upgrade;
  }

  ngOnInit(): void {}

  cancel(): void {
    this.dialogRef.close();
  }

  mapTheError(error: string): string {
    let path  = ''
    switch (error) {
      case "blockStyle":
        path = 'editor.blockStyle';
        break;
      case "italic":
        path = 'editor.italic';
        break;
      case "strikethrough":
        path = 'editor.strikethrough';
        break;
      case "underline":
        path = 'editor.underline';
        break;
      case "InsertUnorderedList":
        path = 'editor.bulletPoint';
        break;
      case "InsertOrderedList":
        path = 'editor.numberedList';
        break;
      case "mcePreview":
        path = 'editor.preview';
        break;
      case "mceLink":
        path = 'editor.hyperlink';
        break;
      case "RemoveFormat":
        path = 'editor.clearFormatting';
        break;
      case "JustifyCenter":
        path = 'editor.centerAlignment';
        break;
      case "JustifyFull":
        path = 'editor.centerAlignment';
        break;
      case "JustifyRight":
        path = 'editor.rightAlignment';
        break;
      case "JustifyLeft":
        path = 'editor.leftAlignment';
        break;
      case "indent":
        path = 'editor.increaseIndent';
        break;
      case "outdent":
        path = 'editor.decreaseIndent';
        break;
      case "fontColor":
        path = 'editor.fontColor';
        break;
      case "backgroundColor":
        path = 'editor.backgroundColor';
        break;
      case "FontName":
        path = 'editor.fontType';
        break;
      case "FontSize":
        path = 'editor.fontSize';
        break;
      case "spellchecker":
        path = 'editor.spellchecker';
        break;
      case "maxFree":
        path = 'flycut.maxFree';
        break;
      case "maxStarter":
        path = 'flycut.maxStarter';
        break;
      case "maxCharFree":
        path = 'flycut.maxCharFree';
        break;
      case "maxCharStarter":
        path = 'flycut.maxCharStarter';
        break;
      case "advancedSearch":
        path = 'advancedSearch.advancedSearch';
        break;
      case "categoriesFree":
        path = 'categories.maxFree';
        break;
      case "categoriesStarter":
        path = 'categories.maxStarter';
        break;
      case "subCategoriesFree":
        path = 'subCategories.maxFree';
        break;
      case "subCategoriesStarter":
        path = 'subCategories.maxStarter';
        break;
      case "flyplatesFree":
        path = 'flyplates.maxFree';
        break;
      case "flyplatesStarter":
        path = 'flyplates.maxStarter';
        break
      case "imagesFree":
        path = 'images.maxFree';
        break;
      case "imagesStarter":
        path = 'images.maxStarter';
        break;
      case "maxNumberOfPersonas":
        path = 'persona.maxNumberOfPersonas';
        break;
      case "maxNumberOfPersonasFree":
        path = 'persona.maxNumberOfPersonasFree';
        break;
      case "maxNumberOfPersonasGrowth":
        path = 'persona.maxNumberOfPersonasGrowth';
        break;
      case "maxNumberOfPersonasPro":
        path = 'persona.maxNumberOfPersonasPro';
        break;
      default:
        break;
    }
    return path
  }

  navigateTo(plan: string): void {
    switch (plan) {
      case "starter":
        this.dialogRef.close();
        this.router.navigate(["/new-plan/YearlyStarter"]);
        break;
      case "growth":
        this.dialogRef.close();
        this.router.navigate(["/new-plan/YearlyGrowth"]);
        break;
      case "sales-pro":
        this.dialogRef.close();
        this.router.navigate(["/new-plan/YearlySalesPro"]);
        break;

      default:
        break;
    }
  }

  getPricing() {
    this.http
      .get(this.apiURL + "v1/subscriptions/plans", { observe: "response" as "body" })
      .subscribe({
        next: (response: any) => {
          const plans = response.body.result;
          console.log(plans);
          this.pricing['starter'] = plans.find((plan: any) => plan.identifier == 'starter-yearly').unit_amount;
          this.pricing['growth'] = plans.find((plan: any) => plan.identifier == 'growth-yearly').unit_amount;
          this.pricing['salesPro'] = plans.find((plan: any) => plan.identifier == 'sales-pro-yearly').unit_amount;
        },
        error: (error) => {
          console.log(error);
        }
      });
  }

  displayedPrice(amount: any): string | number {
    return amount ? Number(amount / 100).toFixed(2) : ''
  }

  private get apiURL(): string {
    return environment.API_URL;
  }
}
