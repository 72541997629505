import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VersionHistoryService {
  // Subject to notify when a version is deleted
  private versionDeletedSource = new Subject<void>();
  versionDeleted$ = this.versionDeletedSource.asObservable();

  constructor() {}

  // Method to notify version deletion
  notifyVersionDeleted() {
    this.versionDeletedSource.next();
  }
}