import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { NotificationService } from "src/app/core/core-services/services/notification.service";

import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { NgbPopoverConfig } from "@ng-bootstrap/ng-bootstrap";
import { DialogService } from "src/app/core/core-services/services/dialog.service";
import { UsersApiService } from "../../services/users/users-api.service";

@Component({
  selector: "app-reactivate",
  templateUrl: "./reactivate.component.html",
  styleUrls: ["./reactivate.component.scss"],
})
export class ReactivateComponent implements OnInit {
  user: any;
  confirmationStep: boolean = false;

  dataSource: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ["select", "name", "actions"];

  constructor(
    public dialogRef: MatDialogRef<ReactivateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly notification: NotificationService,
    private readonly usersApiService: UsersApiService,
    public dialog: DialogService,
    config: NgbPopoverConfig
  ) {
    this.user = this.data.data;
    config.triggers = "hover";
    config.container = "body";
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(
      this.data.data.map((user) => ({
        ...user,
        checked: user.status !== "Invited" && !user.company_id,
      }))
    );
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  reactivateUser(): void {
    if (!this.confirmationStep) {
      this.setConfirmation();
      return;
    }

    const apiData = this.dataSource.data
      .filter((user) => user.checked)
      .map((user: any) => ({ id: user.id }));

    this.usersApiService
      .reactivateUsers({ users: apiData })
      .subscribe((response: any) => {
        if (response?.result?.success) {
          const message = "Users reactivated successfully";
          this.notification.toastWithConfig(message, null, {
            verticalPosition: "top",
            horizontalPosition: "center",
            panelClass: ["green-notification"],
          });
          this.dialogRef.close(apiData);
        } else {
          const message = response?.result?.message || "Failed to reactivate users";
          this.notification.toastWithConfig(message, null, {
            verticalPosition: "top",
            horizontalPosition: "center",
            panelClass: ["red-notification"],
          });
        }
      });
  }

  removeInConfirmationView(element: any): void {
    this.dataSource.data = this.dataSource
      .connect()
      .value.filter((user: any) => user.email !== element.email);
    this.dataSource.paginator = this.paginator;
  }

  setConfirmation() {
    this.confirmationStep = true;
    this.dialogRef.componentInstance.dialogRef.addPanelClass(
      "portal-admin-confirmation"
    );
  }
  
  removeConfirmation() {
    this.dialogRef.componentInstance.dialogRef.removePanelClass(
      "portal-admin-confirmation"
    );
    this.confirmationStep = false;
  }

  close(data?: any): void {
    if (this.confirmationStep) {
      this.removeConfirmation();
      return;
    }

    this.dialogRef.close(data);
  }

  get hasSelected(): boolean {
    return this.dataSource.data.some((user) => user.checked);
  }
}
