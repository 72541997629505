import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Data, NavigationEnd, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Browser, detect } from "detect-browser";
import { filter, map, mergeMap, tap } from "rxjs/operators";

import { Subscription } from "rxjs";
import { AuthGuardService } from "./core/core-services/services/auth-guard.service";
import { MetaService } from "./core/core-services/services/meta.service";
import { Store } from "@ngrx/store";
import { PlanState } from "./core/store/plan/plan.reducer";
import { loadUserCurrentSubscriptionApi } from "./core/store/plan/plan.actions";
import { SharedDataService } from "./core/core-services/services/shared-data.service";
import { ExtensionService } from "./core/core-services/services/extension.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit, OnDestroy {
  private layoutRouter: Subscription;
  private browser_type: Browser;

  constructor(
    private readonly router: Router,
    translate: TranslateService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly metaService: MetaService,
    private readonly authGuard: AuthGuardService,
    private readonly store: Store<PlanState>,
    private readonly sharedData: SharedDataService,
    private readonly extensionService: ExtensionService
  ) {
    authGuard.validateStatus();
    translate.addLangs(["en", "fr"]);
    translate.setDefaultLang("en");
    this.sharedData.deleteAttribute("advancedSearchData");

    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr/) ? browserLang : "en");

    // Store browser type
    var browser = detect();
    if (
      browser &&
      (browser?.name == "edge" || browser?.name == "edge-chromium")
    ) {
      this.browser_type = "edge";
    } else {
      this.browser_type = browser?.name as Browser;
    }
    if (this.browser_type) {
      this.sharedData.setAttribute("browser_type", this.browser_type);
    }
  }

  async recursiveCheckExtensionAuth(): Promise<void> {
    const isSignedIn = await this.extensionService.isExtensionSignedIn()

    if (!isSignedIn) {
      setTimeout(() => {
        this.recursiveCheckExtensionAuth();
      }, 10000)
    }
  }

  ngOnInit(): void {
    this.migrateOldStorage();
    this.layoutRouter = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === "primary"),
        mergeMap((route) => route.data),
        tap(({ title, description }: Data) => {
          if (title && description) this.metaService.updateTitle(title);
          this.metaService.updateDescription(description);
        })
      )
      .subscribe();

    if (this.authGuard.isLoggedIn()) {
      this.store.dispatch(loadUserCurrentSubscriptionApi({}));
    }

    this.extensionService.isExtensionInstalled()
      .then((result) => {
        if (result) {
          this.recursiveCheckExtensionAuth();
        }
      })
  }

  ngOnDestroy(): void {
    this.layoutRouter.unsubscribe();
  }

  migrateOldStorage(): void {
    const oldKeys = [
      "browser_type",
      "lastExternalReferrer",
      "lastExternalReferrerTime",
      "omVisits",
      "omVisitsFirst",
      "onTrial",
      "planId",
      "planIdType",
      "usedTrial"
    ];
  
    oldKeys.forEach((key) => {
      const storedValue = localStorage.getItem(key);
  
      if (storedValue !== null) {
        let parsedValue;
  
        try {
          parsedValue = JSON.parse(storedValue);
        } catch (error) {
          console.warn(`Failed to parse value for key "${key}". Using raw value.`);
          parsedValue = storedValue;
        }
  
        try {
          this.sharedData.setAttribute(key, parsedValue);
        } catch (error) {
          console.error(`Failed to set attribute "${key}" in sharedData:`, error);
          return;
        }
  
        localStorage.removeItem(key);
      }
    });
  }
}
