import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ClipboardService } from "ngx-clipboard";
import { FormGroup, FormBuilder } from "@angular/forms";

import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';

import { DataService } from 'src/app/core/core-services/services/dataservices/data.service';
import { NotificationService } from 'src/app/core/core-services/services/notification.service';
import { ValidationConstants } from "src/app/core/core-services/constants/validation.constants";

import { AuthService } from 'src/app/core/core-services/services/auth.service';
import { DialogService } from 'src/app/core/core-services/services/dialog.service';
import { DOCUMENT, Location } from '@angular/common';
import { ProfileUpdatedSuccessfullyComponent } from 'src/app/onboarding/dialogs/profile-updated-successfully/profile-updated-successfully.component';
import { Store } from '@ngrx/store';
import { PlanState } from 'src/app/core/store/plan/plan.reducer';

@Component({
  selector: 'app-fly-cut-invite',
  templateUrl: './fly-cut-invite.component.html',
  styleUrls: ['./fly-cut-invite.component.scss']
})
export class FlyCutInviteComponent implements OnInit {

  showToolTip: boolean = false;

  inviteCounter = 0;
  firstPersonSignedUp: any = false;
  secondPersonSignedUp: any = false;
  thirdPersonSignedUp: any = false;
  fourthPersonSignedUp: any = false;

  flyshareInviteLink: string;

  firstName:string;
  lastName:string;

  form: FormGroup;
  emails: string[] = [];

  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  timeout: any = null;

  currentPlan$ = this.store.select(state => state.data);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,
    public dialog: DialogService,
    public dialogRef: MatDialogRef<FlyCutInviteComponent>,
    private readonly dataService: DataService,
    private readonly clipboardService: ClipboardService,
    private readonly notification: NotificationService,
    private readonly fb: FormBuilder,
    public authService: AuthService,
    private readonly cdr: ChangeDetectorRef,
    @Inject(DOCUMENT) private readonly document: any,
    private readonly location: Location,
    private readonly store: Store<PlanState>,
  ) {

    let user = this.authService.userDetails;
    this.flyshareInviteLink = `${this.document.location.origin}/session/signup?referel=${user?.user_details?.referral_key||''}`
    const {first_name, last_name} = this.authService.userDetails.user_details;

    this.firstName = first_name;
    this.lastName = last_name;

    this.form = this.fb.group({
      first_name: [
        first_name,
        [
          Validators.required,
          Validators.pattern(ValidationConstants.NON_DIGIT),
          Validators.maxLength(12)
        ],
      ],
      last_name: [
        last_name,
        [
          Validators.required,
          Validators.pattern(ValidationConstants.NON_DIGIT),
          Validators.maxLength(12)
        ],
      ],
      message: ['']
    });
  }

  ngOnInit(): void {
    this.currentPlan$
    .subscribe(({data: currentPlan}: any) => {
         if (currentPlan?.referrals) {
           const referrals = currentPlan.referrals;
           if (referrals[0]) {
             this.inviteCounter = 1
             this.firstPersonSignedUp = JSON.parse(referrals[0])
           }
           if (referrals[1]) {
             this.inviteCounter = 2
             this.secondPersonSignedUp = JSON.parse(referrals[1])
           }
           if (referrals[2]) {
             this.inviteCounter = 3
             this.thirdPersonSignedUp = JSON.parse(referrals[2])
           }
           if (referrals[3]) {
             this.inviteCounter = 4
             this.fourthPersonSignedUp = JSON.parse(referrals[3])
           }
           this.cdr.detectChanges();
         }
       }
    );

    this.location.replaceState('rewards');
    this.form.markAllAsTouched();
  }

  ngOnDestroy(): void {
    if(this.data?.parentPage === 'subscription'){
      this.location.replaceState(`settings?tab=subscription`);
      return
    }
    this.location.replaceState(`flyboard`);
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value) {
      this.emails.push(value)
    }

    // Clear the input value
    event.chipInput!.clear();
  }

  onKeyUp(event: any) {
    clearTimeout(this.timeout);
    let that = this;

    this.timeout = setTimeout(function () {
      if (event.keyCode != ENTER) {
        const email = (event.target.value || '').trim();

        const emailPatternValidator = new FormControl(email.toLowerCase(), [
          Validators.pattern(ValidationConstants.EMAIL_EXTRA_REGEX),
          Validators.minLength(5),
          Validators.email,
          Validators.required
        ]);

        if(emailPatternValidator.valid) {
          event.target.value = ''
          that.emails.push(email)
        }
      }
    }, 1000);
  }

  remove(email: any): void {
    const i = this.emails.indexOf(email);

    if (i >= 0) {
      this.emails.splice(i, 1);
    }
  }

  close(): void {
    this.dialogRef.close({modal: 'flycut_invite'});
  }

  copyLink(): void {
    if (!this.flyshareInviteLink) {
      return;
    }
    
    this.clipboardService.copyFromContent(
      this.flyshareInviteLink
    );

    this.showToolTip = true;
    setTimeout(() => this.showToolTip = false, 2000);
    this.notification.toast("Link copied successfully!");
  }

  share(): void {
    if(this.invalidEmail()){
      this.notification.toast('Invalid email(s)', 'Ok', 2000);
      return;
    }

    if (!this.form.valid) {
      this.notification.toast('Form error', 'Ok', 2000);
      return;
    }

    const { value : {first_name, last_name, message} } = this.form;

    const data = {
      first_name,
      last_name,
      invited_email: this.emails.map(email => email.trim()).join(','),
      personal_message: message
    }
    
    this.dataService.post({url: 'v1/user/invitation', data: data, isLoader: true}).subscribe({
      next: (response:any) => {
        this.notification.toast('Invitation sent successfully', 'Ok', 2000);

        if(this.nameChanged(data)) {
          this.promptUpdateProfile();
        }

        // this.form.reset();
        this.emails = [];
        this.form.controls['message'].reset()
      },
      error: (error) => {
        this.notification.toast(error.error.error ?? 'Something Went Wrong. Check fields.', 'Ok', 2000)
      }
    });
  }

  invalidEmail() {
    return this.emails.some(email => 
       (new FormControl(email.toLowerCase(), [
        Validators.pattern(ValidationConstants.EMAIL_EXTRA_REGEX),
        Validators.minLength(5),
        Validators.email,
        Validators.required
      ])).invalid
    );
  }
  
  nameChanged(payload: any): boolean {
    return (this.firstName !== payload.first_name || this.lastName !== payload.last_name);
  }

  promptUpdateProfile(): void {
    this.dialog.openDialogComponent(
      ProfileUpdatedSuccessfullyComponent,
      { self: this },
      "450px"
    );
  }

  updateProfile() {
    let data = this.form.value
    this.dataService
      .put({ url: "v1/user", isLoader: false, data })
      .subscribe((response: any) => {
        if (response && response.result) {
          this.authService.updateUserDetail();
        }
      },
      (error) => {},
      () => {});
  }

  nextStep(): void {
    this.updateProfile()
  }
}
