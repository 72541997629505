import { EventEmitter, Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject } from "rxjs";
import { AuthService } from "../core-services/services/auth.service";

@Injectable()
export class MenuService {
  list1Event: EventEmitter<any> = new EventEmitter();
  list2Event: EventEmitter<any> = new EventEmitter();
  openMenu: EventEmitter<string> = new EventEmitter();

  public closeSideNavAfterNavigation = new BehaviorSubject(true)
  public closeSideNavAfterNavigation$ = this.closeSideNavAfterNavigation.asObservable()

  constructor(public translate: TranslateService, private authService: AuthService) { }
  
  getAll(): any {
    const userMenu = [{
      link: "/flyboard",
      label: this.translate.instant("FLYBOARD"),
      icon: "icon-ico-dashboard",
      tooltip: "FlyBoard aka Dashboard displays top FlyMSGs used along with individual cost and time savings statistics."
    },
    {
      sublink: "/shortcuts",
      label: this.translate.instant("FLYCUTS"),
      icon: "icon-ico-shortcut",
      tooltip: "FlyCuts aka Shortcuts displays the library of your messages and templates saved.",
      isSubMenu: true,
      isDraggable: true,
    },
    {
      sublink: "/flyplates",
      label: this.translate.instant("FLYPLATES"),
      icon: "icon-ico-template",
      tooltip: "FlyPlates aka Templates. Based on your plan type, you have access to some or all of them.",
      isSubMenu: true,
    },
    {
      sublink: "/flyshare",
      label: this.translate.instant("FLYSHARE"),
      icon: "icon-ico-share",
      tooltip: "FlyShare is your sharing center that allows you to easily manage and access all FlyMSGs you've shared or received.",
      isSubMenu: true,
    },
    {
      sso: "flylearning",
      label: this.translate.instant("FLYLEARNING"),
      icon: "icon-ico-dashboard",
      tooltip: "FlyLearning is our on-demand learning library of over 14 hours of sales prospecting education available only with a Sales Pro Individual or Teams license."
    }];

    if (!this.authService.showAdminSection() && !this.authService.showVengresoAdminSection()) {
      return userMenu;
    }

    const userRoles = this.authService.getUserRole();
    let adminLink = "/";
    let cmcAdminLink = "/";

    if (userRoles.includes("Vengreso Admin")) {
      cmcAdminLink = "/vengreso-admin/users";
    }

    if (userRoles.some(role => ["Global Admin", "Reporting Admin", "Group Admin"].includes(role))) {
      const companySlug = this.authService.getUserCompanySlug();
      adminLink = "/"+companySlug+"/admin/users";
    }

    const adminMenu = [
      {
        sublink: "/settings",
        sublink_two: adminLink,
        sublink_three: cmcAdminLink,
        label: this.translate.instant("ADMIN"),
        icon: "icon-admin-menu",
        tooltip: "Access and manage users, groups and reporting.",
        isSubMenu: true,
      }
    ];

    return [...userMenu, ...adminMenu];
  }
}
