import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { RxwebValidators } from "@rxweb/reactive-form-validators";
import { distinctUntilChanged } from "rxjs/operators";

import { Router } from "@angular/router";
import { getPlanId } from "src/app/core/core-services/constants/plans.constants";
import { DataService } from "src/app/core/core-services/services/dataservices/data.service";
import { DialogService } from "src/app/core/core-services/services/dialog.service";
import { ExtensionService } from "src/app/core/core-services/services/extension.service";
import { NotificationService } from "src/app/core/core-services/services/notification.service";
import { WarningDialogComponent } from "src/app/core/core-services/warning-dialog/warning-dialog.component";
import { CategoryRenameComponent } from "../../shortcuts/add-shortcut-category/category-rename/category-rename.component";
import { AddShortcutComponent } from "../../shortcuts/add-shortcut/add-shortcut.component";
import { ShortcutCategoryService, SHORTCUT_CATEGORIES } from "src/app/core/core-services/services/shortcut-category.service";
import { SharedDataService } from "src/app/core/core-services/services/shared-data.service";
import { DynamicStorage } from "src/app/core/core-services/decorators/dynamic-storage";

@Component({
  selector: "app-template-copy",
  templateUrl: "./template-copy.component.html",
  styleUrls: ["./template-copy.component.scss"]
})
export class TemplateCopyComponent implements OnInit {

  public form: FormGroup;
  @DynamicStorage(SHORTCUT_CATEGORIES) shortcutCategoryList: any[];
  filteredCategoryList: any;
  exactMatch: boolean = false;
  filterCtrl = new FormControl();
  @ViewChild("selectElem") selectElem: any;

  constructor(
    private readonly fb: FormBuilder,
    private readonly dataService: DataService,
    private readonly notification: NotificationService,
    private readonly extensionService: ExtensionService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddShortcutComponent>,
    public dialog: DialogService,
    private readonly router: Router,
    private readonly sharedData: SharedDataService,
    private readonly shortcutCategoryService: ShortcutCategoryService
  ) { }

  ngOnInit(): void {
    this.getShortcutCategory();

    this.sharedData.storage.observe(SHORTCUT_CATEGORIES)
    .pipe(
      distinctUntilChanged(
        (prev, curr) => prev.value === curr.value
      )
    )
    .subscribe((value) => {
      this.shortcutCategoryList = value;
      this.filteredCategoryList = [...this.shortcutCategoryList];
    });

    this.filteredCategoryList = [...this.shortcutCategoryList];

    this.form = this.fb.group({
      template_id: [this.data.data.id],
      category_id: [null, [RxwebValidators.required()]]
    });

    this.filterCtrl.valueChanges
    .subscribe(() => {
      this.filterCategories();
    });
  }

  filterCategories() {
    this.exactMatch = false
    let search = this.filterCtrl.value;
    search = search.toLowerCase();
    if (!search) {
      this.filteredCategoryList = [...this.shortcutCategoryList];
      return
    }

    let filteredCategoryList = []
    for (const o of this.shortcutCategoryList) {
      let catSearch =  o.name.toLowerCase().indexOf(search) > -1; 
      if (catSearch) {
        this.exactMatch =  search === o.name.toLowerCase() ? true : false
        filteredCategoryList.push({...o, sub_category_lv1: []})
        continue
      }
      for (const sub_category_lv1 of o.sub_category_lv1) {
        let catSearch =  sub_category_lv1.name.toLowerCase().indexOf(search) > -1;
        if (catSearch) {
          this.exactMatch =  search === sub_category_lv1.name.toLowerCase() ? true : false
          filteredCategoryList.push({...sub_category_lv1, sub_category_lv1: [], name: `${o.name} / ${sub_category_lv1.name}`})
          continue
        }
        for (const sub_category_lv2 of sub_category_lv1.sub_category_lv2) {
          let catSearch =  sub_category_lv2.name.toLowerCase().indexOf(search) > -1;
          if (catSearch) {
            this.exactMatch =  search === sub_category_lv2.name.toLowerCase() ? true : false
            filteredCategoryList.push({...sub_category_lv2, sub_category_lv1: [], name: `${o.name} / ${sub_category_lv1.name} / ${sub_category_lv2.name}`})
            continue
          }
        }
      }
    }
    this.filteredCategoryList = filteredCategoryList
  }

  shortcutCategoryRename(data: any) {
    this.dialog.openDialogComponent(CategoryRenameComponent, { self: this, data });
  }

  saveCategory(data) {
    this.dataService.post({ url: "v1/user/shortcut-category", data })
      .subscribe((response: any) => {
        if (response && response.result) {
          this.extensionService.sendUpdateMessage();
          this.getShortcutCategory(true);
          this.form.patchValue({
            category_id: response.result
          });
          this.selectElem.close()
          this.notification.toast("Category added successfully!");
        }
      },
        () => { }
      );
  }

  getShortcutCategory(reloadFromApi = false) {
    this.shortcutCategoryService.loadShortcutCategory({reloadFromApi});
  }

  close(): void {
    this.dialogRef.close();
  }

  getCategoryType(id: string) {
    for (const item of this.shortcutCategoryList) {
      if (item.id == id) {
        return "category"
      }
      if (item.sub_category_lv1) {
        for (const subLv1 of item.sub_category_lv1) {
          if (subLv1.id == id) {
            return "sub_categories_lv1"
          }
          if (subLv1.sub_category_lv2) {
            for (const subLv2 of subLv1.sub_category_lv2) {
              if (subLv2.id == id) {
                return "sub_categories_lv2"
              }
            }
          }
        }
      }
    }
  }

  copyToShortcut(data: any) {
    data.type = this.getCategoryType(data.category_id)
    this.dataService.post({ url: "v1/user/copy-shortcut/template/single", data }).subscribe({
      next: (response: any) => {
        if (response && response.result) {
          this.notification.toast("Template added successfully.");
          this.extensionService.sendUpdateMessage();
          if (this.router.url != '/advanced-search-result') {
            this.data.self.listService.getList(true);
          }
          this.data.self?.detectUpdates?.emit();
          this.close();
        }
      },
      error: (error) => {
        if(error.status == 403 && error.error?.error == 'You are not authorized to add flyplates') {
          let planId = getPlanId();
          this.dialog.openDialogComponent(
            WarningDialogComponent,
            { self: this, type: 'Flyplates', showTheStarter: planId === "Free", error: planId === "Free" ? 'flyplatesFree' : 'flyplatesStarter' },
            "800px"
          );
          return;
        }
      }
    });
  }
}
