import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  Renderer2,
  ViewChildren
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";
import { concatMap, first, takeUntil } from "rxjs/operators";
import { getPlanId } from "src/app/core/core-services/constants/plans.constants";
import { DataService } from "src/app/core/core-services/services/dataservices/data.service";
import { ScriptService } from "src/app/core/core-services/services/script.service";
import { StripeService } from "src/app/core/core-services/services/stripe.service";
import { SubscriptionPlanType } from "src/app/core/models/stripe.models";

import { NotificationService } from "src/app/core/core-services/services/notification.service";
import { NotifyService } from "src/app/core/menu/not.service";
import {
  CTA_SALES_PRO_MONTHLY_ID,
  CTA_SALES_PRO_MONTHLY_URL,
  CTA_SALES_PRO_YEARLY_ID,
  CTA_SALES_PRO_YEARLY_URL,
} from "./plan-details.config";
import { SharedDataService } from "src/app/core/core-services/services/shared-data.service";

const stripeStyles = {
  base: {
    backgroundColor: "#F5F5F5",
    borderColor: "#EEEEEE",
  },
};

declare let hbspt: any;
@Component({
  selector: "app-plans-details",
  templateUrl: "./plans-details.component.html",
  styleUrls: ["./plans-details.component.scss"],
})
export class PlansDetailsComponent implements OnInit, OnDestroy {

  @Input('onboarding')
  onboarding: boolean;

  @ViewChildren('buttons') buttons: QueryList<ElementRef>;

  private stripeId = "";
  public client_secret = "";
  public planType: SubscriptionPlanType;
  public subType = SubscriptionPlanType;
  private _destroy$ = new Subject<void>();
  checked = false;
  isSalesProPlan = false;
  // Form Controls
  // TODO: Need to add other billing details to form and send with request
  PaymentName = new FormControl("");
  faqs = [
    {
      title: 0,
      id: "01",
      qa: "What are my payment options?",
      ans: "You can purchase FlyMSG with any major credit card. It’s totally secure, and we use Stripe for all of our payment processing.",
    },
    {
      title: 1,
      id: "02",
      qa: "What is the difference between Freemium and Starter?",
      ans: "The main difference between our Freemium and Starter plans is that Freemium is free and only offers the basic features you need to get started with FlyMSG while the Starter plan allows for the use of more FlyPlates, the creation of more FlyCuts as well as access to all styling options. ",
    },
    {
      title: 2,
      id: "03",
      qa: "What is the difference between Starter and Growth?",
      ans: "With our Growth plan, you’ll be able to create an unlimited number of FlyCuts, FlyPlates, categories, and subcategories and unlimited access to characters and styling options. Not only that, but you’ll also be able to access our spell check features in any language you want. If you’re looking for increased flexibility and variance within your communications, our Growth plan is perfect for you. It is the most common plan used by our users.",
    },
    {
      title: 3,
      id: "04",
      qa: "Can I cancel my subscription at any time?",
      ans: "Before you cancel, consider pausing your subscription for a month. Perhaps that will help your budget. You can do this under the Subscription section within settings. We’d hate to see you go, but if you don’t want to Type Less and Do More, then all you need to do is cancel the renewal within FlyMSG under the Subscription tab in settings.",
    },
    {
      title: 4,
      id: "05",
      qa: "If I have an Annual Subscription, will it renew automatically?",
      ans: "Yes, you will be automatically charged each year on the anniversary date of your subscription at the full retail price regardless of any discount provided to you at the time of purchase. With your annual subscription, you will have access each year to all existing content, all-new FlyPlates and FlyCuts, technical support, and all-new features launched to support your subscription. The name will appear as “Vengreso - FlyMSG Starter or Growth Plan”. ",
    },
    {
      title: 5,
      id: "06",
      qa: "If I have questions before I buy, who do I contact?",
      ans: "if you have any questions about FlyMSG, please do not hesitate to contact us. We want to make sure that you’re confident in your purchase. Schedule a meeting right now by clicking <a href='https://vengreso.com/contact-us'>here</a> . ",
    },
    {
      title: 6,
      id: "07",
      qa: " What is your refund policy?",
      ans: "We truly believe in our proven process and the power of FlyMSG that it’s highly unlikely you’ll ask for a refund. However, you should know there is no refund policy. Once you cancel, you’ll have access to use the application through the end of that billing cycle, and you won’t be charged for the next. Please note that once you cancel, you’ll be downgraded to a free plan, which means most of your FlyCuts formatting will be lost. If you are over the limit of FlyCuts or characters, you will be locked out from using them until you purchase a subscription. ",
    },
    {
      title: 7,
      id: "08",
      qa: "How do I get support after I become a customer?",
      ans: "We offer chat and email support to HeySupport at Vengreso dot com for all our plans and education solutions. On our Starter and Growth plan, a customer service agent will be readily available to answer your questions from 8-5pm CST. On our Freemium plan, your requests get processed and replied to within 2-3 business days. ",
    },
    {
      title: 8,
      id: "09",
      qa: "What happens when I hit my limits in the free plan?",
      ans: "When you hit your limits in our Freemium plan, you will no longer be able to create additional FlyCuts, use new FlyPlates, or possibly utilize the plan’s included features. ",
    },
  ];

  salesProPlanOffers = [
    "UNLIMITED Ondemand Access",
    "15+ Hours of Sales Prospecting Education",
    "50+ Prospecting Scripts",
    "13 Messaging Playbooks",
    "Live Quarterly Sales Training",
    "Monthly All Access Pass",
  ];

  panelOpenState: boolean[] = [];
  planId;
  localPlanId;
  localPlanIdType;
  onTrial: boolean;
  pricing: any = {};
  planIndex = -1;
  usedTrial: any;
  constructor(
    private readonly dataService: DataService,
    private readonly route: ActivatedRoute,
    private readonly stripeService: StripeService,
    private readonly _router: Router,
    private readonly renderer: Renderer2,
    private readonly scriptService: ScriptService,
    private readonly notification: NotificationService,
    private readonly sharedData: SharedDataService,
    public notifyService: NotifyService,
    private readonly el: ElementRef
  ) {
    // loop to add panels
    for (let i = 0; i < 5; i++) {
      this.panelOpenState.push(false);
    }
    this.planId = getPlanId();

    this.localPlanId = this.sharedData.getAttribute("planId");
    this.localPlanIdType = this.sharedData.getAttribute("planIdType");
    this.onTrial = this.sharedData.getAttribute("onTrial") === 'true';
    this.usedTrial = this.sharedData.getAttribute("usedTrial");

    this.plans.forEach((it, ix) => {
      if (it.key.includes((this.localPlanIdType ?? this.localPlanId).split('-')[0].toLowerCase())) {
        this.planIndex = ix;
        it.current = true;
      }
    });

    if (
      this.localPlanId == "sales-pro-yearly" ||
      this.localPlanId == "sales-pro-monthly" ||
      this.localPlanIdType == "sales-pro-yearly" ||
      this.localPlanIdType == "sales-pro-monthly"
    ) {
      this.isSalesProPlan = true;
    }
    this.getPricing();
  }
  public currentPlan(plan) {
    return plan.current ?
      ((this.localPlanIdType ?? this.localPlanId).includes('yearly') ? !this.checked : this.checked && !this.onTrial)
      : false;
  }

  public elements = this.stripeService.stripe.elements();
  public paymentElement = this.elements.create("card", {
    style: stripeStyles,
  });

  ngOnInit(): void {
    if (this._router.url == "/plans") {
      this._router.navigate(["/pricing"]);
      return;
    }
    this.planType =
      SubscriptionPlanType[this.route.snapshot.paramMap.get("id")];
    this.stripeHandler();
  }

  showTrial(plan: any): boolean {
    return (this.onboarding && plan.key == 'sales-pro' && this.localPlanId == 'freemium' && this.usedTrial !== 'true');
  }

  plan_details(plan: string) {
    if (!this.checked) {
      switch (plan) {
        case 'starter':
          plan = this.checked ? 'Starter' : "YearlyStarter"
          break;
        case 'growth':
          plan = this.checked ? 'Growth' : "YearlyGrowth"
          break;
        case 'sales-pro':
          plan = this.checked ? 'SalesPro' : "YearlySalesPro"
      }
    }
    this._router.navigate(["new-plan/" + plan]);
  }


  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  getPricing() {
    this.dataService
      .get({ url: "v1/subscriptions/plans", isLoader: false })
      .subscribe({
        next: (response: any) => {
          response.result.forEach(({ identifier, unit_amount }) => {
            this.pricing[identifier] = unit_amount / 100
          });
        },
        error: (error) => {
          console.log(error)
        }
      });
  }

  private stripeHandler() {
    this.dataService
      .get({ url: "v1/user", isLoader: false })
      .pipe(first())
      .subscribe((response: any) => {
        if (response && response.result) {
          this.stripeId = response.result.stripe_id
            ? response.result.stripe_id
            : "";
        }
      });

    // Stop generating intent for payment that won't be made here
    
    // this.stripeService
    //   .genPaymentIntent()
    //   .pipe(first())
    //   .subscribe((response: string) => {
    //     this.client_secret = response;
    //   });
  }

  public async confirmPaymentSetup() {
    if (this.client_secret !== "") {
      const { setupIntent, error } =
        await this.stripeService.stripe.confirmCardSetup(this.client_secret, {
          payment_method: {
            card: this.paymentElement,
            billing_details: { name: this.PaymentName.value },
          },
        });
      if (error) {
        // Display "error.message" to the user...
        console.error({ error });
      } else {
        // The card has been verified successfully...
        this.stripeService
          .setDefaultPaymentMethod(setupIntent.payment_method)
          .pipe(
            takeUntil(this._destroy$),
            concatMap((response) => {
              if (response) {
                return this.stripeService.getDefaultPaymentMethod();
              } else {
                console.error("Default payment type not set.");
              }
            })
          )
          .subscribe((paymentId) => {
            this.stripeService
              .subToPlan(paymentId, this.planType)
              .pipe(takeUntil(this._destroy$))
              .subscribe((response) => {
                console.log("subscription to plan response: ", { response });
              });
          });
      }
    } else {
      console.warn("please wait a few seconds then try again");
    }
  }
  setValue(e:any) {
    if (e.target.checked) {
      this.checked = e.target.checked;
    } else {
      this.checked = e.target.checked;
    }

    this.loadCtaScripts();
  }

  goToSalesPro() {
    window.location.href = this.checked
      ? CTA_SALES_PRO_MONTHLY_URL
      : CTA_SALES_PRO_YEARLY_URL;
  }

  loadCtaScripts() {
    console.log("Embedding CTA.....");
    const scriptElement = this.scriptService.loadJsScript(
      this.renderer,
      "https://js.hscta.net/cta/current.js"
    );

    scriptElement.onload = () => {
      console.log("CTA Script loaded");

      const config = { useNewLoader: "true", region: "na1" };

      if (this.checked) {
        hbspt.cta.load(11964, CTA_SALES_PRO_MONTHLY_ID, config);
      } else {
        hbspt.cta.load(11964, CTA_SALES_PRO_YEARLY_ID, config);
      }
    };

    scriptElement.onerror = () => {
      console.log("Could not load the CTA Script!");
    };
  }

  displayedPrice(plan: any, anual = false): string | number {
    var key = plan.key + (this.checked ? '' : '-yearly');
    var amount = this.pricing[key == 'sales-pro' ? 'sales-pro-monthly' : key];

    return amount ? Number(!this.checked ? amount / (anual ? 1 : 12) : amount).toFixed(2) : 0.00
  }

  disableButtons(disabled: boolean):void {
    this.buttons.forEach(button => {
      button.nativeElement.disabled = disabled;
      if(disabled === true){
        this.renderer.setStyle(button.nativeElement, 'pointer-events', 'none');
        this.renderer.setStyle(button.nativeElement, 'opacity', '0.65');
      } else {
        this.renderer.removeStyle(button.nativeElement, 'pointer-events');
        this.renderer.removeStyle(button.nativeElement, 'opacity');
      }
    });
  }

  selectPlan(plan, yearly) {
    this.disableButtons(true);

    let mp;
    switch (plan.key) {
      case "free":
      case "freemium":
        mp = ''
        break;
      case "starter":
        mp = yearly ? 'YearlyStarter' : 'Starter'
        break;
      case "growth":
        mp = yearly ? 'YearlyGrowth' : 'Growth'
        break;
      case "sales-pro":
        mp = yearly ? 'YearlySalesPro' : 'SalesPro'
        break;
    }
    this.plan_details(mp);
    this.disableButtons(false);
  }

  actualPlanDescription() {
    switch ((this.localPlanIdType ?? this.localPlanId).toLowerCase()) {
      case "free":
      case "freemium":
        return 'Freemium Plan'
      case "starter":
        return 'Starter'
      case "starter-yearly":
        return 'Starter - YR Plan'
      case "growth":
        return 'Growth'
      case "growth-yearly":
        return 'Growth - YR Plan'
      case "sales-pro-monthly":
        return 'Sales Pro';
      case "sales-pro-yearly":
        return 'Sales Pro - YR Plan';
    }
  }

  filteredPlans() {
    if (this.onboarding || this.onTrial) return this.plans;
    if ((this.localPlanIdType ?? this.localPlanId).toLowerCase().includes('yearly')) {
      return this.plans.filter((plan, index) => index >= this.planIndex);
    } else
      return this.plans.filter((plan, index) => index >= (this.checked ? this.planIndex + 1 : this.planIndex));
  }

  public plans =
    [
      {
        key: 'starter',
        name: 'Starter',
        description: 'Everything in Freemium, <br>Plus...',
        current: false,
        popular: false,
        button: 'Choose Starter',
        freeAIPrompts: '10',
        dots: [
          `<b>FlyMSG</b> <img src="../../../assets/images/ai.svg" width="13px"> : AI Writing for Email and Social Media`,
          'Up to <b>50</b> Templates FlyPlates',
          'Up to 300 Shortcodes FlyMSGs',
          '<b>25,000 Characters per FlyMSG</b>',
          'Up to <b>14</b> Categories & <b>28</b> Subcategories',
          'Spell Check for <b>All<b> Languages',
          '<b>All</b> Fonts in <b>All</b> Font Sizes'
        ],
        keys: [
          'Access to Technical Support',
          'Insert Images into FlyMSGs and FlyPlates',
          'All Text Styling: Italicize, Underline, Bullets, & More',
          'Advanced Search'
        ]
      },
      {
        key: 'growth',
        name: 'Growth',
        description: 'Everything In Starter, <br>Plus...',
        current: false,
        popular: true,
        button: 'Choose Growth',
        freeAIPrompts: '30',
        dots: [
          `<b>FlyMSG</b> <img src="../../../assets/images/ai.svg" width="13px"> : AI Writing for Email and Social Media`,
          '<b>Unlimited</b> <s>Templates</s> FlyPlates',
          '<b>Unlimited</b> <s>Shortcodes</s> FlyMSGs',
          '<b>Unlimited</b> Characters Per FlyCut',
          '<b>Unlimited</b> Categories & Subcategories',
          'Spell Checks for <b>All</b> Languages',
          '<b>All</b> Font Sizes & Styles'
        ],
        keys: [
          'Enhanced Text & Paragraph Styling',
          'Insert All Media into FlyMSGs and FlyPlates',
          'Greater File Storage and Retention',
          'Supports All Languages'
        ]
      },
      {
        key: 'sales-pro',
        name: 'Sales Pro',
        description: 'Everything in Growth, <br>Plus...',
        current: false,
        popular: false,
        button: 'Choose Sales Pro',
        freeAIPrompts: '70',
        dots: [
          `<b>FlyMSG</b> <img src="../../../assets/images/ai.svg" width="13px"> : AI Writing for Email and Social Media`,
          '<b>Unlimited</b> On-demand Access',
          '<b>15+</b> Hours of Sales Prospecting Education',
          '<b>50+</b> Prospecting Scripts',
          '<b>13</b> Messaging Playbooks',
          'Quarterly Sales Content Updates'
        ],
        keys: [
          'All On-demand Content',
          'Learn and Practice Your Way',
          'Tons of Freebies',
          '<a href="https://vengreso.com/modern-sales-mastery-individuals" target="_blank">Read About Sales Pro Here</a>'
        ]
      },

    ];
}
