import {
  ChangeDetectorRef,
  Component,
  OnDestroy
} from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";

import { SignOutMessageService } from "src/app/components/sign-out-message/sign-out-message.service";
import { getPlanId } from "src/app/core/core-services/constants/plans.constants";
import { AuthService } from "src/app/core/core-services/services/auth.service";
import { trackService } from "src/app/core/core-services/services/track.service";
import { SettingsComponent } from "src/app/vengreso/settings/settings.component";
import { NotifyService } from "../menu/not.service";
import { MenuService } from "../menu/menu.service";
import { NotificationService } from "../core-services/services/notification.service";
import { SharedDataService } from "../core-services/services/shared-data.service";

declare global {
  interface Window {
    heap: any;
  }
}

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  providers: [SettingsComponent],
})
export class HeaderComponent implements OnDestroy {
  userDetail: any;
  browser_type;
  show = false;
  notifierSubscription: Subscription =
    this.notifyService.subjectNotifier.subscribe((notified) => {
      this.userDetail = this.authService.userDetails.user_details;
      this.cdr.detectChanges();
    });
  planId: string;
  showAdminSection: boolean;
  showCMCSection: boolean;

  constructor(
    private readonly router: Router,
    public authService: AuthService,
    public trackService: trackService,
    public notifyService: NotifyService,
    private readonly cdr: ChangeDetectorRef,
    public settingsComponent: SettingsComponent,
    public signOutMessageService:SignOutMessageService,
    public menuService: MenuService,
    private readonly sharedData: SharedDataService,
    private readonly notification: NotificationService
  ) {
    this.initialisePlan();

    this.browser_type = this.sharedData.getAttribute("browser_type");
    if (
      this.browser_type == "firefox" ||
      this.browser_type == "opera" ||
      this.browser_type == "safari"
    ) {
      this.show = false;
    } else {
      this.show = true;
    }

    this.userDetail = this.authService.userDetails.user_details;
    this.showAdminSection = this.authService.showAdminSection();
    this.showCMCSection = this.authService.showVengresoAdminSection();
  }

  endMasqueradeSession(): void {
    this.authService.endMasqueradeSession();
    this.router.navigate(["/vengreso-admin/users"]);
  }

  get invitedToCompany(): boolean {
    return this.userDetail.invited_to_company && this.userDetail.invited_to_company_by_admin;
  }

  acceptInvitation(): void {
    let user_id = this.userDetail.id; 
    let company_id = this.userDetail.company_id; 
    this.authService.acceptCompanyInvitation(company_id, user_id).subscribe({
      next: () => {
        this.notification.toast("Invitation Accepted!", "Ok", 4000);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      },
      error: (error: any) => console.log(error)
    });
  }

  rejectInvitation(): void {
    let user_id = this.userDetail.id; 
    let company_id = this.userDetail.company_id; 
    this.authService.rejectCompanyInvitation(company_id, user_id).subscribe({
      next: () => {
        this.notification.toast("Invitation Rejected!", "Ok", 4000);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      },
      error: (error: any) => console.log(error)
    });
  }

  copyToClipboard(email: string): void {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(email).then(() => {
        this.notification.toast("Email copied to clipboard!", "Ok", 5000);
      });
    }
  }

  onMenuOpened(): void {
    this.initialisePlan();
  }

  initialisePlan() {
    this.planId = getPlanId();
    let planId = this.sharedData.getAttribute("planId");
    let planIdType = this.sharedData.getAttribute("planIdType");
    if (
      planId == "sales-pro-yearly" ||
      planId == "sales-pro-monthly" ||
      planIdType == "sales-pro-yearly" ||
      planIdType == "sales-pro-monthly"
    ) {
      this.planId = "Sales Pro";
    }
    if (this.planId == "Free") {
      this.planId = "Freemium";
    }
  }

  logOut(){
    this.signOutMessageService.show();
  }

  goToProfile() {
    this.router.navigate(["/settings"], {
      queryParams: { tab: "Profile" },
    });
  }

  goToSettings(): void {
    this.router.navigate(["/settings"], {
      queryParams: { tab: "subscription" },
    });
  }

  goToAdmin(): void {
    const url = this.menuService.getAll().find((menu) => menu.label == 'Administration').sublink_two;

    window.open(url, '_blank');
  }

  goToCMCAdmin(): void {
    const url = this.menuService.getAll().find((menu) => menu.label == 'Administration').sublink_three;

    window.open(url, '_blank');
  }

  navigateTo(site: any) {
    window.open(site, "_blank");
  }

  navigateToExtension() {
    let site =
      "https://chrome.google.com/webstore/detail/fly-msg-for-chrome/giidlnpcdhcldhfccdhkaicefhpokghc?hl=en&authuser=0";
    if (this.browser_type == "edge") {
      site =
        "https://microsoftedge.microsoft.com/addons/detail/flymsg-text-expansion-to/mmgiiedikndnbnjnhpgjdgoacimlhdkn";
    }
    window.open(site, "_blank");
  }

  ngOnDestroy(): void {
    this.notifierSubscription.unsubscribe();
  }
}
