import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from 'src/app/core/core-services/services/notification.service';
import { MatPaginator } from '@angular/material/paginator';
import { DialogService } from 'src/app/core/core-services/services/dialog.service';
import { NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';
import { MatTableDataSource } from '@angular/material/table';
import { SuperAdminApiService } from '../../services/super-admin-api.service';

@Component({
  selector: 'app-bulk-deactivate-companies',
  templateUrl: './bulk-deactivate-companies.component.html',
  styleUrls: ['./bulk-deactivate-companies.component.scss']
})
export class BulkDeactivateCompaniesComponent implements OnInit {
  companies: any;
  confirmationStep: boolean = false;

  dataSource: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ["select", "name", "contract_start_date", "contract_end_date", "account_status", "actions"];


  constructor(
    public dialogRef: MatDialogRef<BulkDeactivateCompaniesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly notification: NotificationService,
    private readonly apiService: SuperAdminApiService,
    public dialog: DialogService,
    config: NgbPopoverConfig
  ) {
    this.companies = data.data;
    config.triggers = "hover";
    config.container = "body";
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(
      this.data.data.map((company) => ({
        ...company,
        checked: company.slug !== "vengreso",
      }))
    );
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  deactivateCompanies(): void {
    if (!this.confirmationStep) {
      this.setConfirmation();
      return;
    }

    const apiData = this.dataSource.data
      .filter((company) => company.checked)
      .map((company) => company.id);

    this.apiService
      .deactivateCompany(apiData)
      .subscribe((response: any) => {
        if (response?.result?.success) {
          const message = "Companies deactivated successfully.";
          this.notification.toastWithConfig(message, null, {
            verticalPosition: "top",
            horizontalPosition: "center",
            panelClass: ["green-notification"],
          });
          this.dialogRef.close(apiData);
        } else {
          const message = response?.result?.message || "Failed to deactivate companies";
          this.notification.toastWithConfig(message, null, {
            verticalPosition: "top",
            horizontalPosition: "center",
            panelClass: ["red-notification"],
          });
        }
      });
  }

  removeInConfirmationView(element: any): void {
    this.dataSource.data = this.dataSource
      .connect()
      .value.filter((company: any) => company.id !== element.id);
    this.dataSource.paginator = this.paginator;
  }

  setConfirmation() {
    this.confirmationStep = true;
    this.dialogRef.componentInstance.dialogRef.updateSize("unset");
  }
  
  removeConfirmation() {
    this.confirmationStep = false;
    this.dialogRef.componentInstance.dialogRef.updateSize("750px");
  }

  close(data?: any): void {
    if (this.confirmationStep) {
      this.removeConfirmation();
      return;
    }

    this.dialogRef.close(data);
  }

  get hasSelected(): boolean {
    return this.dataSource.data.some((company) => company.checked);
  }
}
