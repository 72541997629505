import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AuthService } from "src/app/core/core-services/services/auth.service";
import { NotificationService } from "src/app/core/core-services/services/notification.service";
import { AdminApiService } from "../../services/admin-api.service";

import { Option } from "../../models/option.model";
import { MatPaginator } from "@angular/material/paginator";
import { Observable, catchError, of } from "rxjs";
import {
  Role,
  ROLES,
} from "src/app/core/core-services/constants/roles.constant";
import { NgbPopoverConfig } from "@ng-bootstrap/ng-bootstrap";
import { DialogService } from "src/app/core/core-services/services/dialog.service";
import { SuperAdminApiService } from "../../services/super-admin-api.service";
import { SelectNewGlobalAdminComponent } from "../../select-new-global-admin/select-new-global-admin.component";
import { CompaniesApiService } from "../../services/company/companies-api.service";
import {
  CompanyLicense,
  CompanyLicenseDetails,
} from "../../services/company/companies-dto";

interface GroupWithSubgroups extends Option {
  subgroups: Option[];
}

@Component({
  selector: "app-cmc-assign-role",
  templateUrl: "./assign-role.component.html",
  styleUrls: ["./assign-role.component.scss"],
})
export class AssignRoleComponent implements OnInit {
  action: string;
  user: any;
  users: any[];
  role: {
    checked: boolean;
    name: Role;
    tooltip: string;
  };
  assignRoleForm: FormGroup;
  confirmAssignRole: boolean = false;
  downgradeRole: boolean = false;
  initialFormValues: any;
  groups: GroupWithSubgroups[] = [];

  constructor(
    private readonly authService: AuthService,
    public dialogRef: MatDialogRef<AssignRoleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly fb: FormBuilder,
    private readonly notification: NotificationService,
    private readonly apiService: SuperAdminApiService,
    private readonly adminApiService: AdminApiService,
    private readonly config: NgbPopoverConfig,
    public dialog: DialogService
  ) {
    this.action = this.data.action;
    this.user = this.data.data;
    this.confirmAssignRole = this.data.confirmAssignRole;
    this.downgradeRole = this.data.downgradeRole;
    config.triggers = "hover";
    config.container = "body";

    if (this.downgradeRole) {
      this.apiService.getCompanyUsers(this.user.company_slug).subscribe((response: any) => {
        this.users = response?.result?.data?.users ?? [];
      });
    }
  }

  ngOnInit(): void {
    this.initialFormValues = this.user;

    this.role = this.data.role;

    if (this.role.name === "Group Admin" || this.role.name === "Reporting Admin") {
      this.assignRoleForm = this.fb.group({
        group:
          this.role.name === "Group Admin"
            ? [null, [Validators.required]]
            : [null],
      });

      this.apiService.getCompanyGroups(this.user.company_slug).subscribe((response: any) => {
        if (response?.result?.success) {
          this.groups = response.result.data.groups.map((group: any) => ({
            value: group.id,
            displayValue: group.name,
            subgroups: group.subgroups?.map((subgroup: any) => ({
              value: subgroup.id,
              displayValue: subgroup.name,
            })),
          }));

          let assignedGroupId = this.groups.find(
            (group: any) => group.displayValue === this.user.group
          )?.value;
          if (assignedGroupId) {
            this.assignRoleForm.get("group").setValue([assignedGroupId]);
          }

          if (
            this.user.groups_admin?.length &&
            this.user.role === this.role.name
          ) {
            this.assignRoleForm.get("group").setValue(this.user.groups_admin);
          }
        }
      });
    } else {
      this.assignRoleForm = this.fb.group({
        group: [null],
      });
      this.setConfirmAssignRole(true);
    }
  }

  get isVengresoAdmin(): boolean {
    return this.authService.getUserRole().includes("Vengreso Admin");
  }

  setConfirmAssignRole(value: boolean) {
    if (value) {
      this.confirmAssignRole = true;
      
      this.dialogRef.componentInstance.dialogRef.updateSize("unset");
    } else {
      if (["Global Admin", "User"].includes(this.role.name)) {
        this.dialogRef.componentInstance.dialogRef.updateSize("500px");
      } else {
        this.dialogRef.componentInstance.dialogRef.updateSize("620px");
      }

      this.confirmAssignRole = false;
    }
  }

  assignRole() {
    if (!this.confirmAssignRole) {
      this.setConfirmAssignRole(true);
      return;
    }
    
    let data = {
      role_name: this.role.name,
      groups: this.assignRoleForm.value.group,
    };

    let request: Observable<Response>;

    if (this.isVengresoAdmin && !this.authService.onCompanyMasqueradeSession) {
      request = this.apiService.assignRole(this.user.id, data);
    } else {
      request = this.adminApiService.assignRole(this.user.id, data);
    }

    request
      .pipe(
        catchError((error) => {
          if (error.status === 409) {
            let message = `Select a new admin to replace ${this.user?.first_name} ${this.user?.last_name} before a User role can be assigned.`;

            this.notification.toastWithConfig(message, null, {
              verticalPosition: "top",
              horizontalPosition: "center",
              panelClass: ["blue-notification"],
            });

            let dialogRef = this.dialog.openDialogComponent(
              SelectNewGlobalAdminComponent,
              {
                data: this.user,
                users: this.users,
                role: this.role,
                confirmAssignRole: this.confirmAssignRole,
                downgradeRole: this.downgradeRole,
                class: "portal-admin",
              },
              "500px"
            );

            dialogRef.afterClosed().subscribe((updatedUser: any) => {
              this.dialogRef.close(updatedUser);
            });

            return of({ error: true, message: message });
          } else {
            let message = "An error occurred while assigning the role";
            if (
              error.error &&
              error.error.result &&
              error.error.result.message
            ) {
              message = error.error.result.message;
            }
            // Show error notification
            this.notification.toastWithConfig(message, null, {
              verticalPosition: "top",
              horizontalPosition: "center",
              panelClass: ["red-notification"],
            });
            // Return an observable with a user-facing error message
            return of({ error: true, message: message });
          }
        })
      )
      .subscribe((response: any) => {
        if (response && response.result && response.result.success == true) {
          this.setConfirmAssignRole(false);
          this.dialogRef.close(response.result.user);
          this.notification.toastWithConfig(
            "New role assigned successfully",
            null,
            {
              verticalPosition: "top",
              horizontalPosition: "center",
              panelClass: ["green-notification"],
            }
          );
        }
      });
  }

  cancelAsignTo(): void {
    if (this.confirmAssignRole && this.role.name === "Group Admin") {
      this.setConfirmAssignRole(false);
      return;
    }
    this.close();
  }

  close(data?: any): void {
    this.dialogRef.close(data);
  }
}
