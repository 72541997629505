export function DynamicStorage(key: string) {
  return function (target: any, propertyKey: string) {
    const storageKey = `vengreso.${key || propertyKey}`;

    Object.defineProperty(target, propertyKey, {
      get() {
        const isMasquerading = !!sessionStorage.getItem("fms-cmc-masquerading-user-id");
        const storage = isMasquerading ? sessionStorage : localStorage;
        const value = storage.getItem(storageKey);
        return value ? JSON.parse(value) : null;
      },
      set(value: any) {
        const isMasquerading = !!sessionStorage.getItem("fms-cmc-masquerading-user-id");
        const storage = isMasquerading ? sessionStorage : localStorage;
        if (value === null || value === undefined) {
          storage.removeItem(storageKey);
        } else {
          storage.setItem(storageKey, JSON.stringify(value));
        }
      }
    });
  };
}
