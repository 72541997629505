import { Injectable } from "@angular/core";
import { DataService } from "src/app/core/core-services/services/dataservices/data.service";
import { AuthService } from "src/app/core/core-services/services/auth.service";
import { AddUsersByEmailRequest, CompanyLicense, CompanyLicenseDetails, EditCompanyRequest } from "./companies-dto";
import { Observable } from "rxjs";
import { CompanyUsersList } from "../users/users-dto";

export interface Result<T = any> {
  result: T;
}

@Injectable({
  providedIn: "root", // This makes the service available application-wide
})
export class CompaniesApiService {
  constructor(
    private readonly dataService: DataService,
    public authService: AuthService
  ) {}

  private get baseUrl() {
    return `v2/admin/company`;
  }

  private get companySlug() {
    return this.authService.getUserCompanySlug();
  }

  update(data: EditCompanyRequest) {
    return this.dataService.put({
      url: `${this.baseUrl}/${data.id}`,
      data: {
        company: data
      },
    });
  }

  getCompanyBySlug(slug: string) {
    return this.dataService.get({
      url: `${this.baseUrl}/${slug}`,
    });
  }
  
  getCompaniesLicenses(): Observable<Result<{
    data: CompanyLicenseDetails[],
    success: boolean
  }>> {
    return this.dataService.get({
      url: `${this.baseUrl}/licenses`,
    });
  }
  
  getCompanyLicenses(): Observable<Result<{
    licenses: CompanyLicense[]
  }>> {
    const url = `${this.baseUrl}/${this.companySlug}/licenses`;
    return this.dataService.get({
      url,
    });
  }

  addUserByEmail(data: AddUsersByEmailRequest): Observable<Result<{
    success: boolean;
    message: string;
    users: CompanyUsersList[];
  }>> {
    const url = `${this.baseUrl}/${this.companySlug}/users/add-by-email`;

    return this.dataService.post({
      url,
      data,
    });
  }
  
  deleteCompany(companyId: string) {
    return this.dataService.delete({
      url: `${this.baseUrl}/${companyId}`,
    });
  }
  
  deleteCompanies(companyIds: string[]) {
    return this.dataService.post({
      url: `${this.baseUrl}/delete`,
      data: {
        companies: companyIds
      }
    });
  }
}
