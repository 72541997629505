import { Injectable } from "@angular/core";
import { IAnalyticsService } from "./analytics.service";

interface Heap {
  track: (event: string, properties?: Object) => void;

  identify: (identity: string) => void;

  resetIdentity: () => void;

  addUserProperties: (properties: Object) => void;

  addEventProperties: (properties: Object) => void;

  removeEventProperty: (property: string) => void;

  clearEventProperties: () => void;

  appid: string;

  userId: string;

  identity: string | null;
}

declare const heap: Heap;

@Injectable({
  providedIn: "root",
})
export class HeapService implements IAnalyticsService {
  constructor() {}

  async track(eventName: string, eventProps: any) {
    try {
      heap.track(eventName, eventProps);
    } catch (e) {
      console.error('Error tracking event', e);
    }
  }

  async identify(identity) {
    try {
      heap.identify(identity);
    } catch (e) {
      console.error('Error tracking event', e);
    }
  }
  
  async addUserProperties(properties: {
    [key: string]: any;
  }) {
    try {
      heap.addUserProperties(properties);
    } catch (e) {
      console.error('Error tracking event', e);
    }
  }

  async identity() {
    return heap?.identity ?? '';
  }

  async userId() {
    return heap?.userId ?? '';
  }
}
