import { Component, Inject, OnInit } from "@angular/core";
import { NgbPopoverConfig } from "@ng-bootstrap/ng-bootstrap";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DialogService } from "src/app/core/core-services/services/dialog.service";
import { Router } from "@angular/router";
import { AuthService } from "src/app/core/core-services/services/auth.service";
import { TourService } from "ngx-ui-tour-md-menu";
import { GlobalHookService } from "src/app/global-hook.service";

const placeholders = {
  textArea:
    "Test your FlyCut here by typing it in now. Remember, if your message has any rich text elements like bold, hyper-linked words, images, etc., it will not display in simple text format.",
  testingRichEditor: "Test your FlyCut here by typing it in now.",
};

@Component({
  selector: "test-flycut",
  templateUrl: "./test-flycut.component.html",
  styleUrls: ["./test-flycut.component.scss"],
})
export class TestFlycutComponent implements OnInit {
  shortcut: any
  plc = placeholders;
  testElement: string = 'text';
  placeholder = true;
  testingRichEditor: string = '';
  textArea: string = placeholders.textArea;

  constructor(
    config: NgbPopoverConfig,
    private readonly authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TestFlycutComponent>,
    public dialog: DialogService,
    private readonly router: Router,
    private readonly tourService: TourService,
    private readonly globalHookService: GlobalHookService,
  ) {
    this.tourService.initialize([
      {
        anchorId: 'step8',
        content: 'Simple Text editors like LinkedIn, Slack or WhatsApp online do not allow you to embed multimedia (images, GIFs, etc) and hyperlinks.',
        title: 'Simple Text Preview',
        enableBackdrop: true,
        disablePageScrolling: true,
        backdropConfig: {
          zIndex: '1100',
        },
        placement: {
          xPosition: 'after',
          yPosition: 'above'
        }
      },
      {
        anchorId: 'step9',
        content: 'Rich Text platforms like Gmail or Outlook allow you to embed multimedia (images, GIFs, etc) and hyperlinks.',
        title: 'Rich Text Preview',
        enableBackdrop: true,
        backdropConfig: {
          zIndex: '1100',
        },
        placement: {
          xPosition: 'after',
          yPosition: 'above'
        }
      },
      {
        anchorId: 'step10',
        content: 'Click the X button to complete testing your FlyCut.',
        title: 'Congrats!',
        enableBackdrop: true,
        backdropConfig: {
          zIndex: '1100',
        },
      }
    ]);

    this.tourService.stepShow$.subscribe((step) => {
      const popover = document.querySelector('.mat-menu-panel.tour-step')?.parentElement;

      if (popover) {
        popover.classList.remove('step8-onboarding-popover-arrow');
        popover.classList.remove('tour-last-step-position');
      }
      
      // const popoverOverlay = document.querySelector('.cdk-overlay-connected-position-bounding-box') as HTMLElement;
      // popoverOverlay.style.zIndex = '1101';
      const overlays = document.querySelectorAll('.ngx-ui-tour_backdrop');
      const muiOverlay = document.querySelector('.cdk-overlay-container');
      //move overlays to the end of muiOverlay
      overlays.forEach((overlay: HTMLElement) => {
        overlay.style.pointerEvents= 'all';
        muiOverlay.appendChild(overlay);
      });

      setTimeout(() => {
        const popover = document.querySelector('.mat-menu-panel.tour-step')?.parentElement;

        if (popover) {

          if (step.anchorId === 'step8' || step.anchorId === 'step9') {
            popover.classList.add('step8-onboarding-popover-arrow');
          }
          if (step.anchorId === 'step10') {
            popover.classList.add('tour-last-step-position');
          }
          
          setTimeout(() => {
            
            function setFmsHeapAttribute(step: string) {
              const popover = document.querySelector('.mat-menu-panel.tour-step')?.parentElement;

              if (!popover) {
                return;
              }
              
              const prevButton = popover.querySelector('.mat-button-base.prev');
              const nextButton = popover.querySelector('.mat-button-base.next');

              if (prevButton) {
                prevButton.setAttribute('data-fms-heap', `walk-me-modal-try-it-out-step-${step}-prev`);
              }
    
              if (nextButton) {
                nextButton.setAttribute('data-fms-heap', `walk-me-modal-try-it-out-step-${step}-next`);
              }
            }
            
            switch(step.anchorId) {
              case 'step8':
                setFmsHeapAttribute('8');
                break;
              case 'step9':
                setFmsHeapAttribute('9');
                break;
              case 'step10':
                setFmsHeapAttribute('10');
                break;
            }
          }, 200);
        }
      }, 200);

      if (step.anchorId === 'step10') {
        document.body.classList.add('tour-last-step');
      } else {
        document.body.classList.remove('tour-last-step');
      }
    });

    this.tourService.end$.subscribe(() => {
      document.body.classList.remove('tour-last-step');
    });

    if (this.isOnboarding) {
      setTimeout(() => {
        this.tourService.start();
      }, 500);
    }

    config.triggers = "hover";
    config.container = "body";
    this.shortcut = data.shortcut
  }

  get isOnboarding() {
    return this.globalHookService.onboardingActive;
  }

  ngOnInit(): void {
    this.showPlaceholder();
  }

  close(): void {
    this.dialogRef.close();
    
    if (this.globalHookService.onboardingActive) {
      this.globalHookService.components.global.startModal(3);
    }
  }

  hidePlaceholder() {
    this.placeholder = false;
  }
  
  showPlaceholder() {
    this.placeholder = this.testingRichEditor === '' || this.testingRichEditor == placeholders.testingRichEditor;
  }

  onChangeEditor(editor: 'text' | 'edit') {
    this.testElement = editor;
    this.showPlaceholder();
    this.textArea = placeholders.textArea;
    this.testingRichEditor = placeholders.testingRichEditor;
  }
  
  checkPlaceHolder(input: string) {
    if (this[input]) {
      this[input] = null;
    } else {
      this[input] = placeholders[input];
    }
  }

  onEditorInput(event: any) {
    this.testingRichEditor = event.target.innerHTML;
  }
}
