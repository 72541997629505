import { Component, Inject, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { NotificationService } from "src/app/core/core-services/services/notification.service";
import { AdminApiService } from "../services/admin-api.service";

import { Option } from "../models/option.model";
import { Observable } from "rxjs";
import { NgbPopoverConfig } from "@ng-bootstrap/ng-bootstrap";
import { DialogService } from "src/app/core/core-services/services/dialog.service";

@Component({
  selector: "app-select-new-global-admin",
  templateUrl: "./select-new-global-admin.component.html",
  styleUrls: ["./select-new-global-admin.component.scss"],
})
export class SelectNewGlobalAdminComponent implements OnInit {
  user: any;
  unassignUser: any;
  role: any;
  assignRoleForm: FormGroup;
  confirmAssignRole: boolean = false;
  users: Option[] = [];

  constructor(
    public dialogRef: MatDialogRef<SelectNewGlobalAdminComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private notification: NotificationService,
    private adminApiService: AdminApiService,
    public dialog: DialogService,
    config: NgbPopoverConfig
  ) {
    this.unassignUser = this.data.data;
    config.triggers = "hover";
    config.container = "body";
  }

  ngOnInit(): void {
    this.role = this.data.role;
    this.users = this.data.users
      .map((user: any) => ({
      value: user.id,
      displayValue: user.first_name ? `${user.first_name} ${user.last_name}` : user.email,
      }))
      .sort((a: Option, b: Option) => a.displayValue.localeCompare(b.displayValue));

    this.assignRoleForm = this.fb.group({
      user: [null, [Validators.required]],
    });
  }

  setConfirmAssignRole(value: boolean) {
    if (value) {
      this.confirmAssignRole = true;
      this.dialogRef.componentInstance.dialogRef.addPanelClass(
        "portal-admin-confirmation"
      );
    } else {
      this.dialogRef.componentInstance.dialogRef.removePanelClass(
        "portal-admin-confirmation"
      );
      this.confirmAssignRole = false;
    }
  }

  onUserChange(event: any) {
    this.user = this.users.find((user) => user.value === event.value);
  }

  assignRole() {
    if (!this.confirmAssignRole) {
      this.setConfirmAssignRole(true);
      return;
    }

    let data = {
      role_name: 'Global Admin',
    };

    let requestNewAdmin: Observable<Response>;

    requestNewAdmin = this.adminApiService.assignRole(this.user.value, data);

    requestNewAdmin.subscribe((responseNewAdmin: any) => {
      if (responseNewAdmin?.result?.success) {
        let requestNewUser: Observable<Response>;
        requestNewUser = this.adminApiService.assignRole(this.unassignUser.id, {
          role_name: this.role.name,
        });

        requestNewUser.subscribe((responseNewUser: any) => {
          if (responseNewUser?.result?.success) {
            this.setConfirmAssignRole(false);
            this.dialogRef.close([responseNewUser.result.user, responseNewAdmin.result.user]);
            this.notification.toastWithConfig(
              "User role assigned successfully",
              null,
              {
                verticalPosition: "top",
                horizontalPosition: "center",
                panelClass: ["green-notification"],
              }
            );
          }
        });
      }
    });
  }

  cancelAsignTo(): void {
    if (this.confirmAssignRole) {
      this.setConfirmAssignRole(false);
      return;
    }
    this.close();
  }

  close(data?: any): void {
    this.dialogRef.close(data);
  }
}
