/// <reference types="chrome"/>
import { EventEmitter, Injectable, Output } from "@angular/core";
import { environment } from "src/environments/environment";
import { Browser, detect } from "detect-browser";
import { SharedDataService } from "./shared-data.service";
import { MasqueradeUserResponse } from "src/app/admin/services/users/users-dto";

@Injectable()
export class ExtensionService {
  @Output() stopBanner = new EventEmitter<any>();

  isChromeBrowser = window.chrome && window.navigator.vendor === "Google Inc.";
  isChromeRuntime = this.isChromeBrowser && chrome.runtime;
  browser_type: Browser;

  constructor(
    private readonly sharedData: SharedDataService,
  ) {
    this.browser_type = this.sharedData.getAttribute("browser_type") as Browser;
    // Store browser type
    var browser = detect();
    if (
      browser &&
      (browser?.name == "edge" || browser?.name == "edge-chromium")
    ) {
      this.browser_type = "edge";
    } else {
      this.browser_type = browser?.name as Browser;
    }

    if (this.browser_type) {
      this.sharedData.setAttribute("browser_type", this.browser_type);
    }
  }

  private checkByImage(): Promise<boolean> {
    return new Promise((resolve) => {
      const img = new Image();
      const crx = this.getBrowserExtensionId(this.browser_type);
      img.src = `chrome-extension://${crx}/images/fm.png`;
      console.log('checkByImage', this.browser_type, crx, img.src)

      img.onload = () => {
        resolve(true);
      };

      img.onerror = () => {
        resolve(false);
      };
    });
  }

  private async checkByMessage(): Promise<boolean> {
    const timeout = new Promise<boolean>((resolve) =>
      setTimeout(() => resolve(false), 1000)
    );

    try {
      return await Promise.race([this.sendExtensionMessage<boolean>({ request: "exists" }), timeout]);
    } catch {
      return false;
    }
  }

  async isExtensionInstalled(): Promise<boolean> {
    let exists = false;
    await Promise.all([
      this.checkByImage().then((result) => {
        if (result && !exists) {
          exists = result;
        }
      }),
      this.checkByMessage().then((result) => {
        if (result && !exists) {
          exists = result;
        }
      }),
    ]);

    return exists;
  }

  async isExtensionSignedIn(): Promise<boolean> {
    const response = await this.sendExtensionMessage<{
      hasAccessToken: boolean
    }>({
      request: "hasAccessToken",
    });

    try {
      if (!response.hasAccessToken) {
        const url = "v1/user/auth/login/extension_sync";
        let userDetails = this.userDetails;

        let data = {
          accessToken: this.accessToken,
          refreshToken: this.refreshToken,
          hubspot_id: userDetails.user_details.hubspot_id,
          email: userDetails.user_details.email,
          userDetails,
        };
        return await this.sendLoginMessage(url, data);
      }
    } catch {
      return false;
    }
  }

  getBrowserExtensionId(browser: Browser) {
    switch (browser) {
      case "edge":
      case "edge-chromium":
        return environment.EDGE_EXTENSION_ID;
      case "opera":
        return environment.OPERA_EXTENSION_ID;
      case "chrome":
        return environment.EXTENSION_ID;
      default:
        throw new Error("Browser not supported for FlyMSG extension");
    }
  }

  sendLogoutMessage() {
    this.sendExtensionMessage({
      request: "logout",
    }).then(() => {
      this.sharedData.setAttribute("is_extention_avialble", "no");
    });
  }

  async updateUserDetails(userDetails) {
    const response = await this.sendExtensionMessage<boolean>({
      request: "update_user",
      data: userDetails,
    });

    return response;
  }

  async sendLoginMessage(url: string, data: any) {
    this.sharedData.setAttribute("is_extention_avialble", "yes");
    try {
      const response = await this.sendExtensionMessage({
        url,
        data,
        request: "login",
      });
      if (response) {
        this.sharedData.setAttribute("is_extention_avialble", "yes");
        return true;
      } else {
        this.sharedData.setAttribute("is_extention_avialble", "no");
        return false;
      }
    } catch {
      this.sharedData.setAttribute("is_extention_avialble", "no");
      return false;
    }
  }

  sendUpdateMessage() {
    this.sendExtensionMessage({
      request: "update",
    });
  }

  sendMasqueradeUser(userId: string) {
    return this.sendExtensionMessage({
      request: "masqueradeUser",
      user_id: userId,
    });
  }

  sendLoginEvent() {
    const url = "v1/user/auth/login/extension_sync";
    let userDetails = this.userDetails;

    let data = {
      accessToken: this.accessToken,
      refreshToken: this.refreshToken,
      hubspot_id: userDetails.user_details.hubspot_id,
      email: userDetails.user_details.email,
      userDetails,
    };
    this.sendLoginMessage(url, data);
  }

  sendLogoutEvent() {
    this.sendLogoutMessage();
  }

  async getVersion() {
    const response = await this.sendExtensionMessage<{ version: number }>({
      request: "version",
    });

    return response.version;
  }

  private sendExtensionMessage<T extends any>(data: any): Promise<T> {
    let retryAttempts: number = 10;
    return new Promise((resolve, reject) => {
      const crx = this.getBrowserExtensionId(this.browser_type);
      
      function sendMessageToExtension() {
        if (retryAttempts === 0) {
          reject("Failed to send message to extension");
          return;
        }

        if (
          typeof chrome !== "undefined" &&
          chrome.runtime &&
          chrome.runtime.sendMessage
        ) {
          chrome.runtime.sendMessage(crx, data, (response) => {
            if (chrome.runtime.lastError) {
              reject(chrome.runtime.lastError);
            } else {
              if (data.request === 'exists') {
                resolve(Boolean(response) as T);
              } else {
                resolve(response);
              }
            }
          });
        } else {
          console.warn("chrome.runtime API not available yet. Retrying...");
          retryAttempts--;
          setTimeout(sendMessageToExtension, 1000);
        }
      }

      sendMessageToExtension();
    });
  }
  
  get masqueradeUser(): MasqueradeUserResponse {
    return sessionStorage.getItem("fms-cmc-masquerading-user-details")
      ? (JSON.parse(
          sessionStorage.getItem("fms-cmc-masquerading-user-details")
        ) as unknown as MasqueradeUserResponse)
      : null;
  }

  get accessToken(): string {
    if (this.masqueradeUser) {
      return this.masqueradeUser.access_token;
    }

    return this.sharedData.getAttribute("accessToken");
  }

  get refreshToken(): string | null {
    if (this.masqueradeUser) {
      return null;
    }

    return this.sharedData.getAttribute("refreshToken");
  }

  get userDetails() {
    if (this.masqueradeUser) {
      return this.masqueradeUser;
    }

    return this.sharedData.getAttribute("userDetails");
  }

}
