import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { MatDialogRef } from "@angular/material/dialog";
import { NotificationService } from "src/app/core/core-services/services/notification.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { lastValueFrom } from "rxjs";
import { Router } from "@angular/router";
import { AdminPortalApiService } from "../../services/admin-portal-api.service";
import { SuperAdminApiService } from "../../services/super-admin-api.service";

@Component({
  selector: "app-vengreso-add-user-manually",
  templateUrl: "./add-user-manually.component.html",
  styleUrls: ["./add-user-manually.component.scss"],
})
export class VengresoAddUserManuallyComponent implements OnInit {
  form: FormGroup;
  invalidEmail: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<VengresoAddUserManuallyComponent>,
    private readonly superAdminApiService: SuperAdminApiService,
    private readonly adminPortalApiService: AdminPortalApiService,
    private readonly notification: NotificationService,
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly location: Location
  ) {}

  ngOnInit(): void {
    let currentUrl = this.router.url;
    if (!currentUrl.includes("add-manually")) {
      this.location.replaceState(currentUrl + "/add-manually");
    }

    this.form = this.fb.group({
      first_name: ["", [Validators.required]],
      last_name: ["", [Validators.required]],
      email: ["", [Validators.required, Validators.email]],
    });
  }

  async onAdd() {
    const data = this.form.value;

    const response: any = await lastValueFrom(
      this.adminPortalApiService.validateEmailUniqueness({
        emails: [data.email],
      })
    );

    if (response?.result?.exists === true) {
      this.invalidEmail = true;
      this.form.controls["email"].setErrors({ invalid: true });
      return;
    } else {
      this.invalidEmail = false;
      this.form.controls["email"].setErrors(null);
    }

    this.superAdminApiService.addUserManually({
      email: data.email,
      firstName: data.first_name,
      lastName: data.last_name,
    }).subscribe({
      next: (response: any) => {
        if (response?.result?.success) {
          this.notification.toastWithConfig("User added", null, {
            verticalPosition: "top",
            horizontalPosition: "center",
            panelClass: ["green-notification"],
          });

          this.close(response.result.user);
        }
      },
      error: (error) => console.log(error),
    });
  }

  ngOnDestroy(): void {
    this.location.replaceState(this.router.url.replace("/add-manually", ""));
  }

  close(data?: any) {
    this.location.replaceState(this.router.url.replace("/add-manually", ""));
    this.dialogRef.close(data);
  }
}
