import { Component, OnInit } from '@angular/core';
import { GlobalHookService } from '../global-hook.service';
import { AuthService } from '../core/core-services/services/auth.service';
import { DialogService } from '../core/core-services/services/dialog.service';
import { WelcomeModalComponent } from '../dashboard/walk-me/welcome-modal/welcome-modal.component';
import { SharedDataService } from '../core/core-services/services/shared-data.service';
import { MatDialogRef } from '@angular/material/dialog';
import { Location } from "@angular/common";
import { Router } from '@angular/router';

@Component({
  selector: 'app-global-overlay',
  templateUrl: './global-overlay.component.html',
  styleUrls: ['./global-overlay.component.scss']
})
export class GlobalOverlayComponent implements OnInit {
  constructor(
    private readonly globalHookService: GlobalHookService,
    private readonly authService: AuthService,
    private readonly dialog: DialogService,
    public sharedData: SharedDataService,
    private readonly location: Location,
    private readonly router: Router,
  ) {
  }

  ngOnInit(): void {
    this.globalHookService.registerComponent('global', this);

    this.globalHookService.functionCalled$.subscribe(({ functionName, args }) => {
      if (this[functionName]) {
        this[functionName](...args);
      }
    });

    const currentUrl = this.location.path();
    if (currentUrl.includes('session') || currentUrl.includes('onboarding')) {
      return;
    }

    if (!this.user.onboardingv2_step || this.user.onboardingv2_step < 1) {
      setTimeout(() => {
        if (!this.globalHookService.onboardingActive) {
          this.globalHookService.components.global.startModal();
        }
    
      }, 2000);
    }
  }

  get user() {
    return this.authService.userDetails?.user_details
  }

  get maxStep() {
    return this.showFlyLearning ? 7 : 6;
  }

  get showFlyLearning() {
    let planIdType = this.sharedData.getAttribute("planIdType") ?? this.sharedData.getAttribute("planId");

    return !!planIdType && ["sales-pro-yearly", "sales-pro-monthly", "pro-plan-teams-smb", "pro-plan-teams-ent"].includes(planIdType);
  }

  startModal(step: number = undefined): void {
    if (step === undefined) {
      step = Math.min(this.authService.userDetails.user_details.onboardingv2_step ?? -1, this.maxStep);
    }

    this.globalHookService.onboardingActive = true;
    const modal = this.dialog.openDialogComponent(
      WelcomeModalComponent,
      { step, class: 'fms-walk-me-modal-container'},
      "400px",
      true
    );

    this.afterModalIsClosed(modal);
  }

  afterModalIsClosed<T>(modal: MatDialogRef<T>) {
    modal.afterClosed().subscribe({
      next: (data) => {
        if(data?.action === 'retake_onboarding'){
          return
        }
        const currentUrl = this.router.url;

        if(currentUrl === '/flyboard?showonboardingprogresstraining=true'){
          this.location.replaceState('flyboard');
        }
      },
    });
  }
}