import {
  AfterViewInit,
  ComponentFactoryResolver,
  Directive,
  ElementRef,
  EventEmitter,
  Host,
  Input,
  OnChanges,
  Optional,
  Output,
  Renderer2,
  Self,
  SimpleChanges,
  ViewContainerRef,
} from "@angular/core";
import { MatIcon } from "@angular/material/icon";
// import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatPaginator } from "@angular/material/paginator";
import { map, startWith, distinctUntilChanged, Subscription } from "rxjs";

@Directive({
  selector: "[appAccountCenterPaginator]",
})
export class AccountCenterPaginatorDirective
  implements AfterViewInit, OnChanges
{
  @Output() pageIndexChangeEmitter: EventEmitter<number> =
    new EventEmitter<number>();
  @Input() showFirstButton = true;
  @Input() showLastButton = true;
  @Input() renderButtonsNumber = 2;
  @Input() appCustomLength: number = 0;
  @Input() hideDefaultArrows = false;

  private dotsEndRef!: HTMLElement;
  private dotsStartRef!: HTMLElement;
  private bubbleContainerRef!: HTMLElement;

  private buttonsRef: HTMLElement[] = [];
  private pageSizeChangeSubscription!: Subscription;

  constructor(
    @Host() @Self() @Optional() private readonly matPag: MatPaginator,
    private elementRef: ElementRef,
    private ren: Renderer2
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes?.["appCustomLength"]?.firstChange) {
      this.appCustomLength = changes?.["appCustomLength"].currentValue;
      this.removeButtons();
      this.switchPage(0);
      this.renderButtons();
      this.showNumbersRange();
    }
  }

  showNumbersRange() {
    const nativeElement = this.elementRef.nativeElement;
    const howManyDisplayedEl = nativeElement.querySelector(
      ".mat-paginator-range-label"
    );
    const end =
      this.matPag.pageIndex * this.matPag.pageSize + this.matPag.pageSize;
    const label = `${
      this.matPag.pageIndex * this.matPag.pageSize + 1
    } – ${Math.min(end, this.appCustomLength)} of ${this.appCustomLength}`;
    howManyDisplayedEl.innerHTML = label;
  }

  ngAfterViewInit(): void {
    this.styleDefaultPagination();
    this.createBubbleDivRef();
    this.buildButtons();

    this.matPag.page
      .pipe(
        map((e) => [e.previousPageIndex ?? 0, e.pageIndex]),
        startWith([0, 0])
      )
      .subscribe(([prev, curr]) => {
        this.changeActiveButtonStyles(prev, curr);
        this.showNumbersRange();
      });

    this.pageSizeChangeSubscription = this.matPag.page
      .pipe(
        map(() => this.matPag.pageSize),
        distinctUntilChanged()
      )
      .subscribe(() => {
        setTimeout(() => {
          this.removeButtons();
          this.switchPage(0);
          this.renderButtons();
          this.showNumbersRange();
        }, 100);
      });
  }

  private renderButtons(): void {
    this.buildButtons();

    this.matPag.page
      .pipe(
        map((e) => [e.previousPageIndex ?? 0, e.pageIndex]),
        startWith([0, 0])
      )
      .subscribe(([prev, curr]) => {
        this.changeActiveButtonStyles(prev, curr);
      });
  }

  private removeButtons(): void {
    this.buttonsRef.forEach((button) => {
      this.ren.removeChild(this.bubbleContainerRef, button);
    });

    this.buttonsRef.length = 0;
  }

  private changeActiveButtonStyles(previousIndex: number, newIndex: number) {
    const currentActive = this.buttonsRef[newIndex];

    this.elementRef.nativeElement
      .querySelectorAll(".g-bubble__active")
      .forEach((element) => {
        this.ren.removeClass(element, "g-bubble__active");
      });

    if (currentActive) {
      // add active style to new active button
      this.ren.addClass(currentActive, "g-bubble__active");
    }

    // hide all buttons
    this.buttonsRef.forEach((button) =>
      this.ren.setStyle(button, "display", "none")
    );

    // show 1 previous button and 1 next button
    const renderElements = 1;
    const endDots = newIndex < this.buttonsRef.length - renderElements - 3;
    const startDots = newIndex - renderElements - 2 > 0;

    const firstButton = this.buttonsRef[0];
    const lastButton = this.buttonsRef[this.buttonsRef.length - 1];

    // last bubble and dots
    this.dotsEndRef &&
      this.ren.setStyle(this.dotsEndRef, "display", endDots ? "block" : "none");
    lastButton &&
      this.ren.setStyle(lastButton, "display", endDots ? "flex" : "none");

    // first bubble and dots
    this.dotsStartRef &&
      this.ren.setStyle(
        this.dotsStartRef,
        "display",
        startDots ? "block" : "none"
      );
    firstButton &&
      this.ren.setStyle(firstButton, "display", startDots ? "flex" : "none");

    // resolve starting and ending index to show buttons
    const startingIndex = startDots
      ? endDots
        ? newIndex - renderElements
        : this.buttonsRef.length - 5
      : 0;
    const endingIndex = endDots
      ? startDots
        ? newIndex + renderElements
        : 4
      : this.buttonsRef.length - 1;

    // display starting buttons
    for (let i = startingIndex; i <= endingIndex; i++) {
      const button = this.buttonsRef[i];
      this.ren.setStyle(button, "display", "flex");
    }
  }

  private buildButtons(): void {
    const neededButtons = Math.ceil(
      this.appCustomLength / this.matPag.pageSize
    );

    // create first button
    this.buttonsRef = [this.createButton(0)];

    // add dots (....) to UI
    this.dotsStartRef = this.createDotsElement();

    // create all buttons needed for navigation (except the first & last one)
    for (let index = 1; index < neededButtons - 1; index++) {
      this.buttonsRef = [...this.buttonsRef, this.createButton(index)];
    }

    // add dots (....) to UI
    this.dotsEndRef = this.createDotsElement();

    // create last button to UI after the dots (....)
    if (neededButtons > 1) {
      this.buttonsRef = [
        ...this.buttonsRef,
        this.createButton(neededButtons - 1),
      ];
    }
  }

  private createDotsElement(): HTMLElement {
    const dotsEl = this.ren.createElement("span");
    const dotsText = this.ren.createText("...");

    // add class
    this.ren.setStyle(dotsEl, "font-size", "18px");
    this.ren.setStyle(dotsEl, "margin-right", "2px");
    this.ren.setStyle(dotsEl, "padding-top", "6px");
    this.ren.setStyle(dotsEl, "color", "#5F177F");
    this.ren.setStyle(dotsEl, "font-weight", "bold");
    this.ren.setStyle(dotsEl, "text-align", "center");
    this.ren.setStyle(dotsEl, "width", "30px");

    // append text to element
    this.ren.appendChild(dotsEl, dotsText);

    // render dots to UI
    this.ren.appendChild(this.bubbleContainerRef, dotsEl);

    // set style none by default
    this.ren.setStyle(dotsEl, "display", "none");

    return dotsEl;
  }

  private createButton(i: number): HTMLElement {
    const bubbleButton = this.ren.createElement("div");
    const text = this.ren.createText(String(i + 1));

    this.ren.addClass(bubbleButton, "g-bubble");
    this.ren.setStyle(bubbleButton, "margin-right", "2px");
    this.ren.appendChild(bubbleButton, text);

    this.ren.listen(bubbleButton, "click", () => {
      this.switchPage(i);
    });

    this.ren.appendChild(this.bubbleContainerRef, bubbleButton);

    this.ren.setStyle(bubbleButton, "display", "none");

    return bubbleButton;
  }

  private switchPage(i: number): void {
    const previousPageIndex = this.matPag.pageIndex;
    this.matPag.pageIndex = i;
    this.matPag["_emitPageEvent"](previousPageIndex);
  }

  private createBubbleDivRef(): void {
    const actionContainer = this.elementRef.nativeElement.querySelector(
      "div.mat-paginator-range-actions"
    );
    const nextButtonDefault = this.elementRef.nativeElement.querySelector(
      "button.mat-paginator-navigation-next"
    );

    this.bubbleContainerRef = this.ren.createElement("div") as HTMLElement;
    this.ren.addClass(this.bubbleContainerRef, "g-bubble-container");

    this.ren.insertBefore(
      actionContainer,
      this.bubbleContainerRef,
      nextButtonDefault
    );
  }

  private styleDefaultPagination() {
    const nativeElement = this.elementRef.nativeElement;

    const paginatorSelect = nativeElement.querySelector(
      ".mat-paginator .mat-form-field-appearance-legacy .mat-form-field-wrapper"
    );
    this.ren.setStyle(
      paginatorSelect,
      "border",
      "1px solid rgba(0, 0, 0, 0.34)"
    );
    this.ren.setStyle(paginatorSelect, "border-radius", "4px");
    this.ren.setStyle(paginatorSelect, "height", "35px");

    const container = nativeElement.querySelector(".mat-paginator-container");
    const itemsPerPage = nativeElement.querySelector(
      ".mat-paginator-page-size"
    );
    const howManyDisplayedEl = nativeElement.querySelector(
      ".mat-paginator-range-label"
    );
    const spanHowManyDisplayedEl = this.ren.createElement("div");
    this.ren.setStyle(spanHowManyDisplayedEl, "display", "flex");
    this.ren.appendChild(spanHowManyDisplayedEl, howManyDisplayedEl);
    this.ren.appendChild(spanHowManyDisplayedEl, this.ren.createText(" items"));
    this.ren.appendChild(container, itemsPerPage);
    this.ren.appendChild(container, spanHowManyDisplayedEl);
    this.ren.setStyle(container, "justify-content", "start");
    this.ren.setStyle(container, "align-items", "center");
    this.ren.setStyle(container, "flex-wrap", "wrap");
    this.ren.setStyle(howManyDisplayedEl, "margin", "0px");
    this.ren.setStyle(howManyDisplayedEl, "margin-right", "3px");
    const paginatorMatArrow = itemsPerPage.querySelector(".mat-select-arrow");
    this.ren.setStyle(paginatorMatArrow, "opacity", "1");

    const paginatorSelectUnderline = nativeElement.querySelector(
      ".mat-paginator .mat-form-field-appearance-legacy .mat-form-field-underline"
    );
    this.ren.setStyle(paginatorSelectUnderline, "display", "none");

    const matPaginatorPageSizeLabel = nativeElement.querySelector(
      ".mat-paginator-page-size-label"
    );
    matPaginatorPageSizeLabel.innerHTML =
      matPaginatorPageSizeLabel.innerHTML.replaceAll(":", "");

    const matPaginatorPageSizeSelect = nativeElement.querySelector(
      ".mat-paginator-page-size-select"
    );
    this.ren.setStyle(matPaginatorPageSizeSelect, "margin", "0px");

    const rangeActions = nativeElement.querySelector(
      ".mat-paginator-range-actions"
    );
    this.ren.setStyle(rangeActions, "margin-right", 0);
    this.ren.setStyle(rangeActions, "margin-right", 0);

    const matFormFieldFlex = nativeElement.querySelector(
      ".mat-form-field-flex"
    );
    this.ren.setStyle(matFormFieldFlex, "height", "35px");

    const maFormFieldInfix = nativeElement.querySelector(
      ".mat-form-field-infix"
    );
    this.ren.addClass(
      maFormFieldInfix,
      "mat-form-field-infix-custom-paginator"
    );
  }
}
