import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import moment from "moment";
import { lastValueFrom, Subscription } from "rxjs";
import { finalize } from "rxjs/operators";
import {
  NgbPopoverConfig,
  NgbDate,
  NgbCalendar,
  NgbDateParserFormatter,
} from "@ng-bootstrap/ng-bootstrap";
import { Chart, ChartConfiguration, ChartType } from "chart.js";
import { MatPaginator } from "@angular/material/paginator";
import Annotation from "chartjs-plugin-annotation";
import { FormControl } from "@angular/forms";

import { StatisticsService } from "src/app/core/core-services/services/statistics.service";
import { EditReportingComponent } from "../../edit-reporting/edit-reporting.component";
import { DialogService } from "src/app/core/core-services/services/dialog.service";
import { TABLE_COLUMN } from "../../admin.data";
import { MatTableDataSource } from "@angular/material/table";
import { AuthService } from "src/app/core/core-services/services/auth.service";
import { AdminApiService } from "../../services/admin-api.service";
import { LoadingService } from "src/app/core/core-services/services/loader.service";
import { NotificationService } from "src/app/core/core-services/services/notification.service";
import { FilterUsersReportingUsage, FilterUsersReportingUsageType, SuperAdminApiService } from "../../services/super-admin-api.service";

@Component({
  selector: "app-vengreso-reporting",
  templateUrl: "./reporting.component.html",
  styleUrls: ["./reporting.component.scss"],
})
export class VengresoReportingComponent implements OnInit {
  tabs: any[];
  companySlug: string;
  currentTrailingPath: string;
  loadedApis: number = 0;
  downloadingCsv: boolean = false;
  dateAvailable: string = moment().subtract(1, "day").format("MMM, DD YYYY");
  subscriptions = new Subscription();
  overview: any[];

  lineChartType: ChartType = "line";
  lineChartData: ChartConfiguration["data"] =
    this.statisticsService.lineChartData;
  lineChartOptions: ChartConfiguration["options"] =
    this.statisticsService.adminLineChartOptions;
  lineChartPlugins: ChartConfiguration["plugins"] = [];

  roiLineChartType: ChartType = this.lineChartType;
  roiLineChartData: ChartConfiguration["data"] = this.lineChartData;
  roiLineChartOptions: ChartConfiguration["options"] = this.lineChartOptions;

  prodLineChartType: ChartType = this.lineChartType;
  prodLineChartData: ChartConfiguration["data"] = this.lineChartData;
  prodLineChartOptions: ChartConfiguration["options"] = this.lineChartOptions;

  totalCharLineChartType: ChartType = this.lineChartType;
  totalCharLineChartData: ChartConfiguration["data"] = this.lineChartData;
  totalCharLineChartOptions: ChartConfiguration["options"] =
    this.lineChartOptions;

  bar_chart_data: any = [0, 0, 0, 0, 0];
  barChartData: ChartConfiguration["data"] = {
    datasets: [
      {
        data: this.bar_chart_data,
        label: "Current Levels",
        backgroundColor: [
          "#FFC20E",
          "#F15A2A",
          "#FFEAAB",
          "#FF9658",
          "#5F177F",
        ],
        borderWidth: 0,
        pointBackgroundColor: "#5F177F",
        fill: "origin",
      },
    ],
    labels: ["Beginner", "Intermediate", "Proficient", "Advanced", "Expert"],
  };
  barChartOptions: ChartConfiguration["options"] = {};
  barChartType: ChartType = "bar";
  barChartlegendItems: { label: string; value: number; color: string }[] = [];

  dateRanges = [
    { text: "Last month", differenceFromNow: 1 },
    { text: "Last 3 months", differenceFromNow: 3 },
    { text: "Last 6 months", differenceFromNow: 6 },
    { text: "Last 9 months", differenceFromNow: 9 },
    { text: "Last 12 months", differenceFromNow: 12 },
    //{ text: "Custom", differenceFromNow: -1 },
  ];
  dateFilterValue: number = 12;
  dateFilterOpened: boolean = false;

  @ViewChild("dropdown") dropdown: ElementRef;
  generalFilterView: FilterUsersReportingUsageType = "main";
  generalFilterSelected: FilterUsersReportingUsage[] = [];
  generalFilterOpened: boolean = false;
  generalSearchList: FilterUsersReportingUsage[] = [];
  generalSearchOptions: any[] = [];
  generalFilterLoading: boolean = false;
  generalFilterSelectedGroup: any;
  generalFilterSelectedSubGroup: any;
  generalFilterSubGroupInGroups: boolean = false;

  filterUsersAvailable: FilterUsersReportingUsage[] = [];
  filterUsersType: FilterUsersReportingUsageType = 'main';

  activeFilterOpened: boolean = false;
  filterCtrl = new FormControl(null);
  activeFilterCtrl = new FormControl([]);
  activeFilterValue: string[] = [];
  activeFilterOptions: string[] = ["Active", "Deactivated", "Not Activated"];
  @ViewChild("selectElem") selectElem: any;
  usageViewMaximised: boolean = true;
  usage: any[];
  selectedChartLabel: string;
  usageCharts: any[] = [
    {
      label: "Cost Savings ($)",
      tooltip:
        "This shows the total cost savings in USD using the average US knowledge worker wage of $31.65/hour over the selected period of time.",
      total: 0,
      average: 0,
      topFive: [],
      chartData: this.lineChartData,
      chartOptions: this.lineChartOptions,
      plugins: this.lineChartPlugins,
      chartType: this.lineChartType,
    },
    {
      label: "Time Saved (hrs)",
      tooltip:
        "This shows the total amount of time users saved over the selected period of time.",
      total: 0,
      average: 0,
      topFive: [],
      chartData: this.lineChartData,
      chartOptions: this.lineChartOptions,
      plugins: this.lineChartPlugins,
      chartType: this.lineChartType,
    },
    {
      label: "Characters Typed",
      tooltip:
        "This shows the total amount of characters users typed over the selected period of time.",
      total: 0,
      average: 0,
      topFive: [],
      chartData: this.lineChartData,
      chartOptions: this.lineChartOptions,
      plugins: this.lineChartPlugins,
      chartType: this.lineChartType,
    },
    {
      label: "FlyCuts Created",
      tooltip:
        "Total number of FlyCuts created from users over the selected period of time.",
      total: 0,
      average: 0,
      topFive: [],
      chartData: this.lineChartData,
      chartOptions: this.lineChartOptions,
      plugins: this.lineChartPlugins,
      chartType: this.lineChartType,
    },
    {
      label: "FlyPlates Added",
      tooltip:
        "Total number of FlyPlates added to FlyCuts from users over the selected period of time.",
      total: 0,
      average: 0,
      topFive: [],
      chartData: this.lineChartData,
      chartOptions: this.lineChartOptions,
      plugins: this.lineChartPlugins,
      chartType: this.lineChartType,
    },
    {
      label: "FlyEngage AI Used",
      tooltip:
        "Total number of times that FlyEngage was used over the selected period of time.",
      total: 0,
      average: 0,
      topFive: [],
      chartData: this.lineChartData,
      chartOptions: this.lineChartOptions,
      plugins: this.lineChartPlugins,
      chartType: this.lineChartType,
    },
    {
      label: "FlyPosts AI Used",
      tooltip:
        "Total number of times that FlyPost was used over the selected period of time.",
      total: 0,
      average: 0,
      topFive: [],
      chartData: this.lineChartData,
      chartOptions: this.lineChartOptions,
      plugins: this.lineChartPlugins,
      chartType: this.lineChartType,
    },
  ];
  filteredUsageCharts: any[];
  extensionChart: any = {
    data: {
      labels: [],
      datasets: [],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
        },
        y: {
          beginAtZero: true,
          min: 0, // Ensure the y-axis starts at zero
          grid: {
            color: "#E0E0E0",
          },
          ticks: {
            callback: function (value) {
              return Math.round(Number(value));
            },
            stepSize: 1,
          },
        },
      },
    },
    type: "line",
    label: "Extension Usage",
    tooltip:
      "The number of extensions installed and uninstalled over the selected period of time.",
  };
  extensionLineLayers = {
    chrome_installed: {
      data: [],
      color: "#00B43D",
      label: "Chrome Installed",
    },
    edge_installed: {
      data: [],
      color: "#58B9FF",
      label: "Edge Installed",
    },
    uninstalls: {
      data: [],
      color: "#FF9658",
      label: "Extension Uninstalled",
    },
  };
  showCustomDatePicker = false;
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate;
  toDate: NgbDate | null = null;
  search: string = "";

  allChecked: boolean = false;
  dataSource: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  defaultColumns: string[] = ["first_name", "last_name", "email"];
  selectedColumns: string[] = [
    "license_type",
    "flymsg_account_creation_date",
    "is_the_user_signed_into_flymsg_extension",
    "last_login_to_flymsg",
  ];
  displayedColumns: string[] = this.defaultColumns;
  tableColumns: any = TABLE_COLUMN;

  expert_users: any = 0;
  beginner_users: any = 0;
  total_time_saved: any = 0;
  total_cost_saved: any = 0;
  total_characters_typed: any = 0;

  overviewLength = Array(4).fill(0);
  loadingCharts: boolean;
  loadingRoiLine: boolean;
  loadingProductivity: boolean;
  loadingTotalCharacterTyped: boolean;
  loadingCurrentFlyMsgCoach: boolean;

  loadingExtensionUsage: boolean;

  constructor(
    private readonly route: ActivatedRoute,
    config: NgbPopoverConfig,
    public statisticsService: StatisticsService,
    private readonly calendar: NgbCalendar,
    private readonly ngbDateParserFormatter: NgbDateParserFormatter,
    public dialog: DialogService,
    private readonly authService: AuthService,
    private readonly adminApiService: AdminApiService,
    private readonly superAdminApiService: SuperAdminApiService,
    private readonly loadingService: LoadingService,
    private readonly cdr: ChangeDetectorRef,
    private readonly notification: NotificationService
  ) {
    this.companySlug = this.authService.getUserCompanySlug();

    let adminBaseUrl = "/vengreso-admin/reporting/";
    this.tabs = [
      { url: adminBaseUrl + "overview", label: "Overview" },
      { url: adminBaseUrl + "usage", label: "Usage" },
      // { url: adminBaseUrl + "details", label: "Details" },
    ];

    config.triggers = "hover";
    config.container = "body";
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), "d", 20);
    Chart.register(Annotation);
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.route.paramMap.subscribe((params) => {
        this.currentTrailingPath = params.get("path");
        this.loadedApis = 0;
        this.loadingCharts = true;

        switch (this.currentTrailingPath) {
          case "overview":
            this.loadOverviewTab();
            break;
          case "usage":
            this.loadUsageTab();
            break;
          case "details":
            this.loadDetailsTab();
            break;
          default:
            break;
        }
      })
    );

    this.filterCtrl.valueChanges.subscribe(() => {
      this.filterGeneralSearch();
    });

    this.activeFilterCtrl.valueChanges.subscribe(() => {
      this.filterActiveSearch();
    });

    this.superAdminApiService
      .getCompaniesForReportingFilters()
      .pipe(finalize(() => (this.generalFilterLoading = false)))
      .subscribe((response: any) => {
        if (response?.result.success) {
          this.filterUsersAvailable = response.result.data;

          this.generalSearchList = this.checkSelected(this.filterUsersAvailable);
        }
      });
  }

  loadInitTabData(
    datePeriod?: { fromDate: string; toDate: string },
    generalFilter?: string
  ) {
    try {
      this.loadingRoiLine = true;
      this.usageCharts[0].loading = true;
      this.superAdminApiService
        .reportingGetROIspotlight(datePeriod, generalFilter)
        .subscribe((response: any) => {
          if (response?.result?.success) {
            let { chart, total_cost_saved, average_cost_savings } =
              response.result.data;
            this.roiLineChartData = this.prepareChartData(chart, "cost_saved");
            this.total_cost_saved = total_cost_saved?.toLocaleString("en-US");
            this.roiLineChartOptions.plugins.annotation = this.getLinePlugin(
              this.roiLineChartData
            );
            this.roiLineChartOptions.plugins.tooltip = {
              callbacks: {
                label: function (context) {
                  let value = context.raw as any;
                  let formattedValue = new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(value);
                  return `Cost Saved: ${formattedValue}`;
                },
              },
            };

            this.usageCharts[0].total = this.total_cost_saved;
            this.usageCharts[0].average =
              average_cost_savings?.toLocaleString("en-US");
            this.usageCharts[0].chartData = this.roiLineChartData;
            this.usageCharts[0].chartOptions = this.roiLineChartOptions;
          }
          this.updateLoadingStatus();
          this.loadingRoiLine = false;
          this.usageCharts[0].loading = false;
        });

      this.usageCharts[1].loading = true;
      this.loadingProductivity = true;
      this.superAdminApiService
        .reportingGetProductivitySpotlight(datePeriod, generalFilter)
        .subscribe((response: any) => {
          if (response?.result?.success) {
            let { chart, total_time_saved, average_time_saved } =
              response.result.data;
            this.prodLineChartData = this.prepareChartData(chart, "time_saved");
            this.total_time_saved = total_time_saved?.toLocaleString("en-US");
            this.prodLineChartOptions.plugins.annotation = this.getLinePlugin(
              this.prodLineChartData
            );
            this.prodLineChartOptions.plugins.tooltip = {
              callbacks: {
                label: function (context) {
                  let value = context.raw as any;
                  let formattedValue = new Intl.NumberFormat("en-US", {
                    style: "decimal",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(value);
                  return `Time Saved (hrs): ${formattedValue}`;
                },
              },
            };

            this.usageCharts[1].total = this.total_time_saved;
            this.usageCharts[1].average =
              average_time_saved?.toLocaleString("en-US");
            this.usageCharts[1].chartData = this.prodLineChartData;
            this.usageCharts[1].chartOptions = this.prodLineChartOptions;
          }
          this.usageCharts[1].loading = false;
          this.loadingProductivity = false;
          this.updateLoadingStatus();
        });

      this.loadingTotalCharacterTyped = true;
      this.usageCharts[2].loading = true;
      this.superAdminApiService
        .reportingGetCharactersTypedSpotlight(datePeriod, generalFilter)
        .subscribe((response: any) => {
          if (response && response.result && response.result.success == true) {
            let { chart, total_characters_typed, average_characters_typed } =
              response.result.data;
            this.totalCharLineChartData = this.prepareChartData(
              chart,
              "characters_typed"
            );

            const totalCharactersTyped = Number(
              total_characters_typed.replace(/,/g, "")
            );

            this.total_characters_typed = new Intl.NumberFormat("en-US", {
              style: "decimal",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }).format(totalCharactersTyped);
            this.totalCharLineChartOptions.plugins.annotation =
              this.getLinePlugin(this.totalCharLineChartData);
            this.prodLineChartOptions.plugins.tooltip = {
              callbacks: {
                label: function (context) {
                  let value = context.raw as any;
                  let formattedValue = new Intl.NumberFormat("en-US", {
                    style: "decimal",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }).format(value);
                  return `Characters Typed: ${formattedValue}`;
                },
              },
            };

            this.usageCharts[2].total = this.total_characters_typed;
            this.usageCharts[2].average =
              average_characters_typed?.toLocaleString("en-US");
            this.usageCharts[2].chartData = this.totalCharLineChartData;
            this.usageCharts[2].chartOptions = this.totalCharLineChartOptions;
          }
          this.updateLoadingStatus();
          this.loadingTotalCharacterTyped = false;
          this.usageCharts[2].loading = false;
        });
    } catch (error) {
      this.loadingCharts = false;
    }
  }

  resendInvitations() {
    this.loadingService.start();
    this.adminApiService
      .resendInvitations()
      .pipe(finalize(() => this.loadingService.stop()))
      .subscribe(
        (response: any) => {
          if (response && response.result && response.result.success) {
            this.notification.toast("Invitations resent successfully");
          } else {
            this.notification.toast("Failed to resend invitations");
          }
        },
        (error) => {
          this.notification.toast("Failed to resend invitations");
        }
      );
  }

  assignLicenses() {
    this.adminApiService.assignLicenses();
  }

  loadOverviewTab() {
    this.loadInitTabData();
    try {
      this.superAdminApiService
        .reportingGetLicenses()
        .subscribe((response: any) => {
          if (response?.result?.success) {
            let {
              inactive_users = 0,
              activated = 0,
              extensions_installed = 0,
              extensions_uninstalled = 0,
            } = response.result.licenses;
            this.overview = [
              {
                count: inactive_users?.toLocaleString("en-US"),
                label: "Inactive Users",
                tooltip:
                  "Inactive users represents the number of users who have not used FlyMSG within the last 30 days.",
                usage: "",
                link: "",
              },
              {
                count: activated?.toLocaleString("en-US"),
                label: "Activated Licenses",
                tooltip: "Total number of teams licenses activated.",
                usage: "",
                link: "",
                sendEmail: true,
              },
              {
                count: extensions_installed?.toLocaleString("en-US"),
                label: "Extension Installed",
                tooltip:
                  "Total number of users with the FlyMSG extension installed.",
                usage: "",
                link: "",
              },
              {
                count: extensions_uninstalled?.toLocaleString("en-US"),
                label: "Extension Uninstalled",
                tooltip:
                  "Total number of users with the FlyMSG extension uninstalled.",
                usage: "",
                link: "",
              },
            ];
          }
          this.updateLoadingStatus();
        });

      this.loadingCurrentFlyMsgCoach = true;
      this.superAdminApiService
        .reportingGetFMScoachLevelSpotlight()
        .subscribe((response: any) => {
          const barChartData = {
            datasets: [
              {
                data: this.bar_chart_data,
                label: "Current Levels",
                backgroundColor: [
                  "#FFC20E",
                  "#F15A2A",
                  "#FFEAAB",
                  "#FF9658",
                  "#5F177F",
                ],
                borderWidth: 0,
                pointBackgroundColor: "#5F177F",
                fill: "origin",
              },
            ],
            labels: [
              "Beginner",
              "Intermediate",
              "Proficient",
              "Advanced",
              "Expert",
            ],
          };

          if (response && response.result && response.result.success == true) {
            const { chart, expert_users, beginner_users } =
              response.result.data;
            this.bar_chart_data = chart;
            this.expert_users = expert_users;
            this.beginner_users = beginner_users;
            barChartData.datasets[0].data = this.bar_chart_data;
            const legendItems = [];
            barChartData.datasets[0].data.forEach((value, index) => {
              legendItems.push({
                label: barChartData.labels[index] as string,
                value: value?.toLocaleString("en-US") as number,
                color: barChartData.datasets[0].backgroundColor[
                  index
                ] as string,
              });
            });
            this.barChartlegendItems = legendItems;
            this.barChartData = barChartData;
            this.barChartOptions = {
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  callbacks: {
                    label: function (context) {
                      return `${context.dataset.label}: ${context.raw}`;
                    },
                  },
                },
              },
              scales: {
                x: {
                  grid: {
                    display: false,
                  },
                  ticks: {
                    callback: function (value, index, values) {
                      return barChartData.labels[index];
                    },
                  },
                },
                y: {
                  beginAtZero: true,
                  grid: {
                    color: "#E0E0E0",
                  },
                  ticks: {
                    callback: function (value) {
                      return Math.round(Number(value));
                    },
                    stepSize: 1, // Ensure the step size is 1 for rounded values
                  },
                },
              },
            };
          }
          this.updateLoadingStatus();
          this.loadingCurrentFlyMsgCoach = false;
        });
    } catch (error) {
      this.loadingCharts = false;
    }
  }

  loadUsageTab(
    datePeriod?: { fromDate: string; toDate: string },
    generalFilter?: string
  ) {
    if (!datePeriod) {
      let fromDate = moment()
        .subtract(this.dateFilterValue ?? 12, "months")
        .format("YYYY-MM-DD");
      let toDate = moment().format("YYYY-MM-DD");
      datePeriod = { fromDate, toDate };
    }

    this.loadInitTabData(datePeriod, generalFilter);
    try {
      // this.adminApiService
      //   .reportingActiveUsers(datePeriod, generalFilter)
      //   .subscribe((response: any) => {
      //     if (response && response.result && response.result.success == true) {
      //       let {
      //         active_users,
      //         inactive_users,
      //         with_extension_installed,
      //         with_extension_uninstalled,
      //       } = response.result.data;

      //       this.usage = [
      //         {
      //           count: inactive_users?.toLocaleString("en-US"),
      //           label: "Deactivated Users",
      //           tooltip: "Total number of users deactivated.",
      //         },
      //         {
      //           count: active_users?.toLocaleString("en-US"),
      //           label: "Activated Users",
      //           tooltip: "Total number of teams licenses activated.",
      //         },
      //         {
      //           count: with_extension_installed?.toLocaleString("en-US"),
      //           label: "Extension Installed",
      //           tooltip:
      //             "Total number of activated users with the extensions installed.",
      //         },
      //         {
      //           count: with_extension_uninstalled?.toLocaleString("en-US"),
      //           label: "Extension Uninstalled",
      //           tooltip: "Total number of extensions uninstalled.",
      //         },
      //       ];
      //     }
      //     this.updateLoadingStatus();
      //   });

      this.usageCharts[0].loadingTopUsers = true;
      this.adminApiService
        .costSavingTopUsers(datePeriod, generalFilter)
        .subscribe((response: any) => {
          if (response && response.result && response.result.success == true) {
            this.usageCharts[0].topFive = response.result.users.map((item) => ({
              ...item,
              count: `$${item.count}`,
            }));
          }
          this.updateLoadingStatus();
          this.usageCharts[0].loadingTopUsers = false;
        });

      this.usageCharts[1].loadingTopUsers = true;
      this.adminApiService
        .timeSavingTopUsers(datePeriod, generalFilter)
        .subscribe((response: any) => {
          if (response && response.result && response.result.success == true) {
            this.usageCharts[1].topFive = response.result.users;
          }
          this.updateLoadingStatus();
          this.usageCharts[1].loadingTopUsers = false;
        });

      this.usageCharts[2].loadingTopUsers = true;
      this.adminApiService
        .charactersTypedSavingTopUsers(datePeriod, generalFilter)
        .subscribe((response: any) => {
          if (response && response.result && response.result.success == true) {
            this.usageCharts[2].topFive = response.result.users;
          }
          this.updateLoadingStatus();
          this.usageCharts[2].loadingTopUsers = false;
        });

      this.usageCharts[3].loadingTopUsers = true;
      this.adminApiService
        .flycutsTopUsers(datePeriod, generalFilter)
        .subscribe((response: any) => {
          if (response && response.result && response.result.success == true) {
            this.usageCharts[3].topFive = response.result.users;
          }
          this.updateLoadingStatus();
          this.usageCharts[3].loadingTopUsers = false;
        });

      this.usageCharts[4].loadingTopUsers = true;
      this.adminApiService
        .flyplatesTopUsers(datePeriod, generalFilter)
        .subscribe((response: any) => {
          if (response && response.result && response.result.success == true) {
            this.usageCharts[4].topFive = response.result.users;
          }
          this.updateLoadingStatus();
          this.usageCharts[4].loadingTopUsers = false;
        });

      this.usageCharts[5].loadingTopUsers = true;
      this.adminApiService
        .flyengageTopUsers(datePeriod, generalFilter)
        .subscribe((response: any) => {
          if (response && response.result && response.result.success == true) {
            this.usageCharts[5].topFive = response.result.users;
          }
          this.updateLoadingStatus();
          this.usageCharts[5].loadingTopUsers = false;
        });

      this.usageCharts[6].loadingTopUsers = true;
      this.adminApiService
        .flypostTopUsers(datePeriod, generalFilter)
        .subscribe((response: any) => {
          if (response && response.result && response.result.success == true) {
            this.usageCharts[6].topFive = response.result.users;
          }
          this.updateLoadingStatus();
          this.usageCharts[6].loadingTopUsers = false;
        });

      this.usageCharts[3].loading = true;
      this.adminApiService
        .reportingGetFlyCutsCreated(datePeriod, generalFilter)
        .subscribe((response: any) => {
          if (response && response.result && response.result.success == true) {
            let {
              chart,
              total_flycuts_created,
              average_flycuts_per_active_user,
            } = response.result.data;

            this.usageCharts[3].average =
              average_flycuts_per_active_user?.toLocaleString("en-US");
            this.usageCharts[3].total =
              total_flycuts_created?.toLocaleString("en-US");
            this.usageCharts[3].chartData = this.prepareUsageChartData(
              chart,
              this.usageCharts[3].label
            );
            this.usageCharts[3].chartOptions.plugins.annotation =
              this.getLinePlugin(this.usageCharts[3].chartData);
          }
          this.updateLoadingStatus();
          this.usageCharts[3].loading = false;
        });

      this.usageCharts[4].loading = true;
      this.adminApiService
        .reportingGetFlyPlatesAdded(datePeriod, generalFilter)
        .subscribe((response: any) => {
          if (response && response.result && response.result.success == true) {
            let {
              chart,
              total_flyplate_created,
              average_flyplate_per_active_user,
            } = response.result.data;

            this.usageCharts[4].average =
              average_flyplate_per_active_user?.toLocaleString("en-US");
            this.usageCharts[4].total =
              total_flyplate_created?.toLocaleString("en-US");
            this.usageCharts[4].chartData = this.prepareUsageChartData(
              chart,
              this.usageCharts[4].label
            );
            this.usageCharts[4].chartOptions.plugins.annotation =
              this.getLinePlugin(this.usageCharts[4].chartData);
          }
          this.updateLoadingStatus();
          this.usageCharts[4].loading = false;
        });

      this.usageCharts[5].loading = true;
      this.adminApiService
        .reportingGetFlyEngageAI(datePeriod, generalFilter)
        .subscribe((response: any) => {
          if (response && response.result && response.result.success == true) {
            let {
              chart,
              total_flyengage_ai_created,
              average_flyengage_ai_per_active_user,
            } = response.result.data;

            this.usageCharts[5].average =
              average_flyengage_ai_per_active_user?.toLocaleString("en-US");
            this.usageCharts[5].total =
              total_flyengage_ai_created?.toLocaleString("en-US");
            this.usageCharts[5].chartData = this.prepareUsageChartData(
              chart,
              this.usageCharts[5].label
            );
            this.usageCharts[5].chartOptions.plugins.annotation =
              this.getLinePlugin(this.usageCharts[5].chartData);
          }
          this.updateLoadingStatus();
          this.usageCharts[5].loading = false;
        });

      this.usageCharts[6].loading = true;
      this.adminApiService
        .reportingGetFlyPostAI(datePeriod, generalFilter)
        .subscribe((response: any) => {
          if (response && response.result && response.result.success == true) {
            let {
              chart,
              total_flypost_ai_created,
              average_flypost_ai_per_active_user,
            } = response.result.data;

            this.usageCharts[6].average =
              average_flypost_ai_per_active_user?.toLocaleString("en-US");
            this.usageCharts[6].total =
              total_flypost_ai_created?.toLocaleString("en-US");
            this.usageCharts[6].chartData = this.prepareUsageChartData(
              chart,
              this.usageCharts[6].label
            );
            this.usageCharts[6].chartOptions.plugins.annotation =
              this.getLinePlugin(this.usageCharts[6].chartData);
          }
          this.updateLoadingStatus();
          this.usageCharts[6].loading = false;
        });

      this.loadingExtensionUsage = true;
      this.adminApiService
        .reportingExtensionUsage(datePeriod, generalFilter)
        .subscribe((response: any) => {
          if (response && response.result && response.result.success == true) {
            let { chart: rawExtensionChartData } = response.result.data;

            let extensionChartData = {
              labels: [],
              datasets: [],
            };

            extensionChartData.labels = Object.keys(rawExtensionChartData);
            extensionChartData.labels.forEach((key: string) => {
              Object.entries(this.extensionLineLayers).forEach(
                ([line, lineLayer], index) => {
                  if (!extensionChartData.datasets[index]) {
                    extensionChartData.datasets.push({
                      label: lineLayer.label,
                      data: [],
                      borderColor: lineLayer.color,
                      tension: 0.5,
                      fill: false,
                    });
                  }
                  extensionChartData.datasets[index].data.push(
                    rawExtensionChartData[key][line]
                  );
                }
              );
            });

            this.extensionChart.data = extensionChartData;
            this.extensionChart.data.datasets = [
              ...extensionChartData.datasets,
            ];
          }
          this.updateLoadingStatus();
          this.loadingExtensionUsage = false;
        });

      this.filteredUsageCharts = this.usageCharts;
    } catch (error) {
      this.loadingCharts = false;
    }
  }

  async loadDetailsTab(datePeriod?: { fromDate: string; toDate: string }) {
    try {
      if (!this.dataSource) {
        this.dataSource = new MatTableDataSource();
      }

      const response: any = await lastValueFrom(
        this.adminApiService.getUserSavedColumns()
      );

      if (response && response.result && response.result.success == true) {
        //ToDo: Ambrose let this endpoint return unique key values
        let columns = [
          ...new Map(
            response.result.columns.map((column: any) => [column.key, column])
          ).values(),
        ];
        this.tableColumns = columns.filter((c: any) => c.checked == true);
        this.displayedColumns = this.tableColumns.map((c: any) => c.key);
        this.selectedColumns = this.displayedColumns.filter(
          (key: string) => !this.defaultColumns.includes(key)
        );
        this.updateLoadingStatus();
      }

      this.adminApiService
        .getUserDetails(datePeriod)
        .subscribe((response: any) => {
          if (response && response.result && response.result.success == true) {
            let data = this.processUserDetailsData(
              response.result.users,
              this.displayedColumns.concat([
                "user_id",
                "group_id",
                "subgroup_id",
                "status",
              ]) //Needed for filters and selection
            );
            this.dataSource = new MatTableDataSource(data);
            this.dataSource.paginator = this.paginator;

            this.runGeneralFilter();
          }
          this.updateLoadingStatus();
        });
    } catch (error) {
      this.loadingCharts = false;
    }
  }

  updateLoadingStatus() {
    this.loadedApis++;
    this.checkAllApisLoaded();
    this.cdr.detectChanges();

    const barChartData = document.getElementById(
      "barChartData"
    ) as HTMLCanvasElement;
    const chartInstance = Chart.getChart(barChartData);
    if (chartInstance) {
      chartInstance.resize();
    }
  }

  checkAllApisLoaded() {
    let allCompleted = false;

    switch (this.currentTrailingPath) {
      case "overview":
        allCompleted = this.loadedApis == 5;
        break;
      case "usage":
        allCompleted = this.loadedApis == 16;
        break;
      case "details":
        allCompleted = this.loadedApis == 2;
        break;
      default:
        break;
    }

    if (allCompleted) {
      this.loadingCharts = false;
    }
  }

  updateAllChecked() {
    this.allChecked =
      this.dataSource.data != null &&
      this.dataSource.data.every((t: any) => t.checked);
  }

  someChecked(): boolean {
    if (this.dataSource.data == null) {
      return false;
    }
    return (
      this.dataSource.data.filter((t: any) => t.checked).length > 0 &&
      !this.allChecked
    );
  }

  checkAll(checked: boolean) {
    this.allChecked = checked;
    if (this.dataSource.data == null) {
      return;
    }
    this.dataSource.data.forEach((t: any) => (t.checked = checked));
  }

  exportCsv(): void {
    let filter = "";
    let report = this.currentTrailingPath;
    switch (report) {
      case "usage":
        let fromDate = moment()
          .subtract(this.dateFilterValue ?? 12, "months")
          .format("YYYY-MM-DD");
        let toDate = moment().format("YYYY-MM-DD");

        let dateFilter = `fromDate=${fromDate}&toDate=${toDate}`;
        let generalFilter = this.generalFilterQueryString;

        filter = !!generalFilter
          ? `${dateFilter}&${generalFilter}`
          : dateFilter;
        break;
      case "details":
        const selectedUsers = this.dataSource.data
          .filter((t: any) => t.checked)
          .map((user: any) => user.user_id);
        filter =
          Array.isArray(selectedUsers) && selectedUsers.length > 0
            ? selectedUsers.join(",")
            : "";
        break;
      default:
        break;
    }

    this.adminApiService.exportCSVReports(filter, report);
  }

  processUserDetailsData(users: any, displayedColumnKeys: string[]) {
    return users.map((item) => {
      let newItem = {};
      displayedColumnKeys.forEach((key) => {
        if (item.hasOwnProperty(key)) {
          newItem[key] = item[key];

          if (
            key ===
            "total_no_of_characters_typed_summarized_monthly_by_flymsg_by_user"
          ) {
            newItem[key] = (newItem[key] ?? "")
              .split(".")
              .map((item) => item.trim())
              .join(".\n");
          }
        }
      });
      return newItem;
    });
  }

  getLinePlugin(chartData: ChartConfiguration["data"]): { annotations: any } {
    return {
      annotations: chartData?.labels?.map((label, index) => ({
        type: "line",
        id: `line${index}`,
        mode: "vertical",
        yMax: Number(chartData?.datasets[0].data[index]),
        xMax: String(label),
        xMin: String(label),
        value: String(label),
        borderColor: "#2a1e36",
        borderWidth: 1,
        borderDash: [5, 5],
        label: {
          content: String(label),
          enabled: true,
          position: "start",
          backgroundColor: "rgba(0, 0, 0, 0)",
        },
      })),
    };
  }

  prepareChartData(
    rawChartData: any,
    filterBy = "time_saved"
  ): ChartConfiguration["data"] {
    const chartData = {
      datasets: [
        {
          data: [],
          label: "",
          backgroundColor: "#F0DBFA",
          borderColor: "#5F177F",
          pointBackgroundColor: "#5F177F",
          fill: "origin",
        },
      ],
      labels: [],
    };

    Object.keys(rawChartData).forEach((key) => {
      chartData.labels.push(key);
      chartData.datasets[0].data.push(rawChartData[key][filterBy]);
    });

    let label = "";

    switch (filterBy) {
      case "characters_typed":
        label = this.titleCase(filterBy);
        break;
      case "cost_saved":
        label = this.titleCase(filterBy) + " ($)";
        break;
      case "time_saved":
        label = this.titleCase(filterBy) + " (hrs)";
        break;

      default:
        break;
    }

    chartData.datasets[0].label = label;

    return chartData;
  }

  prepareUsageChartData(rawChartData: any, label: string) {
    const chartData = {
      datasets: [
        {
          data: [],
          label: "",
          backgroundColor: "#F0DBFA",
          borderColor: "#5F177F",
          pointBackgroundColor: "#5F177F",
          fill: "origin",
        },
      ],
      labels: [],
    };

    chartData.datasets[0].label = label;

    Object.keys(rawChartData).forEach((key) => {
      chartData.labels.push(key);
      chartData.datasets[0].data.push(rawChartData[key].count);
    });

    return chartData;
  }

  titleCase(s: string): string {
    return s
      .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase())
      .replace(/[-_]+(.)/g, (_, c) => " " + c.toUpperCase());
  }

  ngAfterViewInit() {
    if (this.currentTrailingPath === "details") {
      this.dataSource.paginator = this.paginator;
    }
  }

  get emailLink(): string {
    const { first_name, last_name, company } = this.authService.getUserDetail();
    const body: string = `
    "Hello FlyMSG team"
    I'd like to inquire about adding on _______ more licenses to my account.
    Account name: ${company.name}
    Administrator Requesting: ${first_name} ${last_name}`;

    return `mailto:sales@vengreso.com?subject=${encodeURIComponent(
      "Additional License Inquiry"
    )}&body=${encodeURIComponent(body)}`;
  }

  get isReportingAdmin(): boolean {
    return this.authService.getUserRole() === "Reporting Admin";
  }

  get isVengresoAdmin(): boolean {
    return this.authService.getUserRole() === "Vengreso Admin";
  }

  toggleView(): void {
    if (this.loadingCharts) return;

    this.usageViewMaximised = !this.usageViewMaximised;

    if (!this.usageViewMaximised) {
      this.selectChart(this.usageCharts[0].label);
    }

    if (this.usageViewMaximised) {
      this.filteredUsageCharts = this.usageCharts;
      this.loadUsageTab();
      // this.filteredUsageCharts = this.filteredUsageCharts.map(chart => {
      //   chart.chartOptions.plugins.annotation = this.getLinePlugin(chart.chartData);
      //   return chart;
      // });
    }
  }

  selectChart(selectedlabel: string) {
    this.selectedChartLabel = selectedlabel;

    if (this.selectedChartLabel === this.extensionChart.label) {
      this.filteredUsageCharts = [];
    } else {
      this.filteredUsageCharts = this.usageCharts.filter(
        ({ label }) => label == this.selectedChartLabel
      );

      this.filteredUsageCharts[0].chartOptions.plugins.annotation =
        this.getLinePlugin(this.filteredUsageCharts[0].chartData);
    }
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  closeDatePicker() {
    this.showCustomDatePicker = false;
  }

  onSelectionChange(event: any) {
    this.showCustomDatePicker = event?.value === -1;

    if (event?.value === -1) {
      return;
    }

    let fromDate = moment()
      .subtract(event.value ?? 12, "months")
      .format("YYYY-MM-DD");
    let toDate = moment().format("YYYY-MM-DD");

    switch (this.currentTrailingPath) {
      case "usage":
        this.loadUsageTab({ fromDate, toDate });
        break;
      case "details":
        this.loadDetailsTab({ fromDate, toDate });
        break;
      default:
        break;
    }
  }

  applyDateRange() {
    let fromDate = this.ngbDateParserFormatter.format(this.fromDate);
    let toDate = this.ngbDateParserFormatter.format(this.toDate);

    switch (this.currentTrailingPath) {
      case "usage":
        this.loadUsageTab({ fromDate, toDate });
        break;
      case "details":
        this.loadDetailsTab({ fromDate, toDate });
        break;
      default:
        break;
    }

    this.showCustomDatePicker = false;
  }

  get showExtensionChart(): boolean {
    return (
      this.usageViewMaximised ||
      (!this.usageViewMaximised &&
        this.selectedChartLabel === this.extensionChart.label)
    );
  }

  toggleDropdown(event: Event) {
    if (this.loadingCharts) return;

    this.generalFilterOpened = !this.generalFilterOpened;

    if (this.generalFilterOpened == true) {
      // this.switchGeneralFilterView(event, "groups");
    }
  }

  @HostListener("document:click", ["$event"])
  onDocumentClick(event: Event) {
    setTimeout(() => {
      if (
        this.generalFilterOpened &&
        !this.dropdown.nativeElement.contains(event.target)
      ) {
        this.generalFilterOpened = false;
        this.cdr.detectChanges();
      }
    }, 0);
  }

  filterGeneralSearch() {
    let search = this.filterCtrl?.value?.toLowerCase();
    if (!search) {
      return;
    }
    this.generalFilterLoading = true;

    this.generalSearchOptions = this.convertToPlainList(this.filterUsersAvailable)
      .filter(item => item.name.toLowerCase().includes(search));

    this.generalFilterLoading = false;
  }

  switchGeneralFilterView(event: Event, view: FilterUsersReportingUsageType, group?: any) {
    event.stopPropagation();
    this.generalFilterView = view;
    this.generalFilterLoading = true;
  }

  private uncheckChildren(parent: FilterUsersReportingUsage) {
    if (!parent.items?.length) {
      return;
    }

    this.generalFilterSelected = this.generalFilterSelected.filter((selected) => {
      const isChecked = parent.items.find(i => i.id === selected.id);

      if (isChecked) {
        this.uncheckChildren(isChecked);
      }

      return !isChecked;
    });
  }

  updateGeneralFilter(entity: any) {
    if (entity.checked) {
      this.generalFilterSelected.push(entity);

      if (entity.items?.length) {
        this.uncheckChildren(entity);
      }
    } else {
      this.generalFilterSelected = this.generalFilterSelected.filter((selected: any) => selected.id !== entity.id);
    }

    this.generalSearchList = this.checkSelected(this.filterUsersAvailable);
  }

  get selectedObjectCount() {
    return this.generalFilterSelected.reduce<Record<FilterUsersReportingUsageType, number>>((acc, entity) => ({
      ...acc,
      [entity.type]: (acc[entity.type] ?? 0) + 1,
    }), {
      group: 0,
      company: 0,
      subgroup: 0,
      user: 0,
      main: 0,
      email: 0,
    });
  }

  get selectedObject() {
    return this.generalFilterSelected.reduce<Record<FilterUsersReportingUsageType, FilterUsersReportingUsage[]>>((acc, entity) => ({
      ...acc,
      [entity.type]: [...(acc[entity.type] ?? []), entity],
    }), {
      group: [],
      company: [],
      subgroup: [],
      user: [],
      main: [],
      email: [],
    });
  }

  get generalFilterLabel() {
    if (!this.hasGeneralFilterValues) {
      return "All";
    }

    return Object.entries(this.selectedObjectCount)
      .filter(([_, entity]) => entity > 0)
      .map(
        ([type, entity]) =>
          `${entity} ${type}${
            entity > 1 ? "s" : ""
          }`
      )
      .join(", ");
  }

  removeSelected(event: Event, selected: any) {
    event.stopPropagation();

    this.generalFilterSelected = this.generalFilterSelected.filter((entity: any) => entity.id !== selected.id);
    this.generalSearchList = this.generalSearchList.map((list) =>
      list.id === selected.id ? { ...list, checked: false } : list
    );
  }

  unselectAll(event: Event) {
    event.stopPropagation();

    this.generalFilterSelected = [];
    this.generalSearchList = this.generalSearchList.map((list) => ({
      ...list,
      checked: false,
    }));
  }

  runGeneralFilter() {
    if (!this.hasGeneralFilterValues) {
      this.resetFilterPredicate();
      this.dataSource.filter = "";
      return;
    }

    this.dataSource.filterPredicate = (data: any, filter: any) => {
      return (
        filter.group.map((g: any) => g.id).includes(data["group_id"]) ||
        filter.subgroup.map((g: any) => g.id).includes(data["subgroup_id"]) ||
        filter.user.map((u: any) => u.id).includes(data["user_id"])
      );
    };

    this.dataSource.filter = this.generalFilterSelected;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  get hasGeneralFilterValues() {
    const plainList = this.convertToPlainList(this.generalSearchList);
    const allSelected = plainList.some((item) => !item.checked) && this.generalFilterSelected.length > 0;
    return allSelected;
  }

  get generalFilterQueryString(): string {
    if (!this.hasGeneralFilterValues) {
      return '';
    }

    const apiParameterKeys: Record<FilterUsersReportingUsageType, string> = {
      group: "group_ids",
      company: "company_ids",
      subgroup: "subgroup_ids",
      user: "user_ids",
      main: "main_ids",
      email: "email_domains",
    };

    return Object.entries(this.selectedObject)
      .filter(([_, entities]) => entities.length > 0)
      .map(([type, entities]) => {
        const ids = entities
          .map((entity) => entity.id)
          .join(",");
        return `${apiParameterKeys[type]}=${ids}`;
      })
      .join("&");
  }

  closeGeneralFilter(): void {
    this.generalFilterOpened = false;
  }

  applyGeneralFilter(): void {
    switch (this.currentTrailingPath) {
      case "usage":
        this.loadUsageTab(null, this.generalFilterQueryString);
        break;
      case "details":
        this.runGeneralFilter();
        break;
      default:
        break;
    }

    this.closeGeneralFilter();
  }

  filterActiveSearch() {
    let search = this.activeFilterCtrl?.value;

    if (
      !search ||
      search.length < 1 ||
      search.length == this.activeFilterOptions.length
    ) {
      this.search = "";
      this.searchUsers();
      return;
    }

    this.dataSource.filterPredicate = (data: any, filter: any) => {
      return filter.includes(data["status"]);
    };

    this.dataSource.filter = search;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  searchUsers() {
    this.resetFilterPredicate();
    this.dataSource.filter = this.search.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  resetFilterPredicate() {
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      const dataStr = Object.keys(data)
        .reduce((currentTerm, key) => {
          return currentTerm + (data as { [key: string]: any })[key] + " ";
        }, "")
        .toLowerCase();
      return dataStr.indexOf(filter) !== -1;
    };
  }

  get filtersClear() {
    let clear =
      this.dateFilterValue == 12 &&
      !this.filterCtrl.value &&
      !this.hasGeneralFilterValues;
    let status = this.activeFilterCtrl?.value;

    if (this.currentTrailingPath == "details") {
      clear =
        clear &&
        this.search.trim().length < 1 &&
        (status.length < 1 ||
          status.length === this.activeFilterOptions.length);
    }

    return clear;
  }

  clearFilters() {
    this.dateFilterValue = 12;
    this.onSelectionChange({ value: 12 });

    this.generalFilterSelected = [];
    this.filterCtrl.reset();

    if (this.currentTrailingPath == "details") {
      this.search = "";
      this.searchUsers();
      this.activeFilterCtrl.reset([]);
    }
  }

  chartHovered(_event: any) {}

  chartClicked(_event: any) {}

  openCustomiseColumns(): void {
    const dialogRef = this.dialog.openDialogComponent(
      EditReportingComponent,
      {
        defaultColumns: this.defaultColumns,
        selectedColumns: this.selectedColumns,
      },
      "640px"
    );

    dialogRef.afterClosed().subscribe((columns: any) => {
      if (!!columns) {
        this.tableColumns = columns.filter((c: any) => c.checked == true);
        this.displayedColumns = this.tableColumns.map((c: any) => c.key);
      }
    });
  }

  addDashedLinesToCharts(
    chartOptions: ChartConfiguration["options"],
    chartData: ChartConfiguration["data"]
  ): void {
    chartOptions.plugins.annotation = {
      annotations: chartData.labels?.map((label, index) => ({
        type: "line",
        id: `line${index}`,
        mode: "vertical",
        yMax: Number(chartData.datasets[0].data[index]),
        xMax: String(label),
        xMin: String(label),
        value: String(label),
        borderColor: "#2a1e36",
        borderWidth: 1,
        borderDash: [5, 5],
        label: {
          content: String(label),
          enabled: true,
          position: "start",
          backgroundColor: "rgba(0, 0, 0, 0)",
        },
      })),
    };
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  private recursiveIndeterminateCheck(selectedGroupIds: string[], items?: FilterUsersReportingUsage[]) {
    return items?.some((i) => selectedGroupIds.includes(i.id) || this.recursiveIndeterminateCheck(selectedGroupIds, i.items)) ?? false;
  }

  private isParentFilterUsersReportingUsageSelected(item: FilterUsersReportingUsage, plainList: FilterUsersReportingUsage[]) {
    const parent = plainList.find((i) => i.items?.some(j => j.id === item.id));

    if (!parent) {
      return false;
    }

    return parent.checked || this.isParentFilterUsersReportingUsageSelected(parent, plainList);
  }

  private checkSelected(list: FilterUsersReportingUsage[]) {
    const selectedGroupIds = this.generalFilterSelected.map(item => item.id);
    const plainList = this.convertToPlainList(this.generalSearchList);

    return list.map((item) => ({
      ...item,
      checked: selectedGroupIds.includes(item.id),
      indeterminate: item.items ? this.recursiveIndeterminateCheck(selectedGroupIds, item.items) : false,
      items: item.items?.length ? this.checkSelected(item.items) : [],
      disabledAndChecked: this.isParentFilterUsersReportingUsageSelected(item, plainList),
    }));
  }
  
  private convertToPlainList(items: FilterUsersReportingUsage[]) {
    let subItems: FilterUsersReportingUsage[] = [];

    items.filter(item => item.items?.length).forEach((item) => {
      subItems = subItems.concat(this.convertToPlainList(item.items));
    });

    return items.concat(subItems);
  }
}
