/// <reference types="chrome"/>
import { ChangeDetectorRef, Component, OnChanges, OnInit } from "@angular/core";

import { ExtensionService } from "src/app/core/core-services/services/extension.service";
import { SharedDataService } from "src/app/core/core-services/services/shared-data.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-download-banner",
  templateUrl: "./download-banner.component.html",
  styleUrls: ["./download-banner.component.scss"],
})
export class DownloadBannerComponent implements OnInit, OnChanges {
  isChromeBrowser = window.chrome && window.navigator.vendor === "Google Inc.";
  isChromeRuntime = this.isChromeBrowser && chrome.runtime;
  browser_type;
  show = false;
  is_extention_avialble;
  display: boolean = false;
  site =
    "https://chrome.google.com/webstore/detail/fly-msg-for-chrome/giidlnpcdhcldhfccdhkaicefhpokghc?hl=en&authuser=0";
  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly extensionService: ExtensionService,
    private readonly sharedData: SharedDataService
  ) {
    this.is_extention_avialble = this.sharedData.getAttribute("is_extention_avialble");
    this.browser_type = this.sharedData.getAttribute("browser_type");

    if (
      this.browser_type == "firefox" ||
      this.browser_type == "opera" ||
      this.browser_type == "safari"
    ) {
      this.show = false;
    } else {
      this.show = true;
      if (this.browser_type == "edge") {
        this.site =
          "https://microsoftedge.microsoft.com/addons/detail/flymsg-text-expansion-to/mmgiiedikndnbnjnhpgjdgoacimlhdkn";
      }
    }
  }

  ngOnInit(): void {
    this.checkExtension();
  }

  ngOnChanges(): void {
    this.is_extention_avialble = this.sharedData.getAttribute("is_extention_avialble");
    this.browser_type = this.sharedData.getAttribute("browser_type");
    this.checkExtension();
  }

  async checkExtension() {
    this.display = !(await this.extensionService.isExtensionInstalled());
    this.cdr.detectChanges();
    if (this.display) {
      this.extensionService.isExtensionSignedIn();
    }
  }

  stopBannerHandler(val) {
    console.log("val", val);
    if (val) {
      this.display = false;
    }
  }
}
