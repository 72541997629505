//Arranged in order of priority

export type Role = 'Vengreso Admin' | 'Global Admin' | 'Group Admin' | 'Reporting Admin' | 'User';

export const ROLES: Role[] = [
    'Vengreso Admin',
    'Global Admin',
    'Group Admin',
    'Reporting Admin',
    'User'
];
