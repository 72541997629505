import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { NgbPopoverConfig } from "@ng-bootstrap/ng-bootstrap";
import { Location } from "@angular/common";
import { Router } from "@angular/router";
import { distinctUntilChanged } from "rxjs/operators";

import { ApplicationConstants } from "src/app/core/core-services/constants/application.constants";
import {
  PlansConstants,
  PlansRestrictions,
  RewardsConstants,
  getPlanId,
  getRewards,
} from "src/app/core/core-services/constants/plans.constants";
import { DataService } from "src/app/core/core-services/services/dataservices/data.service";
import { DialogService } from "src/app/core/core-services/services/dialog.service";
import { ExtensionService } from "src/app/core/core-services/services/extension.service";
import { NotificationService } from "src/app/core/core-services/services/notification.service";
import { ShortcutService } from "src/app/core/core-services/services/shortcut.service";
import { WarningDialogComponent } from "src/app/core/core-services/warning-dialog/warning-dialog.component";
import { NotifyService } from "src/app/core/menu/not.service";
import { CategoryRenameComponent } from "../add-shortcut-category/category-rename/category-rename.component";
import {
  ShortcutCategoryService,
  SHORTCUT_CATEGORIES,
} from "src/app/core/core-services/services/shortcut-category.service";
import { SharedDataService } from "src/app/core/core-services/services/shared-data.service";
import { DynamicStorage } from "src/app/core/core-services/decorators/dynamic-storage";
import { TourService } from "ngx-ui-tour-md-menu";
import { GlobalHookService } from "src/app/global-hook.service";

@Component({
  selector: "app-add-shortcut",
  templateUrl: "./add-shortcut.component.html",
  styleUrls: ["./add-shortcut.component.scss"],
})
export class AddShortcutComponent implements OnInit {
  public form: FormGroup;
  @DynamicStorage(SHORTCUT_CATEGORIES) categoryList: any[];
  filteredCategoryList: any;
  exactMatch: boolean = false;
  editorConfig: any;
  editorApiKey: string;
  editorManager: any;
  isEditorLoaded: boolean;
  shortcutErrors: any[];
  filterCtrl = new FormControl();
  @ViewChild("selectElem") selectElem: any;

  //list of the restricted feature to starter plan
  Starter = PlansRestrictions.Starter;
  //list of the restricted feature to free plan
  Free = PlansRestrictions.Free;

  shortcutIdToBeEdited;
  step: string;

  constructor(
    private readonly fb: FormBuilder,
    config: NgbPopoverConfig,
    public notifyService: NotifyService,
    private readonly dataService: DataService,
    private readonly shortcutService: ShortcutService,
    private readonly notification: NotificationService,
    private readonly extensionService: ExtensionService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddShortcutComponent>,
    public dialog: DialogService,
    private readonly cdr: ChangeDetectorRef,
    private readonly router: Router,
    private readonly location: Location,
    private readonly sharedData: SharedDataService,
    private readonly shortcutCategoryService: ShortcutCategoryService,
    private readonly tourService: TourService,
    private readonly globalHookService: GlobalHookService,
  ) {
    this.tourService.initialize([
      {
        anchorId: "step2",
        content:
          "Organize your messages in categories and subcategories. Select one category.",
        title: "Select Category",
        enableBackdrop: true,
        disablePageScrolling: true,
        backdropConfig: {
          zIndex: '1100',
        },
      },
      {
        anchorId: "step3",
        content:
          " A best practice is to use a dash (-) or forward slash (/) in front of any word or acronym. Example: “-sig” or “/sig”. If you don't have a shortcut in mind, just use -test1",
        title: "Assign a FlyCut (aka shortcut)",
        enableBackdrop: true,
        backdropConfig: {
          zIndex: '1100',
        }
      },
      {
        anchorId: "step4",
        content:
          "Click on the Save button to add the shortcut to your library.",
        title: "Describe your FlyCut",
        enableBackdrop: true,
        backdropConfig: {
          zIndex: '1100',
        },
      },
      {
        anchorId: "step5",
        content:
          "This content will be automatically deployed  into any text box when type your FlyCut anywhere online.",
        title: "Begin typing...",
        enableBackdrop: true,
        backdropConfig: {
          zIndex: '1100',
        },
      },
      {
        anchorId: "step6",
        content: "Click the Save button to complete creating your FlyCut.",
        title: "Congrats!",
        enableBackdrop: true,
        backdropConfig: {
          zIndex: '1100',
        },
      },
    ]);

    this.tourService.stepShow$.subscribe((step) => {
      const popover = document.querySelector('.mat-menu-panel.tour-step')?.parentElement;

      if (popover) {
        popover.classList.remove('step5-onboarding-popover-arrow');
        popover.classList.remove('step6-onboarding-popover-arrow');
        
        setTimeout(() => {
          function setFmsHeapAttribute(step: string) {
            const popover = document.querySelector('.mat-menu-panel.tour-step')?.parentElement;
            
            if (!popover) {
              return;
            }
            
            const prevButton = popover.querySelector('.mat-button-base.prev');
            const nextButton = popover.querySelector('.mat-button-base.next');

            if (prevButton) {
              prevButton.setAttribute('data-fms-heap', `walk-me-modal-add-flycut-step-${step}-prev`);
            }
  
            if (nextButton) {
              nextButton.setAttribute('data-fms-heap', `walk-me-modal-add-flycut-step-${step}-next`);
            }
          }
          
          switch(step.anchorId) {
            case 'step2':
              setFmsHeapAttribute('2');
              break;
            case 'step3':
              setFmsHeapAttribute('3');
              break;
            case 'step4':
              setFmsHeapAttribute('4');
              break;
            case 'step5':
              setFmsHeapAttribute('5');
              break;
            case 'step6':
              setFmsHeapAttribute('6');
              break;
          }
        }, 200);
      }
      
      const overlays = document.querySelectorAll('.ngx-ui-tour_backdrop');
      const muiOverlay = document.querySelector('.cdk-overlay-container');
      //move overlays to the end of muiOverlay
      overlays.forEach((overlay: HTMLElement) => {
        overlay.style.pointerEvents= 'all';
        muiOverlay.appendChild(overlay);
      });

      this.step = step.anchorId;

      switch(step.anchorId) {
        case 'step6':
          document.body.classList.add('add-flycut-tour-last-step');
          setTimeout(() => {
            const popover = document.querySelector('.mat-menu-panel.tour-step')?.parentElement;
    
            if (popover) {
              popover.classList.add('step6-onboarding-popover-arrow');
            }
          }, 200);
          break;
        case 'step2':
        case 'step3':
        case 'step4':
        case 'step5':
          document.body.classList.remove('add-flycut-tour-last-step');
          setTimeout(() => {
            const popover = document.querySelector('.mat-menu-panel.tour-step')?.parentElement;
            
            if (popover) {
              popover.classList.add('step5-onboarding-popover-arrow');
            }
          }, 200);
          break;
      }

    });

    this.tourService.end$.subscribe(() => {
      document.body.classList.remove('add-flycut-tour-last-step');
    });

    if (this.isOnboarding) {
      setTimeout(() => {
        this.tourService.start();
      }, 500);
    }
    config.triggers = "hover";
    config.container = "body";
  }

  get isOnboarding() {
    return this.globalHookService.onboardingActive;
  }

  ngOnInit(): void {
    this.editorApiKey = ApplicationConstants.TINYMCE_API_KEY;
    this.editorConfig = this.shortcutService.getEditorConfig();
    this.editorConfig.init_instance_callback = (editor) => {
      editor.on("keyup", (e) => {
        this.isValidateShortcut();
      });
    };

    this.form = this.fb.group({
      title: [null, [Validators.required]],
      shortcut: [null, [Validators.required]],
      category_id: [null, [Validators.required]],
      uploads: [[]],
      html: [null, [Validators.required]],
    });

    if (this.data?.action === "edit") {
      this.prefillEditShortcutForm(this.data.shortcut);
      this.shortcutIdToBeEdited = this.data.shortcut.id;
    }

    this.filterCtrl.valueChanges.subscribe(() => {
      this.filterCategories();
    });

    this.getCategories();

    this.sharedData.storage
      .observe(SHORTCUT_CATEGORIES)
      .pipe(distinctUntilChanged((prev, curr) => prev.value === curr.value))
      .subscribe((value) => {
        this.categoryList = value;
        this.setCategoryData();
      });

    this.setCategoryData();

    if (this.data?.action !== "edit") {
      this.location.replaceState("flyboard/add-new-flycut");
    }

    if (this.dialogRef.componentInstance.data.shortcutData) {
      const hasData =
        Object.keys(this.dialogRef.componentInstance.data.shortcutData).length >
        0; // Check for empty object

      if (hasData) {
        const title =
          this.dialogRef.componentInstance.data.shortcutData.title || "";
        const shortcut =
          this.dialogRef.componentInstance.data.shortcutData.flyCut || "";
        const html =
          this.dialogRef.componentInstance.data.shortcutData.flyMsg || "";

        this.form.patchValue({
          title,
          shortcut,
          html,
        });
      } else {
        // Handle the case where shortcutData is an empty object (optional)
        // console.warn('Shortcut data is empty!');
      }
    } else {
      // Handle the case where shortcutData is null or undefined (optional)
      // console.warn('Shortcut data is missing!');
    }
  }

  prefillEditShortcutForm(shortcut) {
    this.form.patchValue({
      title: shortcut.title,
      shortcut: shortcut.shortcut,
      category_id: shortcut.category_id,
      uploads: shortcut.uploads,
      html: shortcut.html,
    });
  }

  isValidateShortcut() {
    let shortcut = this.form.value;
    shortcut.text = this.shortcutService.getInlineStyledHTML(shortcut.html);

    this.shortcutErrors = [];
    let planId = getPlanId();
    if (planId == "Growth") return true;
    if (planId == "Pro Teams - ENT" || planId == "Pro Teams - SMB") {
      planId = "Growth"; //This is easier than refactoring all the check for planId==Growth to give pro features
      return true;
    }

    let result = true;

    let tempDataHtml = shortcut.html;

    shortcut.html = shortcut.html.replaceAll("font-size: 10pt;", "");
    shortcut.html = shortcut.html.replaceAll("font-size: 11pt;", "");
    shortcut.html = shortcut.html.replaceAll("font-size: 12pt;", "");

    shortcut.html = shortcut.html.replaceAll("font-family: calibri;", "");
    shortcut.html = shortcut.html.replaceAll(
      "font-family: arial, helvetica, sans-serif;",
      ""
    );

    if (
      this[planId]?.includes("FontSize") &&
      shortcut.html.includes("font-size: ")
    ) {
      this.shortcutErrors.push(
        "Exceeds font size limit. Use 10pt, 11pt, 12pt font size or upgrade plan. "
      );
      result = false;
    }

    if (this[planId]?.includes("italic") && shortcut.html.includes("<em>")) {
      this.shortcutErrors.push(
        "Exceeds text editor limit. To use italics feature, upgrade your plan. "
      );
      result = false;
    }

    if (
      this[planId]?.includes("underline") &&
      shortcut.html.includes("text-decoration: underline;")
    ) {
      this.shortcutErrors.push(
        "Exceeds text editor limit. To use underline feature, upgrade your plan. "
      );
      result = false;
    }

    if (
      this[planId]?.includes("strikethrough") &&
      shortcut.html.includes("text-decoration: underline;")
    ) {
      this.shortcutErrors.push(
        "Exceeds text editor limit. To use strikethrough feature, upgrade your plan. "
      );
      result = false;
    }

    if (
      this[planId]?.includes("InsertUnorderedList") &&
      shortcut.html.includes("</ul>")
    ) {
      this.shortcutErrors.push(
        "Exceeds text editor limit. To use bullet points, upgrade your plan. "
      );
      result = false;
    }

    if (
      this[planId]?.includes("InsertOrderedList") &&
      shortcut.html.includes("</ol>")
    ) {
      this.shortcutErrors.push(
        "Exceeds text editor limit. To use numbered list, upgrade your plan. "
      );
      result = false;
    }

    if (
      this[planId]?.includes("hyperlinks") &&
      shortcut.html.includes("</a>")
    ) {
      this.shortcutErrors.push(
        "Exceeds text editor limit. To use hyperlinks, upgrade your plan. "
      );
      result = false;
    }

    if (
      this[planId]?.includes("mceApplyTextcolor") &&
      shortcut.html.includes("background-color:")
    ) {
      this.shortcutErrors.push(
        "Exceeds text editor limit. To change background color, upgrade your plan. "
      );
      result = false;
    }

    if (
      this[planId]?.includes("mceApplyTextcolor") &&
      shortcut.html.includes("color:")
    ) {
      this.shortcutErrors.push(
        "Exceeds text editor limit. To change font color, upgrade your plan. "
      );
      result = false;
    }

    if (
      shortcut.html.includes("</pre>") ||
      shortcut.html.includes("</p>") ||
      shortcut.html.includes("</h1>") ||
      shortcut.html.includes("</h2>") ||
      shortcut.html.includes("</h3>") ||
      shortcut.html.includes("</h4>") ||
      shortcut.html.includes("</h5>") ||
      shortcut.html.includes("</h6>")
    ) {
      this.shortcutErrors.push(
        "Exceeds text editor limit. For block styling, upgrade your plan. "
      );
      result = false;
    }

    if (
      this[planId]?.includes("JustifyCenter") &&
      shortcut.html.includes("text-align: center;")
    ) {
      this.shortcutErrors.push(
        "Exceeds text editor limit. To use centered-alignment, upgrade your plan. "
      );
      result = false;
    }

    if (
      this[planId]?.includes("JustifyRight") &&
      shortcut.html.includes("text-align: right;")
    ) {
      this.shortcutErrors.push(
        "Exceeds text editor limit. To use right-alignment, upgrade your plan. "
      );
      result = false;
    }

    if (
      this[planId]?.includes("indent") &&
      shortcut.html.includes("padding-left:")
    ) {
      this.shortcutErrors.push(
        "Exceeds text editor limit. To increase indent, upgrade your plan. "
      );
      result = false;
    }

    if (
      this[planId]?.includes("outdent") &&
      shortcut.html.includes("padding-right:")
    ) {
      this.shortcutErrors.push(
        "Exceeds text editor limit. To decrease indent, upgrade your plan. "
      );
      result = false;
    }

    if (
      this[planId]?.includes("FontName") &&
      shortcut.html.includes("font-family:")
    ) {
      this.shortcutErrors.push(
        "Exceeds text editor limit. Use Arial, Calibri or Sans Serif font type or upgrade your plan. "
      );
      result = false;
    }

    let rewards = getRewards();
    if (
      !rewards.length &&
      !rewards.includes(RewardsConstants.UNLIMITED_CHARACTERS)
    ) {
      let length = shortcut.html
        .replaceAll("&nbsp;", " ")
        .replace(/<\/?[^>]+(>|$)/g, "").length;
      if (planId === "Free" && length >= PlansConstants.FREE.NUM_OF_CHAR) {
        this.shortcutErrors.push(
          "Exceeds 2,500 character limit. Reduce characters or upgrade plan. "
        );
        result = false;
      }
      if (
        planId === "Starter" &&
        length >= PlansConstants.STARTER.NUM_OF_CHAR
      ) {
        this.shortcutErrors.push(
          "Exceeds 25,000 character limit. Reduce characters or upgrade plan. "
        );
        result = false;
      }
    }
    this.cdr.detectChanges();

    shortcut.html = tempDataHtml;

    return result;
  }

  flycutExistsSearch(value: any): any {
    this.dataService
      .flycutExistsSearch({ q: value })
      .subscribe((response: any) => {
        if (response && response.result) {
          if (response.result.data.exists) {
            this.form.controls.shortcut.setErrors({ notUnique: true });
          }
        }
      });
  }

  filterCategories() {
    this.exactMatch = false;
    let search = this.filterCtrl.value;
    search = search.toLowerCase();
    if (!search) {
      this.filteredCategoryList = [...this.categoryList];
      return;
    }

    let filteredCategoryList = [];
    for (const o of this.categoryList) {
      let catSearch = o.name.toLowerCase().indexOf(search) > -1;
      if (catSearch) {
        this.exactMatch = search === o.name.toLowerCase() ? true : false;
        filteredCategoryList.push({ ...o, sub_category_lv1: [] });
        continue;
      }
      for (const sub_category_lv1 of o.sub_category_lv1) {
        let catSearch =
          sub_category_lv1.name.toLowerCase().indexOf(search) > -1;
        if (catSearch) {
          this.exactMatch =
            search === sub_category_lv1.name.toLowerCase() ? true : false;
          filteredCategoryList.push({
            ...sub_category_lv1,
            sub_category_lv1: [],
            name: `${o.name} / ${sub_category_lv1.name}`,
          });
          continue;
        }
        for (const sub_category_lv2 of sub_category_lv1.sub_category_lv2) {
          let catSearch =
            sub_category_lv2.name.toLowerCase().indexOf(search) > -1;
          if (catSearch) {
            this.exactMatch =
              search === sub_category_lv2.name.toLowerCase() ? true : false;
            filteredCategoryList.push({
              ...sub_category_lv2,
              sub_category_lv1: [],
              name: `${o.name} / ${sub_category_lv1.name} / ${sub_category_lv2.name}`,
            });
            continue;
          }
        }
      }
    }
    this.filteredCategoryList = filteredCategoryList;
  }

  saveCategory(data) {
    this.dataService.post({ url: "v1/user/shortcut-category", data }).subscribe(
      (response: any) => {
        if (response && response.result) {
          this.extensionService.sendUpdateMessage();
          this.getCategories(true);
          this.data.self.categoryId = response.result;
          // close the dropdown
          this.selectElem.close();
          this.notification.toast("Category added successfully!");
        }
      },
      () => {}
    );
  }

  shortcutCategoryRename(data: any) {
    this.dialog.openDialogComponent(CategoryRenameComponent, {
      self: this,
      data,
    });
  }

  setEditorManager(event: any) {
    this.isEditorLoaded = true;
    this.editorManager = event.editor.editorManager;
  }

  getCategories(reloadFromApi = false) {
    this.shortcutCategoryService.loadShortcutCategory({ reloadFromApi });
  }

  setCategoryData(): void {
    this.filteredCategoryList = [...this.categoryList];

    let predefinedCategoryId: string;
    if (this.data.self?.lv2CategoryId) {
      predefinedCategoryId = this.data.self?.lv2CategoryId;
    } else if (this.data.self?.lv1CategoryId) {
      predefinedCategoryId = this.data.self?.lv1CategoryId;
    } else if (this.data.self?.categoryId) {
      predefinedCategoryId = this.data.self?.categoryId;
    }

    this.form.patchValue({
      category_id:
        this.data.self.categoryId && this.data.self.categoryId !== "all"
          ? predefinedCategoryId
          : this.categoryList[0].id,
    });
  }

  getCategoryType(id: string) {
    for (const item of this.categoryList) {
      if (item.id == id) {
        return "category";
      }
      if (item.sub_category_lv1) {
        for (const subLv1 of item.sub_category_lv1) {
          if (subLv1.id == id) {
            return "sub_categories_lv1";
          }
          if (subLv1.sub_category_lv2) {
            for (const subLv2 of subLv1.sub_category_lv2) {
              if (subLv2.id == id) {
                return "sub_categories_lv2";
              }
            }
          }
        }
      }
    }
  }

  saveShortcut(data: any, editorId: string) {
    let planId = getPlanId();

    const editor = this.editorManager.get(editorId);
    this.shortcutService.processMedia(
      editor,
      (uploads: string[]) => {
        const shortcut: any = this.dataService.toSave(data, [
          "title",
          "shortcut",
          "category_id",
        ]);
        shortcut.uploads = uploads;
        shortcut.html = editor.getContent();
        shortcut.text = this.shortcutService.getInlineStyledHTML(shortcut.html);
        // Clean text and remove html Tags.
        let leng = shortcut.text
          .replaceAll("&nbsp;", " ")
          .replace(/<\/?[^>]+(>|$)/g, "").length;

        shortcut.first_line = this.shortcutService.getFirstLine(editor);
        shortcut.type = this.getCategoryType(data.category_id);

        let rewards = getRewards();
        if (
          !rewards.length &&
          !rewards.includes(RewardsConstants.UNLIMITED_CHARACTERS)
        ) {
          if (planId === "Free" && leng >= PlansConstants.FREE.NUM_OF_CHAR) {
            this.dialog.openDialogComponent(
              WarningDialogComponent,
              {
                self: this,
                type: "numOfChar",
                showTheStarter: planId === "Free",
                error: "maxCharFree",
              },
              "800px"
            );
            return;
          }
          if (
            planId === "Starter" &&
            leng >= PlansConstants.STARTER.NUM_OF_CHAR
          ) {
            this.dialog.openDialogComponent(
              WarningDialogComponent,
              {
                self: this,
                type: "numOfChar",
                showTheStarter: false,
                error: "maxCharStarter",
              },
              "800px"
            );
            return;
          }
        }

        if (this.data?.action === "edit") {
          this.updateShortcut(shortcut);
        } else {
          this.createShortcut(shortcut);
        }
      },
      data?.uploads
    );
  }

  createShortcut(shortcut) {
    this.dataService
      .post({ url: "v1/user/shortcut", data: shortcut })
      .subscribe((response: any) => {
        if (response?.result) {
          this.extensionService.sendUpdateMessage();
          // this.notification.toast("Shortcut added successfully!");
          this.close({ success: true, result: response });
          if (this.data.isDashboard) {
            this.data.self.getShortcutList();
          }
          //navigate to category page
          if (!this.globalHookService.onboardingActive) {
            this.router.navigate([`/shortcuts/${shortcut.category_id}`]);
          }
        }
        this.tourService.end();
      });
  }

  updateShortcut(shortcut) {
    this.dataService
      .put({
        url: "v1/user/shortcut/" + this.shortcutIdToBeEdited,
        data: shortcut,
        isLoader: false
      })
      .subscribe(
        (response: any) => {
          if (response) {
            this.extensionService.sendUpdateMessage();
            this.notification.toast("Shortcut updated successfully!");
            let updatedshortcut = {
              ...this.data.shortcut,
              title: shortcut.title,
              shortcut: shortcut.shortcut,
              category_id: shortcut.category_id,
              uploads: shortcut.uploads,
              html: shortcut.html,
              text: this.shortcutService.getInlineStyledHTML(shortcut.html),
              first_line: shortcut.first_line,
            };
            this.dialogRef.close({
              success: true,
              action: "edit",
              result: updatedshortcut,
            });
            this.tourService.end();
          }
        },
        (err) => {
          console.log(err);
          if (err.status == 403) {
          }
        }
      );
  }

  close(data?: any): void {
    this.notifyService.notifyAboutChange();
    this.dialogRef.close(data);
    if (this.isOnboarding) {
      this.globalHookService.components.global.startModal(2);
    }
    this.tourService.end();
  }

  ngOnDestroy(): void {
    if (this.data?.action === "edit") {
      return;
    } else {
      if (this.data?.parentPage === "flyboard") {
        this.location.replaceState(`flyboard`);
        return;
      } else if (this.data?.parentPage?.includes("shortcuts")) {
        this.location.replaceState(`${this.data?.parentPage}`);
        return;
      }
      this.location.replaceState(`flyboard`);
    }
  }
}
