import { Component, Inject, OnDestroy, OnInit, Optional, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { Observable, Subscription, lastValueFrom, of } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
  switchMap,
} from "rxjs/operators";
import { DataService } from "src/app/core/core-services/services/dataservices/data.service";
import { AdvancedSearchDialogComponent } from "../advanced-search-dialog/advanced-search-dialog.component";
import { VideoContentComponent } from "./../../../dashboard/video-content/video-content.component";

import { Location } from "@angular/common";
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { MatDatepicker } from "@angular/material/datepicker";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationExtras,
  Router,
} from "@angular/router";
import { DialogService } from "src/app/core/core-services/services/dialog.service";
import { AuthService } from "../../core-services/services/auth.service";
import { SharedDataService } from "../../core-services/services/shared-data.service";
import { GlobalHookService } from "src/app/global-hook.service";
import { TourService } from "ngx-ui-tour-md-menu";
import { MenuService } from "../../menu/menu.service";
@Component({
  selector: "app-top-search-bar",
  templateUrl: "./top-search-bar.component.html",
  styleUrls: ["./top-search-bar.component.scss"],
})
export class TopSearchBarComponent implements OnInit, OnDestroy {
  public isLoading = false;
  public src: string;
  public myControl = new FormControl();
  filteredOptions: Observable<any>;
  advancedSearch = false;
  currentDialog: any;
  flycut: string;
  title: string;
  hasTheWords: string;
  doesntHave: string;
  createdWithin: string;
  minDate: any;
  maxDate: any;
  bySearch: string;
  hasAttachment: boolean;
  hasHyperlink: boolean;
  date: string;
  createdWithinPicker: MatDatepicker<any>;
  show: any;
  progressValue = 0;
  // Initialized to today
  model: any = { jsdate: new Date() };
  progresshow = false;

  currentUrl: string;
  private readonly subscriptions: Subscription[] = [];

  constructor(
    private readonly dataService: DataService,
    private readonly dialog: MatDialog,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly dialogService: DialogService,
    private readonly authService: AuthService,
    private readonly sharedData: SharedDataService,
    private readonly globalHookService: GlobalHookService,
    private readonly location: Location,
    private readonly tourService: TourService,
    private readonly menuService: MenuService,
    @Optional() @Inject(MAT_DIALOG_DATA) public mydata: any
  ) {
    let user = this.authService.userDetails;
    let onboarding_percentage = this.sharedData.getAttribute("onboarding_percentage");
    this.progressValue =
      onboarding_percentage || user.user_details?.onboarding_percentage || 0;

      //PROGRESS BAR SHOULD BE SHOWED FOR ALL USERS
      this.progresshow = true

      this.subscriptions.push(
        router.events.subscribe((val) => {
          // reset search query
          if (
            val instanceof NavigationEnd &&
            val.url != "/advanced-search-result" &&
            val.url == val.urlAfterRedirects
          ) {
            this.myControl.setValue("");
          }
        })
      );
    
  }
  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;

  get onboardingv2_completed() {
    return this.authService.userDetails.user_details?.onboardingv2_completed || false;
  } 

  async ngOnInit(): Promise<void> {
    this.checkIfIsOnboardingProgressTrainingUrl()
    this.isLoading = true;
    this.filteredOptions = this.myControl.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((val) => {
        return this.search(val);
      }),
      finalize(() => (this.isLoading = false))
    );

    this.globalHookService.registerComponent('topBar', this);
  }

  clicked() {
    this.advancedSearch = false;
    this.isLoading = false;
    this.autocomplete.openPanel();
  }
  search(value: any): any {
    if (!value) {
      return of([]);
    }

    this.isLoading = false;
    return this.dataService
      .searchTrack({ q: value })
      .pipe(map((tracks) => tracks.result.data));
  }

  displayFn(value: any): string {
    return value ? value.name : undefined;
  }

  toggleAdvanced() {
    this.advancedSearch = true;
    this.openDialog();
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  openDialog() {
    this.autocomplete.closePanel();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.height = "auto";
    dialogConfig.width = "35%";

    const isSideMenuOpened = this.sharedData.getAttribute("sidePanelOpened")
    
    dialogConfig.position = {
      left: isSideMenuOpened == 'true' ? "240px" : "63px",
      top: "3.5%",
    };
    dialogConfig.panelClass = "advanced-search";

    this.currentDialog = this.dialog.open(
      AdvancedSearchDialogComponent,
      dialogConfig
    );

    this.currentDialog.beforeClosed().subscribe((result) => {
      this.advancedSearch = false;
      this.isLoading = false;
      this.autocomplete.openPanel();
    });
  }

  goToAdvancedSearch() {
    // if (!this.myControl.value.id?.trim()) {
    //   return;
    // }

    this.isLoading = true;
    const navigationExtras: NavigationExtras = {
      state: {
        searchValue: this.myControl.value,
      },
    };
    this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
      this.router.navigate(["/advanced-search-result"], navigationExtras);
    });
  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.goToAdvancedSearch();
    }
  }

  clickOption() {
    this.autocomplete.closePanel();
    this.goToAdvancedSearch();
  }

  showOnboarding() {
    this.globalHookService.components.global.startModal();
  }

  gotToOnboarding() {
    if (this.router.url !== '/flyboard?showonboardingprogresstraining=true') {
      this.router.navigate(["/flyboard"], { queryParams: { showonboardingprogresstraining: true } });
    } else {
      this.showOnboarding();
    }
  }

  showLearningResourcesTooltip() {
    const user = this.authService.userDetails.user_details;

    if (user.tooltip_learning_resources_showed) {
      return;
    }
    
    this.tourService.initialize([
      {
        anchorId: 'step15',
        content: 'Click here to resume your onboarding anytime. There are more learning videos to help you get more useful information about all the FlyMSG features.',
        title: 'Learning Resources',
        nextBtnTitle: 'Got it!',
      },
      {
        anchorId: 'stepFake',
        content: 'This is a fake step',
      }
    ]);
    
    this.tourService.stepShow$.subscribe((step) => {
      if (step.anchorId === 'stepFake') {
        document.body.classList.add('tour-last-step');
        const user = this.authService.userDetails.user_details;
        lastValueFrom(this.authService.patchOnboarding(user.id, user.onboardingv2_step, user.onboardingv2_step, true, false));
        this.tourService.end();
      } else {
        setTimeout(() => {
          const popover = document.querySelector('.mat-menu-panel.tour-step')?.parentElement;

          if (popover) {
            popover.classList.add('step15-onboarding-popover-arrow');
          }
        }, 500);
      }
    });

    this.tourService.end$.subscribe(() => {
      document.body.classList.remove('tour-last-step');
    });

    setTimeout(() => {
      this.tourService.start();
    }, 500);
  }

  get maxStep() {
    return this.showFlyLearning ? 7 : 6;
  }

  get showFlyLearning() {
    let planIdType = this.sharedData.getAttribute("planIdType") ?? this.sharedData.getAttribute("planId");

    return !!planIdType && ["sales-pro-yearly", "sales-pro-monthly", "pro-plan-teams-smb", "pro-plan-teams-ent"].includes(planIdType);
  }

  checkIfIsOnboardingProgressTrainingUrl(){
    if(this.router.url === '/onboardingprogress_training'){
      this.showOnboarding();
      return;
    }
   
    this.subscriptions.push(
      this.route.queryParams.subscribe(params => {
        if(params?.showonboardingprogresstraining === 'true'){
          this.showOnboarding();
        }
      })
    );
    
  }

  afterModalIsClosed<T>(modal: MatDialogRef<T>) {
    modal.afterClosed().subscribe({
      next: (data) => {
        if(data?.action === 'retake_onboarding'){
          return
        }
        if(this.currentUrl === '/flyboard?showonboardingprogresstraining=true'){
          this.location.replaceState('flyboard');
          return
        }
        this.location.replaceState(this.currentUrl || 'flyboard');
      },
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

}
