import { Injectable, OnInit } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, first, map } from "rxjs/operators";
import { Store } from "@ngrx/store";

import {
  PlanStatus,
  SubscriptionPlan,
  SubscriptionPlanType,
} from "../../models/stripe.models";
import { DataService } from "./dataservices/data.service";
import { environment } from "src/environments/environment";
import { NotificationService } from "src/app/core/core-services/services/notification.service";
import { SharedDataService } from "./shared-data.service";
import { NotifyService } from "../../menu/not.service";
import { CookieService } from 'ngx-cookie-service';
import { loadUserCurrentSubscriptionApi } from "../../store/plan/plan.actions";
import { PlanState } from "../../store/plan/plan.reducer";
import { cloneDeep } from "lodash";
import { ExtensionService } from "./extension.service";
import { MasqueradeUserResponse } from "src/app/admin/services/users/users-dto";

@Injectable({
  providedIn: "root",
})
export class StripeService implements OnInit {
  // TODO: store stripe secret here?  Is this necessary?
  private _stripe_secret = "";
  get stripe_secret(): string {
    return this._stripe_secret;
  }
  public stripe = Stripe(environment.STRIPE_KEY);

  constructor(
    private readonly dataService: DataService,
    private readonly extensionService: ExtensionService,
    private readonly notification: NotificationService,
    private readonly sharedData: SharedDataService,
    private readonly notifyService: NotifyService,
    private readonly cookieService: CookieService,
    private readonly store: Store<PlanState>
  ) {}

  ngOnInit(): void {}

  /**
   * Returns observable containing client_secret from payment intent
   * @returns {Observable<string>}
   */
  genPaymentIntent(): Observable<string> {
    return this.dataService
      .get({ url: "v1/subscriptions/payments/intent" })
      .pipe(
        map((response: any) => {
          return response.result.client_secret as string;
        })
      );
  }

  getCurrentPlan(): Observable<SubscriptionPlan> {
    return this.dataService.get({ url: "v1/subscriptions/plans/current" }).pipe(
      map((response: any) => {
        const result = response.result;
        if (result) {
          let identifier = result.identifier;
          
          if (result.identifier == "appsumo-growth-lifetime") {
            identifier = "growth-yearly";
            this.storePlanInformation("planIdType", 'Lifetime')
          }
          if (result.identifier == "dealfuel-growth-lifetime") {
            identifier = "growth-yearly";
            this.storePlanInformation("planIdType", 'dealfuel-growth-lifetime');
          }
          if (result.identifier == "sales-pro-yearly") {
            identifier = "growth-yearly";
            this.storePlanInformation("planIdType", 'sales-pro-yearly');
          }
          if (result.identifier == "sales-pro-monthly") {
            identifier = "growth"; 
            this.storePlanInformation("planIdType", 'sales-pro-monthly');
          }
          if (result.identifier == "pro-plan-teams-smb") {
            identifier = "pro-plan-teams-smb";
            this.storePlanInformation("planIdType", 'pro-plan-teams-smb');
          }
          if (result.identifier == "pro-plan-teams-ent") {
            identifier = "pro-plan-teams-ent";
            this.storePlanInformation("planIdType", 'pro-plan-teams-ent');
          }
          this.storePlanInformation("planId", identifier);
          if (["freemium","starter","growth"].includes(result.identifier)) {
            this.sharedData.setAttribute("planIdType", result.identifier);
          }
          this.sharedData.setAttribute("planId", identifier);
          this.sharedData.setAttribute("usedTrial", result?.used_trial);
          this.sharedData.setAttribute("onTrial", result?.on_trial);

          if (result?.referral_key) {
            let user = this.userDetails;
            user.user_details.referral_key = result?.referral_key;
            this.saveUserDetail(user);
          }

          let previousUserDetail = this.userDetails;
          let { user_details } = response.result;
          if (user_details) {
            let updatedUserDetail = {
              ...previousUserDetail,
              user_details,
            };

            this.saveUserDetail(updatedUserDetail);
            this.extensionService.updateUserDetails(updatedUserDetail);
            this.notifyService.notifyAboutChange();
          }
        
          return {
            stripe_id: result?.stripe_id,
            identifier: identifier,
            title: result.title,
            ends_at: result?.ends_at,
            plan_status: result?.plan_status,
            on_trial: result.on_trial,
            referrals: result?.referrals,
            statistics: result?.statistics
          };
        }
      })
    );
  }

  getDefaultPaymentMethod(loadedReturn: boolean = false): Observable<string | any> {
    return this.dataService
      .get({ url: "v1/subscriptions/payment/methods/default" })
      .pipe(
        map(
          (response: any) => {
            const result = response.result;

            if (!result || !result.id) {
              return "";
            }

            if (loadedReturn) return result;

            return result.id as string;
          }
        )
      );
  }

  setDefaultPaymentMethod(paymentMethodId: string): Observable<boolean> {
    const url = "v1/subscriptions/payment/methods/default";
    const data = {
      payment_method_id: paymentMethodId,
    };
    return this.dataService.post({ url, data, isLoader: false }).pipe(
      map(
        (response: any) => {
          const result = response.result;
          return result ? true : false;
        },
        (error) => {
          return false;
        }
      )
    );
  }

  subToPlan(
    paymentMethodId: string,
    plan: SubscriptionPlanType,
    discountCode?: string
  ): Observable<boolean> {
    const url = "v1/subscriptions/payments";
    let planm = plan.toLowerCase();
    if (planm == "salespro") {
      planm = "sales-pro-monthly";
    }
    let data: any = {
      plan: planm,
      payment_method_id: paymentMethodId
    };

    if(discountCode) {
      data.discount_code = discountCode
    }
    return this.dataService.post({ url, data, isLoader: false }).pipe(
      map(
        (response: any) => {
          const result = response.result;

          if(result) {
            this.store.dispatch(loadUserCurrentSubscriptionApi({ refetchFromApi: true }));
            this.sharedData.setAttribute("planId", plan);
            this.sharedData.setAttribute("planIdType", result.data.identifier);
            this.notifyService.notifyAboutChange({
              plan: plan,
              planStatus: PlanStatus.Active
            });
            this.sharedData.setAttribute("shownLearningIconIndicator", "false");
          }
          
          return result ? true : false;
        }
      )
    );
  }

  pausePlan(): void {
    const url = "v1/subscriptions/cancel";
    this.dataService
      .get({ url })
      .pipe(first())
      .subscribe((subCancelled: any) => {
        this.notification.toast(subCancelled.result.message);
      });
  }

  resumePlan(): void {
    const url = "v1/subscriptions/resume";
    this.dataService
      .get({ url })
      .pipe(first())
      .subscribe((subResumed: any) => {
        this.notification.toast(subResumed.result.message);
      });
  }

  retrieveCouponObject(couponCode: string, planIdentifier: string): any {
    return this.dataService
      .post({ url: "v1/subscriptions/coupon", data: { coupon: couponCode, plan_identifier: planIdentifier } })
  }

  startSalesProTrial() {
      return this.dataService
        .post({ url: "v1/subscriptions/trial", isLoader: true })
        .pipe(
          map((response: any) => {
            this.notification.toast('14-day Professional Plan Trial Successfully Added', 'Ok');
            this.storePlanInformation("planId", SubscriptionPlanType.SalesPro);
            this.notifyService.notifyAboutChange();
            this.sharedData.setAttribute("planId", SubscriptionPlanType.SalesPro);
            this.sharedData.setAttribute("planIdType", "sales-pro-monthly");
            this.notifyService.notifyAboutChange({
              plan: SubscriptionPlanType.SalesPro,
              planStatus: PlanStatus.Trial
            });
          }),
          catchError((error: any) => {
            // This catchError handles errors that occurred before the map.
            console.error("An error occurred:", error);
            return throwError(() => error); // You can re-throw the error or handle it here.
          })
      );
  }

  removePlanInformation() {
    this.sharedData.deleteAttribute("planId");
    this.sharedData.deleteAttribute("planIdType");
    this.cookieService.delete("flymsg_planId", "/", undefined, true, "None");
    this.cookieService.delete("flymsg_planIdType", "/", undefined, true, "None");
  }

  private storePlanInformation(key: string, value: any) {
    this.sharedData.setAttribute(key, value);
    this.cookieService.set(`flymsg_${key}`, value, {
      domain: environment.FLYMSG_DOMAIN,
      path: '/',
      secure: true,
      sameSite: 'None'
    });
  }

  private get masqueradeUser(): MasqueradeUserResponse {
    return sessionStorage.getItem("fms-cmc-masquerading-user-details")
      ? (JSON.parse(
          sessionStorage.getItem("fms-cmc-masquerading-user-details")
        ) as unknown as MasqueradeUserResponse)
      : null;
  }

  private get accessToken(): string {
    if (this.masqueradeUser) {
      return this.masqueradeUser.access_token;
    }

    return this.sharedData.getAttribute("accessToken");
  }

  private get refreshToken(): string | null {
    if (this.masqueradeUser) {
      return null;
    }

    return this.sharedData.getAttribute("refreshToken");
  }

  private get userDetails() {
    if (this.masqueradeUser) {
      return this.masqueradeUser;
    }

    return this.sharedData.getAttribute("userDetails");
  }
  
  private saveUserDetail(result: any) {
    this.sharedData.setAttribute("userDetails", result);
    this.sharedData.setAttribute("accessToken", result.access_token);
    this.sharedData.setAttribute("refreshToken", result.refresh_token);
    if (this.masqueradeUser) {
      this.sharedData.setAttribute(this.masqueradeUser.user_details.id, result);
    }
    this.store.dispatch(loadUserCurrentSubscriptionApi({}));
  }
}
