import { CommonModule } from "@angular/common";
import { NgModule, APP_INITIALIZER } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { IconSpriteModule } from "ng-svg-icon-sprite";
import { TranslateModule } from "@ngx-translate/core";
import { NgbPopoverModule } from "@ng-bootstrap/ng-bootstrap";

import { MatSelectModule } from "@angular/material/select";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatSnackBarModule } from "@angular/material/snack-bar";

import { AuthGuardService } from "./services/auth-guard.service";
import { CanDeactivateGuard } from "./services/can-deactivate-guard.service";
import { AuthService } from "./services/auth.service";
import { DialogService } from "./services/dialog.service";
import { AppLoadService } from "./services/app-load.service";
import { SharedDataService } from "./services/shared-data.service";
import { NavigationService } from "./services/navigation.service";
import { DataService } from "./services/dataservices/data.service";
import { NotificationService } from "./services/notification.service";
import { ShortcutService } from "./services/shortcut.service";
import { ExtensionService } from "./services/extension.service";
import { ScrollService } from "./services/scroll.service";
import { EventService } from "./services/event.service";
import { ListService } from "./services/list.service";

import { AuthInterceptor } from "./interceptors/auth.interceptor";

import { CommonPipe } from "./filter/common.pipe";

import { ValidationConstants } from "./constants/validation.constants";
import { ApplicationConstants } from "./constants/application.constants";

import { ConfirmDialogComponent } from "./confirm-dialog/confirm-dialog.component";
import { ImportDialogComponent } from "./import-dialog/import-dialog.component";
import { LoadingDialogComponent } from "./loading-dialog/loading-dialog.component";
import { ErrorDialogComponent } from "./services/dataservices/error-dialog/error-dialog.component";
import { DownloadBannerComponent } from "src/app/download-banner/download-banner.component";
import { StripeService } from "./services/stripe.service";
import { TemplateService } from "./services/template.service";
import { WarningDialogComponent } from "./warning-dialog/warning-dialog.component";
import { MatIconModule } from "@angular/material/icon";
import { PinToBrowserComponent } from "src/app/dashboard/pin-to-browser/pin-to-browser.component";
import { GiphyDialogComponent } from "./giphy-dialog/giphy-dialog.component";
import { MatGridListModule } from "@angular/material/grid-list";
import { GridGalleryItemComponent } from "./giphy-dialog/grid-gallery-item/grid-gallery-item.component";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { CheckItemCompletedComponent } from "src/app/dashboard/walk-me/check-item-completed/check-item-completed.component";

// Restore shared data from localstorage before app loads
export function restoreSharedData(appLoadService: AppLoadService) {
  return () => appLoadService.restoreSharedData();
}

@NgModule({
  declarations: [
    CommonPipe,
    ConfirmDialogComponent,
    ImportDialogComponent,
    LoadingDialogComponent,
    ErrorDialogComponent,
    DownloadBannerComponent,
    WarningDialogComponent,
    GiphyDialogComponent,
    GridGalleryItemComponent,
    PinToBrowserComponent,
    CheckItemCompletedComponent
  ],
  providers: [
    AppLoadService,
    {
      provide: APP_INITIALIZER,
      useFactory: restoreSharedData,
      deps: [AppLoadService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    AuthService,
    TemplateService,
    AuthGuardService,
    CanDeactivateGuard,
    NotificationService,
    SharedDataService,
    NavigationService,
    ShortcutService,
    ExtensionService,
    DialogService,
    DataService,
    ScrollService,
    EventService,
    ListService,
    StripeService,
    ApplicationConstants,
    ValidationConstants,
  ],
  entryComponents: [],
  imports: [
    FlexLayoutModule,
    CommonModule,
    MatSnackBarModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatButtonModule,
    IconSpriteModule,
    MatDialogModule,MatGridListModule,
    MatIconModule,
    MatProgressBarModule
  ],
  exports: [
    FlexLayoutModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    IconSpriteModule,
    NgbPopoverModule,
    TranslateModule,
    DownloadBannerComponent,
    MatGridListModule,
    PinToBrowserComponent,
    CheckItemCompletedComponent,
  ],
})
export class CoreModule {}
