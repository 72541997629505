import { FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";

import { AuthService } from "src/app/core/core-services/services/auth.service";
import { DialogService } from "src/app/core/core-services/services/dialog.service";
import { DataService } from "src/app/core/core-services/services/dataservices/data.service";
import { NotificationService } from "src/app/core/core-services/services/notification.service";

import { ConfirmDialogComponent } from "src/app/core/core-services/confirm-dialog/confirm-dialog.component";
import { Location } from "@angular/common";
import { NgbPopoverConfig } from "@ng-bootstrap/ng-bootstrap";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { MatChipInputEvent } from "@angular/material/chips";
import { WarningDialogComponent } from "src/app/core/core-services/warning-dialog/warning-dialog.component";
import { SharedDataService } from "src/app/core/core-services/services/shared-data.service";
import { LoadingService } from "src/app/core/core-services/services/loader.service";

@Component({
  selector: "app-user-persona-add",
  templateUrl: "./user-persona-add.component.html",
  styleUrls: ["./user-persona-add.component.scss"],
})
export class UserPersonaAddComponent implements OnInit {
  @ViewChild("wrapperContainer") wrapperContainer!: ElementRef<HTMLElement>;
  @ViewChild("footerElement") footerElement!: ElementRef<HTMLElement>;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  searchPersona: string;
  profileData: any;
  form: FormGroup;
  updating: boolean = false;

  userPersonaId?: string;

  toneOptions: any[] = [
    { label: "Assertive", id: "67be34e534fdd53681092752" },
    { label: "Casual", id: "67be34e534fdd53681092753" },
  ];

  constructor(
    private readonly fb: FormBuilder,
    private readonly dialog: DialogService,
    private readonly route: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly dataService: DataService,
    private readonly router: Router,
    private readonly location: Location,
    private readonly notification: NotificationService,
    private readonly sharedData: SharedDataService,
    private readonly loadingService: LoadingService,
    config: NgbPopoverConfig
  ) {
    config.container = "body";
    config.placement = ["right"];
    config.autoClose = true;
    config.animation = true;

    this.form = this.fb.group({
      name: [null, [Validators.required]],
      professional_interests: [
        [],
        [Validators.required, Validators.minLength(1)],
      ],
      first_name: [null],
      last_name: [null],
      industry: [null, [Validators.required]],
      prompt_tone_id: [null, [Validators.required]],
      type: [
        "person",
        [Validators.required, Validators.pattern("^(person|company)$")],
      ],
      is_default: [false],
      linkedin_url: [null],
      achievements: [null],
      preferred_keywords_and_phrases: [[]],
      communication_style: [[]],
      company_name: [null],
      education_background: [[]],
      field_of_expertise: [[]],
      goals: [null],
      hobbies: [[]],
      personal_interests: [[]],
      skills: [[]],
      taboos: [[]],
      title: [null],
      content: [null, [Validators.maxLength(1000)]],
      networking: [null, [Validators.maxLength(1000)]],
      personal_info: [null, [Validators.maxLength(1000)]],
      ai_emulation: [
        { value: null, disabled: true },
        [Validators.maxLength(1000)],
      ],
    });

    this.form
      .get("type")
      ?.valueChanges.subscribe((value) => this.adjustValidations(value));
  }

  private adjustValidations(type: string): void {
    const firstNameControl = this.form.get("first_name");
    const lastNameControl = this.form.get("last_name");
    const companyNameControl = this.form.get("company_name");

    if (type === "person") {
      firstNameControl?.setValidators([Validators.required]);
      lastNameControl?.setValidators([Validators.required]);
      companyNameControl?.clearValidators();
    } else if (type === "company") {
      firstNameControl?.clearValidators();
      lastNameControl?.clearValidators();
      companyNameControl?.setValidators([Validators.required]);
    }

    firstNameControl?.updateValueAndValidity();
    lastNameControl?.updateValueAndValidity();
    companyNameControl?.updateValueAndValidity();
  }

  loadData(personaId: string, duplicate: boolean): void {
      this.loadingService.start();
      this.dataService
        .get({
          url: `v2/user-persona/details/${personaId}`,
          isLoader: true,
        })
        .subscribe(
          (response: any) => {
            if (response?.result) {
              if (response.result.disabled) {
                this.router.navigate(["/persona"]);
                return;
              }

              this.form.patchValue({
                ...response.result,
                name: duplicate ? `${response.result.name} (Copy)` : response.result.name,
              });
              this.professionalInterests =
                response.result.professional_interests ?? [];
              this.fieldOfExpertise = response.result.field_of_expertise ?? [];
              this.skills = response.result.skills ?? [];
              this.preferredPhrases =
                response.result.preferred_keywords_and_phrases ?? [];
              this.taboos = response.result.taboos ?? [];
              this.personalInterests = response.result.personal_interests ?? [];
              this.hobbies = response.result.hobbies ?? [];
              this.educationBackground =
                response.result.education_background ?? [];
              this.communicationStyle =
                response.result.communication_style ?? [];

              if (!duplicate) {
                this.form.markAsUntouched();
              }
            }
          },
          () => {
            this.loadingService.stop();
          },
          () => {
            this.loadingService.stop();
          }
        );
  }

  ngOnInit(): void {
    this.dataService
      .get({ url: "v2/prompt-tones/", isLoader: false })
      .subscribe((response: any) => {
        if (response?.result) {
          this.toneOptions = response.result.data.map((item) => ({
            label: item.name,
            id: item.id,
          }));
        }
      });

    // if route name (not param) contains duplicate
    const duplicate = this.route.snapshot.url.some((url) => url.path === "duplicate");
    let userPersonaId = this.route.snapshot.paramMap.get("userPersonaId");

    if (userPersonaId && !duplicate) {
      this.userPersonaId = userPersonaId;
      this.loadData(userPersonaId, false);
    } else {
      try {
        this.dataService
          .get({ url: "v2/user-persona/quota", isLoader: false })
          .subscribe(
            (response: any) => {
              if (response?.result) {
                if (response?.result.remaining < 1) {
                  switch (response?.result.quota) {
                    case 1:
                      this.dialog.openDialogComponent(
                        WarningDialogComponent,
                        {
                          type: "persona",
                          showTheStarter: false,
                          error: "maxNumberOfPersonasFree",
                        },
                        "800px"
                      );
                      break;
                    case 3:
                      this.dialog.openDialogComponent(
                        WarningDialogComponent,
                        {
                          type: "persona",
                          showTheStarter: false,
                          pricing: "salesPro",
                          selectedPlan: "salesPro",
                          error: "maxNumberOfPersonasGrowth",
                        },
                        "800px"
                      );
                      break;
                    case 7:
                      this.notification.toast(
                        "You've hit the maximum number of 7 personas for your current plan. Update or delete any existent persona.",
                        "OK",
                        7000
                      );
                      break;
                  }

                  this.router.navigate(["/persona"]);
                } else if (duplicate && userPersonaId) {
                    this.loadData(userPersonaId, true);
                }
              }
            },
            (error) => {},
            () => {}
          );
      } catch (error) {
        console.log(error);
      }
    }
  }

  get isProLicense() {
    let planIdType =
      this.sharedData.getAttribute("planIdType") ??
      this.sharedData.getAttribute("planId");

    return (
      !!planIdType &&
      [
        "sales-pro-yearly",
        "sales-pro-monthly",
        "pro-plan-teams-smb",
        "pro-plan-teams-ent",
      ].includes(planIdType)
    );
  }

  generateAIEmulation(regenerate: boolean = false): void {
    if (regenerate) {
      if (!this.isProLicense) {
        this.dialog.openDialogComponent(
          WarningDialogComponent,
          {
            type: "persona",
            showTheStarter: false,
            pricing: "salesPro",
            selectedPlan: "salesPro",
            error: "maxNumberOfPersonas",
          },
          "800px"
        );
  
        return;
      }

      if (this.form.untouched) {
        this.notification.toast(
          "To regenerate you must change something in any of the fields above.",
          "OK",
          7000
        );
        return;
      }
    }

    const data = this.form.value;
    this.updating = true;

    this.dataService
      .post({ url: "v2/user-persona/generate-persona", isLoader: false, data: { ...data, regenerate: regenerate && this.form.untouched }})
      .subscribe(
        (response: any) => {
          if (response?.result) {
            this.form.controls.ai_emulation.setValue(response.result);

            this.form.markAsUntouched();

            this.notification.toast(
              "AI Emulation generated successfully.",
              "OK",
              7000
            );

            // smooth scroll to the bottom wrapperContainer
            setTimeout(() => {
              this.footerElement.nativeElement.scrollIntoView({
                behavior: "smooth",
              });
            }, 200);
          }
        },
        (error) => {
          this.updating = false;
        },
        () => {
          this.updating = false;
        }
      );
  }

  markAsUntouched(): void {
    setTimeout(() => {
      this.form.markAsUntouched();
    }, 200);
  }

  goBack(): void {
    this.router.navigate(["/persona"]);
  }

  copyToClipboard(): void {
    if (!this.isProLicense) {
      this.dialog.openDialogComponent(
        WarningDialogComponent,
        {
          type: "persona",
          showTheStarter: false,
          pricing: "salesPro",
          selectedPlan: "salesPro",
          error: "maxNumberOfPersonas",
        },
        "800px"
      );

      return;
    }
    
    if (navigator.clipboard) {
      navigator.clipboard.writeText(this.form.controls['ai_emulation']?.value).then(() => {
        this.notification.toast("AI Emulation copied to clipboard!", "Ok", 5000);
      });
    }
  }

  savePersona(): void {
    this.form.controls.ai_emulation.enable();
    const data = this.form.value;
    this.form.controls.ai_emulation.disable();
    this.updating = true;

    if (this.userPersonaId) {
      this.dataService
        .put({
          url: `v2/user-persona/update/${this.userPersonaId}`,
          isLoader: true,
          data,
        })
        .subscribe(
          (response: any) => {
            if (response?.result) {
              this.notification.toast("Persona updated", "OK", 7000);
              this.router.navigate(["/persona"]);
            }
          },
          (error) => {
            this.updating = false;
          },
          () => {
            this.updating = false;
          }
        );
    } else {
      this.dataService
        .post({ url: "v2/user-persona", isLoader: true, data })
        .subscribe(
          (response: any) => {
            if (response?.result) {
              this.notification.toast("Persona added", "OK", 7000);
              this.router.navigate(["/persona"]);
            }
          },
          (error) => {
            this.updating = false;
          },
          () => {
            this.updating = false;
          }
        );
    }
  }

  //#region CHIP LIST FUNCTIONS

  //#region Professional Interests
  @ViewChild("professionalInterestsInput")
  professionalInterestsInput!: ElementRef<HTMLElement>;
  professionalInterests: string[] = [];
  async blur(event): Promise<void> {
    const value = (event.target.value || "").trim();
    if (!value) {
      return;
    }

    this.professionalInterests.push(value);

    event.target.value = "";

    this.form.controls.professional_interests.setValue(
      this.professionalInterests
    );
    this.form.markAsTouched();
  }

  async add(event: MatChipInputEvent): Promise<void> {
    const value = (event.value || "").trim();

    this.professionalInterests.push(value);

    if (event.chipInput) {
      event.chipInput.clear();
    }

    this.form.controls.professional_interests.setValue(
      this.professionalInterests
    );
    this.form.markAsTouched();

    setTimeout(() => {
      const element = this.professionalInterestsInput.nativeElement;
      element.scrollLeft = element.scrollWidth;
    }, 1);
  }

  remove(item: string): void {
    const index = this.professionalInterests.indexOf(item);

    if (index >= 0) {
      this.professionalInterests.splice(index, 1);
      this.form.controls.professional_interests.setValue(
        this.professionalInterests
      );
      this.form.markAsTouched();
    }
  }
  //#endregion

  //#region Field Of Expertise
  @ViewChild("fieldOfExpertiseInput")
  fieldOfExpertiseInput!: ElementRef<HTMLElement>;
  fieldOfExpertise: string[] = [];
  async blurFieldOfExpertise(event): Promise<void> {
    const value = (event.target.value || "").trim();
    if (!value) {
      return;
    }

    this.fieldOfExpertise.push(value);

    event.target.value = "";

    this.form.controls.field_of_expertise.setValue(this.fieldOfExpertise);
    this.form.markAsTouched();
  }

  async addFieldOfExpertise(event: MatChipInputEvent): Promise<void> {
    const value = (event.value || "").trim();

    this.fieldOfExpertise.push(value);

    if (event.chipInput) {
      event.chipInput.clear();
    }

    this.form.controls.field_of_expertise.setValue(this.fieldOfExpertise);
    this.form.markAsTouched();

    setTimeout(() => {
      const element = this.fieldOfExpertiseInput.nativeElement;
      element.scrollLeft = element.scrollWidth;
    }, 1);
  }

  removeFieldOfExpertise(item: string): void {
    const index = this.fieldOfExpertise.indexOf(item);

    if (index >= 0) {
      this.fieldOfExpertise.splice(index, 1);
      this.form.controls.field_of_expertise.setValue(this.fieldOfExpertise);
      this.form.markAsTouched();
    }
  }
  //#endregion

  //#region Skills
  @ViewChild("skillsInput") skillsInput!: ElementRef<HTMLElement>;
  skills: string[] = [];
  async addSkills(event: MatChipInputEvent): Promise<void> {
    const value = (event.value || "").trim();

    this.skills.push(value);

    if (event.chipInput) {
      event.chipInput.clear();
    }

    this.form.controls.skills.setValue(this.skills);
    this.form.markAsTouched();

    setTimeout(() => {
      const element = this.skillsInput.nativeElement;
      element.scrollLeft = element.scrollWidth;
    }, 1);
  }

  async blurSkills(event): Promise<void> {
    const value = (event.target.value || "").trim();
    if (!value) {
      return;
    }

    this.skills.push(value);

    event.target.value = "";

    this.form.controls.skills.setValue(this.skills);
    this.form.markAsTouched();
  }

  removeSkills(item: string): void {
    const index = this.skills.indexOf(item);

    if (index >= 0) {
      this.skills.splice(index, 1);
      this.form.controls.skills.setValue(this.skills);
      this.form.markAsTouched();
    }
  }
  //#endregion

  //#region Preferred Phrases
  @ViewChild("preferredPhrasesInput")
  preferredPhrasesInput!: ElementRef<HTMLElement>;
  preferredPhrases: string[] = [];
  async addPreferredPhrases(event: MatChipInputEvent): Promise<void> {
    const value = (event.value || "").trim();

    this.preferredPhrases.push(value);

    if (event.chipInput) {
      event.chipInput.clear();
    }

    this.form.controls.preferred_keywords_and_phrases.setValue(
      this.preferredPhrases
    );
    this.form.markAsTouched();

    setTimeout(() => {
      const element = this.preferredPhrasesInput.nativeElement;
      element.scrollLeft = element.scrollWidth;
    }, 1);
  }

  async blurPreferredPhrases(event): Promise<void> {
    const value = (event.target.value || "").trim();
    if (!value) {
      return;
    }

    this.preferredPhrases.push(value);

    event.target.value = "";

    this.form.controls.preferred_keywords_and_phrases.setValue(
      this.preferredPhrases
    );
    this.form.markAsTouched();
  }

  removePreferredPhrases(item: string): void {
    const index = this.preferredPhrases.indexOf(item);

    if (index >= 0) {
      this.preferredPhrases.splice(index, 1);
      this.form.controls.preferred_keywords_and_phrases.setValue(
        this.preferredPhrases
      );
      this.form.markAsTouched();
    }
  }
  //#endregion

  //#region Taboos
  @ViewChild("taboosInput") taboosInput!: ElementRef<HTMLElement>;
  taboos: string[] = [];
  async addTaboos(event: MatChipInputEvent): Promise<void> {
    const value = (event.value || "").trim();

    this.taboos.push(value);

    if (event.chipInput) {
      event.chipInput.clear();
    }

    this.form.controls.taboos.setValue(this.taboos);
    this.form.markAsTouched();

    setTimeout(() => {
      const element = this.taboosInput.nativeElement;
      element.scrollLeft = element.scrollWidth;
    }, 1);
  }

  async blurTaboos(event): Promise<void> {
    const value = (event.target.value || "").trim();
    if (!value) {
      return;
    }

    this.taboos.push(value);

    event.target.value = "";

    this.form.controls.taboos.setValue(this.taboos);
    this.form.markAsTouched();
  }

  removeTaboos(item: string): void {
    const index = this.taboos.indexOf(item);

    if (index >= 0) {
      this.taboos.splice(index, 1);
      this.form.controls.taboos.setValue(this.taboos);
      this.form.markAsTouched();
    }
  }
  //#endregion

  //#region Personal Interests
  @ViewChild("personalInterestsInput")
  personalInterestsInput!: ElementRef<HTMLElement>;
  personalInterests: string[] = [];
  async addPersonalInterests(event: MatChipInputEvent): Promise<void> {
    const value = (event.value || "").trim();

    this.personalInterests.push(value);

    if (event.chipInput) {
      event.chipInput.clear();
    }

    this.form.controls.personal_interests.setValue(this.personalInterests);
    this.form.markAsTouched();

    setTimeout(() => {
      const element = this.personalInterestsInput.nativeElement;
      element.scrollLeft = element.scrollWidth;
    }, 1);
  }

  async blurPersonalInterests(event): Promise<void> {
    const value = (event.target.value || "").trim();
    if (!value) {
      return;
    }

    this.personalInterests.push(value);

    event.target.value = "";

    this.form.controls.personal_interests.setValue(this.personalInterests);
    this.form.markAsTouched();
  }

  removePersonalInterests(item: string): void {
    const index = this.personalInterests.indexOf(item);

    if (index >= 0) {
      this.personalInterests.splice(index, 1);
      this.form.controls.personal_interests.setValue(this.personalInterests);
      this.form.markAsTouched();
    }
  }
  //#endregion

  //#region Hobbies
  @ViewChild("hobbiesInput") hobbiesInput!: ElementRef<HTMLElement>;
  hobbies: string[] = [];
  async addHobbies(event: MatChipInputEvent): Promise<void> {
    const value = (event.value || "").trim();

    this.hobbies.push(value);

    if (event.chipInput) {
      event.chipInput.clear();
    }

    this.form.controls.hobbies.setValue(this.hobbies);
    this.form.markAsTouched();

    setTimeout(() => {
      const element = this.hobbiesInput.nativeElement;
      element.scrollLeft = element.scrollWidth;
    }, 1);
  }

  async blurHobbies(event): Promise<void> {
    const value = (event.target.value || "").trim();
    if (!value) {
      return;
    }

    this.hobbies.push(value);

    event.target.value = "";

    this.form.controls.hobbies.setValue(this.hobbies);
    this.form.markAsTouched();
  }

  removeHobbies(item: string): void {
    const index = this.hobbies.indexOf(item);

    if (index >= 0) {
      this.hobbies.splice(index, 1);
      this.form.controls.hobbies.setValue(this.hobbies);
      this.form.markAsTouched();
    }
  }
  //#endregion

  //#region Education Background
  @ViewChild("educationBackgroundInput")
  educationBackgroundInput!: ElementRef<HTMLElement>;
  educationBackground: string[] = [];
  async addEducationBackground(event: MatChipInputEvent): Promise<void> {
    const value = (event.value || "").trim();

    this.educationBackground.push(value);

    if (event.chipInput) {
      event.chipInput.clear();
    }

    this.form.controls.education_background.setValue(this.educationBackground);
    this.form.markAsTouched();

    setTimeout(() => {
      const element = this.educationBackgroundInput.nativeElement;
      element.scrollLeft = element.scrollWidth;
    }, 1);
  }

  async blurEducationBackground(event): Promise<void> {
    const value = (event.target.value || "").trim();
    if (!value) {
      return;
    }

    this.educationBackground.push(value);

    event.target.value = "";

    this.form.controls.education_background.setValue(this.educationBackground);
    this.form.markAsTouched();
  }

  removeEducationBackground(item: string): void {
    const index = this.educationBackground.indexOf(item);

    if (index >= 0) {
      this.educationBackground.splice(index, 1);
      this.form.controls.education_background.setValue(
        this.educationBackground
      );
      this.form.markAsTouched();
    }
  }
  //#endregion

  //#region Communication Style
  @ViewChild("communicationStyleInput")
  communicationStyleInput!: ElementRef<HTMLElement>;
  communicationStyle: string[] = [];
  async addCommunicationStyle(event: MatChipInputEvent): Promise<void> {
    const value = (event.value || "").trim();

    this.communicationStyle.push(value);

    if (event.chipInput) {
      event.chipInput.clear();
    }

    this.form.controls.communication_style.setValue(this.communicationStyle);
    this.form.markAsTouched();

    setTimeout(() => {
      const element = this.communicationStyleInput.nativeElement;
      element.scrollLeft = element.scrollWidth;
    }, 1);
  }

  async blurCommunicationStyle(event): Promise<void> {
    const value = (event.target.value || "").trim();
    if (!value) {
      return;
    }

    this.communicationStyle.push(value);

    event.target.value = "";

    this.form.controls.communication_style.setValue(this.communicationStyle);
    this.form.markAsTouched();
  }

  removeCommunicationStyle(item: string): void {
    const index = this.communicationStyle.indexOf(item);

    if (index >= 0) {
      this.communicationStyle.splice(index, 1);
      this.form.controls.communication_style.setValue(this.communicationStyle);
      this.form.markAsTouched();
    }
  }
  //#endregion

  //#endregion
}
