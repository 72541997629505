import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService, UserDetails } from '../../core/core-services/services/auth.service';
import { getPlanId } from "src/app/core/core-services/constants/plans.constants";
import { NotifyService } from '../../core/menu/not.service';
import { NotificationService } from '../../core/core-services/services/notification.service';
import { Role } from '../../core/core-services/constants/roles.constant';
import { SharedDataService } from 'src/app/core/core-services/services/shared-data.service';

@Component({
  selector: 'app-account-center',
  templateUrl: './account-center.component.html',
  styleUrls: ['./account-center.component.scss']
})
export class AccountCenterComponent implements OnInit {
  user: UserDetails;
  planId: any;
  mainNav: string = '';
  navItems = [];
  
  constructor (
    public authService: AuthService,
    public sharedData: SharedDataService,
    public notifyService: NotifyService,
    private readonly router: Router,
    private readonly notification: NotificationService
  ) { }

  get userRole(): Role {
    // get top role
    // first 'Vengreso Admin',
    // second 'Global Admin',
    // third 'Group Admin',
    // fourth 'Reporting Admin',
    // fifty 'User'
    const roles = this.authService.getUserRole();

    return roles.sort((a, b) => {
      if (a === 'Vengreso Admin') return -1;
      if (b === 'Vengreso Admin') return 1;
      if (a === 'Global Admin') return -1;
      if (b === 'Global Admin') return 1;
      if (a === 'Group Admin') return -1;
      if (b === 'Group Admin') return 1;
      if (a === 'Reporting Admin') return -1;
      if (b === 'Reporting Admin') return 1;
      return 0;
    })[0];
  }

  ngOnInit(): void {
    this.user = this.authService.userDetails.user_details;
    this.planId = getPlanId();

    const roles = this.authService.getUserRole();

    if (roles.some(role => ["Vengreso Admin", "Global Admin", "Reporting Admin", "Group Admin"].includes(role))) {
      this.setCustomerAdminNav();
    } else {
      this.goToDashboard();
    }
  }

  get companyMasqueradeMode(): boolean {
    return this.authService.getUserRole().includes('Vengreso Admin') && !!this.authService.onCompanyMasqueradeSession;
  }

  get masqueradeCompanyName(): string {
    return this.authService.onCompanyMasqueradeSession ? this.authService.getMasqueradeCompanyName : '';
  }

  setCustomerAdminNav(){
    this.mainNav = 'Account Center';
    const companySlug = this.authService.getUserCompanySlug();
    this.navItems = [
      { label: 'Users', link: `/${companySlug}/admin/users` },
      { label: 'Groups', link: `/${companySlug}/admin/groups` },
      { label: 'Reporting', link: `/${companySlug}/admin/reporting` }
    ];
  }

  copyToClipboard(email: string): void {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(email).then(() => {
        this.notification.toast("Email copied to clipboard!", "Ok", 2000);
      });
    }
  }

  goToDashboard(): void {
    this.router.navigate(["/flyboard"]);
  }

  goToSettings(): void {
    this.router.navigate(["/settings"], {
      queryParams: { tab: "subscription" },
    });
  }

  goToPlayList(): void {
    window.open("https://www.youtube.com/@Vengreso/playlists", "_blank");
  }

  navigateToExtension() {
    let site =
      "https://chrome.google.com/webstore/detail/fly-msg-for-chrome/giidlnpcdhcldhfccdhkaicefhpokghc?hl=en&authuser=0";
    if (this.sharedData.getAttribute("browser_type") == "edge") {
      site =
        "https://microsoftedge.microsoft.com/addons/detail/flymsg-text-expansion-to/mmgiiedikndnbnjnhpgjdgoacimlhdkn";
    }
    window.open(site, "_blank");
  }
}
