import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Location } from "@angular/common";
import { MatDialogRef } from "@angular/material/dialog";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import {
  AbstractControl,
  FormControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { MatChipInputEvent } from "@angular/material/chips";

import { ValidationConstants } from "src/app/core/core-services/constants/validation.constants";
import { NotificationService } from "src/app/core/core-services/services/notification.service";
import { lastValueFrom } from "rxjs";
import { Router } from "@angular/router";
import { AdminPortalApiService } from "../../services/admin-portal-api.service";
import { SuperAdminApiService } from "../../services/super-admin-api.service";

@Component({
  selector: "app-vengreso-add-user-by-email",
  templateUrl: "./add-user-by-email.component.html",
  styleUrls: ["./add-user-by-email.component.scss"],
})
export class VengresoAddUserByEmailComponent implements OnInit {
  separatorKeysCodes: number[] = [ENTER, COMMA];
  emails: any[] = [];
  invalidEmail: boolean = false;
  emailValidation: ValidatorFn[] = [
    Validators.pattern(ValidationConstants.EMAIL_EXTRA_REGEX),
    Validators.minLength(5),
    Validators.email,
    Validators.required,
    this.createUniqueEmailValidator(this.emails),
  ];
  emailCtrl: FormControl = new FormControl("", {
    validators: this.emailValidation,
  });

  @ViewChild("emailInput", { static: true })
  emailInput: ElementRef<HTMLInputElement>;

  constructor(
    public dialogRef: MatDialogRef<VengresoAddUserByEmailComponent>,
    private readonly adminPortalApiService: AdminPortalApiService,
    private readonly superAdminApiService: SuperAdminApiService,
    private readonly notification: NotificationService,
    private readonly router: Router,
    private readonly location: Location
  ) {}

  ngOnInit(): void {
    let currentUrl = this.router.url;
    if (!currentUrl.includes("add-by-email")) {
      this.location.replaceState(currentUrl + "/add-by-email");
    }

    this.emailInput.nativeElement.addEventListener(
      "paste",
      this.onPaste.bind(this)
    );
  }

  onAdd() {
    this.superAdminApiService
      .addUserByEmail(this.emails.map((email) => email.address))
      .subscribe((response: any) => {
        if (response?.result?.success === true) {
          this.notification.toastWithConfig("Users added", null, {
            verticalPosition: "top",
            horizontalPosition: "center",
            panelClass: ["green-notification"],
          });
          this.dialogRef.close(response.result.users);
        }
      });
  }

  async onPaste(event: ClipboardEvent): Promise<void> {
    event.preventDefault();

    const clipboardData = event.clipboardData;
    const pastedText = clipboardData?.getData("text");

    if (pastedText) {
      const pastedEmails = pastedText
        .split("\n")
        .join(",")
        .split(",")
        .map((email) => email.trim())
        .filter(
          (email) =>
            this.isValidEmail(email) &&
            this.emails.findIndex((e) => e.address === email) === -1
        );

      const response: any = await lastValueFrom(
        this.adminPortalApiService.validateEmailUniqueness({
          emails: pastedEmails,
        })
      );

      if (response?.result?.exists === true) {
        this.invalidEmail = true;
        this.notification.toast(
          "Please enter a valid or unique email addresses.",
          "OK"
        );
        return;
      }

      this.invalidEmail = false;
      pastedEmails.forEach((value: string) => {
        this.emails.push({ address: value, isSelected: false });
      });

      this.emailCtrl.setValue(null);
    }

    this.emailCtrl.setValue(null);
  }

  async add(event: MatChipInputEvent): Promise<void> {
    const value = (event.value || "").trim();

    this.emailCtrl.setValue(value);

    if (this.emailCtrl.errors !== null) {
      return;
    }

    const response: any = await lastValueFrom(
      this.adminPortalApiService.validateEmailUniqueness({
        emails: [value],
      })
    );

    if (response?.result?.exists === true) {
      this.invalidEmail = true;
      this.notification.toast(
        "Please enter a valid or unique email address.",
        "OK"
      );
      return;
    } else {
      this.invalidEmail = false;
    }

    // Add email
    this.emails.push({ address: value, isSelected: false });

    // Clear the input value
    event.chipInput.clear();
    this.emailCtrl.setValue(null);
  }

  isValidEmail(email: string): boolean {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  }

  remove(email: string): void {
    const index = this.emails.indexOf(email);

    if (index >= 0) {
      this.emails.splice(index, 1);
    }
  }

  createUniqueEmailValidator(emailArray: any[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value) {
        return null;
      }

      const hasEmail = emailArray.some((obj) => obj.address === value);

      return hasEmail ? { uniqueEmail: true } : null;
    };
  }

  get invalidEmails(): boolean {
    if (!this.emailCtrl.value?.trim()) {
      return false;
    }

    const emails =
      this.emailCtrl.touched || this.emailCtrl.dirty
        ? this.emails?.concat({ address: this.emailCtrl.value })
        : this.emails;

    return emails.some(
      (email) =>
        new FormControl(email?.address?.toLowerCase(), this.emailValidation)
          .invalid
    );
  }

  ngOnDestroy(): void {
    let currentUrl = this.router.url;

    this.location.replaceState(currentUrl.replace("/add-by-email", ""));
  }

  onCancel() {
    let currentUrl = this.router.url;
    this.location.replaceState(currentUrl.replace("/add-by-email", ""));
    this.dialogRef.close();
  }
}
