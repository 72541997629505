import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { cloneDeep, forEach } from "lodash";
import { catchError, map, takeUntil } from "rxjs/operators";
import { Observable, Subject, throwError } from "rxjs";
import { Store } from "@ngrx/store";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { detect } from "detect-browser";

import { environment } from "src/environments/environment";
import { getPlanId } from "src/app/core/core-services/constants/plans.constants";
import { DialogService } from "src/app/core/core-services/services/dialog.service";
import { SharedDataService } from "src/app/core/core-services/services/shared-data.service";
import { WarningDialogComponent } from "../../warning-dialog/warning-dialog.component";
import { ExtensionService } from "../extension.service";
import { LoadingService } from "../loader.service";
import { NotificationService } from "./../notification.service";
import { ErrorDialogComponent } from "./error-dialog/error-dialog.component";
import { PlanState } from "src/app/core/store/plan/plan.reducer";
import { loadUserCurrentSubscriptionApi } from "src/app/core/store/plan/plan.actions";
import { SignInErrorComponent } from 'src/app/session/signin/sign-in-error/sign-in-error.component';

class Info {
  url: any;
  data?: any;
  isLoader?: any;
  loaderName?: string;
  httpType?: string;
  file?: File
  ignoreErrors?: boolean;
}

@Injectable()
export class DataService {
  downloadComplete$ = new Subject<string>();

  constructor(
    private readonly http: HttpClient,
    public dialog: DialogService,
    private readonly loadingService: LoadingService,
    private readonly notification: NotificationService,
    private readonly sharedData: SharedDataService,
    private readonly router: Router,
    private readonly dialogRef: MatDialog,
    private readonly extensionService: ExtensionService,
    private readonly store: Store<PlanState>
  ) { }

  startLoader(info: Info) {
    // Start loader before API call
    if (info.isLoader !== false) {
      this.loadingService.start(info.loaderName, "DataService");
    }
  }

  stopLoader(info: Info) {
    // Reset the loader
    if (info.isLoader !== false) {
      this.loadingService.stop(info.loaderName, "DataService");
    }
  }

  post<T = Response>(info: Info): Observable<T> {
    this.startLoader(info);
    return this.http.post(this.apiURL + info.url, info.data, {
      headers: {
        browser: detect()?.name
      }
    }).pipe(
      map((res: Response) => {
        info.httpType = 'post';
        return this.extractData(true, res, info);
      }),
      catchError((err: Response) => {
        return this.handleError(err, info);
      })
    );
  }

  patch<T = Response>(info: Info): Observable<T> {
    this.startLoader(info);
    return this.http.patch(this.apiURL + info.url, info.data, {
      headers: {
        browser: detect()?.name
      }
    }).pipe(
      map((res: Response) => {
        info.httpType = 'patch';
        return this.extractData(true, res, info);
      }),
      catchError((err: Response) => {
        return this.handleError(err, info);
      })
    );
  }

  postFormData(info: Info) {
    try {
      let browser = detect();
      let browser_type;
      if (
        browser &&
        (browser?.name == "edge" || browser?.name == "edge-chromium")
      ) {
        browser_type = "edge";
      } else {
        browser_type = browser?.name;
      }

      const url = this.apiURL + info.url;
      const jsonData = {
        ...info.data?? {},
        browser: browser_type,
      };

      let body = `browser=${browser_type}`;
      Object.keys(jsonData).forEach((key) => {
        body += `&${key}=${jsonData[key]}`;
      });

      return this.http.post(url, body);
    } catch (err) {
      console.log(err)
      return
    }
  }

  postExternal(info: Info): Observable<Response> {
    this.startLoader(info);

    return this.http.post(info.url, info.data).pipe(
      map((res: Response) => {
        return this.extractData(true, res, info);
      }),
      catchError((err: Response) => {
        this.stopLoader(info);
        return throwError(err);
      })
    );
  }

  getExternal(info: Info): Observable<Response> {
    this.startLoader(info);

    return this.http
      .get(info.url, { observe: "response" as "body" })
      .pipe(
        map((res: Response) => {
          // Refresh on Update
          return this.extractData(false, res.body, info);
        }),
        catchError((err: Response) => {
          this.stopLoader(info);
          return throwError(err);
        })
      );
  }

  put(info: Info): Observable<Response> {
    this.startLoader(info);

    return this.http.put(this.apiURL + info.url, info.data).pipe(
      map((res: Response) => {
        info.httpType = 'put';
        return this.extractData(true, res, info);
      }),
      catchError((err: Response) => {
        return this.handleError(err, info);
      })
    );
  }

  get<T>(info: Info): Observable<T> {
    // this.startLoader(info);

    return this.http
      .get(this.apiURL + info.url, { params: info.data, observe: "response" as "body" })
      .pipe(
        map((res: Response) => {
          // Refresh on Update
          this.trackVersionUpdate(res.headers.get("FlyMSG-FrontEnd-Version"));
          return this.extractData(false, res.body, info);
        }),
        catchError((err: Response) => {
          if (info.url.includes("clicked_settings")) {
            return;
          }
          return this.handleError(err, info);
        })
      );
  }

  download(info: Info, filename: string) {
    this.startLoader(info);
    this.http.get(this.apiURL + info.url, { responseType: 'blob' }).subscribe({
      next: (blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url); // Clean up
        this.stopLoader(info);
        this.downloadComplete$.next(filename);
      },
      error: (error) => {
        this.stopLoader(info);
        this.handleError(error, info);
      }
    });
  }

  uploadFile(info: Info, cancelUpload: Subject<void>): Observable<number | HttpResponse<any>> {
    this.startLoader(info);
    const formData: FormData = new FormData();
    formData.append('csv_file', info.file);

    return this.http.post(this.apiURL + info.url, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      takeUntil(cancelUpload),
      map(event => {
        this.stopLoader(info);
        if (event.type === HttpEventType.UploadProgress) {
          return Math.round((100 * event.loaded) / (event.total ?? 1));
        } else if (event instanceof HttpResponse) {
          return event;
        }
        return 0;
      }),
      catchError((err: Response) => {
        this.stopLoader(info);
        return this.handleError(err, info);
      })
     );
  }

  delete(info: Info): Observable<Response> {
    this.startLoader(info);

    return this.http
      .delete(this.apiURL + info.url, { params: info.data })
      .pipe(
        map((res: Response) => {
          info.httpType = 'delete';
          return this.extractData(true, res, info);
        }),
        catchError((err: Response) => {
          return this.handleError(err, info);
        })
      );
  }

  extractData(bool, res: any, info: Info) {
    // Complete the loader as valid response is recieved
    if (bool) {
      this.stopLoader(info);
    }

    this.updatePlanStore(info?.httpType, info.url);

    return res;
  }

  handleError(errorResponse: Response | any, info: Info) {
    this.stopLoader(info);

    if (!info.ignoreErrors) {
      if (errorResponse?.status == 403 && errorResponse?.error?.code == "DEACTIVATED") {
        setTimeout(() => {
          const dialogHandle = this.dialog.openDialogComponent(
            SignInErrorComponent,
            { 
              admin_email: errorResponse?.error?.admin_email,
            },
            "500px"
          );
      
          dialogHandle.afterClosed().subscribe(() => {
            this.dialogRef.closeAll();
            this.extensionService.sendLogoutMessage();
            this.sharedData.clear();
            setTimeout(() => {
              this.router.navigate(["/session/signin"])
            }, 1000);
          });
        }, 2000);
      } else {
        if (errorResponse.status && errorResponse.status >= 500) {
          this.notification.toast(
            "Technical Error! Please contact administrator.",
            "OK",
            10000
          );
          
          if (environment.production) {
            this.dialogRef.closeAll();
            this.extensionService.sendLogoutMessage();
            this.sharedData.clear();
            setTimeout(() => {
              this.router.navigate(["/session/signin"])
            }, 1000);
          }
        } else if (errorResponse.error) {
          if (errorResponse.status == 403) {
            let planId = getPlanId();

            let type;
            if (
              info.url == "v1/user/shortcut" &&
              errorResponse.error?.error ==
              "You have reached limit, the number of flycuts that can be created with current plan"
            ) {
              type = "numOfFlycuts";
              this.dialog.openDialogComponent(
                WarningDialogComponent,
                {
                  self: this,
                  type,
                  showTheStarter: planId === "Free",
                  error: planId === "Free" ? "maxFree" : "maxStarter",
                },
                "800px"
              );
              return;
            }
            if (
              info.url == "v1/user/import/shortcut" &&
              errorResponse.error?.error ==
              "You have reached limit, the number of flycuts that can be created with current plan"
            ) {
              type = "import";
              this.dialog.openDialogComponent(
                WarningDialogComponent,
                { self: this, type, showTheStarter: planId === "Free" },
                "800px"
              );
              return;
            }
            if (
              errorResponse.error?.error ==
              "You can not add more than 3 font per flycut with current plan"
            ) {
              type = "editorFonts";
              this.dialog.openDialogComponent(
                WarningDialogComponent,
                { self: this, type, showTheStarter: false },
                "800px"
              );
              return;
            }
            if (info.url == "v1/user/shortcut-category") {
              type = "categories";
              let error =
                errorResponse.error.error.indexOf("sub categories") != -1
                  ? "subCategories"
                  : "categories";
              error += planId === "Free" ? "Free" : "Starter";
              this.dialog.openDialogComponent(
                WarningDialogComponent,
                { self: this, type, showTheStarter: planId === "Free", error },
                "800px"
              );
              return;
            }
            if (info.url == "v1/user/shortcut_version/rollback") {
              type =
                planId === "Free" ? "versionHistory" : "versionHistoryForStarter";
              this.dialog.openDialogComponent(
                WarningDialogComponent,
                { self: this, type, showTheStarter: planId === "Free" },
                "800px"
              );
              return;
            }
          }
          if (errorResponse.error.error_list) {
            // Setting all errors in an array for further processing
            let errors: any[] = [];
            forEach(errorResponse.error.error_list, (array, key) => {
              errors = errors.concat(array);
            });
            // console.log('===========errors=========================');
            // console.log(errors);
            // console.log('====================================');
            // Show toast for single error and dialog for multiple
            const errorCount = errors.length;
            if (errorCount === 1) {
              this.notification.toast(errors[0], "OK", 6000);
            } else if (errorCount > 1) {
              // Show popup
              this.dialog.openDialogComponent(
                ErrorDialogComponent,
                { data: errors },
                "500px"
              );
            }
          } else if (errorResponse.error.error) {
            this.notification.toast(
              errorResponse.error.message || errorResponse.error.error,
              "OK",
              6000
            );
          }
          if(errorResponse.status == 400 && 
            errorResponse.error.result &&
            errorResponse.error.result.Response &&
            errorResponse.error.result.Response[0] &&
            errorResponse.error.result.Response[0]['@attributes']['Result'] == "Error"){
              this.notification.toast(
                errorResponse.error.result.Response[0]['@attributes']['ResultDescription'] ?? "Error with authenticating you on FlyLearning",
                "OK",
                6000
              );
          }

          if(errorResponse.status == 400 &&
            errorResponse.error.result &&
            errorResponse.error.result.message) {
              this.notification.toast(
                errorResponse.error.result.message,
                "OK",
                6000
              );
          }
        }
      }
    }

    return throwError(errorResponse);
  }

  toSave(data: {}, fields: string[]) {
    const obj = {};
    forEach(fields, (field: string) => {
      obj[field] = data[field];
    });
    return cloneDeep(obj);
  }

  searchTrack({ q }: SearchModel): Observable<any> {
    return this.http.get(
      `${this.apiURL}v1/user/search-data?query=${q}`
    );
  }

  flycutExistsSearch({ q }: SearchModel): Observable<any> {
    return this.http.get(
      `${this.apiURL}v1/user/flycuts-search-name?query=${q}`
    );
  }

  getCategories(): Observable<any> {
    return this.http.get(
      `${this.apiURL}v1/user/categories-search-data`
    );
  }

  getAdvancedSearchResult(queryParams): Observable<any> {
    return this.http.get(
      `${this.apiURL}v1/user/advanced-search-data${queryParams}`
    );
  }

  trackVersionUpdate(front_version) {
    if (!front_version) {
      return;
    }
    let local_front_version = this.sharedData.getAttribute(
      "FlyMSGـFrontEndـVersion"
    );
    let refresh = false;

    if (!local_front_version) {
      this.sharedData.setAttribute("FlyMSGـFrontEndـVersion", front_version);
    }

    if (local_front_version && front_version != local_front_version) {
      this.sharedData.setAttribute("FlyMSGـFrontEndـVersion", front_version);
      refresh = true;
    }

    //refresh
    if (refresh) {
      location.reload();
    }
  }

  updatePlanStore(requestType: string|null, url:string): void {
    const update =
        (['post', 'put', 'delete'].includes(requestType) &&
                (url.includes('/user/shortcut-category') ||
                url.includes('/user/copy-shortcut/template/single') ||
                url.includes('/user/copy-shortcut/category/single'))) ||
        (['post', 'delete'].includes(requestType) && (url.includes('/user/shortcut') ));

    if (update) {
        this.store.dispatch(loadUserCurrentSubscriptionApi({ refetchFromApi: true }));
    }
  }

  private get apiURL(): string {
    return environment.API_URL;
  }
}

export class SearchModel {
  q: string;
}
