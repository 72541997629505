import { Injectable } from '@angular/core';

import { DataService } from 'src/app/core/core-services/services/dataservices/data.service';

export interface Result<T = any> {
  result: {
    data?: T;
    success: boolean;
    message?: string;
  };
}

export interface ValidateEmailUniqueness {
  emails: string[];
  company_id?: string;
}

@Injectable({
  providedIn: 'root'
})
export class AdminPortalApiService {
  constructor(private readonly dataService: DataService) { }

  private get baseUrl() {
    return 'v1/admin-portal/';
  }

  validateEmailUniqueness(data: ValidateEmailUniqueness) {
    const url = `${this.baseUrl}user/exist`;

    return this.dataService.post({
      url,
      data
    });
  }
}
